import algoliasearch, { SearchClient } from "algoliasearch/lite"

/*-- front end api keys that we do not care whether are exposed or not --*/
const getAlgoliaSearchClient = (): SearchClient => {
  return algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID!,
    process.env.GATSBY_ALGOLIA_API_KEY!
  )
}

export default getAlgoliaSearchClient
