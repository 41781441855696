import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Twitter: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      aria-labelledby="twitter"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M0.200012 19.4331C2.98625 19.5899 5.42269 18.9386 7.79883 17.2137C5.39857 16.8036 3.73407 15.706 2.82945 13.4385C3.52902 13.342 4.14417 13.5229 4.86787 13.2334C2.50379 12.1961 0.971959 10.6402 0.91165 7.95042C1.65947 8.01073 2.22637 8.50526 3.11893 8.43289C0.947841 6.31004 0.284443 3.99421 1.64741 1.18386C3.91499 3.78917 6.56855 5.56222 9.81313 6.39447C9.99405 6.44272 10.1629 6.49097 10.3438 6.52715C11.1761 6.72014 12.1651 7.13023 12.6717 7.06992C13.5402 6.96137 12.6717 5.96026 12.8888 4.6576C13.5764 0.604899 18.0391 -1.24053 21.344 1.13561C22.309 1.83518 23.0688 1.82312 24.0217 1.40097C24.5162 1.18386 25.0228 0.966747 25.6138 0.713453C25.4812 1.87137 24.5886 2.51063 23.877 3.36701C24.6851 3.54793 25.3364 3.31876 26.0842 3.07753C25.8309 3.90978 25.2037 4.38018 24.6368 4.83853C24.0458 5.30893 23.8167 5.80346 23.7925 6.5754C23.4307 18.2631 10.1509 27.2973 1.06846 20.1085C0.187959 19.409 1.04433 20.1085 0.200012 19.4331Z" />
    </svg>
  )
}

export default Twitter
