import React, { useState } from 'react';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import { NavItem } from '../../../interfaces/Navigation.interface';
import DropdownMenu from '../DropdownMenu/DropdownMenu.molecule';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Button from '../../atoms/Button/Button.component';
import { ButtonProps } from '../../../interfaces/Button.interface';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import IconButton from '../../atoms/IconButton/IconButton.component';
import Image from '../../atoms/Image/Image.atom';
import Search from '../../../assets/svgs/Search.svg';
import Hamburger from '../../../assets/svgs/Hamburger.svg';
import ChevronDown from '../../../assets/svgs/ChevronDown.svg';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import SearchBarDropdown from './SearchBarDropdown.component';

interface MainNavProps {
  logo: StoryblokAsset;
  links: NavItem[];
  cta: ButtonProps[];
  is_search_enabled: boolean;
  search_link: StoryblokLink;
  search_bar_placeholder: string;
  search_bar_button_label: string;
  toggleSideNav: (state: boolean) => void;
}

const MainNav: React.FC<MainNavProps> = ({
  logo,
  links,
  cta,
  is_search_enabled,
  search_link,
  search_bar_placeholder,
  search_bar_button_label,
  toggleSideNav,
}) => {
  const [isDropdownSearchBarVisible, setIsDropdownSearchBarVisible] = useState<boolean>(false);

  const handleLogoClickDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      type: 'Header Homepage Button',
    });
  };

  const handleMainNavDataLayerEvent = (label: string, link: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      type: 'Main Nav Menu',
      button: {
        text: label,
        url: link,
      },
    });
  };

  return (
    <nav>
      <div className="flex items-center justify-between">
        {logo.filename && (
          <CustomLink
            url="/"
            className="h-[100px] xl:h-[126px] 3xl:h-[142px] py-2 xl:py-4"
            onClick={() => handleLogoClickDataLayerEvent()}
          >
            <Image image={logo} />
          </CustomLink>
        )}

        <div className="flex items-center">
          {links && !!links.length && (
            <ul className="hidden lg:flex space-x-8 3xl:space-x-12">
              {links.map(
                link =>
                  link.display_name && (
                    <li key={link._uid} className="relative flex items-center group last:mr-0">
                      <CustomLink
                        url={link.page_link.cached_url}
                        className="h-[100px] xl:h-[126px] 3xl:h-[142px] flex justify-center items-center text-sm xl:text-base font-semibold"
                        onClick={() => handleMainNavDataLayerEvent(link.display_name, link.page_link.cached_url)}
                      >
                        <div className="flex items-center border-b-2 border-White group-hover:border-Secondary-300 w-full">
                          {link.display_name && (
                            <span className="pr-2 text-sherpa-blue-900 text-Primary-700 group-hover:text-Primary-900 text-sm 3xl:text-base">
                              {link.display_name}
                            </span>
                          )}
                          {link && !!link.dropdown_list?.length && (
                            <ChevronDown className="text-Secondary-300" rotateOnHover={true} />
                          )}
                        </div>
                      </CustomLink>
                      {link && !!link.dropdown_list?.length && (
                        <DropdownMenu
                          links={link.dropdown_list}
                          liStyles="text-Primary-700 hover:text-Primary-900"
                          ulStyles="bg-White rounded-b-2xl drop-shadow-2xl"
                          dataLayerEventType="Main Nav Item Menu Dropdown"
                          parentLinkLabel={link.display_name}
                        />
                      )}
                    </li>
                  ),
              )}
            </ul>
          )}

          {is_search_enabled && (
            <IconButton
              active={isDropdownSearchBarVisible}
              type={ButtonTypes.SECONDARY}
              icon={<Search className="!text-sherpa-white text-Primary-900" />}
              styles={`hidden lg:flex lg:ml-8`}
              ariaLabel="search"
              onClick={() => setIsDropdownSearchBarVisible(!isDropdownSearchBarVisible)}
            />
          )}

          {cta && !!cta.length && cta[0].label && cta[0].link && (
            <Button
              link={cta[0].link}
              label={cta[0].label}
              type={ButtonTypes.PRIMARY}
              size={ButtonSizeTypes.LG}
              className="lg:ml-6"
            ></Button>
          )}

          {links && !!links.length && (
            <IconButton
              type={ButtonTypes.SECONDARY}
              icon={<Hamburger className={'text-sherpa-white text-Primary-900'} />}
              styles={`ml-6 lg:hidden`}
              onClick={() => toggleSideNav(true)}
              ariaLabel="open navigation"
            />
          )}
        </div>
      </div>
      {isDropdownSearchBarVisible && (
        <SearchBarDropdown
          search_link={search_link}
          search_bar_placeholder={search_bar_placeholder}
          search_bar_button_label={search_bar_button_label}
          closeButtonClicked={() => setIsDropdownSearchBarVisible(false)}
        />
      )}
    </nav>
  );
};

export default MainNav;
