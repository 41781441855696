import React from 'react';
import classNames from 'classnames';
import CustomLink from '../CustomLink/CustomLink.atom';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';

interface ButtonProps {
  link?: StoryblokLink;
  label: string | React.ReactNode;
  type: ButtonTypes;
  styles?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ariaLabel?: string;
  loadingAnimation?: React.ReactNode;
  isAnimating?: boolean;
  onClickStatus?: string | null;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  link,
  label,
  type,
  styles,
  onClick,
  ariaLabel,
  loadingAnimation,
  isAnimating,
  onClickStatus,
  fullWidth = false,
}) => {
  const buttonClasses = classNames(
    `${styles} block rounded-full text-sm xl:text-base font-extrabold text-center uppercase no-underline leading-none transition-all ease-in-out duration-300 focus:ring-0 focus:outline-none`,
    {
      '!text-sherpa-body text-Primary-Button-Text bg-SP3 hover:bg-SP8 px-5 py-3 xl:px-6 xl:py-4':
        type === ButtonTypes.PRIMARY,
      '!text-sherpa-body text-Primary-Button-Text bg-SP3 hover:bg-SP8 px-6 py-4': type === ButtonTypes.SECONDARY,
    },
    {
      'w-full': fullWidth,
      'w-fit': !fullWidth,
    },
  );

  return (
    <>
      {link ? (
        <CustomLink url={link.cached_url} linkType={link.linktype} className={buttonClasses} onClick={onClick}>
          {label}
        </CustomLink>
      ) : (
        <button onClick={onClick} className={buttonClasses} aria-label={ariaLabel}>
          {isAnimating && loadingAnimation}
          <span
            className={`block ${isAnimating ? `opacity-0` : `opacity-100`} ${
              onClickStatus === 'error' && `duration-300 delay-[430ms]`
            }`}
          >
            {label}
          </span>

          {/* {isAnimating ? loadingAnimation : label} */}
        </button>
      )}
    </>
  );
};

export default Button;
