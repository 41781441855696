import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Facebook: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="16"
      height="27"
      viewBox="0 0 16 27"
      aria-labelledby="facebook"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M9.74869 15.5205V26.5529H4.68081V15.5205H0.470581V11.0471H4.68081V9.41958C4.68081 3.37712 7.205 0.199951 12.5458 0.199951C14.1831 0.199951 14.5924 0.463091 15.489 0.677501V5.10214C14.4852 4.92672 14.2026 4.82926 13.1598 4.82926C11.922 4.82926 11.2593 5.18011 10.6551 5.87207C10.0508 6.56403 9.74869 7.76278 9.74869 9.47806V11.0569H15.489L13.9492 15.5303H9.74869V15.5205Z" />
    </svg>
  )
}

export default Facebook
