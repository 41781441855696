import React from 'react';

// Bloks
import AvailabilityChecker from '../../bloks/AvailabilityChecker/AvailabilityChecker.blok';
import Comparison from '../../bloks/Comparison/Comparison.blok';
import Countdown from '../../bloks/Countdown/Countdown.blok';
import CTACardsList from '../../bloks/CTACardsList/CTACardsList.blok';
import FAQs from '../../bloks/FAQs/FAQs.blok';
import FindUsFilterAndResults from '../../bloks/FindUsFilterAndResults/FindUsFilterAndResults.blok';
import FindUsHero from '../../bloks/FindUsHero/FindUsHero.blok';
import Footer from '../../bloks/Footer/Footer.blok';
import Form from '../../bloks/Form/Form.blok';
import FullWidth from '../../bloks/FullWidth/FullWidth.blok';
import Header from '../../bloks/Header/Header.blok';
import HomepageHero from '../../bloks/HomepageHero/HomepageHero.blok';
import ImageAndTitledList from '../../bloks/ImageAndTitledList/ImageAndTitledList.blok';
import ImageGallery from '../../bloks/ImageGallery/ImageGallery.blok';
import LogoGallery from '../../bloks/LogoGallery/LogoGallery.blok';
import Newsletter from '../../bloks/Newsletter/Newsletter.blok';
import PageIntroWithImage from '../../bloks/PageIntros/PageIntroWithImage.blok';
import PageIntroWithList from '../../bloks/PageIntros/PageIntroWithList.blok';
import PageIntroWithText from '../../bloks/PageIntros/PageIntroWithText.blok';
import RelatedArticles from '../../bloks/RelatedArticles/RelatedArticles.blok';
import SecondaryHero from '../../bloks/SecondaryHero/SecondaryHero.blok';
import SectionTitle from '../../bloks/SectionTitle/SectionTitle.blok';
import SectionTitleWithCTA from '../../bloks/SectionTitleWithCTA/SectionTitleWithCTA.blok';
import ServiceList from '../../bloks/ServiceList/ServiceList.blok';
import SimpleCTACardsRows from '../../bloks/SimpleCTACardsRows/SimpleCTACardsRows.blok';
import SingleImageBlok from '../../bloks/SingleImageBlok/SingleImageBlok.blok';
import Statistics from '../../bloks/Statistics/Statistics.blok';
import Testimonials from '../../bloks/Testimonials/Testimonials.blok';
import Upcomingholidays from '../../bloks/UpcomingHolidays/UpcomingHolidays.blok';
import Values from '../../bloks/Values/Values.blok';
import WholeOfChild from '../../bloks/WholeOfChild/WholeOfChild.blok';
import WholeOfChildIntro from '../../bloks/WholeOfChildIntro/WholeOfChildIntro.blok';
import WholeOfChildPillars from '../../bloks/WholeOfChildPillars/WholeOfChildPillars.blok';
import ZigZagContentHeavy from '../../bloks/ZigZagContent/ZigZagContentHeavy.blok';
import ZigZagContentLight from '../../bloks/ZigZagContent/ZigZagContentLight.blok';

// Molecules
import FooterContactDetails from '../../molecules/Footer/FooterContactDetails.molecule';
import FooterList from '../../molecules/Footer/FooterList.molecule';
import SimpleCTACardsRow from '../../molecules/SimpleCTACardsRow/SimpleCTACardsRow.molecule';
import Statistic from '../../molecules/Statistic/Statistic.blok';

// Atoms
import SimpleCTACard from '../../atoms/SimpleCTACard/SimpleCTACard.atom';
import Quote from '../../molecules/Quote/Quote.molecule';
// Atoms
import RichText from '../../atoms/RichText/RichText.atom';
import YoutubeVideo from '../../atoms/YoutubeVideo/YoutubeVideo.atom';
import ArticleImage from '../../bloks/ArticleImage/ArticleImage.blok';
import ArticleRichText from '../../bloks/ArticleRichText/ArticleRichText.blok';
import ArticleYoutubeVideo from '../../bloks/ArticleYoutubeVideo/ArticleYoutubeVideo.blok';
import JobApplicationSupport from '../../bloks/JobApplicationSupport/JobApplicationSupport.blok';
import JobInformation from '../../bloks/JobInformation/JobInformation.blok';
import JobSearchFilterAndResults from '../../bloks/JobSearchFilterAndResults/JobSearchFilterAndResults.blok';
import ShareOnSocials from '../../bloks/ShareOnSocials/ShareOnSocials';
import VenueIndex from '../../bloks/VenueIndex/VenueIndex.blok';
import VenueTermsHero from '../../molecules/VenueTermsHero/VenueTermsHero.blok';
import VenueTerms from '../../molecules/VenueTerms/VenueTerms.blok';
import WholeOfChildVariantTwo from '../../bloks/WholeOfChildVariantTwo/WholeOfChildVariantTwo';
import LocationBlock from '../../bloks/LocationBlock/LocationBlock.blok';
import Accordion from '../../bloks/Accordion/Accordion.blok';

interface BlokMap {
  [key: string]: React.FC<any>;
}

const ComponentBlokMap: BlokMap = {
  // Global Elements
  Header: Header,
  Footer: Footer,
  Newsletter: Newsletter,
  Countdown: Countdown,
  // Venue Elements
  AvailabilityChecker: AvailabilityChecker,
  // All Other
  homepage_hero: HomepageHero,
  footer_contact_details: FooterContactDetails,
  footer_list: FooterList,
  zig_zag_content_heavy: ZigZagContentHeavy,
  zig_zag_content_light: ZigZagContentLight,
  section_title: SectionTitle,
  section_title_with_cta: SectionTitleWithCTA,
  image_gallery: ImageGallery,
  related_articles: RelatedArticles,
  testimonials: Testimonials,
  upcoming_holidays: Upcomingholidays,
  statistics: Statistics,
  statistic: Statistic,
  faqs: FAQs,
  accordion: Accordion,
  page_intro_image: PageIntroWithImage,
  page_intro_text: PageIntroWithText,
  page_intro_list: PageIntroWithList,
  whole_of_child: WholeOfChild,
  comparison: Comparison,
  find_us_hero: FindUsHero,
  find_us_filter_and_results: FindUsFilterAndResults,
  full_width: FullWidth,
  service_list: ServiceList,
  image_and_titled_list: ImageAndTitledList,
  cta_cards_list: CTACardsList,
  simple_cta_card: SimpleCTACard,
  simple_cta_cards_row: SimpleCTACardsRow,
  simple_cta_cards_rows: SimpleCTACardsRows,
  secondary_hero: SecondaryHero,
  whole_of_child_intro: WholeOfChildIntro,
  whole_of_child_pillars: WholeOfChildPillars,
  whole_of_child_variant_two: WholeOfChildVariantTwo,
  youtube_video: YoutubeVideo,
  quote: Quote,
  rich_text: RichText,
  image: SingleImageBlok,
  article_image: ArticleImage,
  article_video: ArticleYoutubeVideo,
  article_rich_text: ArticleRichText,
  values: Values,
  form: Form,
  logo_gallery: LogoGallery,
  job_information: JobInformation,
  job_application_support: JobApplicationSupport,
  share_on_socials: ShareOnSocials,
  job_search_filter: JobSearchFilterAndResults,
  find_us_filter_results: VenueIndex,
  // VenueTermsHero: VenueTermsHero,
  VenueTerms: VenueTerms,
  location_block: LocationBlock,
};

export const getComponent = (componentName: string): React.FC<any> => {
  return ComponentBlokMap[componentName];
};
