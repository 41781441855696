import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Tiktok: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      aria-labelledby="tiktok"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M17.4924 1.20244L16.8566 0.199951H13.009V9.22888L12.9959 18.0481C13.0025 18.1137 13.009 18.1857 13.009 18.2513C13.009 20.4593 11.213 22.2612 8.99755 22.2612C6.78207 22.2612 4.98608 20.4659 4.98608 18.2513C4.98608 16.0432 6.78207 14.2413 8.99755 14.2413C9.45638 14.2413 9.9021 14.3265 10.315 14.4706V10.0676C9.88899 9.99549 9.44983 9.95617 8.99755 9.95617C4.42894 9.96273 0.705872 13.6844 0.705872 18.2578C0.705872 22.8312 4.42893 26.5529 9.00411 26.5529C13.5793 26.5529 17.3023 22.8312 17.3023 18.2578V7.76774C18.9607 9.42545 21.1041 11.0438 23.4769 11.5615V7.0601C20.9009 5.92002 18.338 2.55219 17.4924 1.20244Z" />
    </svg>
  )
}

export default Tiktok
