import React from "react"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"
import { LinkItem } from "../../../interfaces/LinkItem.interface"

interface FooterProps {
  supporting_links: LinkItem[]
}

const Footer: React.FC<FooterProps> = ({ supporting_links }) => {
  return (
    <footer className="w-full bg-White text-Primary-900">
      <div className="container mx-auto flex flex-col-reverse py-8 md:flex-row md:justify-between">
        <p>{`© ${new Date().getFullYear()} Junior Adventures Group. All rights reserved.`}</p>
        <ul className="text-left pb-4 md:flex md:space-x-6 space-y-4 md:space-y-0 md:pb-0 min-w-[11rem]">
          {supporting_links &&
            !!supporting_links.length &&
            supporting_links.map(link => (
              <li key={link._uid} className="">
                <CustomLink
                  url={link.page_link.cached_url}
                  className={`text-base font-bold border-b-2 border-White hover:border-Secondary-300 transition-all`}
                >
                  <span>{link.display_name}</span>
                </CustomLink>
              </li>
            ))}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
