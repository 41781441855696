import React, { useContext } from "react";
import Close from "../../../../assets/svgs/Close.svg";
import JobPageContext from "../../../../contexts/JobsPage.context";
import { ButtonTypes } from "../../../../interfaces/enums/ButtonTypes.enum";
import Button from "../../../atoms/AnimatedButton/AnimatedButton.component";
import Card from "../../../molecules/Card/Card.component";
import SearchField from "../../../molecules/SearchField/SearchField.component";
import { JobSearchFilterGroup } from "../JobSearchFilterAndResults.blok";
import JobSearchFilterSection from "./JobSearchFilterSection/JobSearchFilterSection.component";

interface JobSearchFilterProps {
  heading: string;
  cta_label: string;
  filters: JobSearchFilterGroup[];
  clear_all_button_label: string;
  closeButtonClicked: () => void
}

const JobSearchFilter: React.FC<JobSearchFilterProps> = ({
  heading,
  cta_label,
  filters,
  closeButtonClicked,
  clear_all_button_label = 'reset'
}: JobSearchFilterProps) => {
  const { updateSearchBarInputValue, searchBarInputValue, updateSelectedFilters } = useContext(JobPageContext);

  return (
    <>     
      <Card isExpandable={false}>
        <div id="job-search-filter-header">
          <div className="flex justify-between w-full items-center">
            <h2 className="flex justify-between text-2xl xl:text-3xl 2xl:text-4xl font-extrabold pb-4 text-Primary-900 border-b border-dashed w-full">
              {heading} 
              <button
                className="font-bold text-SP4 uppercase whitespace-nowrap ml-4 mr-4 md:mr-0 text-base"
                onClick={() => updateSelectedFilters([])}
              >
                {clear_all_button_label}          
              </button>
            </h2>
            <button className="md:hidden pb-4" onClick={() => closeButtonClicked()}>
              <Close className="" />
            </button>
            
          </div>
          
    
          <div className="pt-4">
            <SearchField onChange={updateSearchBarInputValue} value={searchBarInputValue} />
          </div>         
        </div>      

        <div id="job-search-filter-main-content">
          {filters.map((filter, idx) => <div key={idx}><JobSearchFilterSection filter_section={filter} /></div>)}
        </div>

        {/* <div className="pt-4">
          <Button styles={'bg-White border border-Primary-900'} label={cta_label} type={ButtonTypes.PRIMARY} fullWidth={true} />
        </div> */}
      </Card>  
    </>      
  )
}

export default JobSearchFilter;