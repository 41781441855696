/** @type {import('tailwindcss').Config} */

const plugin = require('tailwindcss/plugin');
const websiteTheme = require(`./tailwind-themes/${process.env.GATSBY_TAILWIND_CONFIG_DATA}`);

module.exports = {
  content: ['./src/pages/**/*.{js,jsx,ts,tsx}', './src/components/**/*.{js,jsx,ts,tsx}'],
  safelist: websiteTheme.safeList,
  theme: {
    colors: {
      current: 'currentColor',
      'Primary-900': websiteTheme.colors['Primary-900'],
      'Primary-800': websiteTheme.colors['Primary-800'],
      'Primary-700': websiteTheme.colors['Primary-700'],
      'Primary-600': websiteTheme.colors['Primary-600'],
      'Primary-500': websiteTheme.colors['Primary-500'],
      'Primary-400': websiteTheme.colors['Primary-400'],
      'Primary-300': websiteTheme.colors['Primary-300'],
      'Primary-200': websiteTheme.colors['Primary-200'],
      'Primary-100': websiteTheme.colors['Primary-100'],
      'Primary-50': websiteTheme.colors['Primary-50'],
      'Secondary-300': websiteTheme.colors['Secondary-300'],
      'Secondary-200': websiteTheme.colors['Secondary-200'],
      'Secondary-100': websiteTheme.colors['Secondary-100'],
      'Secondary-50': websiteTheme.colors['Secondary-50'],
      SP1: websiteTheme.colors['SP1'],
      SP2: websiteTheme.colors['SP2'],
      SP3: websiteTheme.colors['SP3'],
      SP4: websiteTheme.colors['SP4'],
      SP5: websiteTheme.colors['SP5'],
      SP6: websiteTheme.colors['SP6'],
      SP7: websiteTheme.colors['SP7'],
      SP8: websiteTheme.colors['SP8'],
      Body: websiteTheme.colors['Body'],
      'Body-Inactive': websiteTheme.colors['Body-Inactive'],
      'Body-Disabled': websiteTheme.colors['Body-Disabled'],
      White: websiteTheme.colors['White'],
      Black: websiteTheme.colors['Black'],
      Green: websiteTheme.colors['Green'],
      Highlight: websiteTheme.colors['Highlight'],
      Transparent: websiteTheme.colors['Transparent'],
      Opacity9: websiteTheme.colors['Opacity9'],
      // Activity Category Colours
      'Splash-Zone': websiteTheme.colors['Splash-Zone'],
      'Power-Teams': websiteTheme.colors['Power-Teams'],
      'Super-Sports': websiteTheme.colors['Super-Sports'],
      'Globe-Trotters': websiteTheme.colors['Globe-Trotters'],
      'Creative-Inventors': websiteTheme.colors['Creative-Inventors'],
      'Brain-Boosters': websiteTheme.colors['Brain-Boosters'],
      'Zen-Masters': websiteTheme.colors['Zen-Masters'],
      // Tear Colours
      'Dark-Theme-Tear-Primary': websiteTheme.colors['Dark-Theme-Tear-Primary'],
      'Dark-Theme-Tear-Secondary': websiteTheme.colors['Dark-Theme-Tear-Secondary'],
      'Light-Theme-Tear-Primary': websiteTheme.colors['Light-Theme-Tear-Primary'],
      'Light-Theme-Tear-Secondary': websiteTheme.colors['Light-Theme-Tear-Secondary'],
      'Alternative-Theme-Tear-Primary': websiteTheme.colors['Alternative-Theme-Tear-Primary'],
      'Alternative-Theme-Tear-Secondary': websiteTheme.colors['Alternative-Theme-Tear-Secondary'],
      'White-Theme-Tear-Primary': websiteTheme.colors['White-Theme-Tear-Primary'],
      'White-Theme-Tear-Secondary': websiteTheme.colors['White-Theme-Tear-Secondary'],
      // Hero Gradients
      'Homepage-Hero/Gradient-Start': websiteTheme.colors['Homepage-Hero/Gradient-Start'],
      'Homepage-Hero/Gradient-End': websiteTheme.colors['Homepage-Hero/Gradient-End'],
      'Secondary-Hero/Gradient-Start': websiteTheme.colors['Secondary-Hero/Gradient-Start'],
      'Secondary-Hero/Gradient-End': websiteTheme.colors['Secondary-Hero/Gradient-End'],
      // Background Texture
      'Background-Texture-Primary': websiteTheme.colors['Background-Texture-Primary'],
      // Button
      'Primary-Button-Text': websiteTheme.colors['Primary-Button-Text'],

      'sherpa-blue-50': websiteTheme.colors['sherpa-blue-50'],
      'sherpa-blue-100': websiteTheme.colors['sherpa-blue-100'],
      'sherpa-blue-200': websiteTheme.colors['sherpa-blue-200'],
      'sherpa-blue-300': websiteTheme.colors['sherpa-blue-300'],
      'sherpa-blue-400': websiteTheme.colors['sherpa-blue-400'],
      'sherpa-blue-500': websiteTheme.colors['sherpa-blue-500'],
      'sherpa-blue-600': websiteTheme.colors['sherpa-blue-600'],
      'sherpa-blue-700': websiteTheme.colors['sherpa-blue-700'],
      'sherpa-blue-800': websiteTheme.colors['sherpa-blue-800'],
      'sherpa-blue-900': websiteTheme.colors['sherpa-blue-900'],

      'sherpa-red-50': websiteTheme.colors['sherpa-red-50'],
      'sherpa-red-100': websiteTheme.colors['sherpa-red-100'],
      'sherpa-red-200': websiteTheme.colors['sherpa-red-200'],
      'sherpa-red-300': websiteTheme.colors['sherpa-red-300'],
      'sherpa-red-400': websiteTheme.colors['sherpa-red-400'],
      'sherpa-red-500': websiteTheme.colors['sherpa-red-500'],
      'sherpa-red-600': websiteTheme.colors['sherpa-red-600'],
      'sherpa-red-700': websiteTheme.colors['sherpa-red-700'],
      'sherpa-red-800': websiteTheme.colors['sherpa-red-800'],
      'sherpa-red-900': websiteTheme.colors['sherpa-red-900'],

      'sherpa-red-main': websiteTheme.colors['sherpa-red-main'],
      'sherpa-blue-main': websiteTheme.colors['sherpa-blue-main'],
      'sherpa-body': websiteTheme.colors['sherpa-body'],
      'sherpa-yellow': websiteTheme.colors['sherpa-yellow'],
      'sherpa-white': websiteTheme.colors['sherpa-white'],
    },
    fontSize: {
      xs: websiteTheme.fontSize.xs,
      sm: websiteTheme.fontSize.sm,
      base: websiteTheme.fontSize.base,
      lg: websiteTheme.fontSize.lg,
      xl: websiteTheme.fontSize.xl,
      '2xl': websiteTheme.fontSize['2xl'],
      '3xl': websiteTheme.fontSize['3xl'],
      '4xl': websiteTheme.fontSize['4xl'],
      '5xl': websiteTheme.fontSize['5xl'],
      '6xl': websiteTheme.fontSize['6xl'],
    },
    fontFamily: websiteTheme.fontFamily,
    boxShadow: {
      Inner: websiteTheme.boxShadow.Inner,
      Sm: websiteTheme.boxShadow.Sm,
      Default: websiteTheme.boxShadow.Default,
      Md: websiteTheme.boxShadow.Md,
      Lg: websiteTheme.boxShadow.Lg,
      Xl: websiteTheme.boxShadow.Xl,
      '2xl': websiteTheme.boxShadow['2xl'],
    },
    borderRadius: {
      none: websiteTheme.borderRadius.none,
      xs: websiteTheme.borderRadius.xs,
      sm: websiteTheme.borderRadius.sm,
      default: websiteTheme.borderRadius.default,
      lg: websiteTheme.borderRadius.lg,
      xl: websiteTheme.borderRadius.xl,
      '2xl': websiteTheme.borderRadius['2xl'],
      '3xl': websiteTheme.borderRadius['3xl'],
      '4xl': websiteTheme.borderRadius['4xl'],
      '5xl': websiteTheme.borderRadius['5xl'],
      '6xl': websiteTheme.borderRadius['6xl'],
      '7xl': websiteTheme.borderRadius['7xl'],
      '8xl': websiteTheme.borderRadius['8xl'],
      full: websiteTheme.borderRadius['full'],
    },
    screens: {
      sm: websiteTheme.screens.sm,
      md: websiteTheme.screens.md,
      lg: websiteTheme.screens.lg,
      xl: websiteTheme.screens.xl,
      '2xl': websiteTheme.screens['2xl'],
      '3xl': websiteTheme.screens['3xl'],
    },
    container: {
      padding: {
        DEFAULT: websiteTheme.container.padding.DEFAULT,
        xl: websiteTheme.container.padding['xl'],
        '2xl': websiteTheme.container.padding['2xl'],
      },
    },
    extend: {
      transitionDelay: {
        400: websiteTheme.extend.transitionDelay['400'],
        600: websiteTheme.extend.transitionDelay['600'],
        800: websiteTheme.extend.transitionDelay['800'],
        900: websiteTheme.extend.transitionDelay['900'],
        1100: websiteTheme.extend.transitionDelay['1100'],
        1200: websiteTheme.extend.transitionDelay['1200'],
        1300: websiteTheme.extend.transitionDelay['1300'],
      },
    },
  },
  variants: {
    fill: websiteTheme.variants.fill,
    textColor: websiteTheme.variants.textColor,
    scrollbar: websiteTheme.variants.scrollbar,
    boxShadow: websiteTheme.variants.boxShadow,
    extend: {
      gridColumnEnd: websiteTheme.variants.extend.gridColumnEnd,
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(function ({ addUtilities, addComponents, e, config }) {
      addUtilities({
        '.rotate-y-180': {
          transform: 'rotateY(180deg)',
        },
        '.flip-horizontal': {
          transform: 'scaleX(-1)',
        },
        '.flip-vertical': {
          transform: 'scaleY(-1)',
        },
      });
    }),
    require('@savvywombat/tailwindcss-grid-areas'),
    require('@tailwindcss/line-clamp'),
  ],
};
