import React from "react"

interface BackgroundGlobeProps {
  className?: string
}

const BackgroundGlobe: React.FC<BackgroundGlobeProps> = ({ className }) => {
  return (
    <svg
      width="148"
      height="135"
      viewBox="0 0 148 135"
      className={`transfrom fill-current ${className}`}
    >
      <path
        d="M111.116 76.3074C96.9322 100.076 66.0927 107.878 42.3237 93.7366C18.5762 79.5741 10.7535 48.7131 24.8946 24.9441C39.0571 1.19656 69.9181 -6.62614 93.6871 7.51491C117.435 21.6774 125.257 52.5384 111.116 76.3074ZM28.2901 26.9642C15.2451 48.8635 22.4446 77.296 44.3439 90.341C66.2432 103.386 94.6757 96.1866 107.721 74.2873C120.766 52.388 113.566 23.9555 91.667 10.9105C69.7677 -2.13452 41.3351 5.06494 28.2901 26.9642Z"
        fill="#4A1375"
      />
      <path
        d="M99.2963 72.396C98.7376 73.3416 97.4266 73.8359 96.481 73.2771L23.6482 29.8869C22.7026 29.3281 22.5307 27.9097 23.0679 26.9856C23.6267 26.04 24.9376 25.5457 25.8832 26.1045L98.7161 69.4947C99.6617 70.0534 99.8551 71.4504 99.2963 72.396Z"
        fill="#4A1375"
      />
      <path
        d="M109.204 79.6813C109.096 79.8748 108.946 80.0252 108.774 80.1971C102.219 85.6773 85.8646 87.3751 70.7564 84.173C59.5811 81.809 39.2507 74.674 28.7846 53.5484C28.3118 52.5598 28.6986 51.3993 29.6657 50.905C30.6328 50.4107 31.8148 50.819 32.3091 51.7861C42.023 71.4288 61.0425 78.0695 71.5301 80.3046C86.9606 83.5712 101.274 81.2502 106.195 77.1454C107.033 76.4362 108.28 76.5652 108.989 77.4033C109.526 78.0695 109.591 78.9937 109.161 79.6814L109.204 79.6813Z"
        fill="#4A1375"
      />
      <path
        d="M111.739 74.8246C111.331 75.5338 110.514 75.9206 109.655 75.7487C108.58 75.5338 107.893 74.5023 108.107 73.4277C109.418 66.873 104.39 52.7749 93.6441 40.568C86.5306 32.4659 71.2505 19.0771 49.0503 20.6029C47.9543 20.6674 47.0087 19.8508 46.9442 18.7547C46.8798 17.6587 47.6964 16.7131 48.7925 16.6486C72.6904 14.9723 89.002 29.2638 96.6313 37.9246C107.14 49.8951 113.76 65.4976 111.997 74.1799C111.954 74.3948 111.868 74.6097 111.761 74.8031L111.739 74.8246Z"
        fill="#4A1375"
      />
      <path
        d="M90.614 17.2502L50.9632 83.8077C50.4044 84.7533 49.3943 85.1616 48.4487 84.6029C47.5031 84.0441 47.3957 82.9481 47.9544 82.0025L87.6053 15.445C88.164 14.4994 89.1741 14.091 90.1197 14.6498C91.0653 15.2086 91.1727 16.3046 90.614 17.2502Z"
        fill="#4A1375"
      />
      <path
        d="M49.5449 41.6853C44.1076 50.819 35.6617 60.4684 22.7241 66.271C21.7141 66.7223 20.575 66.271 20.1022 65.2824C19.6724 64.2938 20.1022 63.1333 21.0908 62.6605C37.3595 55.3751 46.0848 41.406 50.5334 30.9828C55.7128 18.8834 56.6584 7.92305 55.7342 5.1937C55.3904 4.16213 55.9277 3.0231 56.9592 2.67925C57.9908 2.33539 59.1083 2.89417 59.4737 3.90424C60.9565 8.26691 59.3662 20.4308 54.1869 32.5302C52.9619 35.3885 51.4361 38.5047 49.5449 41.6853Z"
        fill="#4A1375"
      />
      <path
        d="M77.0961 97.3471C76.7737 97.8844 76.1934 98.2497 75.5487 98.2927C74.4742 98.3787 73.5286 97.562 73.4211 96.466C72.0027 77.425 80.6206 62.1879 88.0994 52.7319C96.2875 42.4377 106.216 35.2167 110.751 34.4646C111.825 34.2926 112.836 35.0018 113.029 36.0979C113.201 37.1724 112.492 38.1825 111.396 38.3759C108.559 38.8487 99.3822 44.8877 91.1726 55.2033C84.1236 64.1006 76 78.4136 77.3325 96.1651C77.3755 96.5949 77.2465 96.9818 77.0746 97.3256L77.0961 97.3471Z"
        fill="#4A1375"
      />
      <path
        d="M21.3054 26.9859C20.6177 27.6736 19.5431 27.8455 18.662 27.3512L2.43632 18.2176C1.36177 17.6158 0.996442 16.2619 1.5767 15.2089C2.17844 14.1343 3.53237 13.769 4.58543 14.3492L20.8111 23.4829C21.8856 24.0846 22.251 25.4385 21.6707 26.4916C21.5633 26.685 21.4558 26.8354 21.3054 26.9859Z"
        fill="#4A1375"
      />
      <path
        d="M119.713 85.7634C119.046 86.4296 117.993 86.6231 117.091 86.1503L106.926 80.6271C105.851 80.0253 105.464 78.6929 106.044 77.6399C106.646 76.5653 107.979 76.1785 109.032 76.7587L119.197 82.2819C120.271 82.8836 120.658 84.2161 120.078 85.2691C119.971 85.4626 119.842 85.6345 119.691 85.7849L119.713 85.7634Z"
        fill="#4A1375"
      />
      <path
        d="M111.439 99.1095C86.0792 124.469 44.7951 124.49 19.4143 99.1094C9.93675 89.6319 3.68288 77.6615 1.36186 64.509C-0.916179 51.6574 0.674143 38.5909 5.93943 26.7064C6.45521 25.5889 7.74468 25.0731 8.86221 25.5889C9.97974 26.1047 10.4955 27.3941 9.97973 28.5117C-0.0995284 51.2706 4.92935 78.3922 22.5304 95.9933C46.1705 119.633 84.6393 119.633 108.279 95.9932C109.999 94.274 111.632 92.4258 113.158 90.5131C113.91 89.546 115.307 89.3955 116.274 90.1477C117.241 90.8999 117.391 92.2968 116.639 93.2639C115.027 95.3055 113.265 97.2827 111.417 99.1309L111.439 99.1095Z"
        fill="#4A1375"
      />
      <path
        d="M130.888 118.344C130.028 119.204 128.61 119.204 127.75 118.344L108.344 98.9375C107.484 98.0779 107.484 96.6595 108.344 95.7999C109.203 94.9402 110.622 94.9402 111.481 95.7999L130.888 115.206C131.747 116.066 131.747 117.484 130.888 118.344Z"
        fill="#4A1375"
      />
      <path
        d="M146.856 105.514L118.38 133.989C117.521 134.849 116.102 134.849 115.243 133.989C114.383 133.13 114.383 131.711 115.243 130.851L143.718 102.376C144.578 101.516 145.996 101.516 146.856 102.376C147.715 103.236 147.715 104.654 146.856 105.514Z"
        fill="#4A1375"
      />
      <path
        d="M120.98 86.172C120.293 86.8597 119.218 87.0316 118.359 86.5588C117.284 85.9571 116.897 84.6246 117.477 83.5716C117.585 83.3781 117.714 83.1633 117.843 82.9054C118.853 81.0786 121.002 77.2532 121.754 74.352C122.076 73.17 123.28 72.4822 124.462 72.8046C125.644 73.127 126.332 74.3305 126.009 75.5125C125.107 78.951 122.872 82.9483 121.69 85.0759C121.539 85.3123 121.453 85.5273 121.324 85.6992C121.217 85.8926 121.088 86.0645 120.959 86.1934L120.98 86.172Z"
        fill="#4A1375"
      />
    </svg>
  )
}

export default BackgroundGlobe
