import React from 'react';
import { SVG } from '../../interfaces/SVG.interface';

const Time: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      aria-labelledby="Time"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M10.01 0C15.53 0 20 4.48 20 10C20 15.52 15.53 20 10.01 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10.01 0ZM10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18ZM9.5 5H11V11L5.75 14.15L5 12.92L9.5 10.25V5Z" />
    </svg>
  );
};

export default Time;
