import React, { useContext } from 'react';
import TemplateProps from '../../../interfaces/TemplateProps.interface';
import getStoryblokWrapperEntries from '../../../utils/getStoryblokWrapperEntries/getStoryblokWrapperEntries.util';
import getBlok from '../../../utils/getBlok/getBlok.util';
import { layoutClassNames } from '../../../styles/ClassNames/global.classes';
import DynamicComponent from '../../utils/DynamicComponent/DynamicComponent.component';
import { SiteSearchProps } from './SiteSearchProps.interface';
import getModelledSiteSearchProps from './getModelledSiteSearchProps.util';
import isWindowInExistence from '../../../utils/isWindowInExistence.util';
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web';
import getAlgoliaSearchClient from '../../../utils/Algolia/getAlgoliaSearchClient.util';
import SiteSearchHeroBanner from './components/SiteSearchHeroBanner.component';
import SiteSearchResultsList from './components/SiteSearchResultsList.components';
import SiteSearchPaginationBar from './components/SiteSearchPaginationBar.component';
import Meta from '../../utils/Meta/Meta.component';
import BaseDataLayer from '../../utils/BaseDataLayer/BaseDataLayer.util';
import BreadcrumbBar from '../../bloks/BreadcrumbBar/BreadcrumbBar';
import LayoutContext from '../../../contexts/LayoutContext';

const SiteSearchTemplate: React.FC<TemplateProps> = ({ context }) => {
  const { header, footer, newsletter } = getStoryblokWrapperEntries();

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);

  const {
    breadcrumb_override,
    has_background,
  }: {
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;

  const siteSearchProps: SiteSearchProps = getModelledSiteSearchProps(context.content);

  /*-- to help on the aws side we will wrap it in a string --*/
  const getIndexName = (): string => {
    return `${process.env.GATSBY_ALGOLIA_SITE_SEARCH_INDEX_NAME!}`;
  };

  const getInitialSearchTerm = (): string => {
    if (!isWindowInExistence()) return '';
    const queryParams: string = window.location.search;
    if (queryParams === '' || !queryParams.includes('?')) return '';
    const queryParamsMinusDefinition: string = window.location.search.split('?')[1];
    const queryParamsNestedArray: string[][] = queryParamsMinusDefinition
      .split('&')
      .map(queryParam => queryParam.split('=').map(val => decodeURIComponent(val)));
    const queryParamsObject: { [key: string]: string } = Object.fromEntries(queryParamsNestedArray);
    return queryParamsObject['q'] ?? '';
  };
  const breadcrumbs = useContext(LayoutContext);

  return (
    <BaseDataLayer field_component={context.field_component}>
      {siteSearchProps.seo && <Meta seo={siteSearchProps.seo} url={context.full_slug} />}
      <div className={layoutClassNames}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        <article className="font-montserrat">
          <InstantSearch
            indexName={getIndexName()}
            searchClient={getAlgoliaSearchClient()}
            initialUiState={{
              [getIndexName()]: {
                query: getInitialSearchTerm(),
              },
            }}
          >
            <Configure hitsPerPage={siteSearchProps.pagination_bar_config.max_results_per_page} />
            <SiteSearchHeroBanner {...siteSearchProps.hero_banner_config} />
            {breadcrumbs && (
              <BreadcrumbBar
                {...breadcrumbs}
                breadcrumb_override={breadcrumb_override}
                hasBackground={has_background}
              />
            )}
            <section className="pt-6 space-y-10 md:pt-10 md:space-y-16 xl:pt-14 pb-12 space-y-10 md:pb-16 xl:pb-20">
              <SiteSearchResultsList {...siteSearchProps.results_list_config} />
              <SiteSearchPaginationBar {...siteSearchProps.pagination_bar_config} />
            </section>
          </InstantSearch>
        </article>
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
      </div>
    </BaseDataLayer>
  );
};

export default SiteSearchTemplate;
