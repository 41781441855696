import React, { useEffect, useState } from "react"
import Button from "../../atoms/Button/Button.component"
import MobileFilterToggle from "../../../assets/svgs/MobileFilterToggle.svg"
import classNames from "classnames"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import RichText from "../../atoms/RichText/RichText.atom"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"

interface VenueIndexWithFiltersProps {
  items: JSX.Element[]
  filter?: JSX.Element
  sortByFilter?: JSX.Element
  pagination?: JSX.Element
  toggleFilterMenu: (state: any) => void
  filterMenu: boolean
  activeFilters: string[]
  results_title: string
  clear_all_label: string
  top_content_heading: string
  top_content_body: StoryblokRichtext
}

const VenueIndexWithFilters: React.FC<VenueIndexWithFiltersProps> = ({
  items,
  filter,
  sortByFilter,
  pagination,
  toggleFilterMenu,
  filterMenu,
  activeFilters,
  top_content_heading,
  top_content_body,
  results_title,
  clear_all_label,
}) => {
  const findUsResultsTitleAndFilterBarClassNames = classNames(
    "pt-6 pb-0 space-y-2",
    {
      "xl:pb-0 xl:space-y-0": activeFilters.length === 0,
    }
  )

  return (
    <div className="container mx-auto grid grid-cols-2 gap-x-6 pt-16 pb-10 md:grid-cols-12 md:py-20">
      <div className="col-span-4">{filter}</div>
      <div className="col-span-12 xl:col-span-8">
        <div className="border-b border-b-Body-Inactive border-dashed space-y-2 pb-6">
          {top_content_heading && (
            <h3 className="text-lg font-semibold text-Primary-900">
              {top_content_heading}
            </h3>
          )}
          {top_content_body && <RichText content={top_content_body} />}
        </div>
        <div className={findUsResultsTitleAndFilterBarClassNames}>
          {results_title && (
            <h3 className="font-extrabold text-3xl text-Primary-900">
              {results_title}
            </h3>
          )}
          <div className="flex items-center justify-start">
            <button
              onClick={() => toggleFilterMenu(!filterMenu)}
              className="mr-4 xl:hidden xl:mr-0"
            >
              <MobileFilterToggle
                numberOfSelectedFilters={activeFilters.length}
              />
            </button>

            {!!activeFilters.length && (
              <>
                <ul className="flex items-center justify-start flex-nowrap space-x-4 overflow-x-auto overflow-y-hidden py-4">
                  {activeFilters.map((selected_filter_option: string, idx) => (
                    <li
                      key={`filter-option-${idx}`}
                      className="rounded-default bg-Secondary-100 p-2 text-sm text-Primary-900 font-semibold whitespace-nowrap capitalize"
                    >
                      {selected_filter_option}
                    </li>
                  ))}
                </ul>
                <button
                  className="font-bold text-SP4 uppercase whitespace-nowrap ml-4 hey"
                  onClick={() => setQueryParameters([], QueryParams.FILTERS)}
                >
                  {clear_all_label}
                </button>
              </>
            )}
          </div>
        </div>

        <ul className="pt-2 pb-10 space-y-8 md:pb-16">{items}</ul>
        {pagination}
      </div>
    </div>
  )
}

export default VenueIndexWithFilters
