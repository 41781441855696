import React, { Dispatch, useState } from "react";
import Search from "../../../assets/svgs/Search.svg";

interface SearchFieldProps {
  onChange: Dispatch<string>;
  value: string;
}

const SearchField: React.FC<SearchFieldProps> = ({onChange, value = ''}) => {
  return (
    <div className="flex flex-row bg-SP7 rounded-full w-full py-4 px-7 gap-3">
      <Search className="text-Secondary-300 left-7 top-0 bottom-0 my-auto z-[1] " />
      <input
        onChange={e => onChange(e.target.value)}
        value={value}
        type="text"
        placeholder='Search'
        onKeyPress={e => console.log('handle key press')}
        className='w-full bg-transparent outline-none focus:ring-0 focus:outline-none bg-SP7 leading-6'
      />          
    </div>
  )
}

export default SearchField;