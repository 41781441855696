import React, { useState, useEffect, ElementType } from "react"
import { InView } from "react-intersection-observer"

interface AnimateInComponentProps {
  wrapperComponent?: ElementType<any> & string
  didBecomeVisible: () => void
  classNames?: string
  children: any
}

const AnimateInComponent: React.FC<AnimateInComponentProps> = ({
  wrapperComponent = "section",
  didBecomeVisible,
  classNames = "",
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const handleIsVisibleChange = (newIsVisible: boolean): void => {
    if (!isVisible && newIsVisible) {
      setIsVisible(newIsVisible)
    }
  }

  useEffect(() => {
    if (isVisible) didBecomeVisible()
  }, [isVisible])

  return (
    <InView
      as={wrapperComponent}
      threshold={0.4}
      className={classNames && classNames}
      onChange={inView => handleIsVisibleChange(inView)}
    >
      {children}
    </InView>
  )
}

export default AnimateInComponent
