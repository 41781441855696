import React from "react"
import classNames from "classnames"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BreakpointTypes } from "../../../interfaces/enums/BreakpointTypes.enum"

interface BackgroundIconsProps {
  type: string
  icon?: StoryblokAsset
  iconLeft?: StoryblokAsset
  iconRight?: StoryblokAsset
  svg?: any
  children: React.ReactNode
  className?: string
  iconLeftStyle?: string
  iconRightStyle?: string
  rotateRight?: boolean
  rotateLeft?: boolean
  mirrorXRight?: boolean
  mirrorXLeft?: boolean
  mirrorYLeft?: boolean
  mirrorYRight?: boolean
  visibleOn?: string
  visibleOnTablet?: boolean
}

const BackgroundIcons: React.FC<BackgroundIconsProps> = ({
  type,
  icon,
  iconLeft,
  iconRight,
  svg,
  children,
  iconLeftStyle,
  iconRightStyle,
  className,
  rotateRight = false,
  rotateLeft = false,
  mirrorXRight = false,
  mirrorXLeft = false,
  mirrorYLeft = false,
  mirrorYRight = false,
  visibleOn = BreakpointTypes.MD,
}) => {
  const responsiveVisablilityClassses = classNames({
    "hidden 3xl:block": visibleOn === BreakpointTypes.ThreeXL,
    "hidden 2xl:block": visibleOn === BreakpointTypes.TwoXL,
    "hidden xl:block": visibleOn === BreakpointTypes.XL,
    "hidden lg:block": visibleOn === BreakpointTypes.LG,
    "hidden md:block": visibleOn === BreakpointTypes.MD,
    "hidden sm:block": visibleOn === BreakpointTypes.SM,
    block: visibleOn === BreakpointTypes.MOBILE,
  })

  const IconClasses = classNames(
    `absolute ${className && className} pointer-events-none`
  )
  const LeftIconClasses = classNames(
    {
      " left-0 bottom-0 -translate-x-1/4 xl:translate-x-0":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Inside,
      " left-0 bottom-0 -translate-x-1/4 xl:-translate-x-full":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Outside,
      " left-0 bottom-0 -translate-x-1/4 xl:-translate-x-1/4":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Quarter,
      " left-0 bottom-[50%] top-[50%] -translate-x-1/2 2xl:-translate-x-full":
        type === BackgroundPositionTypes.BottomLeftToTopRight_OutsideX2,
      " left-0 top-0 -translate-x-1/4 xl:-translate-x-0":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Inside,
      " left-0 top-0 -translate-x-1/4 xl:-translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Outside,
      " left-0 top-0 -translate-x-1/2 2xl:-translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_OutsideX2,
      " left-0 top-0 -translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Outside_Full,
      " left-20 top-0 2xl:left-[90px]":
        type === BackgroundPositionTypes.SiteSearch,
      " left-[-50px] top-[50%] -translate-y-1/2 m-auto 2xl:left-[0px]":
        type === BackgroundPositionTypes.VerticallyCentered,
    },
    iconLeftStyle
  )
  const RightIconClasses = classNames(
    {
      " right-0 top-0 translate-x-1/4 xl:translate-x-0":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Inside,
      " right-0 top-0 translate-x-1/4 xl:translate-x-full":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Outside,
      " right-0 top-0 translate-x-1/4 xl:translate-x-1/4":
        type === BackgroundPositionTypes.BottomLeftToTopRight_Quarter,
      " right-0 top-0 translate-x-1/2 2xl:translate-x-full":
        type === BackgroundPositionTypes.BottomLeftToTopRight_OutsideX2,
      " right-0 bottom-0 translate-x-1/4 xl:translate-x-0":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Inside,
      " right-0 bottom-0 translate-x-1/4 xl:translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Outside,
      " right-0 bottom-0 translate-x-1/2 2xl:translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_OutsideX2,
      " right-0 bottom-0 translate-x-full":
        type === BackgroundPositionTypes.TopLeftToBottomRight_Outside_Full,
      " right-20 bottom-0 2xl:right-[90px]":
        type === BackgroundPositionTypes.SiteSearch,
      " right-[-50px] top-[50%] -translate-y-1/2 m-auto 2xl:right-[0px]":
        type === BackgroundPositionTypes.VerticallyCentered,
    },
    iconRightStyle
  )

  const rotateClass = classNames("rotate-270")
  const reverseClass = classNames("rotate-y-180")
  const flippedClass = classNames("-scale-y-100")

  const isSVG = React.isValidElement(icon)

  return (
    <div className="w-full h-full relative">
      <div className={responsiveVisablilityClassses}>
        <div className={IconClasses + LeftIconClasses}>
          <div className={`${mirrorXLeft && reverseClass}`}>
            <div className={`${rotateLeft && rotateClass}`}>
              <div className={`${mirrorYLeft && flippedClass}`}>
                {icon && <Image image={icon} />}
                {iconLeft && <Image image={iconLeft} />}
                {svg && svg}
              </div>
            </div>
          </div>
        </div>
        <div className={IconClasses + RightIconClasses}>
          <div className={`${mirrorXRight && reverseClass}`}>
            <div className={`${rotateRight && rotateClass}`}>
              <div className={`${mirrorYRight && flippedClass}`}>
                {icon && <Image image={icon} />}
                {iconRight && <Image image={iconRight} />}
                {svg && svg}
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default BackgroundIcons
