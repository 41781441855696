import React from "react"
import { SVGWithPaths } from "../../interfaces/SVG.interface"

const Youtube: React.FC<SVGWithPaths> = ({ primaryFill, secondaryFill }) => {
  return (
    <svg width="35" height="24" viewBox="0 0 35 24" aria-labelledby="youtube">
      <path
        className={`transfrom fill-current ${primaryFill}`}
        d="M28.8597 23.2942H6.26963C3.24701 23.2942 0.811768 20.621 0.811768 17.333V6.66709C0.811768 3.36573 3.25925 0.705933 6.26963 0.705933H28.8597C31.8824 0.705933 34.3176 3.3791 34.3176 6.66709V17.333C34.3298 20.6344 31.8824 23.2942 28.8597 23.2942Z"
      />
      <path
        className={`transfrom fill-current ${secondaryFill}`}
        d="M23.4913 11.833L13.9882 6.35303V17.313L23.4913 11.833Z"
      />
    </svg>
  )
}

export default Youtube
