import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Numbers: React.FC<SVG> = ({ className }) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12H3V4H8V12ZM6 6H5V10H6V6ZM14 12H9V4H14V12ZM12 6H11V10H12V6ZM17 4H15V12H17V4ZM20 0V16H0V0H20ZM2 14H18V2H2V14Z" fill="#F46A6C"/>
    </svg>
  )
}

export default Numbers;
