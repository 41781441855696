/*-- npm packages --*/
import React from 'react';
import classNames from 'classnames';

/*-- component bloks --*/

/*-- component molecules --*/
import BackgroundIcons from '../../molecules/BackgroundIcons/BackgroundIcons.molecule';

/*-- component atoms --*/
import Button from '../../atoms/Button/Button.component';

/*-- assets --*/
import BackgroundGlobe from '../../../assets/svgs/BackgroundGlobe.svg';
import Phone from '../../../assets/svgs/Phone.svg';
import Map from '../../../assets/svgs/Map.svg';
import MapPin from '../../../assets/svgs/MapPin.svg';

/*-- interfaces --*/
import { BackgroundPositionTypes } from '../../../interfaces/enums/BackgroundPositionTypes.enum';
import { BreakpointTypes } from '../../../interfaces/enums/BreakpointTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';

/*-- utils --*/
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import { valueExists } from '../../../utils/valueExists/valueExists.util';

/*-- contexts --*/
import { Service, Venue } from '../../../contexts/VenuesContext';
import { Club } from '../../../contexts/FiltersContext';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';

interface VenueNearbyVenuesProps extends BlokWithBorderIcons {
  venue: Venue;
  nearby_venues: Venue[];
  clubs: Club[];
  primary_button_text: string;
  secondary_button_text: string;
  card_border_icon_top_left?: StoryblokAsset;
  card_border_icon_top_right?: StoryblokAsset;
  card_border_icon_bottom_left?: StoryblokAsset;
  card_border_icon_bottom_right?: StoryblokAsset;
  title?: string;
}

const VenueNearbyVenues: React.FC<VenueNearbyVenuesProps> = ({
  venue,
  nearby_venues,
  clubs,
  primary_button_text,
  secondary_button_text,
  card_border_icon_top_left,
  card_border_icon_top_right,
  card_border_icon_bottom_left,
  card_border_icon_bottom_right,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
  title,
}) => {
  const heading = title?.replace('<venue-name>', venue.name);

  const cardWrapperClassNames = (index: number) => {
    return classNames(
      '',
      {
        'col-span-12 md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4': nearby_venues.length == 1,
      },
      {
        'col-span-12 md:col-span-8 lg:col-span-6 2xl:col-span-5': nearby_venues.length == 2,
      },
      {
        'md:col-start-3 lg:col-start-1 2xl:col-start-2': nearby_venues.length == 2 && index % 2 === 0,
      },
      {
        'md:col-start-3': nearby_venues.length == 2 && index % 2 !== 0,
      },
      {
        'col-span-12 md:col-span-8 md:col-start-3 lg:col-span-6 2xl:col-span-5 3xl:col-span-4':
          nearby_venues.length > 2,
      },
      {
        'lg:col-start-1 2xl:col-start-2 3xl:col-start-auto': nearby_venues.length > 2 && index % 2 === 0,
      },
    );
  };

  const getNearbyVenueAddressAsString = (nearbyVenue: Venue): string => {
    const addressPartsArray: (string | undefined)[] = [
      nearbyVenue.address.line1,
      nearbyVenue.address.line2,
      nearbyVenue.address.townCity,
      nearbyVenue.address.county,
      nearbyVenue.address.postcode,
    ];
    const addressPartsArrayExcludingUndefined: string[] = addressPartsArray.filter((addressPart: string | undefined) =>
      valueExists(addressPart),
    ) as string[];
    const addressPartsString: string = addressPartsArrayExcludingUndefined.join(', ');
    return addressPartsString;
  };

  const getNearbyVenueServicesWithoutClubTypeDuplications = (nearbyVenue: Venue): Service[] => {
    if (nearbyVenue.services) {
      return nearbyVenue.services.reduce((accumulator: Service[], current: Service) => {
        if (!accumulator.find((service: Service) => service.clubType === current.clubType)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    } else {
      return [];
    }
  };

  const getServiceIcon = (service: Service): string | undefined => {
    return clubs.filter((club: Club) => club.name === service.clubType)[0].cardIcon;
  };

  return (
    <>
      {nearby_venues && !!nearby_venues.length && (
        <AnimateInComponent
          didBecomeVisible={() => {
            /*-- do we need this --*/
          }}
        >
          <section className="bg-SP6" id="nearby-venues">
            <BorderIconsFactory
              type={BorderIconType.Blok}
              border_icon_top_left={border_icon_top_left}
              border_icon_top_right={border_icon_top_right}
              border_icon_bottom_left={border_icon_bottom_left}
              border_icon_bottom_right={border_icon_bottom_right}
            >
              <div className="container mx-auto py-16 xl:py-20">
                <BackgroundIcons
                  type={
                    nearby_venues.length > 2
                      ? BackgroundPositionTypes.TopLeftToBottomRight_Outside_Full
                      : BackgroundPositionTypes.TopLeftToBottomRight_OutsideX2
                  }
                  visibleOn={nearby_venues.length > 2 ? BreakpointTypes.TwoXL : BreakpointTypes.XL}
                  svg={<BackgroundGlobe></BackgroundGlobe>}
                  mirrorXRight
                >
                  {venue && venue.name && (
                    <div className="grid grid-cols-12">
                      <p className="text-White text-center text-2xl lg:text-3xl 2xl:text-4xl font-extrabold pb-[4.5rem] md:pb-8 lg:pb-10 2xl:pb-16 col-span-12 lg:col-span-10 3xl:col-span-12 lg:col-start-2 3xl:col-start-1">
                        {heading || 'Holiday Clubs near ' + venue.name}
                      </p>
                    </div>
                  )}
                  <ul className="md:gap-x-6 gap-y-6 grid grid-cols-12">
                    {nearby_venues.map((nearby_venue: Venue, index: number) => (
                      <li key={nearby_venue.id} className={cardWrapperClassNames(index)}>
                        <div className="bg-White rounded-6xl overflow-hidden list-none h-full">
                          <BorderIconsFactory
                            type={BorderIconType.Card}
                            border_icon_top_left={card_border_icon_top_left}
                            border_icon_top_right={card_border_icon_top_right}
                            border_icon_bottom_left={card_border_icon_bottom_left}
                            border_icon_bottom_right={card_border_icon_bottom_right}
                          >
                            <div className="flex flex-col items-center justify-center z-[1] space-y-10 p-10">
                              {/*-- top/left column --*/}
                              <div className="w-full">
                                {/*-- name and telephone, address info and find us --*/}
                                <div className="space-y-3">
                                  {/*-- name --*/}
                                  <h4 className="font-semibold text-Primary-900 text-xl">{nearby_venue.name}</h4>
                                  {/*-- telephone, address info and find us --*/}
                                  <div className="flex flex-col space-y-3">
                                    {/*-- telephone and address info --*/}
                                    <div className="flex flex-col space-y-3">
                                      {/*-- telephone --*/}
                                      {nearby_venue.contactNumber && (
                                        <div className="flex items-center justify-start space-x-1.5">
                                          <Phone className="text-Secondary-300" />
                                          <p className="text-sm text-Body font-semibold hidden sm:block">
                                            {nearby_venue?.contactNumber}
                                          </p>
                                          <a
                                            href={`tel:${nearby_venue?.contactNumber}`}
                                            className="text-sm text-Body font-semibold block sm:hidden"
                                          >
                                            {nearby_venue?.contactNumber}
                                          </a>
                                        </div>
                                      )}

                                      {/*-- address --*/}
                                      {getNearbyVenueAddressAsString(nearby_venue) && (
                                        <div className="flex items-center justify-start space-x-1.5">
                                          <Map className="text-Secondary-300" />
                                          <p className="text-sm text-Body font-semibold">
                                            {getNearbyVenueAddressAsString(nearby_venue)}
                                          </p>
                                        </div>
                                      )}
                                    </div>

                                    {/*-- find us --*/}
                                    {nearby_venue.coords && nearby_venue.coords.lat && nearby_venue.coords.lng && (
                                      <div className="flex items-center justify-start space-x-1.5">
                                        <MapPin className="text-Secondary-300" />
                                        <a
                                          href={`https://maps.google.com/?q=${nearby_venue.coords.lat},${nearby_venue.coords.lng}`}
                                          target="_blank"
                                        >
                                          Find us
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/*-- club types list --*/}
                                {nearby_venue.services && (
                                  <div className="pt-4">
                                    <div className="border-t border-t-Body-Disabled border-dashed pt-4 w-full">
                                      <div className="mr-auto">
                                        <ul className="grid grid-cols-2 w-fit gap-x-6 gap-y-2">
                                          {getNearbyVenueServicesWithoutClubTypeDuplications(nearby_venue).map(
                                            (service: Service, index: number) => (
                                              <li
                                                key={`nearby-venue-service-${index}`}
                                                className="flex items-center justify-start"
                                              >
                                                <div className="w-6 h-6 flex items-center justify-start">
                                                  {getServiceIcon(service) !== undefined && (
                                                    <img src={getServiceIcon(service)!} />
                                                  )}
                                                </div>
                                                <p className="whitespace-nowrap pl-2">{service.clubType}</p>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/*-- bottom/right column --*/}
                              <div className="flex flex-col w-full space-y-6">
                                {nearby_venue.links.bookingLink && (
                                  <Button
                                    link={nearby_venue.links.bookingLink}
                                    label={primary_button_text}
                                    type={ButtonTypes.PRIMARY}
                                    className={'!text-base !py-4'}
                                    size={ButtonSizeTypes.LG}
                                  />
                                )}
                                {nearby_venue.links.website_url && (
                                  <Button
                                    link={nearby_venue.links.website_url}
                                    label={secondary_button_text}
                                    type={ButtonTypes.SECONDARY}
                                    className={'!text-SP6 hover:!text-SP6'}
                                    size={ButtonSizeTypes.DEFAULT}
                                    underline
                                  />
                                )}
                              </div>
                            </div>
                          </BorderIconsFactory>
                        </div>
                      </li>
                    ))}
                  </ul>
                </BackgroundIcons>
              </div>
            </BorderIconsFactory>
          </section>
        </AnimateInComponent>
      )}
    </>
  );
};

export default VenueNearbyVenues;
