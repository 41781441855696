import React, { useState } from 'react';
import Statistic, { StatisticBlok } from '../../molecules/Statistic/Statistic.blok';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';
import DarkThemeBlokTear from '../../../assets/svgs/CornerTears/BlokTears/DarkThemeBlokTear.svg';
import TearHorizontalPosition from '../../../interfaces/enums/TearPositioning/TearHorizontalPosition.enum';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

interface StatisticsProps extends BlokWithBorderIcons {
  title: string;
  sub_text: string;
  statistics: StatisticBlok[];
}

const Statistics: React.FC<StatisticsProps> = ({
  title,
  sub_text,
  statistics,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleIsVisibleChange = (newIsVisible: boolean): void => {
    if (!isVisible && newIsVisible) {
      setIsVisible(newIsVisible);
    }
  };

  const titleClassNames: string = classNames(
    'font-extrabold text-2xl transition-all duration-1000 xl:text-3xl 2xl:text-4xl',
    {
      'opacity-0': !isVisible,
      'opacity-100': isVisible,
    },
  );
  const subTextClassNames: string = classNames(
    'font-semibold text-lg transition-all duration-1000 delay-200 lg:text-xl',
    {
      'opacity-0': !isVisible,
      'opacity-100': isVisible,
    },
  );

  return (
    <InView
      as={'section'}
      className="bg-sherpa-red-main bg-Primary-900 relative"
      onChange={inView => handleIsVisibleChange(inView)}
    >
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        {/* <DarkThemeBlokTear horizontalPosition={TearHorizontalPosition.Trailing} /> */}
        <div className="mx-auto container py-12 grid grid-cols-2 gap-x-6 gap-y-10 font-montserrat text-White md:py-16 md:grid-cols-12">
          {/*-- section title --*/}
          {(title || sub_text) && (
            <div className="col-span-2 text-center space-y-3 md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
              <h3 className={titleClassNames}>{title}</h3>
              <p className={subTextClassNames}>{sub_text}</p>
            </div>
          )}

          {/*-- statistics --*/}
          {!!statistics.length && (
            <ul className="col-span-2 grid grid-cols-2 gap-x-6 gap-y-10 md:col-span-10 md:col-start-2 lg:col-span-12 lg:col-start-1 lg:grid-cols-4">
              {statistics.map((statistic: StatisticBlok, index: number) => (
                <Statistic key={statistic._uid} statistic={statistic} index={index} isVisible={isVisible} />
              ))}
            </ul>
          )}
        </div>
      </BorderIconsFactory>
    </InView>
  );
};

export default Statistics;
