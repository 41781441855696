export const parseCustomDate = (customDate: string) => {
  // Extract the day and month from the custom format
  const [day, month] = customDate.split(" ").slice(1)

  // Get the numeric day from the custom format (e.g., "23rd" -> "23")
  const numericDay = parseInt(day)

  // Convert month name to a numeric value (e.g., "Oct" -> 10)
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const numericMonth = monthNames.indexOf(month) + 1

  // Create and return the Date object
  return new Date(new Date().getFullYear(), numericMonth - 1, numericDay)
}
