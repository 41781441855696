import React from "react"

interface EditProps {
  className?: string
}

const Edit: React.FC<EditProps> = ({ className }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={`transfrom fill-current ${className}`}
    >
      <g clipPath="url(#clip0_3890_7626)">
        <path
          d="M5 28.75V35H11.25L29.6833 16.5667L23.4333 10.3167L5 28.75ZM35.6833 10.5667L29.4333 4.31665L25.2167 8.54998L31.4667 14.8L35.6833 10.5667Z"
          fill="#4A1375"
        />
      </g>
      <defs>
        <clipPath id="clip0_3890_7626">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Edit
