import React from "react"
import ReactPaginate from "react-paginate"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"
import getQueryParameters from "../../../utils/getQueryParameters.util"
import setQueryParameters from "../../../utils/setQueryParameters.util"

interface VenueIndexPaginationProps {
  pageCount: number
}

const VenueIndexPagination: React.FC<VenueIndexPaginationProps> = ({
  pageCount,
}) => {
  const Page = getQueryParameters(QueryParams.PAGE)

  const handlePageClick = (event: { selected: number }): void => {
    const pageNumber = event.selected
    setQueryParameters(`${pageNumber + 1}`, QueryParams.PAGE)
  }

  const paginationBarTopBarListItemClassNames = "h-full w-10 md:w-12"
  const paginationBarTopListItemsWithBorderRightClassNames = `${paginationBarTopBarListItemClassNames} border-r border-r-Body-Disabled`
  const paginationBarTopBarListItemAnchorClassNames =
    "w-full h-full flex items-center justify-center text-Primary-900"
  const getPaginationBarBottomBarNumberSpanClassNames = "font-bold"

  return (
    <>
      {pageCount > 0 && (
        <div className="w-full flex items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              pageRangeDisplayed={2}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              forcePage={Page ? Number(Page) - 1 : 0}
              previousLabel="<"
              containerClassName={
                "flex bg-White border border-Body-Disabled rounded-2xl h-12"
              }
              pageClassName={paginationBarTopListItemsWithBorderRightClassNames}
              previousClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              breakClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              nextClassName={paginationBarTopBarListItemClassNames}
              pageLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              previousLinkClassName={
                paginationBarTopBarListItemAnchorClassNames
              }
              breakLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              nextLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              activeLinkClassName={"bg-Primary-900 text-[#fff]"}
            />
            {/* <p className="text-Primary-900">
              Showing{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfFirstVenueOnPage()}
              </span>{" "}
              to{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfLastVenueOnPage()}
              </span>{" "}
              of{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfFilteredVenues()}
              </span>{" "}
              venues
            </p> */}
          </div>
        </div>
      )}
    </>
  )
}

export default VenueIndexPagination
