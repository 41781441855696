import React, { useState } from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import StoryblokHeadingAndRichText from "../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import RichText from "../../atoms/RichText/RichText.atom"
import Image from "../../atoms/Image/Image.atom"
import classNames from "classnames"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface WholeOfChildPillarProps extends BlokWithBorderIcons {
  image: StoryblokAsset
  copy_sections: StoryblokHeadingAndRichText[]
  theme: ThemeActivityCategory
}

const WholeOfChildPillar: React.FC<WholeOfChildPillarProps> = ({
  image,
  copy_sections,
  theme,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didImageBecomeVisible, setDidImageBecomeVisible] =
    useState<boolean>(false)
  const [didCopySectionsBecomeVisible, setDidCopySectionsBecomeVisible] =
    useState<boolean>(false)

  const THEME_ACTIVITY_PILLAR_HEADING: Record<ThemeActivityCategory, string> = {
    "Brain-Boosters": "bg-Brain-Boosters",
    "Zen-Masters": "bg-Zen-Masters",
    "Creative-Inventors": "bg-Creative-Inventors",
    "Globe-Trotters": "bg-Globe-Trotters",
    "Power-Teams": "bg-Power-Teams",
    "Splash-Zone": "bg-Splash-Zone",
    "Super-Sports": "bg-Super-Sports",
  }

  const wholeOfChildMainImageClassNames = classNames(
    "w-full aspect-video max-h-96 transition-all duration-700 ease-in-out opacity-0 translate-y-20",
    {
      "opacity-100 translate-y-0": didImageBecomeVisible,
    }
  )

  const getCopySectionClassNames = (index: number): string => {
    return classNames(
      "translate-all duration-700 ease-in-out opacity-0 translate-y-20",
      {
        "delay-100": index === 1,
        "delay-200": index === 2,
        "opacity-100 translate-y-0": didCopySectionsBecomeVisible,
      }
    )
  }

  return (
    <section>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="mx-auto container pb-12">
          {/*-- image --*/}
          <AnimateInComponent
            wrapperComponent={"div"}
            didBecomeVisible={() => setDidImageBecomeVisible(true)}
          >
            {image && image.filename && (
              <div className={wholeOfChildMainImageClassNames}>
                <Image
                  image={image}
                  objectFit="cover"
                  className="rounded-6xl"
                />
              </div>
            )}
          </AnimateInComponent>

          {/*-- copy sections --*/}
          <AnimateInComponent
            wrapperComponent={"div"}
            didBecomeVisible={() => setDidCopySectionsBecomeVisible(true)}
          >
            {copy_sections && copy_sections.length && (
              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                {copy_sections.map(
                  (
                    copy_section: StoryblokHeadingAndRichText,
                    index: number
                  ) => (
                    <div
                      key={copy_section.heading}
                      className={getCopySectionClassNames(index)}
                    >
                      <h3
                        className={classNames(
                          `text-lg font-semibold text-White rounded-6xl p-6 text-center mt-6 mb-6 md:mb-8 ${THEME_ACTIVITY_PILLAR_HEADING[theme]}`
                        )}
                      >
                        {copy_section.heading}
                      </h3>
                      <RichText content={copy_section.rich_text} />
                    </div>
                  )
                )}
              </div>
            )}
          </AnimateInComponent>
        </div>
      </BorderIconsFactory>
      <hr className="border-Body-Inactive border-dashed" />
    </section>
  )
}

export default WholeOfChildPillar
