import React from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import RichText from "../../atoms/RichText/RichText.atom"
import { ButtonProps } from "../../../interfaces/Button.interface"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"
import Button from "../../atoms/Button/Button.component"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface ArticleRichTextProps extends BlokWithBorderIcons {
  content: StoryblokRichtext
  cta: ButtonProps[]
}

const ArticleRichText: React.FC<ArticleRichTextProps> = ({
  content,
  cta,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  return (
    <section>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="grid grid-cols-2 gap-x-6 pt-10 md:grid-cols-12 lg:pt-12">
          <div className="col-span-2 md:col-span-8 md:col-start-3 space-y-8">
            <RichText
              content={content}
              isArticleRichTextConfigRequired={true}
            />
            {cta && !!cta.length && cta[0].label && cta[0].link && (
              <Button
                link={cta[0].link}
                label={cta[0].label}
                type={ButtonTypes.PRIMARY}
                size={ButtonSizeTypes.DEFAULT}
                className={"w-full sm:w-auto"}
              />
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default ArticleRichText
