import React from "react"

const HappyFace: React.FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 80C17.9557 80 0 62.0784 0 40C0 17.9557 17.9216 0 40 0C62.0443 0 79.9999 17.9216 79.9999 40C79.9659 62.0784 62.0443 80 40 80ZM40 2.69166C19.4208 2.69166 2.65747 19.4549 2.65747 40.0341C2.65747 60.6133 19.4208 77.3765 40 77.3765C60.5792 77.3765 77.3423 60.6133 77.3423 40.0341C77.3423 19.4208 60.5792 2.69166 40 2.69166Z"
        fill="#FF5E67"
      />
      <path
        d="M40.0054 63.9531C31.4535 63.9531 23.3443 59.2512 17.1433 50.6993C16.7004 50.1201 16.8368 49.2683 17.4501 48.8594C18.0293 48.4165 18.8469 48.5528 19.2899 49.1661C24.9798 57.0025 32.3393 61.3296 40.0054 61.3296C47.6715 61.3296 55.0308 57.0025 60.7208 49.1661C61.1637 48.5869 61.9816 48.4506 62.5608 48.8594C63.14 49.3023 63.2762 50.1201 62.8673 50.6993C56.6322 59.2512 48.5233 63.9531 40.0054 63.9531Z"
        fill="#FF5E67"
      />
      <path
        d="M15.4363 54.685C14.7208 54.685 14.1074 54.1058 14.1074 53.3562C14.1074 49.3698 17.208 46.1671 20.9899 46.1671C21.7054 46.1671 22.3188 46.7463 22.3188 47.4959C22.3188 48.2114 21.7395 48.8247 20.9899 48.8247C18.6731 48.8247 16.7651 50.869 16.7651 53.3903C16.7651 54.1058 16.1518 54.685 15.4363 54.685Z"
        fill="#FF5E67"
      />
      <path
        d="M64.5713 54.6855C63.8558 54.6855 63.2426 54.1063 63.2426 53.3567C63.2426 50.8354 61.3345 48.7911 59.0176 48.7911C58.3021 48.7911 57.689 48.2119 57.689 47.4623C57.689 46.7468 58.268 46.1335 59.0176 46.1335C62.7995 46.1335 65.9001 49.3703 65.9001 53.3226C65.866 54.1063 65.2868 54.6855 64.5713 54.6855Z"
        fill="#FF5E67"
      />
      <path
        d="M19.3194 32.4027C19.0468 32.4027 18.7743 32.3346 18.5358 32.1302C17.9566 31.6872 17.8202 30.8695 18.2632 30.2903C20.1371 27.8031 22.9991 26.3721 26.0996 26.3721C29.166 26.3721 31.9941 27.769 33.868 30.1881C34.3109 30.7673 34.2087 31.585 33.6295 32.028C33.0503 32.4709 32.2324 32.3687 31.7895 31.7895C30.4266 30.0177 28.3483 28.9956 26.1337 28.9956C23.8509 28.9956 21.7726 30.0518 20.4097 31.8576C20.1031 32.1983 19.7282 32.4027 19.3194 32.4027Z"
        fill="#FF5E67"
      />
      <path
        d="M47.188 32.4027C46.9155 32.4027 46.643 32.3346 46.4045 32.1302C45.8252 31.6872 45.6889 30.8695 46.1318 30.2903C47.9717 27.8372 50.9018 26.3721 53.9682 26.3721C57.0347 26.3721 59.8627 27.769 61.7367 30.1881C62.1796 30.7673 62.0773 31.585 61.4981 32.028C60.9189 32.4709 60.1011 32.3687 59.6581 31.7895C58.2953 30.0177 56.217 28.9956 54.0023 28.9956C51.7196 28.9956 49.6412 30.0518 48.2784 31.8576C47.9717 32.1983 47.5628 32.4027 47.188 32.4027Z"
        fill="#FF5E67"
      />
    </svg>
  )
}

export default HappyFace
