import { QueryParams } from "../interfaces/enums/QueryParams.enum"

const getFilterQueryParameters = (): string[] => {
  const url = new URL(
    typeof window !== "undefined"
      ? window.location.href
      : "https://www.dali-speakers.com/"
  )
  const params = new URLSearchParams(url.search)
  const value = params.get(QueryParams.FILTERS)

  return value?.split(",") || []
}

export default getFilterQueryParameters
