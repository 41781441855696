import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DotsSpinner from "../../../assets/spinners/dots.spinner";
import MobileFilterToggle from "../../../assets/svgs/MobileFilterToggle.svg";
import JobPageContext from "../../../contexts/JobsPage.context";
import { IFilteredAd, getAds, SalaryType } from "../../../services/jobAdder.service";
import { doesStringMatch, isWithinRange, isWithinRangeMaxMin, populateCountiesFilter, sliceIntoChunks } from "./helper";
import JobResultPaginationBar from "./JobResultPaginationBar/JobResultPaginationBar.component";
import JobResults from "./JobResults/JobResults.component";
import JobSearchAppliedFilters from "./JobSearchFilter/JobSearchAppliedFilters/JobSearchAppliedFilters.component";
import JobSearchFilter from "./JobSearchFilter/JobSearchFilter.component";

export interface JobSearchFilterItem {
  _uid?: string;
  name: string;
  slug?: string;
  value: string | number;
  group: string;
  content?: {
    value: string | number
  }
}

export enum FilterType {
  dynamic = 'dynamic',
  number_range = 'number_range',
  string_match =  'string_match'
}

export interface JobSearchFilterGroup {
  _uid?: string;
  name: string;
  slug?: string;
  content: {
    filter_items: JobSearchFilterItem[],
    filter_type: FilterType
  }
}

interface JobSearchFilterAndResultsProps {
  filter_heading: string;
  filter_cta: string;
  filter_groups: JobSearchFilterGroup[];
  Search_bar_placeholder_text: string;
  Card_primary_cta_label: string;
  Card_secondary_cta_label: string;
  County_Filter_Items: string[];
  No_results_copy: string;
  Page_title: string;
  filter_labels: any
};

const JobSearchFilterAndResults: React.FC<JobSearchFilterAndResultsProps> = ({
  filter_heading,
  filter_cta,
  filter_groups,
  Card_primary_cta_label,
  Card_secondary_cta_label,
}) => {
  const [
    isSmallerDeviceFilterPopupRequired,
    setIsSmallerDeviceFilterPopupRequired,
  ] = useState<boolean>(false)
  const jobsPerPage = 10;
  const [jobs, setJobs] = useState<IFilteredAd[] | []>([]);
  const [filteredJobs, setFilteredJobs] = useState<IFilteredAd[][] | []>([]);
  const [totalJobs, setTotalJobs] = useState<number | null>(null);
  const [selectedFiltersState, updateSelectedFiltersState] = useState<JobSearchFilterItem[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [searchBarValue, setSearchBarValue] = useState<string>('');
  const [filterGroups, setFilterGroups] = useState<JobSearchFilterGroup[]>([]);

  const retrieveJobs = async (offset: number, limit: number) => {
    setJobsLoading(true);
    const retrievedJobs = await getAds({ offset: String(offset), limit: String(limit), jobid: '' });
    
    if (!filterGroups.length) setFilterGroups(populateCountiesFilter(retrievedJobs, filter_groups));

    setJobs(retrievedJobs);
    
    filterJobs(retrievedJobs);
  }

  const filterJobs = (retrievedJobs: IFilteredAd[]) => {
    setJobsLoading(true);
  
    const jobsFilteredBySearch = retrievedJobs.filter((job: IFilteredAd) => {
      return job.title.toLowerCase().includes(searchBarValue.toLowerCase())
        || job.category.toLowerCase().includes(searchBarValue.toLowerCase())
        || job.summary.toLowerCase().includes(searchBarValue.toLowerCase())
        || job.countyBorough.toLowerCase().includes(searchBarValue.toLowerCase());
    });

    const searchFiltersOrganised = selectedFiltersState.reduce((filtersSorted: Record<string, (string | number)[]>, selectedFilter: JobSearchFilterItem) => {
      filtersSorted[selectedFilter.group] = filtersSorted[selectedFilter.group] ? [...filtersSorted[selectedFilter.group], selectedFilter.value] : [selectedFilter.value];
      return filtersSorted;
    }, {});

    const jobsFilteredByFilter = jobsFilteredBySearch.filter((job: IFilteredAd) => {
      if (Object.entries(searchFiltersOrganised).length === 0) return true; 

      return Object.entries(searchFiltersOrganised).every(([filterName, filterValues]) => {
        switch (filterName) {
          case 'Hours to Work':
            return isWithinRange(job.weeklyHours, filterValues);
          case 'Contract':
            return doesStringMatch(job.contractType, filterValues);
          case 'County':
            return doesStringMatch(job.countyBorough, filterValues);
          case 'Annual Salary':
            return job.salary?.ratePer === SalaryType.Year && isWithinRangeMaxMin([job.salary.rateLow, job.salary.rateHigh], filterValues);
          case 'Hourly Rate':
            return job.salary?.ratePer === SalaryType.Hour && isWithinRangeMaxMin([job.salary.rateLow, job.salary.rateHigh], filterValues);
          default:
            return true;
        }
      });
    });

    setTotalJobs(jobsFilteredByFilter.length);   
    setFilteredJobs(sliceIntoChunks(jobsFilteredByFilter, jobsPerPage));
    setCurrentPageIndex(0);
    setJobsLoading(false);
  }

  useEffect(() => {
    filterJobs(jobs.flat());
  }, [searchBarValue, selectedFiltersState])
  

  const updateSelectedFiltersCB = (filters: JobSearchFilterItem[]) => updateSelectedFiltersState(filters);  

  useEffect(() => {
    const fetchData = async () => {
      await retrieveJobs(0, 500);      
    }

    fetchData();
  }, []);

  const filterBarClassNames = classNames(
    "overflow-y-auto col-span-12 md:col-span-4 fixed md:static top-0 bottom-0 transition-all duration-300 w-full z-[52] md:left-0 bg-White md:bg-Transparent",
    {
      "-left-full delay-200": !isSmallerDeviceFilterPopupRequired,
      "left-0": isSmallerDeviceFilterPopupRequired,
    }
  )

  return (
    <section className="bg-SP7">
      <JobPageContext.Provider value={{
        filteredJobs,
        updateFilteredJobs: setFilteredJobs,
        totalNumberOfJobs: totalJobs,
        numberOfJobsPerPage: jobsPerPage,
        currentPageIndex: currentPageIndex,
        updateCurrentPageIndex: setCurrentPageIndex,
        updateSelectedFilters: updateSelectedFiltersCB,
        selectedFilters: selectedFiltersState,
        jobsLoading: jobsLoading,
        searchBarInputValue: searchBarValue,
        updateSearchBarInputValue: setSearchBarValue
      }}>
        {!jobsLoading && (
          <div>
            <div className="container mx-auto sm:gap-0 grid grid-cols-2 pt-10 pb-10 sm:grid-cols-12 sm:py-12">
              <div className="col-span-12 sm:col-span-4">
                <button
                  onClick={() => setIsSmallerDeviceFilterPopupRequired(!isSmallerDeviceFilterPopupRequired)}
                  className="mr-4 md:hidden sm:mr-0"
                >
                  <MobileFilterToggle
                    numberOfSelectedFilters={selectedFiltersState.length}
                  />
                </button>
          
                <JobSearchAppliedFilters filterButtonClicked={() => setIsSmallerDeviceFilterPopupRequired(!isSmallerDeviceFilterPopupRequired)} />                        
              </div>
              
              <div className="col-span-12 sm:col-span-7 sm:col-start-6">
                <div className="hidden md:block"><JobResultPaginationBar /></div>          
              </div>       
            </div>

            <div className="container mx-auto grid grid-cols-2 md:grid-cols-12">      
              <aside className={filterBarClassNames}>         
                <div>
                  {filterGroups && (
                    <JobSearchFilter
                      heading={filter_heading}
                      cta_label={filter_cta}
                      filters={filterGroups}      
                      clear_all_button_label={'reset'}
                      closeButtonClicked={() =>
                        setIsSmallerDeviceFilterPopupRequired(false)
                    }
                    />
                  )}
                </div>
              </aside>
              
              <div className="col-span-12 sm:col-span-7 sm:col-start-6">
                <JobResults
                  Card_primary_cta_label={Card_primary_cta_label}
                  Card_secondary_cta_label={Card_secondary_cta_label}
                  filterButtonClicked={() =>
                    setIsSmallerDeviceFilterPopupRequired(
                      !isSmallerDeviceFilterPopupRequired
                    )
                  }
                />
              </div>
            </div>

            <div className="container mx-auto gap-4 sm:gap-0 grid pt-16 pb-10 grid-cols-12 sm:py-20">        
              <div className="col-span-12 md:col-start-6">
                <JobResultPaginationBar />
              </div>        
            </div>
          </div>
        )}       
        

        {jobsLoading && (<div className='flex items-center justify-center h-full w-full py-10'><DotsSpinner /></div>)}  
        
      </JobPageContext.Provider>
    </section>
  )
}

export default JobSearchFilterAndResults;