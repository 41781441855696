/*-- npm packages --*/
import React from 'react';
import { Accordion } from 'react-accessible-accordion';

/*-- component molecules --*/
import AccordionItem from './AccordionItem.molecule';

/*-- interfaces --*/
import { NavItem } from '../../../interfaces/Navigation.interface';

/*-- assets --*/
import Close from '../../../assets/svgs/Close.svg';
import Search from '../../../assets/svgs/Search.svg';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import Button from '../../atoms/Button/Button.component';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';

interface SideNavProps {
  pre_navigation_links: NavItem[];
  login_link: StoryblokLink;
  main_navigation_links: NavItem[];
  isActive: boolean;
  toggleSideNav: (state: boolean) => void;
}
const SideNav: React.FC<SideNavProps> = ({
  pre_navigation_links,
  login_link,
  main_navigation_links,
  isActive,
  toggleSideNav,
}) => {
  return (
    <div
      className={`flex transition-all duration-[500ms] ease-in-out fixed h-screen w-screen top-0 z-50 lg:hidden ${
        isActive ? 'right-0' : '-right-full'
      }`}
    >
      {isActive && <button className="flex-1" aria-label="close" onClick={() => toggleSideNav(false)}></button>}
      <nav
        className={`bg-White lg:hidden overflow-hidden drop-shadow-sm shadow-[#000] ${
          isActive ? 'opacity-100 w-full sm:w-96' : 'opacity-0 w-0'
        }`}
      >
        <div className="flex justify-between items-center py-3 px-4">
          <p className="text-lg font-bold text-Primary-900">Menu</p>
          <button aria-label="close" onClick={() => toggleSideNav(false)}>
            <Close className="text-Primary-900" />
          </button>
        </div>

        <div className="bg-sherpa-red-main bg-SP6">
          <div className="flex justify-between items-center py-3 px-4">
            <input
              type="text"
              placeholder="Search..."
              className="bg-sherpa-red-main bg-SP6 text-White placeholder-White focus:ring-0 focus:outline-none w-full pr-4"
            />
            <button aria-label="search">
              <Search className="text-White" />
            </button>
          </div>
        </div>
        <Accordion allowZeroExpanded className="divide-y divide-Body-Disabled">
          <div>
            {main_navigation_links &&
              !!main_navigation_links.length &&
              main_navigation_links.map(link => link.display_name && <AccordionItem key={link._uid} link={link} />)}
          </div>
          <div>
            {pre_navigation_links &&
              !!pre_navigation_links.length &&
              pre_navigation_links.map(link => link.display_name && <AccordionItem key={link._uid} link={link} />)}
          </div>
          <div className="px-4 py-6">
            {login_link?.cached_url && (
              <Button
                link={login_link.cached_url}
                label={'LOGIN'}
                type={ButtonTypes.PRIMARY}
                size={ButtonSizeTypes.DEFAULT}
                className="bg-Secondary-100 hover:bg-Secondary-200 w-full"
              />
            )}
          </div>
        </Accordion>
      </nav>
    </div>
  );
};

export default SideNav;
