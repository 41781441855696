import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Play: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      aria-labelledby="Breakfast Clubs"
      className={`transfrom fill-current ${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fill-rule="evenodd">
        <path d="M-2-2h24v24H-2z" />
        <path
          fill="#FAE80B"
          d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm-2-3.5 6-4.5-6-4.5v9Z"
        />
      </g>
    </svg>
  )
}

export default Play
