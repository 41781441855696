import classNames from "classnames";
import React from "react";
import { ButtonTypes } from "../../../../interfaces/enums/ButtonTypes.enum";
import Button from "../../../atoms/AnimatedButton/AnimatedButton.component";

interface JobDescriptionProps {
  title: string;
  description: string;
  applicationLink: string;
  cta_label: string;
}

const JobDescription: React.FC<JobDescriptionProps> = ({ description, title, applicationLink, cta_label }: JobDescriptionProps) => {
  return ( 
    <>
      <div id="job-description-header" className="pb-8 border-b border-b-Body-Disabled border-t-Body-Disabled">
        <h2 className="text-2xl xl:text-3xl 2xl:text-4xl font-extrabold pb-4 lg:pb-6 text-Primary-900">
          {title}
        </h2>

        <Button
          onClick={() => {
            window.open(applicationLink);
          }}
          type={ButtonTypes.PRIMARY}
          label={cta_label}
          styles={'items-center'}
          fullWidth={false}
        />
      </div>  

      <div id="job-description-main-content" className="py-8" dangerouslySetInnerHTML={{__html: description}} />
    </>      
  )
}

export default JobDescription;