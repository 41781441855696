import React, { useContext } from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"
import Venue from "../../../../interfaces/Airtable/Venue.interface"
import FindUsPageVenueCardConfigProps from "../../../../interfaces/FindUsPage/FindUsPageVenueCardConfig.interface"
import StoryblokHeadingAndRichText from "../../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import RichText from "../../../atoms/RichText/RichText.atom"
import FindUsResultsListCard, {
  FindUsResultsCardType,
} from "./FindUsResultsListCard.component"

interface FindUsResultsListProps {
  card_config: FindUsPageVenueCardConfigProps[] | undefined
  results_not_found: StoryblokHeadingAndRichText[] | undefined
  clear_all_button_label: string
}

const FindUsResultsList: React.FC<FindUsResultsListProps> = ({
  card_config,
  results_not_found,
  clear_all_button_label,
}) => {
  const {
    filteredVenues,
    numberOfRequiredVenuesPerPage,
    currentPageIndex,
    reset,
  } = useContext(FindUsPageContext)

  const areFilteredVenueResultsFound = (): boolean => {
    return filteredVenues.flatMap(filteredVenue => filteredVenue).length > 0
  }

  const getFilteredVenuesOnCurrentPageIndex = (): Venue[] => {
    return filteredVenues[currentPageIndex]
  }

  const getResultsNotFound = (): StoryblokHeadingAndRichText | undefined => {
    return results_not_found && results_not_found[0]
  }

  const getResultsNotFoundHeading = (): string => {
    return getResultsNotFound()?.heading ?? "Results not found"
  }

  const getResultsNotFoundRichText = (): StoryblokRichtext | undefined => {
    return getResultsNotFound()?.rich_text
  }

  return (
    <section>
      {areFilteredVenueResultsFound() && (
        <ul className="pt-6 pb-10 space-y-8 md:pb-16">
          {getFilteredVenuesOnCurrentPageIndex().map((venue: Venue) => (
            <FindUsResultsListCard
              key={venue.rawVenueData.Site_Code}
              venue={venue}
              card_config={card_config}
              type={FindUsResultsCardType.LIST}
            />
          ))}
        </ul>
      )}
      {!areFilteredVenueResultsFound() && (
        <div className="w-full h-[400px] flex items-center justify-center">
          <div className="flex flex-col items-center space-y-6 w-full max-w-[500px] text-center">
            <div className="space-y-3">
              <h3 className="font-bold text-xl text-Primary-900">
                {getResultsNotFoundHeading()}
              </h3>
              {getResultsNotFoundRichText() && (
                <RichText content={getResultsNotFoundRichText()!} />
              )}
              {!getResultsNotFoundRichText() && (
                <p>
                  No results were found for the filter options that you have
                  selected. Please clear all of your filters to update the
                  results
                </p>
              )}
            </div>
            <button
              onClick={reset}
              className="font-bold text-SP4 uppercase whitespace-nowrap"
            >
              {clear_all_button_label}
            </button>
          </div>
        </div>
      )}
    </section>
  )
}

export default FindUsResultsList
