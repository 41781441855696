import { getParsedJSON } from "../getParsedJSON/getParsedJSON.util"

const getBlok = (data: any): any => {
  return getParsedJSON(
    data?.edges?.filter((edge: any) =>
      edge?.node?.full_slug?.includes(process.env.GATSBY_DOMAIN_FOLDER)
    )[0]?.node?.content
  )
}

export default getBlok
