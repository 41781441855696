import React from "react"
import {
  SiteSearchResultsListProps,
  SiteSearchResultsTitleAndDescriptionConfig,
} from "../SiteSearchProps.interface"
import {
  useInstantSearch,
  Hits,
  Highlight,
} from "react-instantsearch-hooks-web"
import { valueExists } from "../../../../utils/valueExists/valueExists.util"
import isEmptyString from "../../../../utils/isEmptyString/isEmptyString.util"
import CustomLink from "../../../atoms/CustomLink/CustomLink.atom"
import BackgroundIcons from "../../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import { BackgroundPositionTypes } from "../../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BreakpointTypes } from "../../../../interfaces/enums/BreakpointTypes.enum"

const SiteSearchResultsList: React.FC<SiteSearchResultsListProps> = ({
  background_icon,
  title,
  no_search_term_config,
  no_results_found_config,
}) => {
  const {
    results: { query, nbHits },
  } = useInstantSearch()

  const isSearchQueryPopulatedWithValue = (): boolean => {
    if (!valueExists(query)) return false
    if (isEmptyString(query)) return false
    return true
  }

  const isResultsFound = (): boolean => {
    return nbHits > 0
  }

  return (
    <div className="relative overflow-x-hidden">
      <BackgroundIcons
        type={BackgroundPositionTypes.SiteSearch}
        icon={background_icon}
        iconLeftStyle={"w-[115px] xl:w-[166px]"}
        iconRightStyle={"w-[115px] xl:w-[166px]"}
        visibleOn={BreakpointTypes.LG}
        mirrorXRight
      >
        <div className="container mx-auto grid grid-cols-2 gap-x-6 md:grid-cols-12">
          {!isSearchQueryPopulatedWithValue() && (
            <NoSearchTermEntered {...no_search_term_config} />
          )}
          {isSearchQueryPopulatedWithValue() && (
            <>
              {!isResultsFound() && (
                <NoResultsFound {...no_results_found_config} />
              )}
              {isResultsFound() && <ResultsList title={title} />}
            </>
          )}
        </div>
      </BackgroundIcons>
    </div>
  )
}

const NoSearchTermEntered: React.FC<
  SiteSearchResultsTitleAndDescriptionConfig
> = ({ title, description }) => {
  const isNoSearchTermEnteredRequired = (): boolean => {
    return isTitleRequired() || isDescriptionRequired()
  }

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title)
  }

  const isDescriptionRequired = (): boolean => {
    return valueExists(description) && !isEmptyString(description)
  }

  return (
    <div className="col-span-2 flex items-center justify-center min-h-[500px] md:col-span-8 md:col-start-3">
      {isNoSearchTermEnteredRequired() && (
        <div className="font-montserrat text-center space-y-3">
          {isTitleRequired() && (
            <h3 className="text-2xl font-extrabold">{title}</h3>
          )}
          {isDescriptionRequired() && <p className="text-lg">{description}</p>}
        </div>
      )}
    </div>
  )
}

const NoResultsFound: React.FC<SiteSearchResultsTitleAndDescriptionConfig> = ({
  title,
  description,
}) => {
  const {
    results: { query },
  } = useInstantSearch()

  const isNoResultsFoundInfoBoxRequired = (): boolean => {
    return isTitleRequired() || isDescriptionRequired()
  }

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title)
  }

  const isDescriptionRequired = (): boolean => {
    return valueExists(description) && !isEmptyString(description)
  }

  const getFormattedDescriptionText = (): JSX.Element => {
    const SEARCH_TERM_PLACEHOLDER: string = "<search-term>"
    const descriptionTextSplitIntoIndividualWords: string[] =
      description.split(" ")
    return (
      <p className="text-lg">
        {descriptionTextSplitIntoIndividualWords.map((word: string) =>
          word.includes(SEARCH_TERM_PLACEHOLDER) ? (
            <span className="text-SP1 font-semibold">{query} </span>
          ) : (
            `${word} `
          )
        )}
      </p>
    )
  }

  return (
    <div className="col-span-2 flex items-center justify-center min-h-[500px] md:col-span-8 md:col-start-3">
      {isNoResultsFoundInfoBoxRequired() && (
        <div className="font-montserrat text-center space-y-3">
          {isTitleRequired() && (
            <h3 className="text-2xl font-extrabold">{title}</h3>
          )}
          {isDescriptionRequired() && getFormattedDescriptionText()}
        </div>
      )}
    </div>
  )
}

interface ResultsListProps {
  title: string
}

const ResultsList: React.FC<ResultsListProps> = ({ title }) => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = "<number-of-results-found>"

  const {
    results: { nbHits },
  } = useInstantSearch()

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title)
  }

  const getNumberOfResultsFound = (): string => {
    return `${nbHits}` ?? "0"
  }

  const getFormattedTitle = (): JSX.Element => {
    return (
      <h2 className="font-extrabold text-xl text-Primary-900 pb-4 md:text-2xl">
        {title.replaceAll(
          NUMBER_OF_RESULTS_PLACEHOLDER,
          getNumberOfResultsFound()
        )}
      </h2>
    )
  }

  const ResultListItem = ({ hit }): JSX.Element => {
    return (
      <CustomLink
        className="flex border-b border-b-Body-Inactive border-dashed py-6 space-x-4"
        url={hit.slug.replace(`${process.env.GATSBY_DOMAIN_FOLDER}/`, "")}
      >
        <div className="flex-1">
          <h4 className="text-lg text-Primary-900 font-semibold">
            <Highlight attribute="name" hit={hit} />
          </h4>
          <p className="text-[#3D3D3D]">
            <Highlight attribute="description" hit={hit} />
          </p>
        </div>
      </CustomLink>
    )
  }

  return (
    <div className="col-span-2 md:col-span-8 md:col-start-3">
      {isTitleRequired() && getFormattedTitle()}
      <Hits hitComponent={ResultListItem} />
    </div>
  )
}

export default SiteSearchResultsList
