import React, { memo, useMemo } from 'react';
import { Container } from 'postcss';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import ChevronRight from '../../../assets/svgs/ChevronRight.svg';
import ChevronLeft from '../../../assets/svgs/ChevronLeft.svg';
import { Breadcrumb } from '../../../contexts/LayoutContext';
import classNames from 'classnames';

interface BreadcrumbBarProps {
  breadcrumbs: Breadcrumb[];
  label: string;
  breadcrumb_override?: string;
  hasBackground: boolean;
}

const BreadcrumbBar: React.FC<BreadcrumbBarProps> = ({ breadcrumbs, label, breadcrumb_override, hasBackground }) => {
  const previousBreadcrumb = useMemo<Breadcrumb>(() => {
    return breadcrumbs?.at(-1)!; // we can unwrap this
  }, [breadcrumbs]);

  return (
    <section className={classNames({ 'bg-Background-Texture-Primary': hasBackground })}>
      {/* <BreadcrumbListStructuredData breadcrumbs={breadcrumbs} /> */}
      <div className={'container mx-auto'}>
        {/*-- mobile --*/}
        <div className="flex items-center justify-start h-[68px] sm:hidden">
          {previousBreadcrumb && (
            <>
              <ChevronLeft className="text-Primary-900" />
              <CustomLink url={previousBreadcrumb.slug} className="py-6 text-Primary-900 text-sm">
                {previousBreadcrumb.label}
              </CustomLink>
            </>
          )}
        </div>

        {/*-- tablet and desktop --*/}
        <ul className="hidden sm:flex sm:items-center sm:justify-start sm:h-full sm:gap-x-2 sm:text-sm">
          {breadcrumbs?.map((breadcrumb: Breadcrumb) => (
            <li key={breadcrumb.slug} className="flex items-center justify-start gap-x-2 h-[68px]">
              <CustomLink url={breadcrumb.slug} className="py-6 text-Primary-900 hover:underline">
                {breadcrumb.label}
              </CustomLink>
              <ChevronRight className="text-Primary-900" />
            </li>
          ))}
          <li key="currentPath" className="text-Body-Inactive">
            {breadcrumb_override || label}
          </li>
        </ul>
      </div>
    </section>
  );
};

export default BreadcrumbBar;
