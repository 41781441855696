import React from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import QuoteIconLeft from '../../../assets/svgs/QuoteIconLeft.svg';
import QuoteIconRight from '../../../assets/svgs/QuoteIconRight.svg';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Image from '../../atoms/Image/Image.atom';

interface QuoteProps extends Blok {
  quote: string;
  icon: StoryblokAsset;
}

const Quote: React.FC<QuoteProps> = ({ quote, icon }) => {
  return (
    <section className="grid grid-cols-2 gap-x-6 pt-10 md:grid-cols-12 md:pt-12">
      <div className="hidden xl:py-5 xl:col-span-2 xl:flex xl:items-center xl:justify-center">
        {icon ? <Image image={icon} maxWidth={70} maxHeight={70} /> : <QuoteIconLeft />}
      </div>
      <p className="border-t border-dashed border-t-Body-Disabled pt-10 col-span-2 font-montserrat font-semibold text-xl text-Primary-900 md:col-span-10 md:col-start-2 lg:pt-12 lg:col-span-8 lg:col-start-3">
        {quote}
      </p>
      <div className="hidden xl:py-5 xl:col-span-2 xl:col-start-11 xl:flex xl:items-center xl:justify-center">
        {icon ? <Image image={icon} maxWidth={70} maxHeight={70} className="scale-x-[-1]" /> : <QuoteIconRight />}
      </div>
    </section>
  );
};

export default Quote;
