import React, { useState } from "react"
import { StoryblokIconListItem } from "../../../interfaces/Storyblok/StoryblokIconList.interface"
import PageIntroProps from "./PageIntroProps.interface"
import PageIntroGenerator from "./PageIntroGenerator.component"
import Image from "../../atoms/Image/Image.atom"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import classNames from "classnames"

interface PageIntroWithListProps extends PageIntroProps {
  list: StoryblokIconListItem[]
}

const PageIntroWithList: React.FC<PageIntroWithListProps> = props => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  const getListItemClassNames = (index: number): string => {
    return classNames(
      "col-span-3 flex items-center space-x-3 transition-all duration-700 opacity-0 translate-y-20 ease-in-out",
      {
        "delay-100": index === 1,
        "delay-200": index === 2,
        "delay-300": index === 3,
        "delay-400": index === 4,
        "delay-500": index === 5,
        "delay-600": index === 6,
        "delay-700": index === 7,
        "opacity-100 translate-y-0": didBecomeVisible,
      }
    )
  }

  const getRightColumn = (): JSX.Element => {
    return (
      <AnimateInComponent
        wrapperComponent={"ul"}
        classNames="grid md:grid-cols-3 lg:grid-cols-3 3xl:grid-cols-6 gap-y-6 md:gap-x-6"
        didBecomeVisible={() => setDidBecomeVisible(true)}
      >
        {props.list.map((listItem: StoryblokIconListItem, index: number) => (
          <li key={listItem._uid} className={getListItemClassNames(index)}>
            <div className="w-[32px] h-[32px] min-w-[32px] min-h-[32px] lg:w-[40px] lg:h-[40px] lg:min-w-[40px] lg:min-h-[40px]">
              {listItem?.icon?.filename && <Image image={listItem.icon} />}
            </div>
            <p className="text-base md:text-lg text-Body leading-6 font-semibold">
              {listItem.label}
            </p>
          </li>
        ))}
      </AnimateInComponent>
    )
  }

  return (
    <PageIntroGenerator
      default_props={{ ...props }}
      right_column={getRightColumn()}
    />
  )
}

export default PageIntroWithList
