import React, { useState } from "react"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import { navigate } from "gatsby"
import Button from "../../atoms/Button/Button.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"

interface SearchBarDropdownProps {
  search_link: StoryblokLink
  search_bar_placeholder: string
  search_bar_button_label: string
  closeButtonClicked: () => void
}

const SearchBarDropdown: React.FC<SearchBarDropdownProps> = ({
  search_link,
  search_bar_placeholder,
  search_bar_button_label,
  closeButtonClicked,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("")

  const getCurrentURL = (): string => {
    return window.location.host + window.location.pathname
  }

  const getNewURL = (): string => {
    return `${window.location.host}/${search_link.cached_url.replace(
      `${process.env.GATSBY_DOMAIN_FOLDER}`,
      ""
    )}`
  }

  const isCurrentURLEqualToNewURL = (): boolean => {
    return getNewURL() == getCurrentURL()
  }

  const navigateToSearchPage = (): void => {
    const searchURL: string = `/${search_link.cached_url.replace(
      `${process.env.GATSBY_DOMAIN_FOLDER}`,
      ""
    )}${searchTerm != "" ? `?q=${encodeURI(searchTerm)}` : ""}`

    navigate(searchURL)
    isCurrentURLEqualToNewURL() &&
      setTimeout(() => {
        window.location.reload()
      }, 100) /*-- we may need to increase this time --*/
  }

  const handleEnterButtonPressed = (e: KeyboardEvent): void => {
    if (e.key === "Enter") {
      navigateToSearchPage()
    }
  }

  return (
    <div>
      <div className="container mx-auto grid grid-cols-6 py-6 md:grid-cols-12 md:py-8">
        <div className="col-span-5 border-b border-b-Primary-900 pb-4 flex flex-col md:col-span-8 md:col-start-3 md:flex-row md:pb-6">
          <input
            className="form-input border-none focus:outline-none px-3 focus:ring-0 bg-Primary/p900 w-full ring-0 font-lato font-medium placeholder-Neutral/200 text-Core/White"
            type="text"
            placeholder={search_bar_placeholder}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            onKeyDown={e => handleEnterButtonPressed(e)}
          />
          <Button
            label={search_bar_button_label}
            type={ButtonTypes.PRIMARY}
            size={ButtonSizeTypes.LG}
            className="lg:ml-6"
            onClick={() => navigateToSearchPage()}
          ></Button>
        </div>
        <button
          className="col-span-1 flex items-start justify-center md:col-span-1 md:col-start-11"
          onClick={closeButtonClicked}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2757_27360)">
              <path
                d="M25.3333 8.54663L23.4533 6.66663L16 14.12L8.54663 6.66663L6.66663 8.54663L14.12 16L6.66663 23.4533L8.54663 25.3333L16 17.88L23.4533 25.3333L25.3333 23.4533L17.88 16L25.3333 8.54663Z"
                fill="#4B1375"
              />
            </g>
            <defs>
              <clipPath id="clip0_2757_27360">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default SearchBarDropdown
