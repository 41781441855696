import React, { useState } from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import Modal from "../../molecules/Modal/Modal.molecule"
import YoutubeVideoPlaceholder from "../../molecules/YoutubeVideo/YoutubeVideoPlaceholder.molecule"
import YoutubeVideo from "../../atoms/YoutubeVideo/YoutubeVideo.atom"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"

interface ArticleYoutubeVideoProps extends Blok {
  video_id: string
  placeholder_image: StoryblokAsset
}

const ArticleYoutubeVideo: React.FC<ArticleYoutubeVideoProps> = ({
  video_id,
  placeholder_image,
}) => {
  const [onVideoPlay, setOnVideoPlay] = useState<boolean>(false)

  return (
    <section className="grid grid-cols-2 gap-x-6 pt-10 md:grid-cols-12 md:pt-12">
      <div className="col-span-2 md:col-span-8 md:col-start-3">
        <div className="rounded-6xl w-full overflow-clip">
          <YoutubeVideoPlaceholder
            image={placeholder_image}
            toggleVideo={setOnVideoPlay}
          ></YoutubeVideoPlaceholder>
        </div>
        <Modal toggleModal={onVideoPlay} onClose={setOnVideoPlay}>
          <div className="aspect-video w-full max-h-full">
            <YoutubeVideo
              video_id={video_id}
              videoIsPlaying={onVideoPlay}
              loop={false}
            ></YoutubeVideo>
          </div>
        </Modal>
      </div>
    </section>
  )
}

export default ArticleYoutubeVideo
