import React, { useEffect, useState } from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"

export interface PrimaryActionCardItemProps {
  heading: string
  icon: StoryblokAsset
  activeIcon: StoryblokAsset
  setActiveCard: (state: number) => void
  setSelectedOption: (state: number) => void
  selectedOption: number
  cardItemOption: number
}

const PrimaryActionCardItem: React.FC<PrimaryActionCardItemProps> = ({
  heading,
  icon,
  activeIcon,
  setSelectedOption,
  setActiveCard,
  selectedOption,
  cardItemOption,
}) => {
  return (
    <div
      className={`rounded-[16px] flex justify-center flex-wrap py-8 md:py-10 px-4 md:px-8 cursor-pointer hover:bg-SP3 hover:shadow-[0_8px_30px_rgb(0,0,0,0.13)] transition-all duration-[300ms] ${
        selectedOption === cardItemOption ? "bg-SP3" : "bg-White"
      }`}
      onClick={() => {
        setActiveCard(2)
        setSelectedOption(cardItemOption)
      }}
    >
      <p
        className={`text-center text-xl md:text-2xl font-extrabold w-full pb-2 md:pb-4 ${
          selectedOption === cardItemOption ? "text-Primary-900" : "text-Body"
        }`}
      >
        {heading}
      </p>
      {selectedOption === cardItemOption ? (
        <div className="w-[90px] h-[90px]">
          <Image image={activeIcon} />
        </div>
      ) : (
        <div className="w-[90px] h-[90px]">
          <Image image={icon} />
        </div>
      )}
      <div className="w-full flex justify-center pt-2 md:pt-4">
        <button
          className={`w-[33.3px] h-[33.3px] border-[3px] rounded-full flex justify-center items-center ${
            selectedOption === cardItemOption
              ? "border-Primary-900"
              : "border-Body"
          }`}
        >
          <div
            className={`w-[16px] h-[16px] rounded-full transition-all ${
              selectedOption === cardItemOption
                ? "bg-Primary-900"
                : "bg-transparent"
            }`}
          />
        </button>
      </div>
    </div>
  )
}

export default PrimaryActionCardItem
