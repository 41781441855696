import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../../../interfaces/SEO.interface"

interface MetaProps {
  seo: SEO
  url: string
}

const Meta: React.FC<MetaProps> = ({ seo, url }) => {
  return (
    <>
      <meta charSet="utf-8" />
      {seo?.title && <title>{seo.title}</title>}
      <meta name="viewport" content="width=device-width" />
      {seo?.description && (
        <meta name="description" content={seo.description} />
      )}
      {seo?.og_title && (
        <meta
          property="og:title"
          content={`${seo.og_title} ${seo.og_title && `|`}`}
        />
      )}
      {process.env.SITE_TITLE && (
        <meta property="og:site_name" content={process.env.SITE_TITLE} />
      )}
      {seo?.og_description && (
        <meta property="og:description" content={seo.og_description} />
      )}
      {seo?.og_image && <meta property="og:image" content={seo.og_image} />}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      {seo?.twitter_title && (
        <meta name="twitter:title" content={`${seo.twitter_title}`} />
      )}
      {seo?.twitter_description ||
        (seo?.description && (
          <meta
            name="twitter:description"
            content={`${seo.twitter_description || seo.description}`}
          />
        ))}
      {seo?.twitter_image && (
        <meta name="twitter:image" content={seo.twitter_image} />
      )}
      <meta
        name="facebook-domain-verification"
        content="v95rbt8n9krpm0tb7xevn7qgj1ockp"
      />
      <meta name="language" content={"English"} />
      <link rel="canonical" href={url} />
    </>
  )
}

export default Meta
