/*-- npm packages --*/
import React from "react"

/*-- atoms --*/
import CustomLink from "../CustomLink/CustomLink.atom"

/*-- assets --*/
import Instagram from "../../../assets/svgs/Instagram.svg"
import Twitter from "../../../assets/svgs/Twitter.svg"
import Linkedin from "../../../assets/svgs/Linkedin.svg"
import Facebook from "../../../assets/svgs/Facebook.svg"
import Youtube from "../../../assets/svgs/Youtube.svg"

export enum SocialLinkType {
  INSTAGRAM = "INSTAGRAM",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  FACEBOOK = "FACEBOOK",
  YOUTUBE = "YOUTUBE",
}

interface SocialLinkProps {
  type: SocialLinkType
  url: string
}

const SocialLink: React.FC<SocialLinkProps> = ({ type, url }) => {
  const SVG_CLASSES: string =
    "text-Primary-900 group-hover:text-White transition-all"
  const SECONDARY_SVG_FILL_CLASSES: string =
    "text-White group-hover:text-Primary-900 transition-all"

  const getSocialIcon = (): React.ReactNode => {
    switch (type) {
      case SocialLinkType.INSTAGRAM:
        return (
          <Instagram
            primaryFill={SVG_CLASSES}
            secondaryFill={SECONDARY_SVG_FILL_CLASSES}
          />
        )
      case SocialLinkType.TWITTER:
        return <Twitter className={SVG_CLASSES} />
      case SocialLinkType.LINKEDIN:
        return <Linkedin className={SVG_CLASSES} />
      case SocialLinkType.FACEBOOK:
        return <Facebook className={SVG_CLASSES} />
      case SocialLinkType.YOUTUBE:
        return (
          <Youtube
            primaryFill={SVG_CLASSES}
            secondaryFill={SECONDARY_SVG_FILL_CLASSES}
          />
        )
    }
  }

  return (
    <CustomLink
      className="w-[64px] h-[64px] rounded-full flex items-center justify-center group transition-all bg-White hover:bg-Primary-900"
      url={url}
      aria-label={`${type.toLowerCase()}-social-button`}
    >
      {getSocialIcon()}
    </CustomLink>
  )
}

export default SocialLink
