/*-- npm packages --*/
import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

/*-- component molecules --*/
import VenueHero from '../molecules/VenuePageSections/VenueHero.component';
import VenueInfo from '../molecules/VenuePageSections/VenueInfo.component';
import VenueClubCards from '../molecules/VenuePageSections/VenueClubCards.component';
import VenueClubSchedule from '../molecules/VenuePageSections/VenueClubSchedule.component';
import VenueAvailabilityChecker from '../molecules/VenuePageSections/VenueAvailabilityChecker.component';
import VenueNearbyVenues from '../molecules/VenuePageSections/VenueNearbyVenues.component';

/*-- component utils --*/
import DynamicComponent from '../utils/DynamicComponent/DynamicComponent.component';

/*-- styles --*/
import { layoutClassNames } from '../../styles/ClassNames/global.classes';

/*-- utils --*/
import renderChildren from '../../utils/renderBloks.util';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';
import getBlok from '../../utils/getBlok/getBlok.util';

/*-- interfaces --*/
import { StoryblokAsset } from '../../interfaces/Storyblok/StoryblokAsset.interface';
import TemplateProps from '../../interfaces/TemplateProps.interface';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import { ServiceCardProps } from '../../interfaces/VenuePage/Services.interface';
import SEO from '../../interfaces/SEO.interface';

/*-- contexts --*/
import VenuesContext, { Venue } from '../../contexts/VenuesContext';
import FiltersContext from '../../contexts/FiltersContext';
import VenueNoDataFound from '../molecules/VenuePageSections/VenueNoDataFound.component';
import HolidayProgramSchedule from '../molecules/VenuePageSections/HolidayProgramSchedule.component';
import { ButtonProps } from '../../interfaces/Button.interface';
import LayoutContext from '../../contexts/LayoutContext';
import BreadcrumbBar from '../bloks/BreadcrumbBar/BreadcrumbBar';

const VenuePageTemplate: React.FC<TemplateProps> = ({ context }) => {
  const page_name = context.name;

  const {
    hero_image,
    site_code,
    venue_logo,
    secondary_cta,
    page_footer,
    breadcrumb_override,
    has_background,
  }: {
    SEO?: SEO;
    site_code: string;
    service_cards: ServiceCardProps[];
    hero_image: StoryblokAsset;
    venue_logo: StoryblokAsset;
    secondary_cta: ButtonProps[];
    page_footer: Blok[];
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;

  const field_component = context.field_component ?? 'Page';

  const {
    header,
    venueHero,
    venueInfo,
    venueClubCards,
    venueNoDataFound,
    nearbyVenues,
    availabilityChecker,
    venueClubSchedule,
    zigZagLeft,
    zigZagRight,
    holidayProgramSchedule,
    newsletter,
    footer,
    countdowns,
    banners,
  } = useStaticQuery(graphql`
    {
      header: allStoryblokEntry(filter: { field_component: { eq: "Header" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      venueHero: allStoryblokEntry(filter: { field_component: { eq: "VenueHero" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      venueInfo: allStoryblokEntry(filter: { field_component: { eq: "VenueInfo" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      venueClubCards: allStoryblokEntry(filter: { field_component: { eq: "VenueClubCards" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      venueNoDataFound: allStoryblokEntry(filter: { field_component: { eq: "VenueNoDataFound" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      availabilityChecker: allStoryblokEntry(filter: { field_component: { eq: "AvailabilityChecker" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      venueClubSchedule: allStoryblokEntry(filter: { field_component: { eq: "VenueClubSchedule" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      nearbyVenues: allStoryblokEntry(filter: { field_component: { eq: "NearbyVenues" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      zigZagLeft: allStoryblokEntry(filter: { field_component: { eq: "ZigZagContentLeft" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      zigZagRight: allStoryblokEntry(filter: { field_component: { eq: "ZigZagContentRight" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      holidayProgramSchedule: allStoryblokEntry(filter: { field_component: { eq: "HolidayProgramSchedule" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      newsletter: allStoryblokEntry(filter: { field_component: { eq: "Newsletter" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      footer: allStoryblokEntry(filter: { field_component: { eq: "Footer" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      countdowns: allStoryblokEntry(sort: { fields: id }, filter: { field_component: { eq: "Countdown" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);

  // global bloks
  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);
  const breadcrumbs = useContext(LayoutContext);

  // Venue bloks
  const venueNoDataFoundBlok = getBlok(venueNoDataFound);
  const venueHeroBlok = getBlok(venueHero);
  const venueInfoBlok = getBlok(venueInfo);
  const venueClubCardsBlok = getBlok(venueClubCards);
  const venueClubScheduleBlok = getBlok(venueClubSchedule);
  const availabilityCheckerBlok = getBlok(availabilityChecker);
  const zigZagLeftBlok = getBlok(zigZagLeft);
  const zigZagRightBlok = getBlok(zigZagRight);
  const zigZagLightLeftBlok = {
    ...zigZagLeftBlok,
    component: 'zig_zag_content_light',
    reversed: true,
  };
  const zigZagLightRightBlok = {
    ...zigZagRightBlok,
    component: 'zig_zag_content_light',
    reversed: false,
  };
  const holidayProgramScheduleBlok = getBlok(holidayProgramSchedule);
  const nearbyVenuesBlok = getBlok(nearbyVenues);

  const venues: Venue[] = useContext(VenuesContext);
  const { buildings, clubs } = useContext(FiltersContext);
  const venue: Venue | undefined = venues.filter((venue: Venue) => venue.id == site_code)[0];

  const venue_nearby_venues: Venue[] = venues.filter((loopedVenue: Venue) =>
    venue?.nearbyVenues?.includes(loopedVenue.id),
  );

  const getCountdownBlok = (content: any) => {
    const edges = content?.edges?.filter((edge: any) => edge.node.full_slug.includes(process.env.GATSBY_DOMAIN_FOLDER));

    if (!edges) return null;

    const allCountdownBlocks = edges.map((edge: any) => JSON.parse(edge.node.content));

    // Filter out countdowns that don't have this page uuid attached
    const countdownsForThisPageType = allCountdownBlocks.filter((countdown: any) =>
      countdown.show_on_content_types.includes(field_component),
    );

    // Take the highest priority countdown
    const countdownBlok = countdownsForThisPageType.sort((a: any, b: any) => a.priority - b.priority)[0];

    return countdownBlok;
  };

  const countdownBlok = getCountdownBlok(countdowns);

  return (
    <BaseDataLayer field_component={context.field_component}>
      <div className={layoutClassNames}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        {!venue && <VenueNoDataFound page_name={page_name} {...venueNoDataFoundBlok} />}
        {venue && (
          <>
            {/*-- venue hero --*/}
            <VenueHero
              venue={venue}
              hero_image={hero_image}
              {...venueHeroBlok}
              buildings={buildings}
              secondary_cta={secondary_cta}
            />

            {/*-- countdown blok --*/}
            {countdownBlok && <DynamicComponent blok={countdownBlok} />}

            {breadcrumbs && (
              <BreadcrumbBar
                {...breadcrumbs}
                breadcrumb_override={breadcrumb_override}
                hasBackground={has_background}
              />
            )}
            {/*
            {/*-- venue info --*/}
            <VenueInfo venue={venue} venue_logo={venue_logo} {...venueInfoBlok} clubs={clubs} />

            {/*-- venue club schedule --*/}
            <VenueClubSchedule venue={venue} clubs={clubs} {...venueClubScheduleBlok} />

            {/*-- venue holiday program schedule --*/}
            <HolidayProgramSchedule venue={venue} {...holidayProgramScheduleBlok} />

            {/*-- venue clubs cards --*/}
            <VenueClubCards venue={venue} {...venueClubCardsBlok} />

            {/*-- venue availability checker --*/}
            <VenueAvailabilityChecker venue={venue} {...availabilityCheckerBlok} />

            {/*-- venue nearby clubs --*/}
            <VenueNearbyVenues venue={venue} nearby_venues={venue_nearby_venues} clubs={clubs} {...nearbyVenuesBlok} />

            {/*-- zig zags --*/}
            {zigZagLeftBlok && <DynamicComponent blok={zigZagLightLeftBlok} />}
            {zigZagRightBlok && <DynamicComponent blok={zigZagLightRightBlok} />}
          </>
        )}
        {page_footer && renderChildren(page_footer)}
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
        <textarea
          id="review-summary"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="true"
          className="textArea form-control validate[required,maxSize[1800],minSize[50]]"
          placeholder="Describe the main reasons behind your ratings"
          data-idx-value="review-summary"
          style={{ height: '74px' }}
        />
      </div>
    </BaseDataLayer>
  );
};

export default VenuePageTemplate;
