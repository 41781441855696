import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"

interface ArticleImageVideoProps extends Blok {
  image: StoryblokAsset
  source: string
}

const ArticleImage: React.FC<ArticleImageVideoProps> = ({ image, source }) => {
  return (
    <section className="grid grid-cols-2 gap-x-6 pt-10 md:grid-cols-12 md:pt-12">
      <div className="col-span-2 flex flex-col items-start justify-start space-y-3 md:col-span-8 md:col-start-3">
        {image && (
          <div className="rounded-6xl w-full overflow-clip">
            <Image image={image} objectFit="cover" />
          </div>
        )}
        {source && (
          <p className="text-Body text-sm">
            <strong>Source: </strong>
            {source}
          </p>
        )}
      </div>
    </section>
  )
}

export default ArticleImage
