import classNames from "classnames";
import React from "react";
import Email from "../../../assets/svgs/Email.svg";

interface JobApplicationSupportProps {
  email: string;
  telephone?: string;
}

const JobApplicationSupport: React.FC<JobApplicationSupportProps> = ({ email }) => {
  const listItemStyles = classNames('break-all flex items-center gap-4 font-bold text-Primary-900');

  return (
    <div className="py-10 flex flex-col gap-6 border-b border-t border-b-Body-Disabled border-t-Body-Disabled">
      <h2 className="font-extrabold leading-6 text-2xl text-Primary-900">Support</h2>
      <p>
        If you would like further information and a confidential discussion beforehand to gauge if this is the right role for you, get in touch today on: 
      </p>
      <ul className="flex flex-col gap-6">
        {/* <li className={listItemStyles}><i><Phone /></i><a href={`tel:${telephone}`}>{telephone}</a></li> */}
        {email && (<li className={listItemStyles}><i><Email /></i><a className="break-all" href={`mailto:${email}`}>{email}</a></li>)}
      </ul>
    </div>
  )
}

export default JobApplicationSupport;