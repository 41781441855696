import React from "react"
import Venue from "../interfaces/Airtable/Venue.interface"
import Coordinates from "../interfaces/Coordinates.interface"
import FilterableItem from "../interfaces/Storyblok/StoryblokFilterableItem.interface"

interface FindUsPageContext {
  isInitialLocalStorageCheckComplete: boolean
  searchBarInputValue: string
  searchBarInputValueCoordinates: Coordinates | undefined
  filteredVenues: Venue[][]
  isUpdatingFilteredVenues: boolean
  selectedMapVenue: Venue | undefined
  numberOfRequiredVenuesPerPage: number
  currentPageIndex: number
  selectedFilters: FilterableItem[]
  setIsInitialLocalStorageCheckComplete: (value: boolean) => void
  updateSearchBarInputValue: (value: string) => void
  updateFilteredVenues: () => void
  updateSelectedMapVenue: (selectedMapVenue: Venue | undefined) => void
  updateNumberOfRequiredVenuesPerPage: (
    numberOfRequiredVenuesPerPage: number
  ) => void
  updateCurrentPageIndex: (newPageIndex: number) => void
  updateSelectedFilters: (selectedFilters: FilterableItem[]) => void
  searchBarPrimaryButtonWasClicked: () => void
  reset: () => void
}

const FindUsPageContext = React.createContext<FindUsPageContext>({
  isInitialLocalStorageCheckComplete: false,
  searchBarInputValue: "",
  searchBarInputValueCoordinates: undefined,
  filteredVenues: [],
  isUpdatingFilteredVenues: false,
  selectedMapVenue: undefined,
  numberOfRequiredVenuesPerPage: 8,
  currentPageIndex: 0,
  selectedFilters: [],
  setIsInitialLocalStorageCheckComplete() {},
  updateSearchBarInputValue() {},
  updateFilteredVenues() {},
  updateSelectedMapVenue() {},
  updateNumberOfRequiredVenuesPerPage() {},
  updateCurrentPageIndex() {},
  updateSelectedFilters() {},
  searchBarPrimaryButtonWasClicked() {},
  reset() {},
})

export default FindUsPageContext
