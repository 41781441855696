import React, { useState } from "react"
import classNames from "classnames"
import ComparisonCard from "../../molecules/Comparison/ComparisonCard.molecule"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"

// types
import { CardProps } from "../../../interfaces/cards/ComparisonCard.interface"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface ComparisonProps extends BlokWithBorderIcons {
  cards: CardProps[]
}

const Upcomingholidays: React.FC<ComparisonProps> = ({
  cards,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  const getComparisonCardContainerClassNames = (index: number): string => {
    return classNames(
      "first:pb-16 first:md:pb-8 first:lg:pb-0 col-span-6 transition-all duration-700 delay-500 lg:delay-200 2xl:col-span-5 first:col-start-1 2xl:first:col-start-2",
      {
        "opacity-0": !didBecomeVisible,
        "translate-y-20 lg:translate-y-0 lg:-translate-x-20":
          !didBecomeVisible && index === 0,
        "translate-y-20 lg:translate-y-0 lg:translate-x-20":
          !didBecomeVisible && index === 1,
        "opacity-100 translate-y-0 lg:translate-x-0": didBecomeVisible,
      }
    )
  }

  return (
    <section>
      <BorderIconsFactory type={BorderIconType.Blok}>
        <div className="container mx-auto py-20 md:py-12 lg:py-20 3xl:py-24">
          <AnimateInComponent
            wrapperComponent={"div"}
            classNames={"lg:grid grid-cols-12 lg:gap-x-6 auto-rows-max"}
            didBecomeVisible={() => setDidBecomeVisible(true)}
          >
            {cards &&
              !!cards.length &&
              cards.map(
                (
                  {
                    image,
                    heading,
                    body_text,
                    cta,
                    _uid,
                    border_icon_top_left,
                    border_icon_top_right,
                    border_icon_bottom_left,
                    border_icon_bottom_right,
                  },
                  index: number
                ) => (
                  <div
                    className={getComparisonCardContainerClassNames(index)}
                    key={_uid}
                  >
                    <ComparisonCard
                      _uid={_uid}
                      image={image}
                      heading={heading}
                      body_text={body_text}
                      cta={cta}
                      border_icon_top_left={border_icon_top_left}
                      border_icon_top_right={border_icon_top_right}
                      border_icon_bottom_left={border_icon_bottom_left}
                      border_icon_bottom_right={border_icon_bottom_right}
                      component={"we don't need this"}
                      _editable={"we don't need this"}
                    />
                  </div>
                )
              )}
          </AnimateInComponent>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default Upcomingholidays
