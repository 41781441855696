import React from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import StoryblokHeadingAndRichText from "../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import RichText from "../../atoms/RichText/RichText.atom"

interface VenueIndexResultsNotFoundProps {
  results_not_found: StoryblokHeadingAndRichText[] | undefined
  clear_all_label: string
}

const VenueIndexResultsNotFound: React.FC<VenueIndexResultsNotFoundProps> = ({
  results_not_found,
  clear_all_label,
}) => {
  const getResultsNotFound = (): StoryblokHeadingAndRichText | undefined => {
    return results_not_found && results_not_found[0]
  }

  const getResultsNotFoundHeading = (): string => {
    return getResultsNotFound()?.heading ?? "Results not found"
  }

  const getResultsNotFoundRichText = (): StoryblokRichtext | undefined => {
    return getResultsNotFound()?.rich_text
  }

  return (
    <div className="w-full h-[400px] flex items-center justify-center">
      <div className="flex flex-col items-center space-y-6 w-full max-w-[500px] text-center">
        <div className="space-y-3">
          <h3 className="font-bold text-xl text-Primary-900">
            {getResultsNotFoundHeading()}
          </h3>
          {getResultsNotFoundRichText() && (
            <RichText content={getResultsNotFoundRichText()!} />
          )}
          {!getResultsNotFoundRichText() && (
            <p>
              No results were found for the filter options that you have
              selected. Please clear all of your filters to update the results
            </p>
          )}
        </div>
        <button
          onClick={() => setQueryParameters([], QueryParams.FILTERS)}
          className="font-bold text-SP4 uppercase whitespace-nowrap"
        >
          {clear_all_label}
        </button>
      </div>
    </div>
  )
}

export default VenueIndexResultsNotFound
