import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { isEditMode } from "../../../utils/isEditMode.util"
import CTACard, { CTACardProps } from "./CTACard.blok"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface CTACardsListProps extends BlokWithBorderIcons {
  cards: CTACardProps[]
}

const CTACardsList: React.FC<CTACardsListProps> = ({
  cards,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const getEditModeCTACard = (index: number): CTACardProps => {
    const EMPTY_CTA_CARD: CTACardProps = {
      _uid: `empty_cta_card_${index}`,
      component: "cta_card",
      _editable: "",
      image: undefined,
      heading: undefined,
      body: undefined,
      cta: [],
    }
    return cards[index] ?? EMPTY_CTA_CARD
  }

  if (isEditMode()) {
    return (
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <section className="pt-16 pb-10 lg:py-16 xl:py-20">
          <ul className="container mx-auto grid grid-cols-2 gap-y-6 md:grid-cols-12 md:gap-x-6 md:gap-y-10 lg:py-12">
            {Array(cards.length < 3 ? 3 : cards.length)
              .fill(1)
              .map((_, index) => (
                <CTACard
                  key={`loading-cta-card--${index + 1}`}
                  {...getEditModeCTACard(index)}
                />
              ))}
          </ul>
        </section>
      </BorderIconsFactory>
    )
  }

  return (
    <>
      {!!cards.length && (
        <section>
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <ul className="container mx-auto grid grid-cols-2 gap-y-6 md:grid-cols-12 md:gap-x-6 md:gap-y-10 lg:py-12">
              {cards.map((card: CTACardProps, index: number) => (
                <CTACard key={`cta-card--${index + 1}`} {...card} />
              ))}
            </ul>
          </BorderIconsFactory>
        </section>
      )}
    </>
  )
}

export default CTACardsList
