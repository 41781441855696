import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { isEditMode } from "../../../utils/isEditMode.util"
import SimpleCTACardsRow, {
  SimpleCTACardsRowProps,
} from "../../molecules/SimpleCTACardsRow/SimpleCTACardsRow.molecule"

interface SimpleCTACardsRowsProps extends Blok {
  rows?: SimpleCTACardsRowProps[]
}

const SimpleCTACardsRows: React.FC<SimpleCTACardsRowsProps> = ({
  rows = [],
}) => {
  const getSimpleCTACardsRowsLength = (): number => {
    if (isEditMode()) {
      return rows.length < 1 ? 1 : rows.length
    } else {
      return rows.length
    }
  }

  const getSimpleCTACardsRow = (index: number): SimpleCTACardsRowProps => {
    const EMPTY_SIMPLE_CTA_CARDS_ROW: SimpleCTACardsRowProps = {
      _uid: `empty_simple_cta_cards_row_${index}`,
      component: "simple_cta_cards_row",
      _editable: "",
      heading: undefined,
      cards: [],
    }
    return rows[index] ?? EMPTY_SIMPLE_CTA_CARDS_ROW
  }

  return (
    <section className="py-10 md:py-12 xl:py-20">
      <ul className="container mx-auto space-y-12 md:space-y-16">
        {Array(getSimpleCTACardsRowsLength())
          .fill(1)
          .map((_, index) => (
            <SimpleCTACardsRow
              key={getSimpleCTACardsRow(index)._uid}
              {...getSimpleCTACardsRow(index)}
            />
          ))}
      </ul>
    </section>
  )
}

export default SimpleCTACardsRows
