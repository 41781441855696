import classNames from 'classnames';
import React, { useState } from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Image from '../../atoms/Image/Image.atom';
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import RichText from '../../atoms/RichText/RichText.atom';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

interface ValuesItemProps extends Blok {
  icon: StoryblokAsset;
  heading: string;
  subheading: StoryblokRichtext;
}
interface ValuesProps extends BlokWithBorderIcons {
  blocks: ValuesItemProps[];
}

const Values: React.FC<ValuesProps> = ({
  blocks,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false);

  const getBlockClassName = (index: number): string => {
    return classNames(
      'flex flex-col items-center text-center opacity-0 translate-y-20 transition-all ease-in-out duration-700',
      {
        'delay-100': index === 1,
        'delay-200': index === 2,
        'delay-300': index === 3,
        'opacity-100 translate-y-0': didBecomeVisible,
      },
    );
  };

  return (
    <AnimateInComponent didBecomeVisible={() => setDidBecomeVisible(true)}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="mx-auto container py-12 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-10">
          {blocks && blocks.length && (
            <>
              {blocks.map((value_item, index) => (
                <div key={value_item._uid} className={getBlockClassName(index)}>
                  <div className="mb-6 w-16 md:w-20">
                    <Image image={value_item.icon} objectFit="contain" />
                  </div>

                  <h3 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold text-Primary-900 ">
                    {value_item.heading}
                  </h3>
                  <div className="text-lg md:text-2xl xl:text-3xl font-semibold text-SP6">
                    <RichText content={value_item.subheading} />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  );
};

export default Values;
