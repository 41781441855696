import React from "react";
import { ButtonTypes } from "../../../../interfaces/enums/ButtonTypes.enum";
import Button from "../../../atoms/AnimatedButton/AnimatedButton.component";
import { JobSummaryItem } from "../JobInformation.blok";
import JobSummarySections from "./JobSummaryItems.blok";

export interface JobSummaryInterface {
  summaryItems: JobSummaryItem[];
  applicationLink: string;
  cta_label: string;
}

const JobSummary: React.FC<JobSummaryInterface> = ({ summaryItems = [], applicationLink, cta_label }: JobSummaryInterface) => {
  return (
   <>
     <div>
       <JobSummarySections jobSummaryItems={summaryItems} />
        <Button
          onClick={() => {
            window.open(applicationLink);
          }}
          type={ButtonTypes.PRIMARY}
          label={cta_label}   
          fullWidth={true}
        />
     </div>
    </>  
  )
}

export default JobSummary;