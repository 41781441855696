import React, { useState } from "react"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import SecondaryActionCardItem from "./SecondaryActionCardItem.molecule"
import { ButtonProps } from "../../../interfaces/Button.interface"

export interface SecondaryActionCardProps {
  step_2_heading: string

  step_2_hc_option_1_heading: string
  step_2_hc_option_1_subheading: string
  step_2_hc_option_1_primary_cta: ButtonProps[]

  step_2_hc_option_2_heading: string
  step_2_hc_option_2_subheading: string
  step_2_hc_option_2_primary_cta: ButtonProps[]
  step_2_hc_option_2_secondary_cta: ButtonProps[]

  step_2_tt_option_1_heading: string
  step_2_tt_option_1_icon: StoryblokAsset
  step_2_tt_option_1_primary_cta: ButtonProps[]

  step_2_tt_option_2_heading: string
  step_2_tt_option_2_icon: StoryblokAsset
  step_2_tt_option_2_primary_cta: ButtonProps[]

  isActive: boolean
  setActiveCard: (state: number) => void
  activeCard: number
  selectedOption: number
}

const SecondaryActionCard: React.FC<SecondaryActionCardProps> = ({
  step_2_heading,

  step_2_hc_option_1_heading,
  step_2_hc_option_1_subheading,
  step_2_hc_option_1_primary_cta,

  step_2_hc_option_2_heading,
  step_2_hc_option_2_subheading,
  step_2_hc_option_2_primary_cta,
  step_2_hc_option_2_secondary_cta,

  step_2_tt_option_1_heading,
  step_2_tt_option_1_icon,
  step_2_tt_option_1_primary_cta,

  step_2_tt_option_2_heading,
  step_2_tt_option_2_icon,
  step_2_tt_option_2_primary_cta,

  isActive,
  setActiveCard,
  activeCard,
  selectedOption,
}) => {
  return (
    <div
      className={`bg-SP7 p-6 sm:p-8 md:p-12 rounded-[20px] transition-all duration-150 delay-150 ${
        isActive ? "scale-y-100" : "h-[94px] md:h-[141px] cursor-pointer"
      }`}
      onClick={() => setActiveCard(2)}
    >
      <p className="text-xl md:text-3xl text-body font-extrabold">
        2: {step_2_heading}
      </p>

      <div
        className={`grid grid-cols-10 gap-6 pt-8 md:pt-12  ${
          isActive ? "opacity-1 duration-300 delay-300" : "opacity-0 duration-0"
        }`}
      >
        <div className="col-span-10 col-start-1 md:col-span-8 md:col-start-2 lg:col-span-4 lg:col-start-2">
          {selectedOption === 1 ? (
            <SecondaryActionCardItem
              heading={step_2_hc_option_1_heading}
              subheading={step_2_hc_option_1_subheading}
              primaryCta={step_2_hc_option_1_primary_cta}
              setActiveCard={setActiveCard}
              cardItemOption={1}
            />
          ) : (
            <SecondaryActionCardItem
              heading={step_2_tt_option_1_heading}
              icon={step_2_tt_option_1_icon}
              primaryCta={step_2_tt_option_1_primary_cta}
              setActiveCard={setActiveCard}
              cardItemOption={1}
            />
          )}
        </div>
        <div className="col-span-10 col-start-1 md:col-span-8 md:col-start-2 lg:col-span-4 lg:col-start-6">
          {selectedOption === 1 ? (
            <SecondaryActionCardItem
              heading={step_2_hc_option_2_heading}
              subheading={step_2_hc_option_2_subheading}
              primaryCta={step_2_hc_option_2_primary_cta}
              secondaryCta={step_2_hc_option_2_secondary_cta}
              setActiveCard={setActiveCard}
              cardItemOption={2}
            />
          ) : (
            <SecondaryActionCardItem
              heading={step_2_tt_option_2_heading}
              icon={step_2_tt_option_2_icon}
              primaryCta={step_2_tt_option_2_primary_cta}
              setActiveCard={setActiveCard}
              cardItemOption={2}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SecondaryActionCard
