import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import React, { RefObject } from 'react';
import { StoryblokAsset, StoryblokAssetPropTypes } from '../../../interfaces/Storyblok/StoryblokAsset.interface';

export interface ImageProps {
  image?: StoryblokAsset | string;
  imageUrl?: string;
  maxWidth?: number;
  maxHeight?: number;
  objectFit?: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none';
  objectPosition?: 'center' | 'right' | 'left' | 'top' | 'bottom';
  style?: string;
  filters?: string;
  ref?: RefObject<HTMLDivElement>;
  className?: string;
}

const Image: React.FC<ImageProps> = ({
  image,
  imageUrl,
  maxWidth = undefined,
  maxHeight = undefined,
  objectFit = 'contain',
  objectPosition = 'center',
  style = '',
  ref,
  className,
}) => {
  const imageExists = (image: StoryblokAsset | string): boolean => {
    if (typeof image === 'string') {
      return false;
    }

    if (getImageAsStoryblokAsset(image)?.filename == null || getImageAsStoryblokAsset(image)?.filename == '') {
      return false;
    }

    return true;
  };

  const getImageAsStoryblokAsset = (image: StoryblokAsset | string): StoryblokAsset => {
    return image as StoryblokAsset;
  };

  const isSVG = (image: StoryblokAsset): boolean => {
    if (imageExists(image)) {
      const imageAsStoryblokAsset: StoryblokAsset = getImageAsStoryblokAsset(image);
      const fileExtensionRegex: RegExp = /\.[0-9a-z]+$/i;
      const potentialSVGFileExtensions: string[] = ['.svg', '.SVG'];
      return potentialSVGFileExtensions.includes(
        imageAsStoryblokAsset.filename.match(fileExtensionRegex)?.length
          ? imageAsStoryblokAsset.filename.match(fileExtensionRegex)[0]
          : '',
      );
    }
    return false;
  };

  const fluidImage =
    getImageAsStoryblokAsset(image) &&
    getImageAsStoryblokAsset(image).filename &&
    getFluidGatsbyImage(getImageAsStoryblokAsset(image).filename, {
      quality: 90,
    });

  const fluidImageFromUrl =
    imageUrl &&
    getFluidGatsbyImage(imageUrl, {
      quality: 90,
    });

  const styles = {
    width: '100%',
    maxWidth: maxWidth ? `${maxWidth}px` : '100%',
    maxHeight: maxHeight ? `${maxHeight}px` : '100%',
  };

  return (
    <>
      {imageExists(image) && (
        <>
          {isSVG(image as StoryblokAsset) ? (
            <img
              src={getImageAsStoryblokAsset(image).filename}
              alt={getImageAsStoryblokAsset(image).alt}
              style={{
                objectFit: objectFit,
                objectPosition: 'bottom',
                width: '100%',
                // height: '100%',
                maxWidth: maxWidth ? `${maxWidth}px` : '100%',
                maxHeight: maxHeight ? `${maxHeight}px` : '100%',
              }}
              loading={'lazy'}
            />
          ) : (
            fluidImage && (
              <Img
                fluid={fluidImage}
                alt={
                  getImageAsStoryblokAsset(image) && getImageAsStoryblokAsset(image).alt
                    ? getImageAsStoryblokAsset(image).alt
                    : 'Tile image'
                }
                style={{
                  width: '100%',
                  maxWidth: maxWidth,
                  height: '100%',
                  maxHeight: maxHeight,
                }}
                imgStyle={{
                  objectFit: objectFit,
                  objectPosition: objectPosition,
                  width: '100%',
                  maxWidth: maxWidth,
                  maxHeight: maxHeight,
                }}
                loading={'auto'}
                ref={ref}
                className={className}
              />
            )
          )}
        </>
      )}
      {imageUrl && (
        <Img
          fluid={fluidImageFromUrl}
          style={{
            width: '100%',
            maxWidth: maxWidth,
            height: '100%',
            maxHeight: maxHeight,
          }}
          imgStyle={{
            objectFit: objectFit,
            objectPosition: objectPosition,
            width: '100%',
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          }}
          loading={'auto'}
          ref={ref}
          className={className}
        />
      )}
    </>
  );
};

export default Image;
