import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Tick: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 80.588 61.158"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M29.658 61.157a4.673 4.673 0 0 1-3.305-1.369L1.37 34.808a4.674 4.674 0 0 1 0-6.611 4.673 4.673 0 0 1 6.611 0l21.485 21.481 42.96-48.117a4.675 4.675 0 1 1 6.975 6.227L33.145 59.595a4.678 4.678 0 0 1-3.355 1.56l-.132.002z" />
    </svg>
  )
}

export default Tick
