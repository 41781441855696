import React from 'react';

import PageTemplate from '../Page.template';
import HomePageTemplate from '../Homepage.template';
import VenueIndexPageTemplate from '../VenueIndexPage.template';
import VenuePageTemplate from '../VenuePage.template';
import ArticleTemplate from '../Article.template';
import ArticleIndexTemplate from '../ArticleIndex/ArticleIndex.template';
import SiteSearchTemplate from '../SiteSearch/SiteSearch.template';
import FrequentlyAskedQuestionsTemplate from '../FrequentlyAskedQuestions/FrequentlyAskedQuestions.template';
import LoginOrRegisterPageTemplate from '../LoginOrRegister.template';

interface TemplatesMap {
  [key: string]: React.FC<any>;
}

const TemplatesMap: TemplatesMap = {
  page: PageTemplate,
  homepage: HomePageTemplate,
  school_holiday: PageTemplate,
  term_time: PageTemplate,
  parent_support: PageTemplate,
  careers: PageTemplate,
  for_schools: PageTemplate,
  venue_index: VenueIndexPageTemplate,
  venue: VenuePageTemplate,
  article: ArticleTemplate,
  gallery: PageTemplate,
  site_search: SiteSearchTemplate,
  article_index: ArticleIndexTemplate,
  faqs_index: FrequentlyAskedQuestionsTemplate,
  login_or_register: LoginOrRegisterPageTemplate,
};

export const getTemplate = (templateName: string): React.FC<any> => {
  return TemplatesMap[templateName] ?? PageTemplate;
};
