import React from 'react';
import { valueExists } from '../../../../utils/valueExists/valueExists.util';
import { SiteSearchHeroBannerProps } from '../SiteSearchProps.interface';
import { SearchBox, useInstantSearch } from 'react-instantsearch-hooks-web';
import isEmptyString from '../../../../utils/isEmptyString/isEmptyString.util';

const SiteSearchHeroBanner: React.FC<SiteSearchHeroBannerProps> = ({ title, description, input_placeholder }) => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = '<number-of-results-found>';

  const {
    results: { query, nbHits },
  } = useInstantSearch();

  const isTopBarRequired = (): boolean => {
    return isTitleRequired() || isDescriptionRequired();
  };

  const isTitleRequired = (): boolean => {
    return valueExists(title);
  };

  const isDescriptionRequired = (): boolean => {
    return valueExists(description) && isSearchQueryPopulatedWithValue();
  };

  const isSearchQueryPopulatedWithValue = (): boolean => {
    if (!valueExists(query)) return false;
    if (isEmptyString(query)) return false;
    return true;
  };

  const getNumberOfResultsFound = (): string => {
    return `${nbHits}` ?? '0';
  };

  const getFormattedTitle = (): JSX.Element => {
    return (
      <h1 className="font-extrabold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl">
        {title.replaceAll(NUMBER_OF_RESULTS_PLACEHOLDER, getNumberOfResultsFound())}
      </h1>
    );
  };

  const getFormattedDescription = (): JSX.Element => {
    const descriptionTextSplitIntoIndividualWords: string[] = description.split(' ');
    return (
      <p className="text-lg font-semibold">
        {descriptionTextSplitIntoIndividualWords.map((word: string) =>
          word === NUMBER_OF_RESULTS_PLACEHOLDER ? (
            <span className="text-SP1">{getNumberOfResultsFound()} </span>
          ) : (
            `${word} `
          ),
        )}
      </p>
    );
  };

  return (
    <section className="bg-gradient-to-r !from-sherpa-red-main !to-sherpa-red-300 from-Primary-900 to-SP6">
      <div className="container mx-auto pt-12 pb-10 space-y-6 grid grid-cols-2 gap-x-6 md:pt-16 md:pb-12 md:grid-cols-12 xl:pt-20 xl:pb-16 2xl:pt-24">
        {isTopBarRequired() && (
          <div className="space-y-4 text-White text-center col-span-2 md:col-span-8 md:col-start-3">
            {isTitleRequired() && getFormattedTitle()}
            {isDescriptionRequired() && getFormattedDescription()}
          </div>
        )}
        <SearchBox
          placeholder={input_placeholder}
          classNames={{
            root: 'border-b border-b-SP1 pb-2 col-span-2 md:col-span-8 md:col-start-3',
            form: 'w-full',
            input:
              'w-full border-l border-l-SP1 bg-Transparent py-2 px-3 font-semibold placeholder-White text-SP1 focus:outline-none',
            submit: 'hidden',
            submitIcon: 'hidden',
            reset: 'hidden hover:hidden',
            resetIcon: 'hidden hover:hidden',
          }}
        />
      </div>
    </section>
  );
};

export default SiteSearchHeroBanner;
