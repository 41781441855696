import React from 'react';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import Image from '../../atoms/Image/Image.atom';
import Policy from '../../molecules/Footer/Policy.molecule';
import { LinkItem } from '../../../interfaces/LinkItem.interface';
import DynamicComponent from '../../utils/DynamicComponent/DynamicComponent.component';

interface FooterProps extends Blok {
  descriptive_text: string;
  logo: StoryblokAsset;
  slogan: string;
  supporting_links: LinkItem[];
  columns: Blok[];
}

const Footer: React.FC<FooterProps> = ({ columns, slogan, logo, supporting_links }) => {
  const handleLogoClickDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      type: 'Footer Homepage Button',
    });
  };

  return (
    <footer className="w-full bg-sherpa-blue-500 bg-Primary-900 !text-sherpa-body text-[white] overflow-hidden">
      <div className="py-10 lg:pt-20 lg:pb-10 xl:py-20">
        <div className="container mx-auto">
          <div className="flex justify-center items-start">
            <div className="max-w-[323px] md:max-w-[496px]">
              <Image image={slogan} />
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-12 pt-12 gap-x-6">
            <div className="col-span-1 md:col-span-3 xl:col-span-2 flex justify-center xl:block">
              {logo && (
                <CustomLink url="/" className="w-full block" onClick={() => handleLogoClickDataLayerEvent()}>
                  <Image objectFit={'contain'} image={logo} className="!h-auto" />
                </CustomLink>
              )}
            </div>

            {columns &&
              !!columns.length &&
              columns.map((linkList, index) =>
                linkList.component !== 'footer_contact_details' ? (
                  <div
                    key={linkList._uid}
                    className={`col-span-1 md:col-span-3 xl:col-span-2 pb-10 xl:pb-0 ${
                      index === 1 && 'flex justify-center md:block'
                    }`}
                  >
                    <DynamicComponent blok={linkList} />
                  </div>
                ) : (
                  <div
                    key={linkList._uid}
                    className="col-span-2 md:col-span-12 xl:col-span-3 xl:col-start-10 pt-10 xl:pt-0 border-t border-White xl:border-t-0"
                  >
                    <DynamicComponent blok={linkList} />
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
      {supporting_links && !!supporting_links.length && <Policy supporting_links={supporting_links} />}
    </footer>
  );
};

export default Footer;
