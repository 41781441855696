import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import RichText from "../../atoms/RichText/RichText.atom"
import Image from "../../atoms/Image/Image.atom"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import classNames from "classnames"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface WholeOfChildIntroProps extends BlokWithBorderIcons {
  heading: string
  subheading: string
  body: StoryblokRichtext
  theme: ThemeActivityCategory
  image: StoryblokAsset
  image_heading: string
}

const WholeOfChildIntro: React.FC<WholeOfChildIntroProps> = ({
  heading,
  subheading,
  body,
  theme,
  image,
  image_heading,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const THEME_ACTIVITY_CATEGORY_TEXT_COLOR: Record<
    ThemeActivityCategory,
    string
  > = {
    "Brain-Boosters": "text-Brain-Boosters",
    "Zen-Masters": "text-Zen-Masters",
    "Creative-Inventors": "text-Creative-Inventors",
    "Globe-Trotters": "text-Globe-Trotters",
    "Power-Teams": "text-Power-Teams",
    "Splash-Zone": "text-Splash-Zone",
    "Super-Sports": "text-Super-Sports",
  }
  const THEME_ACTIVITY_CATEGORY_BORDERS: Record<ThemeActivityCategory, string> =
    {
      "Brain-Boosters": "border-Brain-Boosters",
      "Zen-Masters": "border-Zen-Masters",
      "Creative-Inventors": "border-Creative-Inventors",
      "Globe-Trotters": "border-Globe-Trotters",
      "Power-Teams": "border-Power-Teams",
      "Splash-Zone": "border-Splash-Zone",
      "Super-Sports": "border-Super-Sports",
    }

  const headingClassNames = classNames(
    `text-2xl font-extrabold ${THEME_ACTIVITY_CATEGORY_TEXT_COLOR[theme]}`
  )
  const contentWrapperClassNames = classNames(
    `border-l-4 pl-6 ${THEME_ACTIVITY_CATEGORY_BORDERS[theme]}
    mb-6
    md:col-span-2
    `
  )

  return (
    <section>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto my-12 md:grid md:grid-cols-3 gap-x-6">
          <div className={contentWrapperClassNames}>
            <div className="flex items-center gap-4 mb-3">
              <h2 className={headingClassNames}>
                {heading}
                <span className="text-Primary-900 ml-2">{subheading}</span>
              </h2>
            </div>

            {body && <RichText content={body} />}
          </div>

          <div className="flex items-center gap-4 mb-3 md:col-span-1">
            {image?.filename && (
              <Image image={image} maxWidth={56} maxHeight={56} />
            )}
            <h2 className="text-lg font-extrabold text-Primary-900">
              {image_heading}
            </h2>
          </div>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default WholeOfChildIntro
