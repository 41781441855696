import classNames from "classnames"
import React from "react"
import { ButtonProps } from "../../../interfaces/Button.interface"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { isEditMode } from "../../../utils/isEditMode.util"
import Button from "../../atoms/Button/Button.component"
import Image from "../../atoms/Image/Image.atom"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

export interface CTACardProps extends BlokWithBorderIcons {
  image?: StoryblokAsset
  heading?: string
  body?: string
  cta: ButtonProps[]
}

const CTACard: React.FC<CTACardProps> = ({
  _uid,
  image,
  heading,
  body,
  cta,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const isCTACardValid = (): boolean => {
    return isImagePopulated() && isHeadingPopulated() && isCTAPopulated()
  }

  const isImagePopulated = (): boolean => {
    return (
      image != undefined &&
      image?.filename != undefined &&
      image?.filename != ""
    )
  }

  const isHeadingPopulated = (): boolean => {
    return heading != undefined && heading != ""
  }
  const isCTAPopulated = (): boolean => {
    return (
      Array.isArray(cta) &&
      cta.length > 0 &&
      cta[0].label != undefined &&
      cta[0].link.cached_url != undefined
    )
  }

  const imageContainerClassNames = classNames(
    "w-full aspect-video rounded-6xl overflow-clip",
    {
      "bg-Transparent": !isEditMode() || isImagePopulated(),
      "bg-SP7": isEditMode() && !isImagePopulated(),
    }
  )

  const headingClassNames = classNames("font-extrabold text-xl xl:text-2xl", {
    "bg-Transparent": !isEditMode() || isHeadingPopulated(),
    "block bg-SP7 rounded-2xl w-4/5 h-8": isEditMode() && !isHeadingPopulated(),
  })

  return (
    <>
      {(isEditMode() || isCTACardValid()) && (
        <li key={_uid} className="col-span-2 md:col-span-6 lg:col-span-4">
          <BorderIconsFactory
            type={BorderIconType.Card}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className="space-y-6 flex flex-col md:h-full">
              <div className={imageContainerClassNames}>
                {isImagePopulated() && (
                  <Image image={image!} objectFit="cover" />
                )}
              </div>
              <div className="flex flex-col items-start justify-between space-y-8 flex-1">
                <div className="w-full flex flex-col items-start justify-start space-y-3 text-Primary-900">
                  <h4 className={headingClassNames}>
                    {isHeadingPopulated() ? heading : ""}
                  </h4>

                  {isEditMode() && !isCTACardValid() && (
                    <div className="w-full space-y-1">
                      <div className="w-[96%] h-3 bg-SP7"></div>
                      <div className="w-full h-3 bg-SP7"></div>
                      <div className="w-[96%] h-3 bg-SP7"></div>
                    </div>
                  )}

                  {body && <p>{body}</p>}
                </div>

                {isEditMode() && !isCTAPopulated() && (
                  <div className="w-28 h-10 rounded-7xl bg-SP7"></div>
                )}

                {isCTAPopulated() && (
                  <Button
                    link={cta[0].link}
                    label={cta[0].label}
                    type={ButtonTypes.PRIMARY}
                    size={ButtonSizeTypes.DEFAULT}
                  />
                )}
              </div>
            </div>
          </BorderIconsFactory>
        </li>
      )}
    </>
  )
}

export default CTACard
