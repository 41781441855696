import React from 'react';
import classNames from 'classnames';

import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Image from '../../atoms/Image/Image.atom';

export enum BorderIconType {
  Blok,
  Card,
}

export enum BorderIconVerticalPosition {
  Top,
  Bottom,
}

export enum BorderIconHorizontalPosition {
  Leading,
  Trailing,
}

interface BorderIconProps {
  type: BorderIconType;
  vertical_position: BorderIconVerticalPosition;
  horizontal_position: BorderIconHorizontalPosition;
  border_icon: StoryblokAsset;
}

const BorderIcon: React.FC<BorderIconProps> = ({ type, vertical_position, horizontal_position, border_icon }) => {
  return (
    <div
      className={classNames('absolute h-fit', {
        'top-0': vertical_position === BorderIconVerticalPosition.Top,
        'bottom-0': vertical_position === BorderIconVerticalPosition.Bottom,
        'left-0': horizontal_position === BorderIconHorizontalPosition.Leading,
        'right-0': horizontal_position === BorderIconHorizontalPosition.Trailing,
        'max-h-[206px]': type === BorderIconType.Blok,
        'max-h-[78px]': type === BorderIconType.Card,
      })}
    >
      <Image image={border_icon} objectFit={'contain'} className="h-full" />
    </div>
  );
};

export default BorderIcon;
