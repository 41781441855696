import React from 'react';

interface RoundedTriangleProps {
  className?: string;
}

const RoundedTriangle: React.FC<RoundedTriangleProps> = ({ className }) => {
  return (
    <svg width="57" height="25" viewBox="0 0 57 25" className={`transfrom fill-current ${className}`}>
      <path d="M0 0H28.2843H56.5685L33.9411 22.6274C30.8169 25.7516 25.7516 25.7516 22.6274 22.6274L0 0Z" />
    </svg>
  );
};

export default RoundedTriangle;
