import React, { useContext } from 'react';
import TemplateProps from '../../../interfaces/TemplateProps.interface';
import getStoryblokWrapperEntries from '../../../utils/getStoryblokWrapperEntries/getStoryblokWrapperEntries.util';
import getBlok from '../../../utils/getBlok/getBlok.util';
import { layoutClassNames } from '../../../styles/ClassNames/global.classes';
import DynamicComponent from '../../utils/DynamicComponent/DynamicComponent.component';
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web';
import { ArticleProps } from './ArticleProps.interface';
import getModelledArticleProps from './getModelledArticleProps.util';
import getAlgoliaSearchClient from '../../../utils/Algolia/getAlgoliaSearchClient.util';
import ArticleIndexResultsList from './components/ArticleIndexResultsList.components';
import ArticleIndexPaginationBar from './components/ArticleIndexPaginationBar.component';
import ArticleIndexProps from './ArticleProps.interface';
import SecondaryHero from '../../bloks/SecondaryHero/SecondaryHero.blok';
import SectionTitle from '../../bloks/SectionTitle/SectionTitle.blok';
import Meta from '../../utils/Meta/Meta.component';
import BaseDataLayer from '../../utils/BaseDataLayer/BaseDataLayer.util';
import BreadcrumbBar from '../../bloks/BreadcrumbBar/BreadcrumbBar';
import LayoutContext from '../../../contexts/LayoutContext';

const ArticleIndexTemplate: React.FC<TemplateProps> = ({ context }) => {
  const {
    SEO,
    hero_title,
    hero_body,
    hero_cta,
    hero_image,
    hero_background_icon,
    section_title_heading,
    section_title_body,
    section_title_background_icon,
    section_title_theme,
    breadcrumb_override,
    has_background,
  }: ArticleIndexProps = context.content;
  const { header, footer, newsletter } = getStoryblokWrapperEntries();

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);
  const breadcrumbs = useContext(LayoutContext);

  const articleProps: ArticleProps = getModelledArticleProps(context.content);

  /*-- to help on the aws side we will wrap it in a string --*/
  const getIndexName = (): string => {
    return `${process.env.GATSBY_ALGOLIA_ARTICLES_SEARCH_INDEX_NAME!}`;
  };

  return (
    <BaseDataLayer field_component={context.field_component}>
      <div className={layoutClassNames}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        {hero_title && (
          <SecondaryHero
            name={hero_title}
            body={hero_body}
            cta={hero_cta}
            background_icon={hero_background_icon}
            image={hero_image}
          />
        )}
        {breadcrumbs && (
          <BreadcrumbBar {...breadcrumbs} breadcrumb_override={breadcrumb_override} hasBackground={has_background} />
        )}
        {hero_title && (
          <SectionTitle
            section_title={section_title_heading}
            sub_text={section_title_body}
            icon={section_title_background_icon}
            theme={section_title_theme}
          />
        )}
        <InstantSearch indexName={getIndexName()} searchClient={getAlgoliaSearchClient()}>
          <Configure hitsPerPage={articleProps.pagination_bar_config.max_results_per_page} />
          <section className="py-12 space-y-10 md:py-16 md:space-y-16 xl:py-20">
            <ArticleIndexResultsList />
            <ArticleIndexPaginationBar {...articleProps.pagination_bar_config} />
          </section>
        </InstantSearch>
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
      </div>
    </BaseDataLayer>
  );
};

export default ArticleIndexTemplate;
