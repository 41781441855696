import ImageBlok from "../interfaces/MediaAsset/ImageBlok.interface"
import { YoutubeVideoBlok } from "../interfaces/MediaAsset/YoutubeVideoBlok.interface"

export const getSelectedAssetType = (media_asset: any): any => {
  const isMediaAssetSelected = media_asset && !!media_asset.length

  const isImageAssetSelected = (): boolean => {
    return isMediaAssetSelected && media_asset[0].component === "image"
  }

  const isVideoAssetSelected = (): boolean => {
    return isMediaAssetSelected && media_asset[0].component === "youtube_video"
  }

  const getImage = (): ImageBlok | null => {
    return isImageAssetSelected() ? (media_asset[0] as ImageBlok) : null
  }

  const getYoutubeVideo = (): YoutubeVideoBlok | null => {
    return isVideoAssetSelected() ? (media_asset[0] as YoutubeVideoBlok) : null
  }

  return {
    isImageAssetSelected,
    getImage,
    getYoutubeVideo,
  }
}
