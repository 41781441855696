import React from "react"

interface BackgroundTear1Props {
  className?: string
}

const BackgroundTear1: React.FC<BackgroundTear1Props> = ({ className }) => {
  return (
    <svg
      xmlSpace="preserve"
      // style="enable-background:new 0 0 1000 145.2"
      viewBox="0 0 1000 145.2"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M0 48.3V0h1000v93.4c-4.7.4-9.5.8-14.2 1.3-9.1 1-18.2 2.1-27.2 3.1-5.4.6-10.9 1.3-16.3 1.9-6 .7-12.1 1.5-18.1 2.1-5.5.6-11 .9-16.5 1.6-6.2.7-12.4 1.8-18.6 2.6-6.6.8-13.2 1.4-19.8 2.3-10.4 1.4-20.8 3-31.2 4.5-6.3.9-12.7 1.7-19 2.6-6.4.9-12.8 2-19.2 2.9-3.1.5-6.3.7-9.4 1.2-6.2.9-12.3 2-18.5 2.9-4.8.7-9.7 1.3-14.6 1.9-8.2 1-16.4 2.1-24.6 3-6.8.8-13.6 1.5-20.4 2.2-12.6 1.3-25.2 2.7-37.9 3.8-12.4 1.1-24.8 2-37.2 2.8-7.6.5-15.2.7-22.8 1-1.5.1-3.1.3-4.6.4h-38c-4.2-.3-8.4-.7-12.6-1-6.5-.4-13.1-.7-19.7-1.2-12.2-1-24.4-2-36.7-3.3-7.1-.7-14.2-1.9-21.3-2.9-4.6-.7-9.2-1.3-13.8-2.1-7.4-1.2-14.7-2.6-22.1-3.9-4.5-.8-9-1.5-13.4-2.3-4.2-.8-8.3-1.9-12.5-2.7-4.4-.9-8.8-1.6-13.2-2.5-5.7-1.1-11.5-2.4-17.2-3.6l-23.1-4.5c-8.3-1.6-16.5-3.2-24.8-4.6-4.8-.9-9.6-1.5-14.5-2.3-6.2-1-12.5-2.1-18.7-3-7.5-1.1-15-2.2-22.5-3-9.1-1-18.2-1.6-27.4-2.4-10.7-.9-21.3-1.7-32-2.5-7.4-.6-14.8-.9-22.2-1.6-11.2-1-22.5-2.1-33.7-3.2-4.2-.4-8.4-.9-12.6-1.5-5.1-.8-10.1-1.8-15.2-2.8-5.9-1.1-11.8-2.2-17.6-3.6-10.3-2.6-20.5-5.8-30.9-8-10.2-2.1-20.6-3.1-30.8-4.9-4.8-.9-9.7-2.2-14.3-3.9-4.8-1.7-9.2-4.4-14-6.2-11.2-3.7-23-5.4-35.1-5.7z" />{" "}
    </svg>
  )
}

export default BackgroundTear1
