import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import BackgroundTexture from "../../molecules/BackgroundTexture/BackgroundTexture.molecule"
import { BackgroundTextureTypes } from "../../../interfaces/enums/BackgroundTextureTypes.enum"
import Button from "../../atoms/Button/Button.component"
import { ButtonProps } from "../../../interfaces/Button.interface"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"

interface SectionTitleWithCTAProps extends Blok {
  section_title: string
  sub_text: string
  cta: ButtonProps[]
  theme: string
}

const SectionTitleWithCTA: React.FC<SectionTitleWithCTAProps> = ({
  section_title,
  sub_text,
  cta,
  theme,
}) => {
  return (
    <section className={`text-Primary-900 overflow-hidden`}>
      <BackgroundTexture
        type={BackgroundTextureTypes.SECONDARY_TEAR}
        theme={theme}
      >
        <div className="container mx-auto pt-10 md:pt-16 2xl:pt-24">
          <div className="flex justify-between md:grid grid-cols-12 gap-x-6">
            <div className="col-span-7 col-start-1">
              <div>
                {section_title && (
                  <p className="text-xl md:text-2xl xl:text-3xl 3xl:text-4xl font-extrabold">
                    {section_title}
                  </p>
                )}
                {sub_text && (
                  <p className="hidden md:block text-lg 2xl:text-xl pt-4 xl:pt-6">
                    {sub_text}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-5 flex justify-end">
              {!!cta.length && cta[0].label && cta[0].link && (
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY_WITH_ARROW}
                ></Button>
              )}
            </div>
          </div>
        </div>
      </BackgroundTexture>
    </section>
  )
}

export default SectionTitleWithCTA
