import classNames from "classnames"
import React, { useState } from "react"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import PageIntroProps from "./PageIntroProps.interface"
import Button from "../../atoms/Button/Button.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"
import RichText from "../../atoms/RichText/RichText.atom"
import WhiteThemeBlokTear from "../../../assets/svgs/CornerTears/BlokTears/WhiteThemeBlokTear.svg"
import TearHorizontalPosition from "../../../interfaces/enums/TearPositioning/TearHorizontalPosition.enum"
import TearVerticalPosition from "../../../interfaces/enums/TearPositioning/TearVerticalPosition.enum"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface PageIntroGeneratorProps {
  default_props: PageIntroProps
  top_title_text?: string
  right_column: JSX.Element
}

const PageIntroGenerator: React.FC<PageIntroGeneratorProps> = ({
  default_props: {
    heading,
    body,
    cta,
    show_background_tear,
    border_icon_top_left,
    border_icon_top_right,
    border_icon_bottom_left,
    border_icon_bottom_right,
  },
  top_title_text = undefined,
  right_column,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  const getTopTitleClassNames = classNames(
    "text-2xl font-extrabold pb-4 transition-all duration-500 ease-in-out delay-200 lg:pb-6 xl:text-3xl 2xl:text-4xl",
    {
      "opacity-100 translate-y-0": didBecomeVisible,
      "opacity-0 translate-y-4": !didBecomeVisible,
    }
  )

  const leftColumnClassNames = classNames(
    "transition-all duration-500 ease-in-out delay-200 col-span-2 lg:col-span-5 xl:col-span-6 2xl:col-span-5",
    {
      "opacity-100 translate-y-0": didBecomeVisible,
      "opacity-0 translate-y-4": !didBecomeVisible,
    }
  )

  const rightColumnClassNames = classNames(
    "transition-all duration-500 ease-in-out delay-200 col-span-2 lg:col-span-6 lg:col-start-7 xl:col-span-5 xl:col-start-8 2xl:col-span-6 2xl:col-start-7 rounded-2xl overflow-hidden",
    {
      "opacity-100 translate-y-0": didBecomeVisible,
      "opacity-0 translate-y-4": !didBecomeVisible,
    }
  )

  const hasCTAButton = (): boolean => {
    return (
      cta !== undefined &&
      cta.length > 0 &&
      cta[0].label !== undefined &&
      cta[0].link !== undefined
    )
  }

  return (
    <AnimateInComponent didBecomeVisible={() => setDidBecomeVisible(true)}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto text-Primary-900 pt-12 pb-16 lg:py-16 2xl:py-20">
          {top_title_text && (
            <h2 className={getTopTitleClassNames}>{top_title_text}</h2>
          )}
          <div className="grid grid-cols-2 gap-y-8 lg:grid-cols-12 lg:gap-x-6 lg:gap-y-0">
            <div className={leftColumnClassNames}>
              {heading && !top_title_text && (
                <h2 className="text-2xl xl:text-3xl 2xl:text-4xl font-extrabold pb-4 lg:pb-6">
                  {heading}
                </h2>
              )}
              {body && (
                <div
                  className={`text-Body ${
                    hasCTAButton() && "pb-6 md:pb-10 lg:pb-10"
                  } 2xl:text-lg`}
                >
                  <RichText content={body} />
                </div>
              )}

              {hasCTAButton() && (
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.LG}
                  className={`w-full transition-all duration-500 ease-in-out delay-[600ms] ${
                    didBecomeVisible
                      ? "opacity-100 translate-y-0"
                      : "opacity-0 translate-y-4"
                  } md:w-auto`}
                ></Button>
              )}
            </div>

            <div className={rightColumnClassNames}>{right_column}</div>
          </div>
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  )
}

export default PageIntroGenerator
