import React from "react"

interface HeroBannerContext {
  isExtendedHeightRequired: boolean
}

const HeroBannerContext = React.createContext<HeroBannerContext>({
  isExtendedHeightRequired: false,
})

export default HeroBannerContext
