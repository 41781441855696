import React, { useContext } from 'react';
import TemplateProps from '../../../interfaces/TemplateProps.interface';
import getStoryblokWrapperEntries from '../../../utils/getStoryblokWrapperEntries/getStoryblokWrapperEntries.util';
import getBlok from '../../../utils/getBlok/getBlok.util';
import { layoutClassNames } from '../../../styles/ClassNames/global.classes';
import DynamicComponent from '../../utils/DynamicComponent/DynamicComponent.component';
import { FAQsSearchProps } from './FAQsSearchProps.interface';
import getModelledFAQsSearchProps from './getModelledFAQsSearchProps.util';
import { InstantSearch, Configure } from 'react-instantsearch-hooks-web';
import getAlgoliaSearchClient from '../../../utils/Algolia/getAlgoliaSearchClient.util';
import FAQsSearchHeroBanner from './components/FAQsSearchHeroBanner.component';
import FAQsSearchResultsList from './components/FAQsSearchResultsList.component';
import FAQsSearchPaginationBar from './components/FAQsSearchPaginationBar.component';
import Meta from '../../utils/Meta/Meta.component';
import BaseDataLayer from '../../utils/BaseDataLayer/BaseDataLayer.util';
import BreadcrumbBar from '../../bloks/BreadcrumbBar/BreadcrumbBar';
import LayoutContext from '../../../contexts/LayoutContext';

const FrequentlyAskedQuestionsTemplate: React.FC<TemplateProps> = ({ context }) => {
  const { header, footer, newsletter } = getStoryblokWrapperEntries();
  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);

  const {
    breadcrumb_override,
    has_background,
  }: {
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;

  const faqsSearchProps: FAQsSearchProps = getModelledFAQsSearchProps(context.content);

  const getIndexName = (): string => {
    return `${process.env.GATSBY_ALGOLIA_FAQS_SEARCH_INDEX_NAME!}`;
  };
  const breadcrumbs = useContext(LayoutContext);
  return (
    <BaseDataLayer field_component={context.field_component}>
      {faqsSearchProps.seo && <Meta seo={faqsSearchProps.seo} url={context.full_slug} />}
      <div className={layoutClassNames}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        <article className="font-montserrat">
          <InstantSearch indexName={getIndexName()} searchClient={getAlgoliaSearchClient()}>
            <Configure hitsPerPage={faqsSearchProps.pagination_bar_config.max_results_per_page} />
            <FAQsSearchHeroBanner {...faqsSearchProps.hero_banner_config} />
            {breadcrumbs && (
              <BreadcrumbBar
                {...breadcrumbs}
                breadcrumb_override={breadcrumb_override}
                hasBackground={has_background}
              />
            )}
            <section className="py-12 space-y-10 md:py-16 md:space-y-16 xl:py-20">
              <FAQsSearchResultsList {...faqsSearchProps.results_list_config} />
              <FAQsSearchPaginationBar {...faqsSearchProps.pagination_bar_config} />
            </section>
          </InstantSearch>
        </article>
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
      </div>
    </BaseDataLayer>
  );
};

export default FrequentlyAskedQuestionsTemplate;
