import React from 'react';
import { SVG } from '../../interfaces/SVG.interface';

const Hamburger: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      aria-labelledby="menu"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M1 12.9H17C17.55 12.9 18 12.45 18 11.9C18 11.35 17.55 10.9 17 10.9H1C0.45 10.9 0 11.35 0 11.9C0 12.45 0.45 12.9 1 12.9ZM1 7.90002H17C17.55 7.90002 18 7.45002 18 6.90002C18 6.35002 17.55 5.90002 17 5.90002H1C0.45 5.90002 0 6.35002 0 6.90002C0 7.45002 0.45 7.90002 1 7.90002ZM0 1.90002C0 2.45002 0.45 2.90002 1 2.90002H17C17.55 2.90002 18 2.45002 18 1.90002C18 1.35002 17.55 0.900024 17 0.900024H1C0.45 0.900024 0 1.35002 0 1.90002Z" />
    </svg>
  );
};

export default Hamburger;
