import React from "react"

interface QuotationMarkProps {
  className?: string
}

const QuotationMark: React.FC<QuotationMarkProps> = ({
  className = "fill-SP1",
}) => {
  return (
    <svg
      width="84"
      height="86"
      viewBox="0 0 84 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`transform fill-current ${className && className}`}
    >
      <g filter="url(#filter0_dd_869_3129)">
        <path d="M60.6666 18.6667H52.6666L47.3333 29.3334V45.3334H63.3333V29.3334H55.3333L60.6666 18.6667ZM34 18.6667H26L20.6666 29.3334V45.3334H36.6666V29.3334H28.6666L34 18.6667Z" />
      </g>
      <defs>
        <filter
          id="filter0_dd_869_3129"
          x="-10"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_869_3129"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.133333 0 0 0 0 0.258824 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_869_3129"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="5"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_869_3129"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.133333 0 0 0 0 0.258824 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_869_3129"
            result="effect2_dropShadow_869_3129"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_869_3129"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default QuotationMark
