import React from "react"

interface MapProps {
  className?: string
}

const Map: React.FC<MapProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`transfrom fill-current ${className}`}
    >
      <g clipPath="url(#clip0_2232_19557)">
        <path d="M15 5.10002L9 3.00002L3 5.02002V21.22L9 18.89L15 20.99L21 18.97V2.77002L15 5.10002ZM15 18.89L9 16.78V5.11002L15 7.22002V18.89Z" />
      </g>
      <defs>
        <clipPath id="clip0_2232_19557">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Map
