import * as React from "react"
import DynamicComponent from "../components/utils/DynamicComponent/DynamicComponent.component"
import Blok from "../interfaces/Storyblok/Blok.interface"

export const renderChildren = (
  children: Blok[][] | undefined
): JSX.Element[] => {
  return !!children
    ? children
        .map((child: Blok[]) => renderBloks(child))
        .flatMap((elements: JSX.Element[]) => elements)
    : []
}

const renderBloks = (body: Blok[], countdown?: Blok): JSX.Element[] => {
  const isHeroBlok = (blok: Blok) => {
    const heroBannerComponents: string[] = ["homepage_hero", "secondary_hero"]
    return heroBannerComponents.includes(blok.component)
  }

  const bloks: JSX.Element[] = body?.reduce<JSX.Element[]>((bloks, blok) => {
    const blockArray = [
      ...bloks,
      <DynamicComponent blok={blok} key={blok._uid} />,
    ]

    if (isHeroBlok(blok) && countdown) {
      blockArray.push(
        <DynamicComponent blok={countdown} key={countdown._uid} />
      )
    }

    return blockArray
  }, [])

  return bloks
}

export default renderBloks
