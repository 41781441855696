import React, { useContext } from "react"
import FindUsPageVenueCardConfigProps from "../../../../interfaces/FindUsPage/FindUsPageVenueCardConfig.interface"
import Venue from "../../../../interfaces/Airtable/Venue.interface"
import classNames from "classnames"
import Phone from "../../../../assets/svgs/Phone.svg"
import Map from "../../../../assets/svgs/Map.svg"
import MapPin from "../../../../assets/svgs/MapPin.svg"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"
import Image from "../../../atoms/Image/Image.atom"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"
import Button from "../../../atoms/Button/Button.component"
import { ButtonSizeTypes } from "../../../../interfaces/enums/ButtonSizeTypes.enum"
import { ButtonTypes } from "../../../../interfaces/enums/ButtonTypes.enum"
import { valueExists } from "../../../../utils/valueExists/valueExists.util"
import { BorderIconsProps } from "../../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../../utils/BorderIconsFactory/BorderIcon.util"

export enum FindUsResultsCardType {
  MAP,
  LIST,
  VENUE_CARD,
}

interface FindUsResultsListCardProps {
  venue: Venue
  card_config?: FindUsPageVenueCardConfigProps[]
  type: FindUsResultsCardType
}

const FindUsResultsListCard: React.FC<FindUsResultsListCardProps> = ({
  venue,
  card_config,
  type,
}) => {
  const { searchBarInputValue, searchBarInputValueCoordinates } =
    useContext(FindUsPageContext)

  const isFindUsListCard = (): boolean => {
    return type === FindUsResultsCardType.LIST
  }

  const getCardConfig = (): FindUsPageVenueCardConfigProps | undefined => {
    return card_config && card_config[0]
  }

  const requiredCardsBorderIcons = (): BorderIconsProps => {
    return {
      border_icon_top_left: getCardConfig()?.border_icon_top_left,
      border_icon_top_right: getCardConfig()?.border_icon_top_right,
      border_icon_bottom_left: getCardConfig()?.border_icon_bottom_left,
      border_icon_bottom_right: getCardConfig()?.border_icon_bottom_right,
    }
  }

  const getMatchingFilterItemsToShow = (): FilterableItem[] => {
    if (
      getCardConfig() &&
      getCardConfig()?.matching_filter_items_to_show &&
      getCardConfig()!.matching_filter_items_to_show!.length > 0
    ) {
      return getCardConfig()!.matching_filter_items_to_show?.filter(
        (matchingFilterItemToShow: FilterableItem) =>
          venue
            .getFilterables()
            .includes(matchingFilterItemToShow?.content?.filterable_item)
      )
    } else {
      return []
    }
  }

  const hasMatchingFilterItemsToShow = (): boolean => {
    return getMatchingFilterItemsToShow().length > 0
  }

  const cardClassNames = classNames(
    "relative bg-White rounded-6xl overflow-hidden list-none",
    {
      "max-w-[380px]": !isFindUsListCard(),
    }
  )

  const cardContentContainerClassNames = classNames(
    "flex flex-col items-center justify-center z-[1] space-y-10",
    {
      "p-10": !isFindUsListCard(),
      "p-6 md:p-10 lg:flex-row lg:justify-between lg:space-y-0 xl:px-6 2xl:px-10":
        isFindUsListCard(),
    }
  )

  const cardNameAndAddressInfoContainerClassNames = classNames("space-y-3", {
    "xl:space-y-4 3xl:space-y-3": isFindUsListCard(),
  })

  const cardVenueNameClassNames = classNames(
    "font-semibold text-Primary-900 text-xl",
    {
      "md:text-2xl": isFindUsListCard(),
    }
  )

  const addressInfoContainerClassNames = classNames(
    "flex flex-col space-y-3 w-full",
    {
      "lg:flex-row lg:space-y-0 lg:space-x-4": isFindUsListCard(),
    }
  )

  const addressInfoSectionContainerClassNames = classNames(
    "flex items-center justify-start space-x-1.5"
  )

  const addressInfoFindUsSectionContainerClassNames = classNames("", {
    "flex items-center justify-start space-x-1.5": isFindUsListCard(),
    hidden: !isFindUsListCard(),
  })

  const addressInfoLinkClassNames = "text-sm text-Body font-semibold"

  const cardContentLeftFilterItemsListClassNames = classNames("w-full flex", {
    "flex-row flex-wrap": !isFindUsListCard(),
    "flex-col md:flex-row md:flex-wrap md:space-y-0 md:max-w-[420px]":
      isFindUsListCard(),
  })

  const cardContentLeftFilterItemsItemClassNames = classNames(
    "flex items-center justify-start space-x-2 pt-2",
    {
      "w-1/2": !isFindUsListCard(),
      "w-full md:pr-4 md:max-w-[210px]": isFindUsListCard(),
    }
  )

  const cardContentRightButtonsContainerClassNames = classNames(
    "flex flex-col w-full space-y-6 w-full",
    {
      "lg:w-fit": isFindUsListCard(),
    }
  )

  return (
    <li className={cardClassNames}>
      <BorderIconsFactory
        type={BorderIconType.Card}
        {...requiredCardsBorderIcons()}
      >
        <div className={cardContentContainerClassNames}>
          {/*-- top/left column --*/}
          <div>
            {/*-- distance, name and telephone, address info and find us --*/}
            <div className={cardNameAndAddressInfoContainerClassNames}>
              {/*-- distance, name --*/}
              <div>
                {venue.hasClientFriendlyDistanceFromCoordinates(
                  searchBarInputValue,
                  searchBarInputValueCoordinates
                ) &&
                  isFindUsListCard() && (
                    <p className="font-semibold text-sm text-Body">
                      {venue.getClientFriendlyDistanceFromCoordinates(
                        searchBarInputValue,
                        searchBarInputValueCoordinates
                      )}
                    </p>
                  )}
                <h4 className={cardVenueNameClassNames}>
                  {venue.getVenueSiteDetails().name}
                </h4>
              </div>

              {/*-- telephone, address info and find us --*/}
              <div
                className={`${addressInfoContainerClassNames} border-b border-b-Body-Inactive border-dashed pb-4`}
              >
                {/*-- telephone and address info --*/}
                <div className={addressInfoContainerClassNames}>
                  {/*-- telephone --*/}
                  {venue.getVenueSiteDetails().setting_phone_number && (
                    <div className={addressInfoSectionContainerClassNames}>
                      <Phone className="fill-Secondary-300" />
                      <p
                        className={`${addressInfoLinkClassNames} hidden sm:block`}
                      >
                        {venue.getVenueSiteDetails().setting_phone_number}{" "}
                      </p>
                      <a
                        href={`tel:${
                          venue.getVenueSiteDetails().setting_phone_number
                        }`}
                        className={`${addressInfoLinkClassNames} block sm:hidden`}
                      >
                        {venue.getVenueSiteDetails().setting_phone_number}{" "}
                      </a>
                    </div>
                  )}

                  {/*-- address --*/}
                  {venue.hasVenueAddress() && (
                    <div className={addressInfoSectionContainerClassNames}>
                      <Map />
                      <p className={addressInfoLinkClassNames}>
                        {valueExists(
                          venue.getVenueSiteDetails().address?.addressLineOne
                        ) && (
                          <span>
                            {
                              venue.getVenueSiteDetails().address
                                ?.addressLineOne
                            }
                          </span>
                        )}
                        {valueExists(
                          venue.getVenueSiteDetails().address?.addressLineTwo
                        ) && (
                          <span>
                            ,{" "}
                            {
                              venue.getVenueSiteDetails().address
                                ?.addressLineTwo
                            }
                          </span>
                        )}
                        {valueExists(
                          venue.getVenueSiteDetails().address?.townOrCity
                        ) && (
                          <span>
                            , {venue.getVenueSiteDetails().address?.townOrCity}
                          </span>
                        )}
                        {valueExists(
                          venue.getVenueSiteDetails().address?.postcode
                        ) && (
                          <span>
                            {!valueExists(
                              venue.getVenueSiteDetails().address?.townOrCity
                            ) && <span>,</span>}
                            <span>
                              {" "}
                              {venue.getVenueSiteDetails().address?.postcode}
                            </span>
                          </span>
                        )}
                      </p>
                    </div>
                  )}
                </div>

                {/*-- find us --*/}
                {venue.hasVenueCoordinates() && (
                  <div className={addressInfoFindUsSectionContainerClassNames}>
                    <MapPin />
                    <a
                      href={`http://maps.google.com/maps?q=${
                        venue.getCoordinates()?.latitude
                      },${venue.getCoordinates()?.longitude}`}
                      target="_blank"
                      className="uppercase font-semibold text-sm text-Primary-900 underline whitespace-nowrap"
                    >
                      Find us
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/*-- filterable club types list (optional) --*/}
            <div>
              {hasMatchingFilterItemsToShow() && (
                <div className="pt-4 w-full">
                  <ul className={cardContentLeftFilterItemsListClassNames}>
                    {getMatchingFilterItemsToShow().map(
                      (matchingFilterItemToShow: FilterableItem) => (
                        <li
                          key={matchingFilterItemToShow.uuid}
                          className={cardContentLeftFilterItemsItemClassNames}
                        >
                          {matchingFilterItemToShow.content
                            .results_card_icon && (
                            <div className="w-6 h-6">
                              <Image
                                image={
                                  matchingFilterItemToShow.content
                                    .results_card_icon
                                }
                              />
                            </div>
                          )}
                          <p className="whitespace-nowrap">
                            {matchingFilterItemToShow.name}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/*-- bottom/right column --*/}
          <div className={cardContentRightButtonsContainerClassNames}>
            {venue.getBookNowLink() && (
              <Button
                link={venue.getBookNowLink()}
                label={getCardConfig()?.primary_button_text ?? "Book Now"}
                type={ButtonTypes.PRIMARY}
                size={
                  (isFindUsListCard() && ButtonSizeTypes.SM) ||
                  ButtonSizeTypes.DEFAULT
                }
              />
            )}

            {venue.getBrochurewareVenuePageURL() && (
              <Button
                link={venue.getBrochurewareVenuePageURL()}
                label={getCardConfig()?.secondary_button_text ?? "Sneak peek"}
                type={ButtonTypes.SECONDARY}
                underline
                className={
                  "cursor-pointer text-Primary-900 whitespace-nowrap hover:!text-Primary-900"
                }
                size={
                  (isFindUsListCard() && ButtonSizeTypes.SM) ||
                  ButtonSizeTypes.DEFAULT
                }
              />
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </li>
  )
}

export default FindUsResultsListCard
