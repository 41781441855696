import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"

interface SingleImagelBlokProps extends Blok {
  image: StoryblokAsset
}

const SingleImageBlok: React.FC<SingleImagelBlokProps> = ({ image }) => {
  return <Image image={image} />
}

export default SingleImageBlok
