/*-- npm packages --*/
import React from 'react';

/*-- component bloks --*/

/*-- component molecules --*/
import BackgroundIcons from '../../molecules/BackgroundIcons/BackgroundIcons.molecule';

/*-- component atoms --*/
import Button from '../../atoms/Button/Button.component';

/*-- context --*/
import { Service, Venue } from '../../../contexts/VenuesContext';

/*-- interfaces --*/
import { BackgroundPositionTypes } from '../../../interfaces/enums/BackgroundPositionTypes.enum';
import { BreakpointTypes } from '../../../interfaces/enums/BreakpointTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';

/*-- assets --*/
import BackgroundClock from '../../../assets/svgs/BackgroundClock.svg';
import { Club } from '../../../contexts/FiltersContext';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import moment from 'moment';
import { parseCustomDate } from '../../../utils/parseCustomDate.util';
import classNames from 'classnames';

interface VenueClubScheduleProps extends BlokWithBorderIcons {
  venue: Venue;
  clubs: Club[];
  academic_year: string;
  sub_heading: string;
  body_copy: string;
  button_label: string;
  secondary_button_label: string;
  text_club: string;
  text_date_term: string;
  text_days_times: string;
  text_price_per_session: string;
  text_fee_after_css: string;
  // showPricingVariant: boolean;
  booking_type_default_text: string;
  booking_type: string;
  sort_order: string[];
}

const VenueClubSchedule: React.FC<VenueClubScheduleProps> = ({
  venue,
  clubs,
  sub_heading,
  body_copy,
  button_label,
  text_club,
  text_date_term,
  text_days_times,
  text_price_per_session,
  text_fee_after_css,
  // showPricingVariant,
  booking_type_default_text,
  booking_type,
  secondary_button_label,
  sort_order,
}) => {
  const getRowBackgroundColour = (service: Service): string | undefined => {
    return clubs.filter((club: Club) => club.name === service.clubType)[0].websiteTableRowColour;
  };

  const holidayClubs = venue?.services
    ?.filter(service => service.clubType === 'Holiday club')
    ?.sort((a, b) => {
      const dateA = parseCustomDate(a.startDate);
      const dateB = parseCustomDate(b.startDate);
      return dateA - dateB;
    });

  const allOtherServices = venue?.services?.filter(service => service.clubType !== 'Holiday club');

  const orderByDate = (services: Service[]) => {
    if (!services) return [];
    return services.sort(function (a, b) {
      return moment(a.startDate, 'DD-MM-YYYY').diff(moment(b.startDate, 'DD-MM-YYYY'));
    });
  };

  const orderByClubType = (services: Service[]) => {
    return services.sort(function (a, b) {
      if (sort_order) {
        if (sort_order.indexOf(a.clubType) > sort_order.indexOf(b.clubType)) return 1;
        if (sort_order.indexOf(a.clubType) < sort_order.indexOf(b.clubType)) return -1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const allServices = orderByClubType([...(allOtherServices || []), ...(orderByDate(holidayClubs) || [])]);

  const showPricingVariant = !!allServices.find(service => service.tieredPricing);

  const showFeeColumn = showPricingVariant
    ? !!allServices?.filter(service => service?.prices?.filter(price => price.subsidisedPrice !== 0)?.length)?.length
    : !!venue?.services?.filter(service => service?.discountedPrice !== 0)?.length;

  const BookingType = ({ service, defaultText }: { service: Service; defaultText: string }) => {
    if (!service.tieredPricing) {
      return <>{defaultText}</>;
    }

    return service.prices?.length ? (
      <table width={220}>
        <tbody>
          {service.prices.map((price, i) => (
            <tr className="border-b border-[#dcdcdc]" key={`${price.BookingType}${i}`}>
              <td className=" font-normal text-Body">{price.bookingType}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <>-</>
    );
  };

  const FullPrice = ({ service }: { service: Service }) => {
    if (!service.tieredPricing) {
      return (
        <td className="px-6">
          <p className="py-6 text-Body font-normal">
            {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
            {parseFloat(`${service.currentPrice}`).toFixed(2)}
          </p>
        </td>
      );
    }

    return showPricingVariant ? (
      <td width="150">
        {service.prices?.length ? (
          <table width={'100%'}>
            <tbody>
              {service.prices.map((price, i) => (
                <tr className="border-b border-[#dcdcdc]" key={`${price.BookingType}-${i}`}>
                  <td className="px-6 font-normal text-Body">
                    {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
                    {parseFloat(`${price.price}`).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>-</>
        )}
      </td>
    ) : (
      <td className="px-6">
        <p className="py-6 text-Body font-normal">
          {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
          {parseFloat(`${service.currentPrice}`).toFixed(2)}
        </p>
      </td>
    );
  };

  const FeeColumn = ({ service }: { service: Service }) => {
    if (service.currentPrice || !showPricingVariant) {
      return (
        <td className="px-6 font-normal">
          <p className="py-6 text-Body font-normal">
            {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
            {parseFloat(`${service.discountedPrice}`).toFixed(2)}
          </p>
        </td>
      );
    }

    return (
      <td width={150} className="px-6">
        {service.prices?.length ? (
          <table width={'100%'}>
            <tbody>
              {service.prices.map(price => (
                <tr className="border-b border-[#dcdcdc]" key={price.bookingType}>
                  <td className="font-normal text-Body">
                    {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
                    {parseFloat(`${price.subsidisedPrice}`).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          '-'
        )}
      </td>
    );
  };

  return (
    <>
      {venue && venue.services && (
        <section className="!bg-sherpa-red-main bg-SP6 overflow-hidden" id="venue-club-schedule">
          <div className="container mx-auto py-16 xl:py-20">
            <BackgroundIcons
              type={BackgroundPositionTypes.TopLeftToBottomRight_Outside}
              visibleOn={BreakpointTypes.XL}
              svg={<BackgroundClock className="!text-sherpa-blue-500 text-[#4A1375]"></BackgroundClock>}
              mirrorXRight
            >
              {venue.name && (
                <div className="grid grid-cols-12">
                  <h2 className="text-White text-center text-2xl lg:text-3xl 2xl:text-4xl font-extrabold pb-[4.5rem] md:pb-8 lg:pb-10 2xl:pb-16 col-span-12 lg:col-span-10 3xl:col-span-12 lg:col-start-2 3xl:col-start-1">
                    What's on at {venue.name}
                  </h2>
                </div>
              )}
              <div className="-mr-4 md:mr-0 md:gap-x-6 gap-y-6 grid grid-cols-12 overflow-auto">
                <table className="col-span-12 col-start-1 3xl:col-span-10 3xl:col-start-2 rounded-7xl min-w-[40rem]">
                  <thead>
                    <tr className="bg-Primary-900 text-White text-left text-lg font-extrabold leading-7">
                      <th className="py-4 px-6">{text_club}</th>
                      <th className="py-4 px-6">{text_date_term}</th>
                      <th className="py-4 px-6">{text_days_times}</th>
                      {showPricingVariant && <th className="py-4 px-6">{booking_type}</th>}
                      <th className="py-4 px-6">{text_price_per_session}</th>
                      {showFeeColumn && <th className="py-4 px-6">{text_fee_after_css}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {allServices.map((service: Service) => (
                      <tr
                        key={`${service.name}-table-row`}
                        className="text-base leading-6"
                        style={{
                          backgroundColor: getRowBackgroundColour(service) || '#FFF',
                        }}
                      >
                        <td>
                          <p className="text-Primary-900 font-bold py-6 px-6">{service.name}</p>
                        </td>
                        <td>
                          <p className="py-6 px-6 text-Body font-normal">
                            {service.startDate !== 'Invalid date'
                              ? `${service.startDate} - ${service.endDate}`
                              : `${service.term}`}
                          </p>
                        </td>
                        <td>
                          <p className="py-6 px-6 text-Body font-normal flex flex-col">
                            {service.daysRunning && <span>{service.daysRunning}</span>}
                            {service.startTime} - {service.endTime}
                          </p>
                        </td>
                        {showPricingVariant && (
                          <td width={220} className="px-6">
                            <BookingType service={service} defaultText={booking_type_default_text} />
                          </td>
                        )}
                        <FullPrice service={service} />
                        {showFeeColumn && <FeeColumn service={service} />}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="grid grid-cols-12 gap-x-6">
                <div className="text-White pt-10 col-span-12 md:col-span-10 md:col-start-2 md:pt-12 lg:col-span-6 lg:col-start-4">
                  {(venue.serviceListInfoTitle || sub_heading) && (
                    <h3 className="text-center text-xl md:text-2xl font-extrabold">
                      {venue.serviceListInfoTitle || sub_heading}
                    </h3>
                  )}
                  {(venue.serviceListInfoCopy || body_copy) && (
                    <p className="text-center max-w-2xl m-auto">{venue.serviceListInfoCopy || body_copy}</p>
                  )}
                  {venue.links.bookingLink && (
                    <div className="flex justify-center pt-10 space-x-4">
                      <Button
                        link={venue.links.bookingLink}
                        label={button_label}
                        type={ButtonTypes.PRIMARY}
                        size={ButtonSizeTypes.XL}
                      ></Button>

                      {venue.termsLink ? (
                        <Button
                          link={venue.termsLink}
                          label={secondary_button_label}
                          type={ButtonTypes.SECONDARY}
                          size={ButtonSizeTypes.XL}
                        ></Button>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </BackgroundIcons>
          </div>
        </section>
      )}
    </>
  );
};

export default VenueClubSchedule;
