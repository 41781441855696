import React, { useEffect, useState } from 'react';
import Close from '../../../assets/svgs/Close.svg';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Image from '../../atoms/Image/Image.atom';

interface BannerProps {
  icon: StoryblokAsset;
  heading: string;
  subheading: string;
}

const Banner: React.FC<BannerProps> = ({ icon, heading, subheading }) => {
  const [showBanner, setShowBanner] = useState(true);

  const localStorageKey = `SHOW_BANNER ${heading} ${subheading}`;

  useEffect(() => {
    const data = window.localStorage.getItem(localStorageKey);

    if (data !== null) setShowBanner(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(showBanner));
  }, [showBanner]);

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="!bg-sherpa-blue-500 bg-SP2 !text-sherpa-body text-Primary-900">
      <div className="container mx-auto flex md:flex-row justify-between py-4">
        <div className="flex items-center gap-3 md:mx-auto">
          <div className="hidden md:block">
            <Image image={icon} maxWidth={40} maxHeight={40} />
          </div>

          <div className="flex flex-col lg:flex-row items-start lg:items-center lg:gap-3 ">
            <div className="font-semibold">{heading}</div>

            <div className="font-extrabold">{subheading}</div>
          </div>
        </div>
        <button onClick={handleClose}>
          <Close className="text-sherpa-body" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
