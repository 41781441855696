export enum BackgroundPositionTypes {
  BottomLeftToTopRight_Inside = "BottomLeftToTopRight_Inside",
  BottomLeftToTopRight_Outside = "BottomLeftToTopRight_Outside",
  BottomLeftToTopRight_Quarter = "BottomLeftToTopRight_Quarter",
  BottomLeftToTopRight_OutsideX2 = "BottomLeftToTopRight_OutsideX2",
  TopLeftToBottomRight_Inside = "TopLeftToBottomRight_Inside",
  TopLeftToBottomRight_Outside = "TopLeftToBottomRight_Outside",
  TopLeftToBottomRight_OutsideX2 = "TopLeftToBottomRight_OutsideX2",
  TopLeftToBottomRight_Outside_Full = "TopLeftToBottomRight_Outside_Full",
  SiteSearch = "SiteSearch",
  VerticallyCentered = "VerticallyCentered",
}
