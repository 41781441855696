import React from "react"
import { ArticleResultsListProps } from "../ArticleProps.interface"
import { Hits } from "react-instantsearch-hooks-web"
import CustomLink from "../../../atoms/CustomLink/CustomLink.atom"
import Image from "../../../atoms/Image/Image.atom"

const ArticleIndexResultsList: React.FC = ({}) => {
  return (
    <div className="container mx-auto">
      <ResultsList />
    </div>
  )
}

const ResultsList: React.FC = () => {
  const ResultListItem = ({ hit }: any): JSX.Element | null => {
    if (hit.preview_card_content) {
      const { title, description, author, image, tag } =
        hit.preview_card_content
      const date = hit.first_published_at_date

      return (
        <CustomLink
          className="block"
          url={hit.slug.replace(`${process.env.GATSBY_DOMAIN_FOLDER}/`, "")}
        >
          <Image image={image} className="rounded-[16px] overflow-hidden" />
          <div className="pt-6">
            <p className="text-SP6 text-sm font-semibold">{`${date} - ${author}`}</p>
            <h4 className="text-lg text-Primary-900 font-semibold pt-1 pb-3">
              {title}
            </h4>
            <p className="text-Body text-base">{description}</p>
          </div>
        </CustomLink>
      )
    }
    return null
  }
  return (
    <Hits
      className="prose-ol:grid prose-ol:grid-cols-1 prose-ol:md:grid-cols-2 prose-ol:lg:grid-cols-3 prose-ol:gap-y-12 prose-ol:gap-x-6"
      hitComponent={ResultListItem}
    />
  )
}

export default ArticleIndexResultsList
