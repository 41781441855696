import React from "react"

export interface Building {
  name: string
  websiteFilterItem: string
  icon: string
  heroIcon: string
}

export interface Facility {
  name: string
  websiteFilterItem: string
  cardIcon: string
  icon: string
}

export interface Club {
  cardIcon: string
  icon: string
  name: string
  websiteFilterItem: string
  websiteTableRowColour: string
}

export interface Filters {
  clubs: Club[]
  facilities: Facility[]
  buildings: Building[]
}

const FiltersContext = React.createContext<Filters>({
  clubs: [],
  facilities: [],
  buildings: [],
})

export default FiltersContext
