import React from "react"
import { YoutubeVideoPlaceholderProps } from "../../../interfaces/MediaAsset/YoutubeVideoBlok.interface"
import Image from "../../atoms/Image/Image.atom"
import Play from "../../../assets/svgs/Play.svg"
import classNames from "classnames"

export const YoutubeVideoPlaceholder: React.FC<
  YoutubeVideoPlaceholderProps
> = ({ image, toggleVideo, positionedAbsolute }) => {
  const VideoGradientOverlayClassNames = classNames(
    "absolute top-0 bottom-0 right-0 left-0 z-20 bg-Primary-900 transition-all duration-500 opacity-40 group-hover:opacity-50 cursor-pointer"
  )
  const PlayButtonClassNames = classNames(
    "absolute top-0 bottom-0 right-0 left-0 m-auto w-20 h-20 z-20 transition-all duration-500 group-hover:scale-110 pointer-events-none"
  )
  const VideoPlaceholdaerClassNames = classNames(
    "absolute top-0 bottom-0 right-0 left-0 z-10"
  )

  return (
    <div className={`w-full h-full relative group`}>
      <div
        className={VideoGradientOverlayClassNames}
        onClick={() => {
          toggleVideo(true)
        }}
      ></div>
      <button className={PlayButtonClassNames}>
        <Play className="w-20 h-20" />
      </button>
      <div className={`${positionedAbsolute && VideoPlaceholdaerClassNames}`}>
        {image && <Image image={image} objectFit="cover" />}
      </div>
    </div>
  )
}

export default YoutubeVideoPlaceholder
