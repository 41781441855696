import React from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import StoryblokHeadingAndRichText from '../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface';
import { StoryblokIconList, StoryblokIconListItem } from '../../../interfaces/Storyblok/StoryblokIconList.interface';
import { ButtonProps } from '../../../interfaces/Button.interface';
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import RichText from '../../atoms/RichText/RichText.atom';
import Image from '../../atoms/Image/Image.atom';
import Button from '../../atoms/Button/Button.component';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import classNames from 'classnames';

interface Card {
  heading: string;
  body: string;
  image: StoryblokAsset;
  link: StoryblokLink;
}

interface CardList {
  cards: Card[];
}
interface WholeOfChildVariantTwoProps extends BlokWithBorderIcons {
  image: StoryblokAsset;
  copy_sections: StoryblokHeadingAndRichText[];
  card_list: CardList[];
  cta: ButtonProps[];
}

const CardContent: React.FC<Card> = ({ heading, body, image, link }) => {
  return (
    <li className="flex flex-col items-center px-10 py-6 space-y-3 text-center transition-all">
      <Image image={image} maxWidth={64} maxHeight={64} />
      <h4
        className={classNames(`text-lg font-semibold text-Primary-900 `, {
          'group-hover:text-White': link?.cached_url,
        })}
      >
        {heading}
      </h4>
      <p
        className={classNames(`text-Body`, {
          'group-hover:text-White': link?.cached_url,
        })}
      >
        {body}
      </p>
    </li>
  );
};

const WholeOfChildVariantTwo: React.FC<WholeOfChildVariantTwoProps> = ({
  copy_sections,
  card_list,
  cta,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  return (
    <AnimateInComponent didBecomeVisible={() => {}}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="mx-auto container pb-12 gap-x-6 gap-y-10 font-montserrat md:pb-16 lg:pb-20">
          {/*-- content --*/}
          <div className="">
            {/*-- copy sections --*/}
            {copy_sections && copy_sections.length && (
              <ul className="space-y-8 text-center">
                {copy_sections.map((copy_section: StoryblokHeadingAndRichText) => (
                  <li className="space-y-4">
                    <h3 className="text-lg font-semibold text-Primary-900">{copy_section.heading}</h3>
                    <RichText content={copy_section.rich_text} />
                  </li>
                ))}
              </ul>
            )}

            {card_list &&
              card_list.length &&
              card_list.map(card_list => (
                <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 pt-10 sm:pt-20">
                  {card_list.cards.map((card: Card) =>
                    card?.link?.cached_url ? (
                      <CustomLink
                        url={card.link.cached_url}
                        className="border border-SP6 rounded-[12px] py-4 group hover:bg-Primary-900 transition-all"
                      >
                        <CardContent {...card} />
                      </CustomLink>
                    ) : (
                      <div className="group">
                        <CardContent {...card} />
                      </div>
                    ),
                  )}
                </ul>
              ))}

            {/*-- cta --*/}
            {cta && cta.length && (
              <div className="flex justify-center pt-12 sm:pt-16">
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.XL}
                  className={''}
                ></Button>
              </div>
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  );
};

export default WholeOfChildVariantTwo;
