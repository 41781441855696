import { Venue } from "../contexts/VenuesContext"

const getPaginationConfig = (
  page: number,
  itemsPerPage: number,
  items: Venue[]
): {
  currentItems: any[]
  pageCount: number
} => {
  const pageNumber = page - 1 || 0
  const itemOffset = (pageNumber * itemsPerPage) % items.length
  const endOffset = itemOffset + itemsPerPage
  const currentItems = items.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(items.length / itemsPerPage)

  return {
    currentItems,
    pageCount,
  }
}

export default getPaginationConfig
