import classNames from "classnames";
import React from "react";
import Location from "../../../../../assets/svgs/Location.svg";
import Numbers from "../../../../../assets/svgs/Numbers.svg";
import { ButtonTypes } from "../../../../../interfaces/enums/ButtonTypes.enum";
import { IFilteredAd, showSalaryOrPerHour } from "../../../../../services/jobAdder.service";
import Button from "../../../../atoms/AnimatedButton/AnimatedButton.component";
import Card from "../../../../molecules/Card/Card.component";

interface JobResultItemProps {
  Card_primary_cta_label: string;
  Card_secondary_cta_label: string;
  job: IFilteredAd;
};

const JobResultItem: React.FC<JobResultItemProps> = ({ Card_primary_cta_label, Card_secondary_cta_label, job }: JobResultItemProps) => {
  const jobDescriptionSubTitle = classNames('leading-7 font-semibold text-2xl font-monserrat text-Primary-900 pt-1');
  const { adId, contractType, category, countyBorough, weeklyHours, title, summary, applicationLink, salary, hourlyRate } = job;

  return (
    <>
      <Card>
        <div>
          <p className="text-xs"><span className="uppercase">{contractType}</span> - {weeklyHours} hours a week</p>
          <h3 className={jobDescriptionSubTitle}>{title}</h3>

          <div id="salary-and-location-bar" className="border-b border-dashed flex flex-row gap-5 py-4 align-start justify-start">
            {salary && (<div className="text-sm font-semibold items-center flex gap-2.5"><i><Numbers className={'text-Secondary-300'} /></i><p>{`${showSalaryOrPerHour(salary)}`}</p></div>)}
            {countyBorough && <div className="text-sm font-semibold items-center flex gap-2.5"><i><Location className={'text-Secondary-300'} /></i><p>{countyBorough}</p></div>}
            {/* TODO this has been removed as we do not currently have this */}
            {/* <div className="text-sm font-semibold items-center flex gap-2.5"><i><Building className={'text-Secondary-300'} /></i><p>Building A, South Croydon</p></div> */}
          </div>

          <div id="job-card-content" className="py-4">
            <p className="line-clamp-3" dangerouslySetInnerHTML={{__html: summary}} />
          </div>

          <div id="job-card-footer" className="py-4 flex gap-7">
            <Button
              onClick={() => {
                window.open(applicationLink);
              }}
              type={ButtonTypes.PRIMARY}
              label={Card_primary_cta_label}
              styles={'items-center'}
            />
            <Button
              link={{
                cached_url: `join-our-team/job-application?adid=${adId}`
              }}
              type={ButtonTypes.SECONDARY}
              styles={'bg-White border border-Primary-900'}
              label={Card_secondary_cta_label}
            />
          </div>
        </div>
      </Card>
    </>      
  )
}

export default JobResultItem;