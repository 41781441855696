import classNames from "classnames";
import React, { useContext } from "react";
import Tick from "../../../../../assets/svgs/Tick.svg";
import JobPageContext from "../../../../../contexts/JobsPage.context";
import { JobSearchFilterItem } from "../../JobSearchFilterAndResults.blok";

interface JobSearchFilterSectionItemProps {
  filter_item: JobSearchFilterItem;
  filterGroupName: string;
}

const JobSearchFilterSectionItem: React.FC<JobSearchFilterSectionItemProps> = ({
  filter_item,
  filterGroupName
}) => { 
  const { updateSelectedFilters, selectedFilters } = useContext(JobPageContext);
  const isFilterItemSelected = () => {
    return selectedFilters.find((filter) => filter.name === filter_item.name);
  }
  const checkboxButtonClassNames = classNames(
    "border-2 border-Secondary-300 rounded-sm w-3.5 h-3.5 p-[1px] flex items-center justify-center",
    {
      "bg-Secondary-300": isFilterItemSelected(),
    }
  );

  return (
    <li className="flex items-center justify-between">
      <div className="flex items-center justify-start">
        <p className="text-Body">{filter_item.name}</p>
      </div>
      <button
        className={checkboxButtonClassNames}
        onClick={() => {  
          const newFiltersArray =
            selectedFilters.find((filter) => filter.name === filter_item.name)
              ? selectedFilters.filter((filter: JobSearchFilterItem) => filter.name !== filter_item.name)
              : [...selectedFilters, { name: filter_item.name, value: filter_item.content!.value, group: filterGroupName,  }];
             
          updateSelectedFilters(newFiltersArray);          
        }}
      >
        {isFilterItemSelected() && <Tick className="fill-White" />}
      </button>
    </li>
  )
}

export default JobSearchFilterSectionItem
