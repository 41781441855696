import React from "react"

interface BackgroundTear2Props {
  className?: string
}

const BackgroundTear2: React.FC<BackgroundTear2Props> = ({ className }) => {
  return (
    <svg
      xmlSpace="preserve"
      //   style="enable-background:new 0 0 1000 112.6"
      viewBox="0 0 1000 112.6"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M0 46.9V0h1000v45.4c-8 .7-15.7 1.4-23.5 2-8.4.7-16.8 1.3-25.2 2-7.5.6-15 1.3-22.6 2-7.5.7-14.9 1.3-22.4 2-7.3.7-14.6 1.3-21.9 2-7.1.7-14.1 1.3-21.2 2-6.8.7-13.7 1.3-20.5 2-8.4.8-16.7 1.6-25.1 2.5-9.3 1-18.7 2-28 3-3.9.4-7.9.8-11.8 1.3-6.7.8-13.4 1.7-20.1 2.5-3.9.5-7.9.9-11.8 1.4-5.7.8-11.4 1.7-17.1 2.5-5.8.8-11.7 1.5-17.5 2.3-8.2 1.2-16.3 2.5-24.5 3.6-5.9.8-11.8 1.5-17.7 2.3-4.1.5-8.2 1.2-12.4 1.7-4.4.5-8.8.9-13.2 1.4-6.9.8-13.8 1.7-20.7 2.4-7.7.8-15.3 1.5-23 2-10.7.6-21.3 1.2-32 1.3-9.9.1-19.8-.3-29.7-.5-5.3-.1-10.6-.5-15.9-.8-3.2-.2-6.5-.4-9.7-.8-4.3-.5-8.7-1.1-13-1.7-5.6-.7-11.2-1.3-16.7-2.2-6.9-1.1-13.8-2.4-20.6-3.7L441.8 76c-6.7-1.3-13.4-2.6-20-3.9-6.8-1.3-13.6-2.7-20.4-3.9-7.9-1.4-15.9-2.8-23.8-4-5.6-.9-11.3-1.6-16.9-2.2-6.6-.7-13.2-1.3-19.8-1.8-9.8-.7-19.6-1.3-29.3-2-7.8-.6-15.6-1.1-23.3-2.1-8.8-1.1-17.6-2.6-26.4-4.2-4.8-.9-9.5-2.3-14.2-3.4-6.3-1.5-12.5-3.2-18.9-4.4-5.1-1-10.3-1.4-15.5-2.2-2.3-.4-4.6-1.1-6.8-1.9-3-1.2-5.8-2.8-8.8-4-9.4-3.6-19.3-4.3-29.2-4.7-4.1-.2-8.2.2-12.3.3L119 32.8c-7.2.2-14.4.1-21.6.3-7.4.2-14.7.4-22.1.7-1.2 0-2.4.2-3.5.5-5.4 1.5-10.7 3.4-16.2 4.5-9.5 1.9-19.1 3.4-28.7 5-5 .8-10.1 1.5-15.1 2.2-4.2.2-8 .5-11.8.9z" />
    </svg>
  )
}

export default BackgroundTear2
