import { navigate } from "gatsby"
import { QueryParams } from "../interfaces/enums/QueryParams.enum"

const setQueryParameters = (value: string[] | string, key: string): void => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    if (key === QueryParams.FILTERS) {
      if (value.length > 0) {
        params.set(QueryParams.FILTERS, value.toString().toLowerCase())
        params.delete(QueryParams.PAGE)
        navigate(`/find-us/?${params.toString()}`)
      } else {
        params.delete(QueryParams.FILTERS)
        params.delete(QueryParams.LAT_LNG)
        navigate(`/find-us/?${params.toString()}`)
      }
    } else if (key === QueryParams.LAT_LNG) {
      params.set(QueryParams.LAT_LNG, value as string)
      params.delete(QueryParams.SEARCH)
      navigate(`/find-us/?${params.toString()}`)
    } else if (key === QueryParams.PAGE) {
      params.set(QueryParams.PAGE, value as string)
      navigate(`/find-us/?${params.toString()}`)
    } else if (key === QueryParams.SEARCH) {
      params.set(QueryParams.SEARCH, value as string)
      params.delete(QueryParams.LAT_LNG)
      navigate(`/find-us/?${params.toString()}`)
    }
  }
}

export default setQueryParameters
