import React, { useContext } from "react"
import Tick from "../../../../assets/svgs/Tick.svg"
import Image from "../../../atoms/Image/Image.atom"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"
import classNames from "classnames"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"

interface FindUsFilterSectionItemProps {
  filterable_item: FilterableItem
}

const FindUsFilterSectionItem: React.FC<FindUsFilterSectionItemProps> = ({
  filterable_item,
}) => {
  const { selectedFilters, updateSelectedFilters } =
    useContext(FindUsPageContext)

  const getFilterableItemName = (filterable_item: FilterableItem): string => {
    return filterable_item.content.filterable_item
  }

  const isFilterOptionSelected = (): boolean => {
    return (
      selectedFilters.filter(
        (selectedFilter: FilterableItem) =>
          getFilterableItemName(selectedFilter) ===
          getFilterableItemName(filterable_item)
      ).length > 0
    )
  }

  const checkboxButtonClassNames = classNames(
    "border-2 border-Secondary-300 rounded-sm w-3.5 h-3.5 p-[1px] flex items-center justify-center",
    {
      "bg-Secondary-300": isFilterOptionSelected(),
    }
  )

  const attemptToUpdateSelectedFilters = (): void => {
    if (isFilterOptionSelected()) {
      const selectedFiltersExcludingSelectedFilterableItem: FilterableItem[] =
        selectedFilters.filter(
          (selectedFilter: FilterableItem) =>
            getFilterableItemName(selectedFilter) !==
            getFilterableItemName(filterable_item)
        )
      updateSelectedFilters(selectedFiltersExcludingSelectedFilterableItem)
    } else {
      const selectedFiltersIncludingSelectedFilterableItem: FilterableItem[] = [
        ...selectedFilters,
        filterable_item,
      ]
      updateSelectedFilters(selectedFiltersIncludingSelectedFilterableItem)
    }
  }

  const handleFilterToggleDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "venueFilter",
      filterName: filterable_item.name,
      filterSelected: isFilterOptionSelected() ? "Not Ticked" : "Ticked",
    })
  }

  return (
    <li className="flex items-center justify-between">
      <div className="flex items-center justify-start space-x-4">
        <div className="w-10 h-10">
          <Image image={filterable_item.content.filter_list_icon} />
        </div>
        <p className="text-Body font-bold">{filterable_item.name}</p>
      </div>
      <button
        className={checkboxButtonClassNames}
        onClick={() => {
          attemptToUpdateSelectedFilters()
          handleFilterToggleDataLayerEvent()
        }}
      >
        {isFilterOptionSelected() && <Tick className="fill-White" />}
      </button>
    </li>
  )
}

export default FindUsFilterSectionItem
