import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HR,
  NODE_BR,
  StoryblokRichtext,
} from 'storyblok-rich-text-react-renderer';
import DynamicComponent from '../../utils/DynamicComponent/DynamicComponent.component';
import './RichText.css';
import CustomLink from '../CustomLink/CustomLink.atom';

interface RichTextProps {
  content: StoryblokRichtext;
  isArticleRichTextConfigRequired?: boolean;
  className?: string;
}

const RichText: React.FC<RichTextProps> = ({ content, isArticleRichTextConfigRequired = false, className }) => {
  useEffect(() => {
    removeSiteDirectoryFromAnchorHrefs();
  }, []);

  const removeSiteDirectoryFromAnchorHrefs = (): void => {
    const richTextArticles: Array<Element> = Array.from(document.getElementsByClassName('rich-text'));
    richTextArticles.map((richTextArticle: Element) => {
      const anchorTags: Array<Element> = Array.from(richTextArticle.getElementsByTagName('a')).filter(
        (anchorTag: Element) => !anchorTag.className.includes('custom-link'),
      );
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.href = anchorTag.href.replaceAll(`${process.env.GATSBY_DOMAIN_FOLDER}/`, '');
      });
    });
  };

  const article = classNames(`rich-text prose max-w-none`);
  const headings = classNames(
    `prose-headings:font-semibold prose-headings:leading-tight`,
    {
      'prose-headings:text-Primary-900': isArticleRichTextConfigRequired,
      'prose-headings:text-Body': !isArticleRichTextConfigRequired,
    },
    className,
  );
  const h1 = classNames(
    `prose-h1:font-extrabold prose-h1:text-2xl prose-h1:md:text-3xl prose-h1:xl:text-4xl prose-h1:3xl:text-5xl`,
  );
  const h2 = classNames('', {
    'prose-h2:text-xl prose-h2:md:text-2xl': isArticleRichTextConfigRequired,
    'prose-h2:text-2xl prose-h2:md:text-4xl': !isArticleRichTextConfigRequired,
  });
  const h3 = classNames('', {
    'prose-h3:text-lg prose-h3:md:text-xl': isArticleRichTextConfigRequired,
    'prose-h3:text-2xl prose-h3:md:text-4xl': !isArticleRichTextConfigRequired,
  });
  const h4 = classNames(`prose-h4:font-semibold prose-h4:text-xl`);
  const p = classNames(`prose-p:my-0 prose-p:break-word prose-p:leading-6`);
  const bold = classNames(`prose-strong:font-bold`);

  return (
    <article className={`${article} ${headings} ${h1} ${h2} ${h3} ${h4} ${p} ${bold} space-y-6`}>
      {render(content, {
        markResolvers: {
          [MARK_BOLD]: (children: any) => <strong>{children}</strong>,
          [MARK_LINK]: (children: any, props: any) => {
            const { href } = props;
            return (
              <CustomLink url={href} className="text-Primary-900">
                {children}
              </CustomLink>
            );
          },
        },
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children: any) => <p>{children}</p>,
          [NODE_BR]: () => <br></br>,
          [NODE_HR]: () => <hr></hr>,
        },
        defaultBlokResolver: (name, props: any) => (
          <DynamicComponent blok={{ ...props, component: name }} key={props.uid} />
        ),
      })}
    </article>
  );
};

RichText.defaultProps = {
  content: undefined,
};

RichText.propTypes = {
  content: PropTypes.any.isRequired,
};

export default RichText;
