import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { Widget } from "@typeform/embed-react"
import { isEditMode } from "../../../utils/isEditMode.util"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface FormProps extends BlokWithBorderIcons {
  form_id: string
  form_name: string
}

const Form: React.FC<FormProps> = ({
  form_id,
  form_name,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const handleFormSubmitDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formSubmit",
      formID: form_id,
      formName: form_name,
      _clear: true,
    })
  }

  if (form_id === "") {
    return (
      <>
        {isEditMode() && (
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <section className="container mx-auto flex flex-col items-center justify-center space-y-4 py-10 font-montserrat md:py-12">
              <h2 className="font-extrabold text-Primary-900 text-xl md:text-2xl">
                No form id provided yet
              </h2>
              <p className="text-center">
                To show the form on this page, please include its corresponding
                form id
              </p>
            </section>
          </BorderIconsFactory>
        )}
      </>
    )
  }

  return (
    <section id={form_id}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="h-[800px] scroll-m-20">
          <Widget
            id={form_id}
            className="w-full h-full"
            onSubmit={() => handleFormSubmitDataLayerEvent()}
          />
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default Form
