import React, { useEffect } from "react"
import isWindowInExistence from "../../../utils/isWindowInExistence.util"

interface BaseDataLayerProps {
  field_component: string
  children: any
}

const BaseDataLayer: React.FC<BaseDataLayerProps> = ({
  field_component,
  children,
}) => {
  const clientFriendlyFieldComponentName: string = field_component
    .split("_")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  useEffect(() => {
    if (isWindowInExistence()) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "BaseDataLayer",
        market: `${process.env.GATSBY_DOMAIN_MARKET}`,
        pageType: clientFriendlyFieldComponentName,
        _clear: true,
      })
    }
  }, [])

  return children
}

export default BaseDataLayer
