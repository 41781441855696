import React from 'react';
import classNames from 'classnames';
import CustomLink from '../CustomLink/CustomLink.atom';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';

interface ButtonProps {
  link?: StoryblokLink | string;
  label: string | React.ReactNode;
  type: ButtonTypes;
  size?: ButtonSizeTypes;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  ariaLabel?: string;
  underline?: boolean;
  id?: string;
  fullWidth?: boolean;
  styles?: string;
  wrap?: boolean;
  theme: string;
}

interface BtnContentProps {
  label: string | React.ReactNode;
  arrowOnMobile: boolean;
  wrap?: boolean;
}

const BtnContent: React.FC<BtnContentProps> = ({ label, arrowOnMobile, wrap }) => {
  return (
    <>
      {arrowOnMobile ? (
        <>
          <span className="hidden md:block md:whitespace-nowrap">{label}</span>
          <svg width="16" height="16" viewBox="0 0 16 16" className="md:hidden">
            <path
              d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z"
              fill="#4B1375"
            />
          </svg>
        </>
      ) : (
        <span className={classNames({ 'whitespace-nowrap': !wrap })}>{label}</span>
      )}
    </>
  );
};

const Button: React.FC<ButtonProps> = ({
  link,
  label,
  type,
  size,
  className,
  onClick,
  ariaLabel,
  underline = false,
  fullWidth = false,
  id,
  styles,
  wrap,
  theme,
}) => {
  const buttonClasses = classNames(
    `${styles} inline-block h-fit font-extrabold text-center uppercase leading-none transition-background transition-text ease-in-out duration-300 cursor-pointer focus:ring-0 focus:outline-none`,
    {
      underline: underline,
    },
    // types
    {
      '!text-sherpa-body text-Primary-Button-Text bg-SP3 hover:!bg-sherpa-blue-700 hover:bg-SP8 rounded-full':
        type === ButtonTypes.PRIMARY,
      'text-White hover:text-SP1 uppercase': type === ButtonTypes.SECONDARY,
      '!text-sherpa-blue-900 hover:!text-sherpa-body text-SP6 hover:text-Primary-Button-Text uppercase':
        type === ButtonTypes.TERTIARY,
      '!text-sherpa-body text-Primary-Button-Text bg-SP3 hover:!bg-sherpa-blue-700 hover:bg-SP8 px-8 py-4 w-full md:w-auto md:py-[20px] lg:py-[24px] xl:py-[24px] 3xl:py-[23px] text-base 3xl:text-lg uppercase rounded-full md:rounded-r-full md:rounded-l-none !text-sherpa-body':
        type === ButtonTypes.WITH_INPUT,
      '!text-sherpa-body text-Primary-Button-Text bg-SP3 hover:!bg-sherpa-blue-700 hover:bg-SP8 px-3 py-3 md:px-6 md:py-4 2xl:px-8 2xl:py-6 rounded-full text-base 2xl:text-lg':
        type === ButtonTypes.PRIMARY_WITH_ARROW,
    },
    // sizes
    {
      'px-5 py-3 text-sm xl:text-base leading-4': size === ButtonSizeTypes.DEFAULT,
    },
    {
      'px-5 py-3 xl:px-6 xl:py-4 text-sm xl:text-base leading-4': size === ButtonSizeTypes.LG,
    },
    {
      'px-6 text-sm leading-[48px] md:leading-[66px] xl:text-base': size === ButtonSizeTypes.XL,
    },
    // combinations
    {
      'p-4 lg:px-5 lg:py-3 lg:text-sm': size === ButtonSizeTypes.SM && type === ButtonTypes.PRIMARY,
    },
    {
      'text-sm': size === ButtonSizeTypes.SM && type === ButtonTypes.SECONDARY,
    },
    {
      '!p-0 text-base': size === ButtonSizeTypes.DEFAULT && type === ButtonTypes.SECONDARY,
    },
    {
      'text-sm': size === ButtonSizeTypes.SM && type === ButtonTypes.TERTIARY,
    },
    {
      '!p-0 text-base': size === ButtonSizeTypes.DEFAULT && type === ButtonTypes.TERTIARY,
    },
    {
      'w-full': fullWidth,
    },
    {
      '!text-White hover:!text-White': theme === 'WHITE',
    },
    className ? className : '',
  );

  const handleButtonClickDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      type: 'cta',
      button: {
        label: label,
        url: link,
        location_url: window.location,
      },
      _clear: true,
    });
  };

  const { anchor } = link || {};
  const hasAnchor = anchor && typeof anchor === 'string';

  return (
    <>
      {link ? (
        <CustomLink
          url={`${typeof link === 'string' ? link : link.cached_url}`}
          anchor={hasAnchor ? anchor : ''}
          linkType={typeof link === 'string' ? undefined : link.linktype}
          className={buttonClasses}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            handleButtonClickDataLayerEvent();
            onClick && onClick(e);
          }}
          id={id}
        >
          <BtnContent label={label} arrowOnMobile={type === ButtonTypes.PRIMARY_WITH_ARROW} wrap={wrap} />
        </CustomLink>
      ) : (
        <button onClick={onClick} className={buttonClasses} aria-label={ariaLabel || 'button'} id={id}>
          <BtnContent label={label} arrowOnMobile={type === ButtonTypes.PRIMARY_WITH_ARROW} wrap={wrap} />
        </button>
      )}
    </>
  );
};

export default Button;
