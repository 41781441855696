import React from "react";
import DropdownItem from "../../../../molecules/DropdownItem/DropdownItem.component";
import { JobSearchFilterGroup, JobSearchFilterItem } from "../../JobSearchFilterAndResults.blok";
import JobSearchFilterSectionItem from "../JobSearchFilterSectionItem/JobSearchFilterSectionItem.component";

interface JobSearchFilterSectionProps {
  filter_section: JobSearchFilterGroup
}

const JobSearchFilterSection: React.FC<JobSearchFilterSectionProps> = ({
  filter_section,
}) => {
  const { name, content } = filter_section;
  const { filter_items } = content;

  return (
    <>
      <DropdownItem heading={name}>
        <ul className="flex flex-col gap-5">     
          {filter_items.map((filter_item: JobSearchFilterItem, idx) => (            
            <div key={idx}>
              <JobSearchFilterSectionItem
                key={filter_item._uid}
                filter_item={filter_item}
                filterGroupName={filter_section.name}
              />  
            </div>                   
          ))}
        </ul>
      </DropdownItem>
    </>    
  )
}

export default JobSearchFilterSection;
