import React from "react"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import { NavItem } from "../../../interfaces/Navigation.interface"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"

interface FooterListProps {
  display_name: string
  list_items: NavItem[]
  page_link: StoryblokLink
}

const FooterList: React.FC<FooterListProps> = ({
  display_name,
  list_items,
  page_link,
}) => {
  const handleFooterListTitleButtonClickDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "click",
      type: "Footer Menu Title",
      button: {
        label: display_name,
        link: page_link.cached_url,
      },
    })
  }

  const handleFooterListItemButtonClickDataLayerEvent = (
    label: string,
    link: string
  ): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "click",
      type: "Footer Menu",
      button: {
        text: label,
        url: link,
      },
      section: display_name,
    })
  }

  return (
    <>
      {page_link.cached_url !== "" ? (
        <CustomLink
          url={page_link.cached_url}
          className={`pb-3 text-lg sm:text-xl font-bold hover:text-SP1 transition-all`}
          onClick={() => handleFooterListTitleButtonClickDataLayerEvent()}
        >
          <h3>{display_name}</h3>
        </CustomLink>
      ) : (
        <h3 className="pb-3 text-lg sm:text-xl font-bold">{display_name}</h3>
      )}

      <ul>
        {list_items &&
          !!list_items.length &&
          list_items.map(
            link =>
              link.page_link && (
                <li key={link._uid} className="pb-2">
                  <CustomLink
                    url={link.page_link.cached_url}
                    className={`text-base font-semibold block hover:text-SP1 transition-all`}
                    onClick={() =>
                      handleFooterListItemButtonClickDataLayerEvent(
                        link.display_name,
                        link.page_link.cached_url
                      )
                    }
                  >
                    <span>{link.display_name}</span>
                  </CustomLink>
                </li>
              )
          )}
      </ul>
    </>
  )
}

export default FooterList
