import React, { useState, useContext } from "react"
import Location from "../../../assets/svgs/Location.svg"
import UserLocation from "../../../assets/svgs/UserLocation.svg"
import HeroBannerSearchBarConfigProps from "../../../interfaces/HeroBannerSearchBarConfig.interface"
import FindUsPageContext from "../../../contexts/FindUsPage.context"
import Button from "../../atoms/Button/Button.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import getCurrentUserPostcode from "../../../utils/getCurrentUserLocation/getCurrentUserPostcode.util"
import { simulateMouseClick } from "../../../utils/simulateMouseClick.util"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import getQueryParameters from "../../../utils/getQueryParameters.util"

interface FindUsHeroSearchBarProps {
  search_bar_config: HeroBannerSearchBarConfigProps[]
}

const FindUsHeroSearchBar: React.FC<FindUsHeroSearchBarProps> = ({
  search_bar_config,
}) => {
  const [isFetchingCurrentUserPostcode, setIsFetchingCurrentUserPostcode] =
    useState<boolean>(false)
  const [
    fetchingCurrentUserPostcodeErrorMessage,
    setFetchingCurrentUserPostcodeErrorMessage,
  ] = useState<string>()
  const [searchBarInputValue, setSearchBarInputValue] = useState(
    getQueryParameters(QueryParams.ZIP)
  )

  const {
    updateSearchBarInputValue,
    searchBarPrimaryButtonWasClicked,
    isUpdatingFilteredVenues,
  } = useContext(FindUsPageContext)

  const getSearchBarConfig = (): HeroBannerSearchBarConfigProps | undefined => {
    return search_bar_config && search_bar_config[0]
  }

  const getCurrentUserLocation = (): void => {
    if (!isFetchingCurrentUserPostcode) {
      setIsFetchingCurrentUserPostcode(true)
      getCurrentUserPostcode(
        (postcode: string) => {
          updateSearchBarInputValue(postcode)
          setIsFetchingCurrentUserPostcode(false)
        },
        (errorMessage: string) => {
          setIsFetchingCurrentUserPostcode(false)
          setFetchingCurrentUserPostcodeErrorMessage(errorMessage)
        }
      )
    }
  }

  const isSearchBarInteractionDisabled = (): boolean => {
    return isFetchingCurrentUserPostcode || isUpdatingFilteredVenues
  }

  const getPrimaryButtonLabel = (): string => {
    return isUpdatingFilteredVenues ? "Updating..." : "Find Clubs"
  }

  const handleKeyPress = (e: any) => {
    const FindClubsBtn = document.querySelector("#findClubsBtn")
    if (e.key == "Enter" && FindClubsBtn) {
      searchBarInputValue &&
        setQueryParameters(searchBarInputValue, QueryParams.ZIP)
    }
  }

  return (
    <></>
    // <div className="col-span-6 col-start-4 md:flex items-center justify-center space-y-4 md:space-y-0 md:drop-shadow-lg">
    //   <div className="relative w-full flex">
    //     <Location className="text-Secondary-300 absolute left-7 top-0 bottom-0 my-auto z-[1]" />
    //     {!isFetchingCurrentUserPostcode && (
    //       <input
    //         onChange={e => setSearchBarInputValue(e.target.value)}
    //         value={searchBarInputValue}
    //         type="text"
    //         placeholder={
    //           getSearchBarConfig()?.placeholder_text ??
    //           "Enter postcode or venue name"
    //         }
    //         onKeyDown={e => handleKeyPress(e)}
    //         className="text-sm md:text-base 2xl:text-lg py-3 md:py-4 lg:py-5 pl-14 pr-14 leading-6 focus:ring-0 focus:outline-none rounded-full md:rounded-l-full md:rounded-r-none w-full placeholder-Primary-900"
    //       />
    //     )}
    //     {isFetchingCurrentUserPostcode && (
    //       <p className="bg-White text-Primary-900 text-sm md:text-base 2xl:text-lg py-3 md:py-4 lg:py-5 pl-14 pr-14 leading-6 focus:ring-0 focus:outline-none rounded-full md:rounded-l-full md:rounded-r-none w-full">
    //         Finding your location...
    //       </p>
    //     )}
    //     <button
    //       onClick={() => getCurrentUserLocation()}
    //       aria-label="My location"
    //       className={`${
    //         isSearchBarInteractionDisabled() && "pointer-events-none"
    //       }`}
    //     >
    //       <UserLocation
    //         className={`text-Primary-900 absolute right-4 top-0 bottom-0 my-auto scale-[0.8] ${
    //           isFetchingCurrentUserPostcode && "animate-spin"
    //         } lg:scale-90 3xl:scale-100`}
    //       ></UserLocation>
    //     </button>
    //   </div>

    //   <Button
    //     label={getPrimaryButtonLabel()}
    //     type={ButtonTypes.WITH_INPUT}
    //     className={`whitespace-nowrap ${
    //       isSearchBarInteractionDisabled() && "pointer-events-none"
    //     }`}
    //     onClick={() => {
    //       if (!isSearchBarInteractionDisabled()) {
    //         searchBarPrimaryButtonWasClicked()
    //       }
    //     }}
    //     id={"findClubsBtn"}
    //   ></Button>
    // </div>
  )
}

export default FindUsHeroSearchBar
