import classNames from 'classnames';
import React from 'react';
import Email from '../../../assets/svgs/Email.svg';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import Image from '../../atoms/Image/Image.atom';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import Open from '../../../assets/svgs/Open.svg';
import BackgroundTexture from '../../molecules/BackgroundTexture/BackgroundTexture.molecule';
import { BackgroundTextureTypes } from '../../../interfaces/enums/BackgroundTextureTypes.enum';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';

interface Location {
  link_label: string;
  link_description: string;
  image: StoryblokAsset;
  link: StoryblokLink;
}

interface LocationBlockProps extends BlokWithBorderIcons {
  header: string;
  body: string;
  image: StoryblokAsset;
  locations: Location[];
}

const LocationBlock: React.FC<LocationBlockProps> = ({ header, body, image, locations, border_icon_bottom_left }) => {
  return (
    <div className={`relative overflow-hidden`}>
      <BorderIconsFactory type={BorderIconType.Blok} border_icon_bottom_left={border_icon_bottom_left}>
        <div className="container mx-auto h-full py-12 md:py-16 2xl:py-20 grid grid-cols-12 lg:gap-x-6 gap-y-10 lg:gap-y-0 relative z-10">
          <div className="col-span-12 md:col-span-12 lg:col-span-6 3xl:col-span-5 text-Primary-900">
            {header && <h2 className="text-2xl md:text-3xl xl:text-4xl font-extrabold">{header}</h2>}
            {body && <p className="text-base xl:text-lg text-Body pt-4 lg:pt-6">{body}</p>}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6 pt-10 lg:pt-12">
              {locations?.length &&
                locations.map(
                  location =>
                    location?.link?.cached_url && (
                      <CustomLink
                        url={location.link.cached_url}
                        className="bg-Primary-50 hover:bg-Primary-900 flex space-x-4 p-3 rounded-[12px] group transition-all"
                      >
                        {location.image && (
                          <Image
                            image={location.image}
                            objectFit={'cover'}
                            className="w-12 h-12"
                            maxWidth={48}
                            maxHeight={48}
                          />
                        )}
                        <div className="text-Body group-hover:text-White">
                          <p className="font-bold">{location.link_label}</p>
                          <p className="">{location.link_description}</p>
                        </div>
                        <div className="flex justify-end items-center grow">
                          <Open className="text-Primary-900 group-hover:text-White" />
                        </div>
                      </CustomLink>
                    ),
                )}
            </div>
          </div>
          <div className="hidden lg:block lg:col-span-6 lg:col-start-7 xl:col-span-5 xl:col-start-8 3xl:col-span-6 3xl:col-start-7 overflow-hidden">
            <div className="rounded-7xl overflow-hidden h-full xl:max-h-[425px] ml-10 xl:ml-0">
              {image && <Image image={image} objectFit={'cover'} />}
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </div>
  );
};

export default LocationBlock;
