import React from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import { useDynamicBackground } from "../../../hooks/useDynamicBackground.hook"
import "animate.css/animate.min.css"
import DarkThemeBlokTear from "../../../assets/svgs/CornerTears/BlokTears/DarkThemeBlokTear.svg"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface ImageProps {
  image: StoryblokAsset
}
interface LogoGalleryProps extends BlokWithBorderIcons {
  heading: string
  body: string
  logos: StoryblokAsset[]
}

interface ImageBlokProps {
  images: ImageProps[]
  transitionDelay: string
}

const ImageBlok: React.FC<ImageBlokProps> = ({ images, transitionDelay }) => {
  const { activeImageIndex, imageRefs } = useDynamicBackground(images, 2000)

  return (
    <>
      {images &&
        !!images.length &&
        images.map((image, index) => (
          <span
            key={image.image.id}
            className={`absolute top-0 right-0 bottom-0 left-0 m-auto`}
          >
            <Image
              image={image.image}
              objectFit="contain"
              className={`transition-opacity duration-700 ${transitionDelay} ${
                index === activeImageIndex ? "opacity-100" : "opacity-0"
              }`}
            ></Image>
          </span>
        ))}
    </>
  )
}

const LogoGallery: React.FC<LogoGalleryProps> = ({
  heading,
  body,
  logos = [],
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  // Convert "logos" into ImageProps[]
  const images: ImageProps[] = logos.map(logo => {
    return {
      image: logo,
    }
  })

  function createGroups(arr: ImageProps[], numGroups: number) {
    const perGroup = Math.ceil(arr.length / numGroups)
    return new Array(Math.ceil(numGroups))
      .fill("")
      .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
  }

  const howManySlots = images?.length ? images.length / 2 : 8
  const logosBySlot = createGroups(images, howManySlots)

  return (
    <section className="bg-Primary-900">
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto py-12 md:py-16 lg:py-20 z-10">
          <div className="text-center text-White w-10/12 mx-auto">
            <h2
              className="font-extrabold 
            text-2xl md:text-3xl xl:text-4x
            mb-6
            "
            >
              {heading}
            </h2>
            <div className="mb-12">{body}</div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {logosBySlot.map((logos, index) => {
              const delayClasses = [
                "delay-0",
                "delay-[1000ms]",
                "delay-[3000ms]",
                "delay-[2000ms]",
                "delay-[1000ms]",
                "delay-[2000ms]",
                "delay-0",
                "delay-[3000ms]",
              ]

              return (
                <div key={index} className="col-span-1">
                  <div className="bg-White rounded-6xl overflow-hidden p-4">
                    <div className="relative h-28">
                      <ImageBlok
                        images={logos}
                        transitionDelay={delayClasses[index]}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default LogoGallery
