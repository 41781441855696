import React from "react"
import { JobSummaryItem } from "../JobInformation.blok"

interface JobSummarySectionsProps {
  jobSummaryItems: JobSummaryItem[]
}

const JobSummarySections: React.FC<JobSummarySectionsProps> = ({
  jobSummaryItems = [],
}) => {
  return (
    <ul className="pb-4">
      {jobSummaryItems.map(({ summaryItemLabel, summaryItemValue }: JobSummaryItem) => (
        <>
          {summaryItemValue && (
            <li className="grid grid-cols-2 py-4 [&:not(:last-child)]:border-b border-b-Body-Disabled">
              <p className='text-base font-semibold text-Primary-900 leading-6 font-montserrat'>{summaryItemLabel}</p>
              <p className='text-base text-[#3D3D3D] leading-6 font-montserrat'>{summaryItemValue}</p>
           </li>
          )}          
        </>        
      ))}
    </ul>
  )
}

export default JobSummarySections
