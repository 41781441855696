import React, { useState } from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import ImageCarousel from "../../molecules/ImageCarousel/ImageCarousel.molecule"
import "animate.css/animate.min.css"
import { useDynamicBackground } from "../../../hooks/useDynamicBackground.hook"
import classNames from "classnames"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface ImageProps {
  image: StoryblokAsset
}
interface ImageGalleryProps extends BlokWithBorderIcons {
  left_image_blok: ImageProps[]
  top_image_blok: ImageProps[]
  bottom_image_blok: ImageProps[]
  right_image_blok: ImageProps[]
}

interface ImageBlokProps {
  images: ImageProps[]
  transitionDelay: string
}

export const ImageBlok: React.FC<ImageBlokProps> = ({
  images,
  transitionDelay,
}) => {
  const { activeImageIndex, imageRefs } = useDynamicBackground(images, 4000)

  return (
    <>
      {images &&
        !!images.length &&
        images.map((image, index) => (
          <span
            className={`absolute top-0 right-0 bottom-0 left-0 m-auto rounded-7xl overflow-hidden`}
          >
            <Image
              image={image.image}
              objectFit="cover"
              ref={imageRefs[index]}
              className={`transition-opacity duration-700 ${transitionDelay} ${
                index === activeImageIndex ? "opacity-100" : "opacity-0"
              }`}
            ></Image>
          </span>
        ))}
    </>
  )
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  left_image_blok = [],
  top_image_blok = [],
  bottom_image_blok = [],
  right_image_blok = [],
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)
  const stackedImages = [...top_image_blok, ...bottom_image_blok]
  const galleryHeight = classNames(
    "md:h-[304.61px] lg:h-[419.01px] xl:h-[475px] 2xl:h-[548px] 3xl:h-[693px]"
  )
  const galleryTopImageHeight = classNames(
    "h-[calc(50%-0.5rem)] lg:h-[calc(50%-0.75rem)] xl:h-[calc(57%-0.75rem)] 2xl:h-[calc(50%-0.75rem)] 3xl:h-[calc(68%-0.75rem)]"
  )
  const galleryBottomImageHeight = classNames(
    "h-[calc(50%-0.5rem)] lg:h-[calc(50%-0.75rem)] xl:h-[calc(43%-0.75rem)] 2xl:h-[calc(50%-0.75rem)] 3xl:h-[calc(32%-0.75rem)]"
  )
  const galleryItem = classNames("w-full rounded-6xl overflow-hidden relative")
  const galleryTransition = classNames(
    "transition-[opacity, transform] duration-500 ease-in-out opacity-0 translate-y-20",
    {
      "opacity-100 !translate-y-0": didBecomeVisible,
    }
  )
  const leftColumnTransition = classNames("delay-200")
  const middleColumnTransition = classNames("delay-400")
  const rightColumnTransition = classNames("delay-600")

  return (
    <section className="bg-White">
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <AnimateInComponent
          wrapperComponent={"div"}
          classNames={galleryTransition}
          didBecomeVisible={() => setDidBecomeVisible(true)}
        >
          <div className="md:container mx-auto md:pt-6 md:pb-12 lg:pt-12 lg:pb-16 xl:pt-16 xl:pb-24">
            <div
              className={`hidden md:grid grid-cols-3 gap-x-4 lg:gap-x-6 ${galleryHeight}`}
            >
              <div
                className={`${galleryItem} ${galleryTransition} ${leftColumnTransition}`}
              >
                <ImageBlok images={left_image_blok} transitionDelay="delay-0" />
              </div>

              <div>
                <div
                  className={`flex flex-wrap gap-y-4 lg:gap-y-6 ${galleryHeight}`}
                >
                  <div
                    className={`${galleryTopImageHeight} ${galleryItem} ${galleryTransition} ${middleColumnTransition}`}
                  >
                    <ImageBlok
                      images={top_image_blok}
                      transitionDelay="delay-[2000ms]"
                    />
                  </div>
                  <div
                    className={`${galleryBottomImageHeight} ${galleryItem} ${galleryTransition} ${middleColumnTransition}`}
                  >
                    <ImageBlok
                      images={bottom_image_blok}
                      transitionDelay="delay-[4000ms]"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${galleryItem} ${galleryTransition} ${rightColumnTransition}`}
              >
                <ImageBlok
                  images={right_image_blok}
                  transitionDelay="delay-[6000ms]"
                />
              </div>
            </div>
          </div>
          <div className="block md:hidden max-w-[28rem] pt-6 pb-[3.7rem] mx-auto">
            <div className={`${galleryTransition} ${leftColumnTransition}`}>
              <ImageCarousel
                leftImages={left_image_blok}
                rightImages={right_image_blok}
                stackedImages={stackedImages}
              ></ImageCarousel>
            </div>
          </div>
        </AnimateInComponent>
      </BorderIconsFactory>
    </section>
  )
}

export default ImageGallery
