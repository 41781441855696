import React from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import PageIntroProps from "./PageIntroProps.interface"
import PageIntroGenerator from "./PageIntroGenerator.component"
import Image from "../../atoms/Image/Image.atom"

interface PageIntroWithImageProps extends PageIntroProps {
  image: StoryblokAsset
}

const PageIntroWithImage: React.FC<PageIntroWithImageProps> = props => {
  const getRightColumn = (): JSX.Element => {
    return <Image image={props.image} objectFit="cover" />
  }

  return (
    <PageIntroGenerator
      default_props={{ ...props }}
      right_column={getRightColumn()}
    />
  )
}

export default PageIntroWithImage
