import React, { useContext } from 'react';
import { VenueIndexCardConfigProps } from '../../../interfaces/VenueIndex/VenueIndex.interface';
import classNames from 'classnames';
import Phone from '../../../assets/svgs/Phone.svg';
import Map from '../../../assets/svgs/Map.svg';
import MapPin from '../../../assets/svgs/MapPin.svg';
import Image from '../../atoms/Image/Image.atom';
import Button from '../../atoms/Button/Button.component';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { Venue, Service, VenueWithinRange } from '../../../contexts/VenuesContext';
import FiltersContext, { Facility } from '../../../contexts/FiltersContext';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import { getMiles } from '../../../utils/getMiles.util';

export enum FindUsResultsCardType {
  MAP,
  LIST,
  VENUE_CARD,
}

interface VenueIndexResultCardProps {
  venue: VenueWithinRange;
  card_config: VenueIndexCardConfigProps[];
  type: FindUsResultsCardType;
}

const VenueIndexResultCard: React.FC<VenueIndexResultCardProps> = ({ venue, card_config, type }) => {
  const { clubs, facilities, buildings } = useContext(FiltersContext);

  const isFindUsListCard = (): boolean => {
    return type === FindUsResultsCardType.LIST;
  };
  const cardClassNames = classNames('bg-White rounded-6xl overflow-hidden list-none', {
    'max-w-[380px]': !isFindUsListCard(),
  });

  const cardContentContainerClassNames = classNames('flex flex-col items-center justify-center z-[1] space-y-10', {
    'p-10': !isFindUsListCard(),
    'p-6 md:p-10 lg:flex-row lg:justify-between lg:space-y-0 xl:px-6 2xl:px-10': isFindUsListCard(),
  });

  const cardNameAndAddressInfoContainerClassNames = classNames('space-y-3', {
    'xl:space-y-4 3xl:space-y-3': isFindUsListCard(),
  });

  const cardVenueNameClassNames = classNames('font-semibold text-Primary-900 text-xl', {
    'md:text-2xl': isFindUsListCard(),
  });

  const addressInfoContainerClassNames = classNames('flex flex-col space-y-3 w-full pr-4', {
    'lg:flex-row lg:space-y-0 lg:space-x-4': isFindUsListCard(),
  });

  const addressInfoSectionContainerClassNames = classNames('flex items-center justify-start space-x-1.5');

  const addressInfoFindUsSectionContainerClassNames = classNames('', {
    'flex items-center justify-start space-x-1.5 whitespace-nowrap': isFindUsListCard(),
    hidden: !isFindUsListCard(),
  });

  const addressInfoLinkClassNames = 'text-sm text-Body font-semibold';

  const cardContentLeftFilterItemsListClassNames = classNames('w-full flex', {
    'flex-row flex-wrap': !isFindUsListCard(),
    'flex-col md:flex-row md:flex-wrap md:space-y-0 md:max-w-[450px]': isFindUsListCard(),
  });

  const cardContentLeftFilterItemsItemClassNames = classNames('flex items-center justify-start space-x-2 pt-2', {
    'w-1/2': !isFindUsListCard(),
    'w-full md:pr-4 md:max-w-[225px]': isFindUsListCard(),
  });

  const cardContentRightButtonsContainerClassNames = classNames('flex flex-col w-full space-y-6 w-full', {
    'lg:w-fit': isFindUsListCard(),
  });

  // loop through venue services
  const venueClubs = venue.services
    ?.reduce(function (filtered: { name: string; icon: string }[], service: Service) {
      const allClubs = clubs.filter(club => club.websiteFilterItem).map(club => club.name);

      // is service in allowed list
      if (allClubs.includes(service.clubType)) {
        const matchedClub = clubs.find(club => club.name === service.clubType);

        filtered.push({
          name: service.clubType,
          icon: matchedClub?.cardIcon || '',
        });
      }
      return filtered;
    }, [])
    .filter((value, index, self) => index === self.findIndex(t => t.name === value.name && t.icon === value.icon));

  const venueFacilities = venue.facilities
    ?.reduce(function (filtered: { name: string; icon: string }[], facility: Facility) {
      const allFacilities = facilities.filter(facility => facility.websiteFilterItem).map(facility => facility.name);
      if (allFacilities.includes(facility.name)) {
        const matchedFacility = facilities.find(f => f.name === facility.name);
        filtered.push({
          name: facility.name,
          icon: matchedFacility?.cardIcon || '',
        });
      }
      return filtered;
    }, [])
    .filter((value, index, self) => index === self.findIndex(t => t.name === value.name));

  const address = [
    venue.address.line1,
    venue.address.line2,
    venue.address.townCity,
    venue.address.region,
    venue.address.postcode,
  ]
    .filter(item => item)
    .join(', ');

  return (
    <li className={cardClassNames}>
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={card_config ? card_config[0]?.border_icon_top_left : undefined}
        border_icon_top_right={card_config ? card_config[0]?.border_icon_top_right : undefined}
        border_icon_bottom_left={card_config ? card_config[0]?.border_icon_bottom_left : undefined}
        border_icon_bottom_right={card_config ? card_config[0]?.border_icon_bottom_right : undefined}
      >
        <div className={cardContentContainerClassNames}>
          {/*-- top/left column --*/}
          <div>
            {/*-- distance, name and telephone, address info and find us --*/}
            <div className={cardNameAndAddressInfoContainerClassNames}>
              {/*-- distance, name --*/}
              <div>
                {venue?.distanceBetween && (
                  <p className="font-semibold text-sm text-Body">{getMiles(venue?.distanceBetween)} miles away</p>
                )}
                <h4 className={cardVenueNameClassNames}>{venue.name}</h4>
              </div>

              {/*-- telephone, address info and find us --*/}
              <div className={`${addressInfoContainerClassNames} border-b border-b-Body-Inactive border-dashed pb-4`}>
                {/*-- telephone and address info --*/}
                <div className={addressInfoContainerClassNames}>
                  {/*-- telephone --*/}
                  {venue.contactNumber && (
                    <div className={addressInfoSectionContainerClassNames}>
                      <Phone className="text-Secondary-300" />
                      <p className={`${addressInfoLinkClassNames} hidden sm:block whitespace-nowrap`}>
                        {venue.contactNumber}
                      </p>
                      <a
                        href={`tel:${venue.contactNumber}`}
                        className={`${addressInfoLinkClassNames} block sm:hidden whitespace-nowrap`}
                      >
                        {venue.contactNumber}
                      </a>
                    </div>
                  )}

                  {/*-- address --*/}
                  {address && (
                    <div className={addressInfoSectionContainerClassNames}>
                      <Map className="min-w-[1.5em] text-Secondary-300" />
                      <p className={addressInfoLinkClassNames}>
                        <span>{address}</span>
                      </p>
                    </div>
                  )}
                </div>

                {/*-- find us --*/}
                {venue.coords.lat && venue.coords.lng && (
                  <div className={addressInfoFindUsSectionContainerClassNames}>
                    <MapPin className="text-Secondary-300" />
                    <a
                      href={`http://maps.google.com/maps?q=${venue.coords.lat},${venue.coords.lng}`}
                      target="_blank"
                      className="uppercase font-semibold text-sm text-Primary-900 underline whitespace-nowrap"
                    >
                      Find us
                    </a>
                  </div>
                )}
              </div>
            </div>

            {/*-- filterable club types list (optional) --*/}
            <div>
              <div className="pt-4 w-full">
                <ul className={cardContentLeftFilterItemsListClassNames}>
                  {venueFacilities?.map((facility, idx) => (
                    <li key={`facility-${idx}`} className={cardContentLeftFilterItemsItemClassNames}>
                      {facility.icon && (
                        <div className="w-6 h-6 min-w-[1.5rem] min-h-[1.5rem]">
                          <Image imageUrl={facility.icon} />
                        </div>
                      )}
                      {facility.name && <p className="whitespace-nowrap">{facility.name}</p>}
                    </li>
                  ))}
                  {[...new Set(venueClubs)]?.map((club, idx) => (
                    <li key={`service-${idx}`} className={cardContentLeftFilterItemsItemClassNames}>
                      {club.icon && (
                        <div className="w-6 h-6 min-w-[1.5rem] min-h-[1.5rem]">
                          <Image imageUrl={club.icon} />
                        </div>
                      )}
                      {club.name && <p className="whitespace-nowrap">{club.name}</p>}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/*-- bottom/right column --*/}
          <div className={cardContentRightButtonsContainerClassNames}>
            {venue.links.bookingLink && (
              <Button
                link={venue.links.bookingLink}
                label={card_config ? card_config[0]?.primary_button_text || 'Book Now' : 'Book Now'}
                type={ButtonTypes.PRIMARY}
                size={(isFindUsListCard() && ButtonSizeTypes.SM) || ButtonSizeTypes.DEFAULT}
              />
            )}

            {venue.links.website_url && (
              <Button
                link={venue.links.website_url}
                label={card_config ? card_config[0]?.secondary_button_text || 'Sneak peek' : 'Sneak peek'}
                type={ButtonTypes.SECONDARY}
                underline
                className={'cursor-pointer text-Primary-900 whitespace-nowrap hover:!text-Primary-900'}
                size={(isFindUsListCard() && ButtonSizeTypes.SM) || ButtonSizeTypes.DEFAULT}
              />
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </li>
  );
};

export default VenueIndexResultCard;
