import React from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import StoryblokTestimonial from '../../../interfaces/Storyblok/StoryblokTestimonial.interface';
import Slider, { Settings } from 'react-slick';
import QuotationMark from '../../../assets/svgs/QuotationMark.svg';
import HappyFace from '../../../assets/svgs/HappyFace.svg';
import classNames from 'classnames';
import RoundedTriangle from '../../../assets/svgs/RoundedTriangle.svg';

interface TestimonialsProps extends Blok {
  testimonials: StoryblokTestimonial[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  const getSliderSettings = (): Settings => {
    return {
      dots: testimonials.length > 1,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: testimonials.length > 1,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
    };
  };

  const isSliderCarouselFunctionalityRequired = testimonials.length > 1;

  const testimonialsContainerClassNames = classNames('md:pb-14 lg:pt-6 lg:pb-16 xl:pb-20 overflow-hidden', {
    'pb-20': isSliderCarouselFunctionalityRequired,
    'pb-12': !isSliderCarouselFunctionalityRequired,
  });

  return (
    <section className={testimonialsContainerClassNames}>
      {!!testimonials.length && (
        <Slider {...getSliderSettings()}>
          {testimonials.map((testimonial: StoryblokTestimonial) => (
            <div key={testimonial.uuid} className="container mx-auto !grid grid-cols-2 gap-x-6 md:grid-cols-12">
              <div className="col-span-2 flex flex-col items-center justify-center space-y-6 font-montserrat md:col-span-10 md:col-start-2 md:col-end-12 lg:col-span-8 lg:col-start-3 lg:col-end-11">
                {/*-- quote box --*/}
                <div className="flex flex-col items-center">
                  <div className="flex flex-col !bg-sherpa-blue-500 bg-SP6 rounded-6xl p-6">
                    <QuotationMark className="fill-SP1" />
                    <p className="font-bold text-lg text-White">{testimonial.content.testimonial_text}</p>
                    <QuotationMark className="rotate-180 fill-SP1 self-end" />
                  </div>
                  <RoundedTriangle className="text-sherpa-blue-500 text-SP6" />
                </div>

                {/*-- happy face icon --*/}
                <HappyFace />

                {/*-- testimonial author information --*/}
                <div className="text-center">
                  <p className="font-secondary text-Primary-900 text-xl font-semibold">{testimonial.content.name}</p>
                  <p className="text-Body text-lg">{testimonial.content.role_type}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
};

export default Testimonials;
