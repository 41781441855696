import React, { useContext } from "react";
import ReactPaginate from "react-paginate";
import JobPageContext from "../../../../contexts/JobsPage.context";

interface JobResultPaginationBarProps {
  results_per_page?: number
}

const JobResultPaginationBar: React.FC<JobResultPaginationBarProps> = () => {
  const paginationBarTopBarListItemClassNames = "h-full w-10 md:w-12";
  const paginationBarTopListItemsWithBorderRightClassNames = `${paginationBarTopBarListItemClassNames} border-r border-r-Body-Disabled`;
  const paginationBarTopBarListItemAnchorClassNames =
    "w-full h-full flex items-center justify-center text-Primary-900";
  const getPaginationBarBottomBarNumberSpanClassNames = "font-bold";
  const {  
    currentPageIndex,
    numberOfJobsPerPage,
    updateCurrentPageIndex,
    totalNumberOfJobs,
    jobsLoading
  } = useContext(JobPageContext);


  const getNumberOfFirstJobOnPage = (): number => {
    if (totalNumberOfJobs === 0) return 0;
    return numberOfJobsPerPage * currentPageIndex + 1
  }

  const getNumberOfLastJobOnPage = (): number | null => {
    return totalNumberOfJobs! <
      numberOfJobsPerPage ? totalNumberOfJobs
      : (
        getNumberOfFirstJobOnPage() -
        1 +
        numberOfJobsPerPage
      )
  }

  return (
    <>     
      {!jobsLoading && (<div className="w-full flex flex-col sm:flex-row items-center justify-center md:justify-end">
        <div className="flex flex-col lg:flex-row items-center gap-6">
          <p className="text-Primary-900 lg:order-0 order-1">
            Showing{" "}
            <span className={getPaginationBarBottomBarNumberSpanClassNames}>
              {getNumberOfFirstJobOnPage()}
            </span>{" "}
            to{" "}
            <span className={getPaginationBarBottomBarNumberSpanClassNames}>
              {getNumberOfLastJobOnPage()}
            </span>{" "}
            of{" "}
            <span className={getPaginationBarBottomBarNumberSpanClassNames}>
              {totalNumberOfJobs}
            </span>{" "}
            vacancies
          </p>
          <div className="lg:order-1 order-0">
            <ReactPaginate
              forcePage={currentPageIndex}
              breakLabel="..."
              nextLabel=">"
              onPageChange={event => {
                updateCurrentPageIndex(event.selected, numberOfJobsPerPage)
              }
              }
              pageRangeDisplayed={2}
              pageCount={totalNumberOfJobs! / numberOfJobsPerPage}
              previousLabel="<"
              containerClassName={
                "flex bg-White border border-Body-Disabled rounded-2xl h-12"
              }
              pageClassName={paginationBarTopListItemsWithBorderRightClassNames}
              previousClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              breakClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              nextClassName={paginationBarTopBarListItemClassNames}
              pageLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              previousLinkClassName={
                paginationBarTopBarListItemAnchorClassNames
              }
              breakLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              nextLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              activeLinkClassName={"bg-Primary-900 text-[#fff]"}
            />
          </div>          
        </div>
      </div>)}
    </>
  )
}

export default JobResultPaginationBar
