import React from "react"
import { FilterItemSection } from "../FindUsFilterAndResults.blok"
import FindUsFilterSection from "./FindUsFilterSection.component"

interface FindUsFilterSectionsProps {
  filter_sections: FilterItemSection[]
}

const FindUsFilterSections: React.FC<FindUsFilterSectionsProps> = ({
  filter_sections,
}) => {
  return (
    <ul>
      {filter_sections.map((filter_section: FilterItemSection) => (
        <FindUsFilterSection
          key={filter_section._uid}
          filter_section={filter_section}
        />
      ))}
    </ul>
  )
}

export default FindUsFilterSections
