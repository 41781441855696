import { SphericalUtil } from 'node-geometry-library';
import React, { useContext, useEffect, useState } from 'react';
import VenuesContext, { Venue, VenueWithinRange } from '../../../contexts/VenuesContext';
import Coordinates from '../../../interfaces/Coordinates.interface';
import getPaginationConfig from '../../../utils/getPaginationConfig.util';
import VenueIndexWithFilters from '../../molecules/VenueIndexWithFilters/VenueIndexWithFilters.molecule';

import { useLocation } from '@reach/router';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import FiltersContext from '../../../contexts/FiltersContext';
import StoryblokHeadingAndRichText from '../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface';
import {
  AgeRangeItem,
  VenueIndexCardConfigProps,
  VenueIndexMapConfigProps,
  VenueIndexSearchBarProps,
} from '../../../interfaces/VenueIndex/VenueIndex.interface';
import { QueryParams } from '../../../interfaces/enums/QueryParams.enum';
import getFilterQueryParameters from '../../../utils/getFilterQueryParameters.util';
import getQueryParameters from '../../../utils/getQueryParameters.util';
import VenueIndexFilter from '../../molecules/VenueIndexFilter/VenueIndexFilter.molecule';
import VenueIndexPagination from '../../molecules/VenueIndexPagination/VenueIndexPagination.molecule';
import VenueIndexResultCard, {
  FindUsResultsCardType,
} from '../../molecules/VenueIndexResultCard/VenueIndexResultCard.component';
import VenueIndexResultsNotFound from '../../molecules/VenueIndexResultsNotFound/VenueIndexResultsNotFound.molecule';
import VenueIndexMap from '../VenueIndexMap/VenueIndexMap.blok';

interface VenueIndexProps {
  results_title: string;
  filter_title: string;
  clear_all_label: string;
  club_types: boolean;
  facilities: boolean;
  building_type: boolean;
  age_range: boolean;
  age_range_items: AgeRangeItem[];
  top_content_heading: string;
  top_content_body: StoryblokRichtext;
  book_now_label: string;
  sneak_peak_label: string;
  map_config: VenueIndexMapConfigProps[];
  search_bar: VenueIndexSearchBarProps[];
  card_config: VenueIndexCardConfigProps[];
  results_not_found: StoryblokHeadingAndRichText[] | undefined;
}

const VenueIndex: React.FC<VenueIndexProps> = ({
  results_title,
  filter_title,
  clear_all_label,
  club_types,
  facilities,
  building_type,
  age_range,
  age_range_items,
  book_now_label,
  sneak_peak_label,
  map_config,
  search_bar,
  card_config,
  results_not_found,
  ...props
}) => {
  const venueData = useContext(VenuesContext);
  const filtersData = useContext(FiltersContext);
  const [venues, setVenues] = useState<Venue[]>(venueData);
  const [filterMenu, setFilterMenu] = useState<boolean>(false);
  const toggleFilterMenu = (state: boolean): void => setFilterMenu(state);

  const filters = getFilterQueryParameters();
  const Page = getQueryParameters(QueryParams.PAGE) ? Number(getQueryParameters(QueryParams.PAGE)) : 1;

  const location = useLocation();

  useEffect(() => {
    const latLng = getQueryParameters(QueryParams.LAT_LNG);
    const key = getQueryParameters(QueryParams.SEARCH);
    if (latLng) {
      if (latLng === 'null') {
        setVenues([]);
      } else {
        const coords = {
          lat: Number(latLng.split(',')[0]),
          lng: Number(latLng.split(',')[1]),
        };
        getApplicableVenues(coords);
      }
    } else if (key) {
      const matchedVenues = venueData.filter(venue =>
        venue?.name?.toLowerCase().includes(decodeURIComponent(key).toLowerCase()),
      );
      if (matchedVenues.length > 0) {
      }
      setVenues(matchedVenues);
    } else {
      getApplicableVenues(undefined);
    }
  }, [location]);

  const allFilters: any[] = [];
  club_types && allFilters.push({ filterItems: filtersData.clubs });
  building_type && allFilters.push({ filterItems: filtersData.buildings });
  facilities && allFilters.push({ filterItems: filtersData.facilities });
  age_range &&
    allFilters.push({
      filterItems: age_range_items.map(({ name, icon }) => {
        return { name, icon: icon?.filename, websiteFilterItem: 'true' };
      }),
    });

  const sortVenues = (venues: VenueWithinRange[]): VenueWithinRange[] => {
    return venues.sort((a, b) => {
      return a.distanceBetween > b.distanceBetween ? 1 : b.distanceBetween > a.distanceBetween ? -1 : 0;
    });
  };

  const getVenuesWithinRange = (searchCoordinates: Coordinates): Venue[] => {
    const venuesWithinRange = venueData.reduce(function (filtered: VenueWithinRange[], venue: Venue) {
      const distanceBetween: number = SphericalUtil.computeDistanceBetween(searchCoordinates, {
        lat: venue.coords.lat,
        lng: venue.coords.lng,
      });
      const TWENTY_MILES_IN_METRES: number = 32187;
      const isVenueWithinSelectedDistance: boolean = distanceBetween < TWENTY_MILES_IN_METRES;
      if (venue.coords.lat && venue.coords.lng && isVenueWithinSelectedDistance) {
        const venueWithDistanceParam = {
          ...venue,
          distanceBetween: distanceBetween,
        };
        filtered.push(venueWithDistanceParam);
      }
      return filtered;
    }, []);
    return sortVenues(venuesWithinRange);
  };

  const getFilteredVenues = (venueWithinRange: Venue[]): Venue[] => {
    const serviceFilterCount: number = filtersData.clubs.filter(club =>
      filters.includes(club.name.toLowerCase()),
    ).length;
    const buildingsFilterCount: number = filtersData.buildings.filter(building =>
      filters.includes(building.name.toLowerCase()),
    ).length;
    const facilitiesFilterCount: number = filtersData.facilities.filter(facility =>
      filters.includes(facility.name.toLowerCase()),
    ).length;
    const ageFilterCount: number = age_range_items.filter(age => filters.includes(age.name)).length;

    const venuesWithSelectedFilters: Venue[] = venueWithinRange.filter(venue => {
      const hasService: boolean =
        !serviceFilterCount || !!venue.services?.find(service => filters.includes(service?.clubType?.toLowerCase()));

      const hasFacility: boolean =
        !facilitiesFilterCount || !!venue.facilities?.find(facility => filters.includes(facility?.name?.toLowerCase()));

      const hasBuilding: boolean =
        !buildingsFilterCount || !!filters.includes(venue?.buildingType?.name?.toLowerCase());

      const hasAgeRange: boolean = !ageFilterCount || !!filters.includes(`${venue?.age?.min} - ${venue?.age?.max}`);

      return [hasService, hasFacility, hasBuilding, hasAgeRange].every(item => item);
    });
    return venuesWithSelectedFilters;
  };

  const getApplicableVenues = async (coordinates?: Coordinates): Promise<void> => {
    if (coordinates?.lat && coordinates?.lng) {
      const venueWithinRange = getVenuesWithinRange(coordinates);
      const filteredVenues = getFilteredVenues(venueWithinRange);
      setVenues(filteredVenues);
    } else {
      const filteredVenues = getFilteredVenues(venueData);
      setVenues(filteredVenues);
    }
  };

  // PAGINATION CONFIGURATION
  const { currentItems, pageCount } = getPaginationConfig(Page, 12, venues);

  return (
    <section className="bg-sherpa-blue-50 bg-SP7">
      <VenueIndexMap
        map_config={map_config}
        search_bar_config={search_bar}
        card_config={card_config}
        venues={venues}
      ></VenueIndexMap>
      <VenueIndexWithFilters
        items={
          currentItems.length
            ? currentItems.map((item, idx) => (
                <VenueIndexResultCard
                  venue={item}
                  type={FindUsResultsCardType.LIST}
                  key={`VenueIndexResultCard-${idx}`}
                  card_config={card_config}
                />
              ))
            : [<VenueIndexResultsNotFound clear_all_label={clear_all_label} results_not_found={results_not_found} />]
        }
        filter={
          <VenueIndexFilter
            allFilters={allFilters}
            activeFilters={getFilterQueryParameters()}
            filterMenu={filterMenu}
            toggleFilterMenu={toggleFilterMenu}
            filter_title={filter_title}
            clear_all_label={clear_all_label}
          />
        }
        pagination={<VenueIndexPagination pageCount={pageCount} />}
        filterMenu={filterMenu}
        toggleFilterMenu={toggleFilterMenu}
        activeFilters={getFilterQueryParameters()}
        results_title={results_title}
        clear_all_label={clear_all_label}
        {...props}
      />
    </section>
  );
};

export default VenueIndex;
