import React from 'react';

interface BackgroundClockProps {
  className?: string;
}

const BackgroundClock: React.FC<BackgroundClockProps> = ({ className }) => {
  return (
    <svg width="176" height="178" viewBox="0 0 176 178" className={`transfrom fill-current ${className}`}>
      <path d="M143.022 143.178C116.47 169.729 73.2568 169.729 46.7054 143.178C20.1541 116.627 20.1541 73.4131 46.7054 46.8617C73.2568 20.3103 116.47 20.3104 143.022 46.8617C169.573 73.4131 169.573 116.627 143.022 143.178ZM51.0807 51.237C26.9267 75.3909 26.9268 114.649 51.0807 138.803C75.2347 162.957 114.492 162.956 138.646 138.803C162.8 114.649 162.8 75.391 138.646 51.237C114.492 27.083 75.2347 27.083 51.0807 51.237Z" />
      <path d="M13.3214 127.475C13.0816 127.715 12.8119 127.924 12.5122 128.044C10.9839 128.793 9.12593 128.194 8.37673 126.666C0.764941 111.382 -3.91005 86.0896 7.92719 65.0822C8.76628 63.5838 10.6543 63.0744 12.1527 63.9135C13.6511 64.7526 14.1605 66.6405 13.3214 68.1389C2.71282 87.0186 6.99824 110.004 13.9208 123.939C14.5201 125.137 14.2504 126.606 13.3514 127.505L13.3214 127.475Z" />
      <path d="M126.809 13.9873C125.85 14.9462 124.352 15.186 123.123 14.4967C105.802 4.72725 86.5324 4.57736 67.3231 14.0172C65.7948 14.7664 63.9668 14.1371 63.1876 12.6387C62.4384 11.1104 63.0678 9.28239 64.5661 8.50323C85.6934 -1.89556 107 -1.68585 126.15 9.13247C127.648 9.97157 128.158 11.8596 127.319 13.358C127.169 13.6277 127.019 13.8375 126.809 14.0472L126.809 13.9873Z" />
      <path d="M98.6993 98.5561L74.2757 122.98C73.077 124.178 71.0991 124.178 69.9004 122.98C68.7017 121.781 68.7016 119.803 69.9004 118.604L92.1664 96.3384L59.8612 64.0332C58.6625 62.8345 58.6625 60.8567 59.8613 59.658C61.06 58.4593 63.0378 58.4593 64.2365 59.658L98.6993 94.1208C99.898 95.3195 99.8981 97.2974 98.6993 98.4961V98.5561Z" />
      <path d="M106.79 176.772C106.341 177.221 105.712 177.551 105.022 177.641C103.344 177.881 101.786 176.682 101.546 175.004L100.557 167.901C100.317 166.223 101.516 164.665 103.194 164.425C104.872 164.185 106.431 165.384 106.67 167.062L107.659 174.165C107.809 175.154 107.45 176.113 106.79 176.772Z" />
      <path d="M174.907 108.655C174.308 109.255 173.499 109.584 172.57 109.554L167.715 109.315C166.007 109.225 164.688 107.786 164.778 106.078C164.868 104.37 166.306 103.051 168.014 103.141L172.869 103.381C174.577 103.471 175.896 104.909 175.806 106.617C175.776 107.427 175.417 108.146 174.907 108.655Z" />
      <path d="M134.061 135.656C132.863 136.855 130.885 136.855 129.686 135.656L125.76 131.73C124.561 130.532 124.561 128.554 125.76 127.355C126.959 126.156 128.937 126.156 130.135 127.355L134.061 131.281C135.26 132.48 135.26 134.457 134.061 135.656Z" />
      <path d="M134.061 62.355L130.136 66.2807C128.937 67.4795 126.959 67.4794 125.76 66.2807C124.562 65.082 124.562 63.1042 125.76 61.9055L129.686 57.9797C130.885 56.781 132.863 56.7809 134.061 57.9796C135.26 59.1783 135.26 61.1563 134.061 62.355Z" />
      <path d="M67.2933 129.962L63.817 133.438C62.6183 134.637 60.6404 134.637 59.4417 133.438C58.243 132.24 58.243 130.262 59.4417 129.063L62.9179 125.587C64.1166 124.388 66.0945 124.388 67.2932 125.587C68.4919 126.786 68.492 128.763 67.2933 129.962Z" />
    </svg>
  );
};

export default BackgroundClock;
