import React from "react"
import PropTypes from "prop-types"

interface PlaceholderComponentProps {
  componentName: string
}

const Placeholder: React.FC<PlaceholderComponentProps> = ({
  componentName,
}) => (
  <div className="flex justify-center items-center border py-8">
    <p>
      The component <strong>{componentName}</strong> has not been created yet.
    </p>
  </div>
)

Placeholder.defaultProps = {
  componentName: "unknown",
}

Placeholder.propTypes = {
  componentName: PropTypes.string.isRequired,
}

export default Placeholder
