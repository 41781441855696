import React from "react"

interface UserLocationProps {
  className?: string
}

const UserLocation: React.FC<UserLocationProps> = ({ className }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      aria-labelledby="UserLocation"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M15 9.66665C12.0533 9.66665 9.66666 12.0533 9.66666 15C9.66666 17.9466 12.0533 20.3333 15 20.3333C17.9467 20.3333 20.3333 17.9466 20.3333 15C20.3333 12.0533 17.9467 9.66665 15 9.66665ZM26.92 13.6666C26.3067 8.10665 21.8933 3.69331 16.3333 3.07998V0.333313H13.6667V3.07998C8.10666 3.69331 3.69333 8.10665 3.07999 13.6666H0.333328V16.3333H3.07999C3.69333 21.8933 8.10666 26.3066 13.6667 26.92V29.6666H16.3333V26.92C21.8933 26.3066 26.3067 21.8933 26.92 16.3333H29.6667V13.6666H26.92ZM15 24.3333C9.83999 24.3333 5.66666 20.16 5.66666 15C5.66666 9.83998 9.83999 5.66665 15 5.66665C20.16 5.66665 24.3333 9.83998 24.3333 15C24.3333 20.16 20.16 24.3333 15 24.3333Z" />
    </svg>
  )
}

export default UserLocation
