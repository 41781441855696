import React from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import StoryblokHeadingAndRichText from '../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface';
import { StoryblokIconList, StoryblokIconListItem } from '../../../interfaces/Storyblok/StoryblokIconList.interface';
import { ButtonProps } from '../../../interfaces/Button.interface';
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import RichText from '../../atoms/RichText/RichText.atom';
import Image from '../../atoms/Image/Image.atom';
import Button from '../../atoms/Button/Button.component';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

interface WholeOfChildProps extends BlokWithBorderIcons {
  image: StoryblokAsset;
  copy_sections: StoryblokHeadingAndRichText[];
  icon_list: StoryblokIconList[];
  cta: ButtonProps[];
}

const WholeOfChild: React.FC<WholeOfChildProps> = ({
  image,
  copy_sections,
  icon_list,
  cta,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  //
  return (
    <AnimateInComponent didBecomeVisible={() => {}}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="mx-auto container py-12 grid grid-cols-2 gap-x-6 gap-y-10 font-montserrat md:py-16 md:grid-cols-12 lg:py-20">
          {/*-- content --*/}
          <div className="col-span-2 space-y-10 md:col-span-10 md:col-start-2 md:col-end-12 md:space-y-16 lg:col-span-8 lg:col-start-3 lg:col-end-11 xl:col-span-6 xl:col-start-1 xl:col-end-7 2xl:col-span-5 2xl:col-end-6 3xl:col-span-6 3xl:col-end-7">
            {/*-- copy sections --*/}
            {copy_sections && copy_sections.length && (
              <ul className="space-y-8">
                {copy_sections.map((copy_section: StoryblokHeadingAndRichText) => (
                  <li className="space-y-4">
                    <h3 className="text-lg font-semibold text-Primary-900">{copy_section.heading}</h3>
                    <RichText content={copy_section.rich_text} />
                  </li>
                ))}
              </ul>
            )}

            {/*-- icon list --*/}
            {icon_list && icon_list.length && (
              <ul className="grid grid-cols-2 gap-x-6 gap-y-12 md:grid-cols-12">
                {icon_list[0].items.map((icon_list_item: StoryblokIconListItem) => (
                  <li className="col-span-1 space-y-3 flex flex-col items-center md:col-span-5 md:items-start">
                    <Image image={icon_list_item.icon} maxWidth={64} maxHeight={64} />
                    <h4 className="text-lg font-semibold text-Primary-900 text-center lg:text-left">
                      {icon_list_item.label}
                    </h4>
                  </li>
                ))}
              </ul>
            )}

            {/*-- cta --*/}
            {cta && cta.length && (
              <div className="grid grid-cols-2 gap-x-6 gap-y-12 md:grid-cols-12">
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.XL}
                  className={'col-span-2 md:col-span-12 lg:col-span-10'}
                ></Button>
              </div>
            )}
          </div>

          {/*-- image --*/}
          <div className="col-span-2 md:col-span-8 md:col-start-3 md:col-end-11 lg:col-span-6 lg:col-start-4 lg:col-end-10 xl:col-span-6 xl:col-start-7 xl:col-end-13 xl:flex xl:items-center xl:justify-center 3xl:col-span-5 3xl:col-start-8">
            {image && image.filename && (
              <div className="w-full aspect-[100/128]">
                <Image image={image} />
              </div>
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  );
};

export default WholeOfChild;
