import { IFilteredAd } from "../../../services/jobAdder.service";
import { FilterType, JobSearchFilterGroup, JobSearchFilterItem } from "./JobSearchFilterAndResults.blok";

export const filterToFunctionMap = {
  'Hours to Work': isWithinRange,
  'Contract': doesStringMatch,
}

export function isWithinRangeMaxMin(values: [string | number, string | number], matchers: (string | number)[]): boolean {
  return matchers.some((matcher) => {
    const [minMatcer, maxMatcher] = String(matcher).split('-');

    return values.some((value) => Number(value) >= Number(minMatcer) && Number(value) < Number(maxMatcher))
  });
}

export function isWithinRange(value: string | number, matchers: (string | number)[]): boolean {
  return matchers.some((matcher) => {
    const [min, max] = String(matcher).split('-');

    return Number(value) >= Number(min) && Number(value) < Number(max);
  });
}

export function doesStringMatch(value: string, matchers: (string | number)[]): boolean { 
  return matchers.some((matcher) => { 
    return String(value).toLowerCase() === String(matcher).toLowerCase();
  });
}

export function sliceIntoChunks(jobsFiltered: IFilteredAd[], jobsPerPage: number): IFilteredAd[][] {
  const jobsInChunks = [];
  for (let i = 0; i < jobsFiltered.length; i += jobsPerPage) {
      const chunk = jobsFiltered.slice(i, i + jobsPerPage);
      jobsInChunks.push(chunk);
  }
  return jobsInChunks;
}

export function populateCountiesFilter(jobs: IFilteredAd[], filter_groups: JobSearchFilterGroup[])  {
  const countyFilterItems = jobs.reduce((tally: JobSearchFilterItem[], job: IFilteredAd) => {
    const { countyBorough } = job;

    return tally.find((filterItem) => filterItem.name === countyBorough) ? tally : [...tally, { name: countyBorough, value: countyBorough.toLowerCase(), content: { value: countyBorough.toLowerCase() }, group: 'County' }];
  }, [])

  const indexOfCountyObject = filter_groups.findIndex((filterGroup: JobSearchFilterGroup) => filterGroup.name === 'County');

  if (indexOfCountyObject !== -1) {
    filter_groups.splice(indexOfCountyObject, 1);
    const newFilterGroups = ([...filter_groups, { name: 'County', content: { filter_items: countyFilterItems, filter_type: FilterType.string_match } }]);
    return newFilterGroups;
  }

  return filter_groups;  
}

