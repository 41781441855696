/*-- npm packages --*/
import React, { useContext } from "react"

/*-- component atoms --*/
import Button from "../../atoms/AnimatedButton/AnimatedButton.component"

/*-- context --*/
import VenuePageContext from "../../../contexts/VenuePageContext"

/*-- interfaces --*/
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface VenueNoDataFoundProps extends BlokWithBorderIcons {
  page_name: string
  title: string
  description: string
  cta_label: string
  cta_link: StoryblokLink
}

const VenueNoDataFound: React.FC<VenueNoDataFoundProps> = ({
  page_name,
  title,
  description,
  cta_label,
  cta_link,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const getTransformedText = (textToTransform: string): string => {
    const VENUE_NAME_INJECTOR: string = "<venue-name>"
    return textToTransform?.replaceAll(VENUE_NAME_INJECTOR, page_name)
  }

  return (
    <section className="bg-White text-Primary-900">
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto grid grid-cols-12 gap-x-6 py-20 text-center font-montserrat md:py-32 lg:py-40">
          <div className="col-span-12 flex flex-col items-center justify-center gap-y-10 md:col-span-10 md:col-start-1 lg:col-span-8 lg:col-start-3">
            <div className="space-y-6">
              {title && (
                <h1 className="font-semibold text-2xl">
                  {getTransformedText(title)}
                </h1>
              )}
              {description && <p>{getTransformedText(description)}</p>}
            </div>
            <Button
              link={cta_link}
              label={cta_label}
              type={ButtonTypes.PRIMARY}
            />
          </div>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default VenueNoDataFound
