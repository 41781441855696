export interface AddressProps {
  Address_Line_One?: string
  Address_Line_Two?: string
  Town_or_City?: string
  Postcode?: string
}

export enum PartnerIconTypes {
  SCH = "SCH",
}

export enum ClubTypes {
  Holiday_Clubs = "Holiday clubs",
  Breakfast_Clubs = "Breakfast clubs",
  After_School_Clubs = "After school clubs",
  Extra_Curricular_Clubs = "Extra curricular clubs",
}
