import React, { useContext } from "react";
import JobPageContext from "../../../../../contexts/JobsPage.context";
import { JobSearchFilterItem } from "../../JobSearchFilterAndResults.blok";

interface JobSearchAppliedFiltersProps {  
  filterButtonClicked: () => void,
}

const JobSearchAppliedFilters: React.FC<
  JobSearchAppliedFiltersProps
> = () => {
  const { selectedFilters } = useContext(JobPageContext);

  return (   
    <div className="flex items-center justify-start h-full">
      {Boolean(selectedFilters.length) && (
        <>
          <ul className="flex items-start justify-start content-start gap-4 flex-wrap">
            {selectedFilters.map((filter: JobSearchFilterItem) => {
              return (<li className="rounded-default bg-Secondary-100 px-2 py-1 text-sm text-Primary-900 font-semibold whitespace-nowrap">
                {filter.name}
              </li>)
            })} 
          </ul>          
        </>
      )}
    </div>
  )
}

export default JobSearchAppliedFilters
