import isEmptyString from "../isEmptyString/isEmptyString.util"
import isEmptyArray from "../isEmptyArray/isEmptyArray.util"

export const valueExists = (value: any): boolean => {
  return value != undefined && !isEmptyString(value) && !isEmptyArray(value)
}

export const valuesExist = (values: any[]): boolean => {
  return values.filter((value: any) => valueExists(value)).length > 0
}
