/*-- npm packages --*/
import React from 'react';

/*-- component bloks --*/

/*-- component molecules --*/

/*-- component atoms --*/
import Button from '../../atoms/Button/Button.component';
import Image from '../../atoms/Image/Image.atom';

/*-- assets --*/
import Download from '../../../assets/svgs/Download.svg';
import Location from '../../../assets/svgs/Location.svg';

/*-- interfaces --*/
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';

/*-- contexts --*/
import { Service, Venue } from '../../../contexts/VenuesContext';
import { Club } from '../../../contexts/FiltersContext';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

interface VenueInfoProps extends BlokWithBorderIcons {
  venue: Venue;
  venue_logo: StoryblokAsset;
  iframe_link: string;
  cta_label: string;
  clubs: Club[];
  services_heading: string;
}

const VenueInfo: React.FC<VenueInfoProps> = ({
  venue,
  venue_logo,
  iframe_link,
  cta_label,
  clubs,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
  services_heading,
}) => {
  const getVenueAvailabilityCheckerIframeLink = (): string | undefined => {
    if (venue && venue.links && venue.links.availabiltyLink) {
      return venue.links.availabiltyLink;
    } else if (iframe_link) {
      return iframe_link;
    } else {
      return undefined;
    }
  };

  const getServicesWithoutClubTypeDuplications = (): Service[] => {
    if (venue?.services) {
      return venue?.services.reduce((accumulator: Service[], current: Service) => {
        if (!accumulator.find((service: Service) => service.clubType === current.clubType)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    } else {
      return [];
    }
  };

  const getCorrespondingIconForService = (service: Service): string | undefined => {
    return clubs.filter((club: Club) => club.name === service.clubType)[0].icon;
  };

  const IndoorFacilities: string = venue?.facilities
    ?.filter(facility => facility?.location?.toLowerCase() === 'indoor area')
    .map(facility => facility.name)
    .join(', ');
  const OutdoorFacilities: string = venue?.facilities
    ?.filter(facility => facility?.location?.toLowerCase() === 'outdoor area')
    .map(facility => facility.name)
    .join(', ');
  const AllApplicableFacilities = IndoorFacilities + OutdoorFacilities;

  return (
    <>
      {venue && (
        <section id="venue-info">
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className="container mx-auto pb-16 pt-12 lg:pt-16 md:grid md:grid-cols-12 md:gap-x-6 relative z-10">
              {/*-- left column --*/}
              <div className="md:col-span-5 pb-6 md:pb-0 text-center md:text-left">
                {venue.name && (
                  <h2 className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-extrabold text-Primary-900 pb-3">
                    {venue.name}
                  </h2>
                )}
                {venue.age?.min && (
                  <div className="!bg-sherpa-blue-50 bg-Secondary-50 inline-block rounded-[4px] mb-4">
                    <p className="text-Primary-900 p-1.5">
                      {venue.age.min}
                      {venue.age.max && '-'}
                      {venue.age.max && venue.age.max} yrs
                    </p>
                  </div>
                )}
                {venue.inspectionStandards && venue.inspectionStandards.referenceNumber && (
                  <p className="flex items-center justify-start text-base font-semibold text-Body pb-6 md:pb-8">
                    {venue.inspectionStandards.type && (
                      <span className="pr-1">{venue.inspectionStandards.type} No:</span>
                    )}
                    {venue.inspectionStandards.referenceNumber}
                  </p>
                )}
                {venue_logo && venue_logo.filename && (
                  <div className="max-w-[123px] max-h-[123px] mb-10 md:mb-12 m-auto md:m-0">
                    <Image image={venue_logo} objectFit={'cover'} />
                  </div>
                )}
                {cta_label && getVenueAvailabilityCheckerIframeLink() && (
                  <Button
                    link={getVenueAvailabilityCheckerIframeLink()}
                    label={cta_label}
                    type={ButtonTypes.PRIMARY}
                    size={ButtonSizeTypes.LG}
                    className="w-full md:w-auto"
                  ></Button>
                )}
              </div>
              {/*-- right column --*/}
              <div className="md:col-span-7 md:col-start-6 xl:col-span-6 xl:col-start-7 divide-y divide-dashed divide-Primary-900">
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-3 divide-y divide-dashed divide-Primary-900 lg:divide-y-0">
                  <div className="col-span-6 pb-6 lg:pb-0">
                    <p className="text-Primary-900 text-xl font-semibold pb-4">{services_heading || 'Services'}</p>
                    {getServicesWithoutClubTypeDuplications() && (
                      <ul className="space-y-3">
                        {getServicesWithoutClubTypeDuplications().map((service: Service, index: number) => (
                          <li
                            key={`${service.reference}-${index}`}
                            className="flex items-center justify-start space-x-3"
                          >
                            {getCorrespondingIconForService(service) && (
                              <div className="w-10 h-10">
                                <Image
                                  image={{
                                    filename: getCorrespondingIconForService(service)!,
                                  }}
                                  objectFit="contain"
                                  objectPosition="center"
                                />
                              </div>
                            )}
                            <p className="font-montserrat font-normal text-Body">{service.clubType}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="col-span-6 pt-6 lg:pt-0">
                    <div>
                      {venue.coords && venue.coords.lat && venue.coords.lng && (
                        <div>
                          <p className="text-Primary-900 text-xl font-semibold pb-4">Get Directions</p>
                          <div className="flex items-center space-x-2">
                            <Location className="text-Secondary-300 -scale-x-100 h-[18px] w-[18px]" />
                            <a
                              className="text-Primary-900 underline"
                              href={`https://maps.google.com/?q=${venue.coords.lat},${venue.coords.lng}`}
                              target="_blank"
                            >
                              Find us via Google Maps
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="pt-6">
                      {venue.inspectionStandards?.reportLink && (
                        <div>
                          <p className="text-Primary-900 text-xl font-semibold pb-4 flex items-center justify-start">
                            {venue.inspectionStandards.type && (
                              <span className="pr-1">{venue.inspectionStandards.type}</span>
                            )}
                            Report
                          </p>
                          <div className="flex items-center space-x-2">
                            <Download className="text-Secondary-300 -scale-x-100 h-[18px] w-[18px]" />
                            <a
                              className="text-Primary-900 underline"
                              href={venue.inspectionStandards.reportLink}
                              target="_blank"
                              download
                            >
                              <p>{`Download ${venue.inspectionStandards.type} Report`}</p>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-8 pt-8">
                  {venue.pickupAndDropoffInstructions && (
                    <div className="pb-8">
                      <p className="text-Primary-900 text-xl font-semibold pb-4">Drop-off and Collection</p>
                      <p className="text-Body text-lg">{venue.pickupAndDropoffInstructions}</p>
                    </div>
                  )}
                  {/*-- USPs goes here --*/}
                  {!!AllApplicableFacilities && (
                    <div>
                      <p className="text-Primary-900 text-xl font-semibold pb-4">Facilities at this venue</p>
                      {IndoorFacilities && <p className="text-Body text-lg">Indoor facilities: {IndoorFacilities}</p>}
                      {OutdoorFacilities && (
                        <p className="text-Body text-lg">Outdoor facilities: {OutdoorFacilities}</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </BorderIconsFactory>
        </section>
      )}
    </>
  );
};

export default VenueInfo;
