import React, { useContext, useState } from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import BackgroundIcons from "../BackgroundIcons/BackgroundIcons.molecule"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import Header from "../../bloks/Header/Header.blok"
import VenueTermsHeroSearchBar from "../VenueTermsHero/VenuTermsHeroSearchBar.component"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import VenuesContext, { Venue } from "../../../contexts/VenuesContext"

interface TermBlock {
  heading: string
  body: string
  additional_text_block?: TermBlock[]
}

export interface VenueTermsProps extends Blok {
  header: string
  button_label: string
  input_placeholder: string
  background_icon: StoryblokAsset
  term_blocks: TermBlock[]
}

const VenueTerms: React.FC<VenueTermsProps> = ({
  term_blocks,
  header,
  background_icon,
  ...props
}) => {
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null)
  const ReplaceKeyWords = (text: string) => {
    const tba: string = `(tba)`
    const breakfastClubService = selectedVenue?.services?.find(
      service => service.clubType === "Breakfast club"
    )
    const afterschoolClubService = selectedVenue?.services?.find(
      service => service.clubType === "Afterschool club"
    )
    const holidayClubService = selectedVenue?.services?.find(
      service => service.clubType === "Holiday club"
    )

    if (selectedVenue) {
      return text
        .replace(
          "<permanent-booking-cancellation>",
          selectedVenue.permanentBookingCancellation
            ? selectedVenue.permanentBookingCancellation.toString()
            : tba
        )
        .replace(
          "<pickup-late-fee>",
          selectedVenue.pickupLateFee
            ? selectedVenue.pickupLateFee.toString()
            : tba
        )
        .replace(
          "<no-show-fee>",
          selectedVenue.noShowFee ? selectedVenue.noShowFee.toString() : tba
        )
        .replace(
          "<daily-disc>",
          selectedVenue.dailyDiscount ? selectedVenue.dailyDiscount : tba
        )
        .replace(
          "<weekly-disc>",
          selectedVenue.weeklyDiscount ? selectedVenue.weeklyDiscount : tba
        )
        .replace(
          "<sibling-disc>",
          selectedVenue.siblingDiscount ? selectedVenue.siblingDiscount : tba
        )
        .replace("<tt-early-finish>", tba)
        .replace("<hc-early-finish>", holidayClubService?.earlyFinish || tba)
        .replace(
          "<tt-late-fee>",
          breakfastClubService?.lateFee
            ? breakfastClubService.lateFee.toString()
            : afterschoolClubService?.lateFee
            ? afterschoolClubService.lateFee.toString()
            : tba
        )
        .replace(
          "<hc-late-fee>",
          holidayClubService?.lateFee
            ? holidayClubService.lateFee.toString()
            : tba
        )
        .replace(
          "<tt-booking-cut-off>",
          breakfastClubService?.bookingCutOff ||
            afterschoolClubService?.bookingCutOff ||
            tba
        )
        .replace(
          "<hc-booking-cut-off>",
          holidayClubService?.bookingCutOff || tba
        )
        .replace(
          "<tt-cancellation-period>",
          breakfastClubService?.cancellationPeriod ||
            afterschoolClubService?.cancellationPeriod ||
            tba
        )
        .replace(
          "<hc-cancellation-period>",
          holidayClubService?.cancellationPeriod || tba
        )
        .replace(
          "<tt-registration-fee>",
          breakfastClubService?.registrationFee ||
            afterschoolClubService?.registrationFee ||
            tba
        )
        .replace(
          "<hc-registration-fee>",
          holidayClubService?.registrationFee || tba
        )
    }
  }

  return (
    <section>
      <div className="bg-gradient-to-r from-Primary-900 to-SP6">
        <div className="container mx-auto py-16 relative">
          <BackgroundIcons
            type={BackgroundPositionTypes.VerticallyCentered}
            icon={background_icon}
            rotateRight
            mirrorXLeft
          >
            <h1 className="text-White font-extrabold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl text-center py-10">
              {header}
            </h1>
            <div className="sm:grid grid-cols-12 pb-12">
              <VenueTermsHeroSearchBar
                setSelectedVenue={setSelectedVenue}
                selectedVenue={selectedVenue}
                {...props}
              />
            </div>
          </BackgroundIcons>
        </div>
      </div>
      {selectedVenue && (
        <div className="bg-White">
          <div className="container mx-auto">
            <div className="grid grid-cols-12 w-full gap-8 py-20">
              {!!term_blocks.length &&
                term_blocks.map(block => (
                  <div
                    key={`term_block_${block.heading}`}
                    className="space-y-3 col-span-12 lg:col-span-6"
                  >
                    <h3 className="text-Primary-900 font-bold text-3xl">
                      {block.heading}
                    </h3>
                    <p>{ReplaceKeyWords(block.body)}</p>
                    {!!block?.additional_text_block?.length &&
                      block?.additional_text_block.map(additional_block => (
                        <div>
                          <h3 className="text-Primary-900 font-bold text-xl pt-2">
                            {additional_block.heading}
                          </h3>
                          <p>{ReplaceKeyWords(additional_block.body)}</p>
                        </div>
                      ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default VenueTerms
