import React from "react"
import AnimatedButton from "../../atoms/AnimatedButton/AnimatedButton.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import DotsSpinner from "../../../assets/spinners/dots.spinner"
import classNames from "classnames"
import Tick from "../../../assets/svgs/Tick.svg"
import Close from "../../../assets/svgs/Close.svg"

interface NewsletterBtnProps {
  status: string | null
  message: string | Error | null | any
  submit: (e: React.MouseEvent<HTMLElement>) => void
  cta_label: string
  formError: string
}

export const NewsletterBtn: React.FC<NewsletterBtnProps> = ({
  status,
  cta_label,
  submit,
}) => {
  const transformBtn = classNames(
    `max-h-[48px] min-h-[48px] min-w-[48px] !w-[48px] !p-0`
  )
  const successBtn = classNames(`bg-SP3 pointer-events-none`)

  return (
    <div>
      <div className="flex justify-center items-center h-[48px]">
        {cta_label && (
          <div className="relative">
            <AnimatedButton
              label={cta_label}
              type={ButtonTypes.SECONDARY}
              onClick={submit}
              styles={`w-[186px] h-[48px] transition-width duration-500 flex justify-center items-center
              ${status === "sending" && `${transformBtn}`}
                ${status === "success" && `${transformBtn} ${successBtn}`}
              `}
              loadingAnimation={
                <div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-[40px] h-[40px]">
                  <div className="relative">
                    <div
                      className={`scale-50 absolute top-[20px] bottom-0 right-0 left-[-20px] m-auto w-[80px] h-[80px] transition-opacity duration-300 opacity-100 ${
                        status === "success" && "!opacity-0"
                      } 
                `}
                    >
                      <DotsSpinner></DotsSpinner>
                    </div>
                    <div
                      className={`absolute top-[4px] bottom-0 right-0 left-[3px] m-auto transition-opacity duration-300 delay-300 opacity-0 ${
                        status === "success" && "opacity-100"
                      }
                `}
                    >
                      <Tick className="text-White w-8 h-8"></Tick>
                    </div>
                    <div
                      className={`absolute top-[6px] bottom-0 right-0 left-[6px] m-auto transition-opacity duration-300 delay-300 opacity-0
                `}
                    >
                      <Close className="text-White w-7 h-7"></Close>
                    </div>
                  </div>
                </div>
              }
              isAnimating={status === "sending" || status === "success"}
              onClickStatus={status}
            ></AnimatedButton>
          </div>
        )}
      </div>
    </div>
  )
}
