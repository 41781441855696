import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { valueExists } from "../../../utils/valueExists/valueExists.util"

interface CustomLinkProps {
  children: React.ReactNode
  url: string | undefined
  linkType?: string
  className?: string
  activeClass?: string
  isDisabled?: boolean
  onMouseEnter?: Function
  onMouseLeave?: Function
  anchor?: any
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  id?: string
}

const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  url,
  linkType = undefined,
  className,
  activeClass,
  isDisabled = false,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
  anchor,
  id,
}) => {
  let path = url || ""
  const hasAnchor = anchor

  const isEmail = (): boolean => {
    return (
      (valueExists(linkType) && linkType === "email") ||
      path?.startsWith("mailto:")
    )
  }

  const isOutboundLink =
    /^https?:\/\//.test(path) ||
    path?.startsWith("mailto:") ||
    path?.startsWith("tel:")

  const getPath = (): string => {
    if (isEmail()) {
      return path.startsWith("mailto:") ? path : `mailto:${path}`
    } else if (hasAnchor) {
      return `${path}#${anchor}`
    } else {
      return path
    }
  }

  const getRequiredTarget = (): string => {
    return isOutboundLink ? "_blank" : ""
  }

  if (isOutboundLink || isEmail()) {
    return (
      <a
        href={getPath()}
        target={getRequiredTarget()}
        rel="noreferrer noopener"
        aria-label={getPath()}
        className={`custom-link ${className} ${
          isDisabled && "pointer-events-none"
        }`}
        onMouseEnter={() => onMouseEnter}
        onMouseLeave={() => onMouseLeave}
      >
        {children}
      </a>
    )
  }

  if (!path?.startsWith("/")) path = `/${path}`

  if (path === "/home") path = "/"

  /*-- domain folder related stuff --*/
  const GATSBY_DOMAIN_FOLDER = process.env.GATSBY_DOMAIN_FOLDER
  if (GATSBY_DOMAIN_FOLDER && path.includes(GATSBY_DOMAIN_FOLDER))
    path = path.replace(`${GATSBY_DOMAIN_FOLDER}`, "")

  if (path.charAt(path.length - 1) != "/" && !path.includes("?"))
    path = path + "/"
  path = path.toLowerCase()

  if (hasAnchor) {
    path = path.substring(0, path.length - 1)
    path = `${path}#${anchor}`
  }

  return (
    <Link
      to={path}
      aria-label={path}
      className={`custom-link ${
        isDisabled && "pointer-events-none"
      } ${className}`}
      activeClassName={activeClass}
      onMouseEnter={() => onMouseEnter}
      onMouseLeave={() => onMouseLeave}
      onClick={onClick}
      id={id}
    >
      {children}
    </Link>
  )
}

CustomLink.defaultProps = {
  url: "",
}

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
}

export default CustomLink
