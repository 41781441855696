import React from "react"
import SimpleCTACard, {
  SimpleCTACardProps,
} from "../../atoms/SimpleCTACard/SimpleCTACard.atom"
import isUndefined from "../../../utils/isUndefined/isUndefined.util"
import isEmptyString from "../../../utils/isEmptyString/isEmptyString.util"
import { isEditMode } from "../../../utils/isEditMode.util"
import classNames from "classnames"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

export interface SimpleCTACardsRowProps extends BlokWithBorderIcons {
  heading?: string
  cards?: SimpleCTACardProps[]
}

const SimpleCTACardsRow: React.FC<SimpleCTACardsRowProps> = ({
  heading = undefined,
  cards = [],
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const isHeadingPopulated = (): boolean => {
    return !isUndefined(heading) && !isEmptyString(heading)
  }

  const getSimpleCTACardsLength = (): number => {
    if (isEditMode()) {
      return cards.length < 1 ? 4 : cards.length
    } else {
      return cards.length
    }
  }

  const getSimpleCTACard = (index: number): SimpleCTACardProps => {
    const EMPTY_SIMPLE_CTA_CARD: SimpleCTACardProps = {
      _uid: `empty_simple_cta_card_${index}`,
      component: "simple_cta_card",
      _editable: "",
      link: undefined,
      image: undefined,
      heading: undefined,
    }
    return cards[index] ?? EMPTY_SIMPLE_CTA_CARD
  }

  const headingClassNames = classNames(
    "font-montserrat font-extrabold text-Body text-2xl",
    {
      "bg-Transparent": !isEditMode() || isHeadingPopulated(),
      "block bg-SP7 rounded-2xl w-4/5 max-w-[256px] h-7":
        isEditMode() && !isHeadingPopulated(),
    }
  )

  return (
    <BorderIconsFactory
      type={BorderIconType.Blok}
      border_icon_top_left={border_icon_top_left}
      border_icon_top_right={border_icon_top_right}
      border_icon_bottom_left={border_icon_bottom_left}
      border_icon_bottom_right={border_icon_bottom_right}
    >
      <div className="space-y-12">
        <h3 className={headingClassNames}>
          {isHeadingPopulated() ? heading : ""}
        </h3>
        <ul className="grid grid-cols-2 gap-y-12 md:grid-cols-12 md:gap-x-6">
          {Array(getSimpleCTACardsLength())
            .fill(1)
            .map((_, index) => (
              <SimpleCTACard
                key={getSimpleCTACard(index)._uid}
                {...getSimpleCTACard(index)}
              />
            ))}
        </ul>
      </div>
    </BorderIconsFactory>
  )
}

export default SimpleCTACardsRow
