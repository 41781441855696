import React, { useState, useEffect } from 'react';
import { FAQsSearchResultsListProps, FAQsSearchResultsTitleAndDescriptionConfig } from '../FAQsSearchProps.interface';
import { useInstantSearch, Hits, Highlight } from 'react-instantsearch-hooks-web';
import { valueExists } from '../../../../utils/valueExists/valueExists.util';
import isEmptyString from '../../../../utils/isEmptyString/isEmptyString.util';
import BackgroundIcons from '../../../molecules/BackgroundIcons/BackgroundIcons.molecule';
import { BackgroundPositionTypes } from '../../../../interfaces/enums/BackgroundPositionTypes.enum';
import { BreakpointTypes } from '../../../../interfaces/enums/BreakpointTypes.enum';
import classNames from 'classnames';
import { Collapse } from 'react-collapse';
import RichText from '../../../atoms/RichText/RichText.atom';
import {
  render,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKE,
  MARK_UNDERLINE,
  MARK_CODE,
  MARK_STYLED,
  MARK_LINK,
  NODE_HEADING,
  NODE_CODEBLOCK,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_OL,
  NODE_UL,
  NODE_LI,
  NODE_HR,
  NODE_BR,
} from 'storyblok-rich-text-react-renderer';

const FAQsSearchResultsList: React.FC<FAQsSearchResultsListProps> = ({
  background_icon,
  title,
  no_results_found_config,
}) => {
  const {
    results: { nbHits },
  } = useInstantSearch();

  const isResultsFound = (): boolean => {
    return nbHits > 0;
  };

  return (
    <div className="relative overflow-x-hidden overflow-y-auto">
      <BackgroundIcons
        type={BackgroundPositionTypes.SiteSearch}
        icon={background_icon}
        iconLeftStyle={'-rotate-45 w-[115px] xl:w-[166px]'}
        iconRightStyle={'rotate-45 w-[115px] xl:w-[166px]'}
        visibleOn={BreakpointTypes.LG}
      >
        <div className="container mx-auto grid grid-cols-2 gap-x-6 md:grid-cols-12">
          <>
            {!isResultsFound() && <NoResultsFound {...no_results_found_config} />}
            {isResultsFound() && <ResultsList title={title} />}
          </>
        </div>
      </BackgroundIcons>
    </div>
  );
};

const NoResultsFound: React.FC<FAQsSearchResultsTitleAndDescriptionConfig> = ({ title, description }) => {
  const {
    results: { query },
  } = useInstantSearch();

  const isNoResultsFoundInfoBoxRequired = (): boolean => {
    return isTitleRequired() || isDescriptionRequired();
  };

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title);
  };

  const isDescriptionRequired = (): boolean => {
    return valueExists(description) && !isEmptyString(description);
  };

  const getFormattedDescriptionText = (): JSX.Element => {
    const SEARCH_TERM_PLACEHOLDER: string = '<search-term>';
    const descriptionTextSplitIntoIndividualWords: string[] = description.split(' ');
    return (
      <p className="text-lg">
        {descriptionTextSplitIntoIndividualWords.map((word: string) =>
          word.includes(SEARCH_TERM_PLACEHOLDER) ? (
            <span className="text-SP1 font-semibold">{query} </span>
          ) : (
            `${word} `
          ),
        )}
      </p>
    );
  };

  return (
    <div className="col-span-2 flex items-center justify-center min-h-[500px] md:col-span-8 md:col-start-3">
      {isNoResultsFoundInfoBoxRequired() && (
        <div className="font-montserrat text-center space-y-3">
          {isTitleRequired() && <h3 className="text-2xl font-extrabold">{title}</h3>}
          {isDescriptionRequired() && getFormattedDescriptionText()}
        </div>
      )}
    </div>
  );
};

interface ResultsListProps {
  title: string;
}

const ResultsList: React.FC<ResultsListProps> = ({ title }) => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = '<number-of-results-found>';

  const {
    results: { nbHits },
  } = useInstantSearch();

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title);
  };

  const getNumberOfResultsFound = (): string => {
    return `${nbHits}` ?? '0';
  };

  const getFormattedTitle = (): JSX.Element => {
    return (
      <h2 className="font-extrabold text-xl text-Primary-900 pb-4 md:text-2xl">
        {title.replaceAll(NUMBER_OF_RESULTS_PLACEHOLDER, getNumberOfResultsFound())}
      </h2>
    );
  };

  const ResultListItem = ({ hit }): JSX.Element => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const {
      results: { query },
    } = useInstantSearch();

    useEffect(() => {
      setIsExpanded(faqHasMatchWithinAnswer());
    }, []);

    const faqHasMatchWithinAnswer = (): boolean => {
      const textNodes = render(hit.answer, {
        markResolvers: {
          [MARK_BOLD]: (children: any) => children,
          [MARK_ITALIC]: (children: any) => children,
          [MARK_STRIKE]: (children: any) => children,
          [MARK_UNDERLINE]: (children: any) => children,
          [MARK_CODE]: (children: any) => children,
          [MARK_STYLED]: (children: any) => children,
          [MARK_LINK]: (children: any) => children,
        },
        nodeResolvers: {
          [NODE_HEADING]: (children: any) => children,
          [NODE_CODEBLOCK]: (children: any) => children,
          [NODE_PARAGRAPH]: (children: any) => children,
          [NODE_QUOTE]: (children: any) => children,
          [NODE_OL]: (children: any) => children,
          [NODE_UL]: (children: any) => children,
          [NODE_LI]: (children: any) => children,
          [NODE_IMAGE]: (children: any) => null,
          [NODE_HR]: () => null,
          [NODE_BR]: () => null,
        },
      })
        .flat(2)
        .filter((str: any) => Boolean(str))
        .join(' ');
      return query ? textNodes.includes(query) : false;
    };

    const faqClassNames = classNames(
      'w-full h-fit rounded-7xl font-monserrat transition-all duration-1000 delay-0 space-y-4',
      {
        'bg-SP7 bg-sherpa-blue-50': isExpanded,
        'bg-none': !isExpanded,
      },
    );

    const faqQuestionClassNames = classNames(
      'cursor-pointer flex items-center justify-between border-b transition-all text-lg font-semibold w-full px-6 space-x-4 md:text-xl',
      {
        'border-b-Transparent text-Primary-900 pt-8': isExpanded,
        'border-b-Body-Disabled text-Body py-4': !isExpanded,
      },
    );

    const faqAnswerClassNames = classNames('block px-6 pb-6', {
      'duration-1000 opacity-100': isExpanded,
      'duration-1000 opacity-0': !isExpanded,
    });

    interface OpenCloseIconProps {
      isFAQSelected: boolean;
    }

    const OpenCloseIcon: React.FC<OpenCloseIconProps> = ({ isFAQSelected }) => {
      const openCloseIconClassNames: string = classNames(
        'w-[26px] h-[26px] border-2 rounded-full flex flex-col items-center justify-center',
        {
          'border-Body': !isFAQSelected,
          'border-Primary-900': isFAQSelected,
        },
      );

      const getLineClassNames = (isRotatableLine: boolean = false): string => {
        return classNames('w-3 h-[2px] relative rounded-full transition-all duration-600', {
          'bg-Body': !isFAQSelected,
          'bg-Primary-900': isFAQSelected,
          'rotate-90': isRotatableLine && !isFAQSelected,
          'top-[1.5px]': !isRotatableLine,
          '-top-[0.5px]': isRotatableLine,
        });
      };

      return (
        <div className={openCloseIconClassNames}>
          <div className={getLineClassNames()}></div>
          <div className={getLineClassNames(true)}></div>
        </div>
      );
    };

    return (
      <div className={faqClassNames}>
        <button className={faqQuestionClassNames} onClick={() => setIsExpanded(!isExpanded)}>
          <p className="flex-1 text-left">
            {hit.question}
            {/* <Highlight attribute="question" hit={hit} /> */}
          </p>
          <OpenCloseIcon isFAQSelected={isExpanded} />
        </button>
        <Collapse isOpened={isExpanded}>
          {/* <p className={faqAnswerClassNames}>
            <Highlight attribute="answer" hit={hit} />
          </p> */}
          <div className={faqAnswerClassNames}>
            <RichText content={hit.answer} />
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <div className="col-span-2 md:col-span-8 md:col-start-3">
      {isTitleRequired() && getFormattedTitle()}
      <Hits
        hitComponent={ResultListItem}
        classNames={{
          root: 'overflow-auto',
          list: 'space-y-6 overflow-auto',
        }}
      />
    </div>
  );
};

export default FAQsSearchResultsList;
