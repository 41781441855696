import classNames from "classnames";
import React, { useState } from "react";
import Minus from "../../../assets/svgs/Minus.svg";
import Plus from "../../../assets/svgs/Plus.svg";

interface DropdownItemProps {
  children: React.ReactNode;
  heading: string;
}

const DropdownItem: React.FC<DropdownItemProps> = ({children, heading}: DropdownItemProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const cardBodyClasses = classNames('w-full py-6 px-4 border-b');
  const cardHeaderClasses = classNames('flex justify-between items-center cursor-pointer w-full bg-White py-6 border-b');

  return (
    <>    
      <div className={cardHeaderClasses} onClick={() => setIsOpened((prevState: boolean) => !prevState)}>
        <p className='font-semibold'>{heading}</p>         
        <i className="pr-4">
          {isOpened ? <Minus /> : <Plus />}
        </i>    
      </div>

      {(isOpened) && (
        <div className={cardBodyClasses}>
          {children}
        </div>
      )}
    </>    
  );
}

export default DropdownItem;