import React from "react"

interface PhoneProps {
  className?: string
}

const Phone: React.FC<PhoneProps> = ({ className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      aria-labelledby="phone"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M1.78 12.4467L4.32 12.1567C4.93 12.0867 5.53 12.2967 5.96 12.7267L7.8 14.5667C10.63 13.1267 12.95 10.8167 14.39 7.97666L12.54 6.12666C12.11 5.69666 11.9 5.09666 11.97 4.48666L12.26 1.96666C12.38 0.956655 13.23 0.196655 14.25 0.196655H15.98C17.11 0.196655 18.05 1.13666 17.98 2.26666C17.45 10.8067 10.62 17.6267 2.09 18.1567C0.959995 18.2267 0.019995 17.2867 0.019995 16.1567V14.4267C0.00999501 13.4167 0.769995 12.5667 1.78 12.4467Z" />
    </svg>
  )
}

export default Phone
