/*-- npm packages --*/
import React from 'react';
import classNames from 'classnames';

/*-- component atoms --*/
import Button from '../../atoms/Button/Button.component';
import Image from '../../atoms/Image/Image.atom';

/*-- interfaces --*/
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import { ButtonProps } from '../../../interfaces/Button.interface';
import { CardProps } from '../../../interfaces/cards/ComparisonCard.interface';

/*-- enums --*/
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import TearHorizontalPosition from '../../../interfaces/enums/TearPositioning/TearHorizontalPosition.enum';
import TearVerticalPosition from '../../../interfaces/enums/TearPositioning/TearVerticalPosition.enum';

/*-- svgs --*/
import LightThemeCardTear from '../../../assets/svgs/CornerTears/CardTears/LightThemeCardTear.svg';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

const ComparisonCard: React.FC<CardProps> = ({
  image,
  heading,
  body_text,
  cta,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const ContainerClassNames = classNames('z-1 md:flex md:items-stretch lg:block');

  const ImageWrapperClassNames = classNames(
    'md:mr-6 lg:mr-0 md:min-w-[260px] lg:w-auto aspect-[16/9] md:aspect-[3/4] lg:aspect-[16/9] -mt-12 md:-mt-0 lg:-mt-16 pb-6 md:pb-0 lg:pb-8 drop-shadow-2xl md:drop-shadow-none lg:drop-shadow-2xl',
  );

  const ImageClassNames = classNames('h-full overflow-hidden rounded-7xl');

  return (
    <div className="!bg-sherpa-red-main z-[0] bg-SP6 text-White rounded-7xl h-full">
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="px-4 pt-4 pb-14 md:p-6 lg:p-8 2xl:p-10">
          <div className={ContainerClassNames}>
            <div className={ImageWrapperClassNames}>
              <div className={ImageClassNames}>{image && <Image image={image} objectFit="cover" />}</div>
            </div>
            <div>
              {heading && (
                <p className="font-secondary text-2xl lg:text-3xl font-extrabold pb-3 md:pb-4 lg:pb-3 3xl:pb-4">
                  {heading}
                </p>
              )}
              <p className="text-lg pb-8">{body_text}</p>
              {cta && !!cta.length && cta[0].label && cta[0].link?.cached_url && (
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.LG}
                  className="w-full md:w-auto"
                ></Button>
              )}
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </div>
  );
};

export default ComparisonCard;
