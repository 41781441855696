import React from 'react';

interface ChevronRightProps {
  className?: string;
  rotateOnHover?: boolean;
  rotate?: boolean;
}
const ChevronRight: React.FC<ChevronRightProps> = ({ className, rotateOnHover, rotate }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`transfrom fill-current ${className} ${rotateOnHover && 'group-hover:rotate-180'} ${
        rotate && 'rotate-180'
      }`}
    >
      <g clip-path="url(#clip0_4541_2821)">
        <path d="M8.33333 5L7.15833 6.175L10.975 10L7.15833 13.825L8.33333 15L13.3333 10L8.33333 5Z" fill="#C9C9C9" />
      </g>
      <defs>
        <clipPath id="clip0_4541_2821">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronRight;
