import classNames from "classnames"
import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import { isEditMode } from "../../../utils/isEditMode.util"
import CustomLink from "../CustomLink/CustomLink.atom"
import Image from "../Image/Image.atom"
import isUndefined from "../../../utils/isUndefined/isUndefined.util"
import isEmptyString from "../../../utils/isEmptyString/isEmptyString.util"

export interface SimpleCTACardProps extends Blok {
  link?: StoryblokLink
  image?: StoryblokAsset
  heading?: string
}

const SimpleCTACard: React.FC<SimpleCTACardProps> = ({
  link = undefined,
  image = undefined,
  heading = undefined,
}) => {
  const isSimpleCTACardValid = (): boolean => {
    return isLinkPopulated() && isImagePopulated() && isHeadingPopulated()
  }

  const isLinkPopulated = (): boolean => {
    if (isUndefined(link)) {
      return false
    } else {
      if (isUndefined(link?.cached_url)) {
        return false
      } else {
        return !isEmptyString(link?.cached_url)
      }
    }
  }

  const isImagePopulated = (): boolean => {
    if (isUndefined(image)) {
      return false
    } else {
      if (isUndefined(image?.filename)) {
        return false
      } else {
        return !isEmptyString(image?.filename)
      }
    }
  }

  const isHeadingPopulated = (): boolean => {
    return !isUndefined(heading) && !isEmptyString(heading)
  }

  const linkClassNames = classNames("w-full space-y-6", {
    "pointer-events-none": !isLinkPopulated(),
  })

  const imageClassNames = classNames(
    "w-full h-[260px] rounded-6xl overflow-clip xl:h-[268px] 2xl:h-[260px]",
    {
      "bg-Transparent": !isEditMode() || isImagePopulated(),
      "bg-SP7": isEditMode() && !isImagePopulated(),
    }
  )

  const headingClassNames = classNames(
    "font-montserrat font-extrabold text-Primary-900",
    {
      "bg-Transparent": !isEditMode() || isHeadingPopulated(),
      "block bg-SP7 rounded-2xl w-4/5 h-7":
        isEditMode() && !isHeadingPopulated(),
    }
  )

  return (
    <>
      {(isEditMode() || isSimpleCTACardValid()) && (
        <li className="col-span-2 md:col-span-6 xl:col-span-4">
          <CustomLink url={link?.cached_url} className={linkClassNames}>
            <div className={imageClassNames}>
              {isImagePopulated() && <Image image={image!} objectFit="cover" />}
            </div>
            <h4 className={headingClassNames}>
              {isHeadingPopulated() ? heading : ""}
            </h4>
          </CustomLink>
        </li>
      )}
    </>
  )
}

export default SimpleCTACard
