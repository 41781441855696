import React from "react"
import { FindUsResultsConfigProps } from "../FindUsFilterAndResults.blok"
import StoryblokHeadingAndRichText from "../../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"
import FindUsResultsTopBar from "./FindUsResultsTopBar.component"
import FindUsResultsTitleAndFiltersBar from "./FindUsResultsTitleAndFiltersBar.component"
import FindUsResultsList from "./FindUsResultsList.component"
import FindUsResultsPaginationBar from "./FindUsResultsPaginationBar.component"

interface FindUsResultsProps {
  results_config: FindUsResultsConfigProps[]
  clear_all_button_label: string
  filterButtonClicked: () => void
}

const FindUsResults: React.FC<FindUsResultsProps> = ({
  results_config,
  clear_all_button_label,
  filterButtonClicked,
}) => {
  const getFilterResultsConfig = (): FindUsResultsConfigProps | undefined => {
    return results_config && results_config[0]
  }

  const getFilterResultsConfigTopBarContent = ():
    | StoryblokHeadingAndRichText
    | undefined => {
    return (
      getFilterResultsConfig() &&
      getFilterResultsConfig()?.top_bar_content &&
      getFilterResultsConfig()?.top_bar_content[0]
    )
  }

  const getSelectedFilterOptions = (): FilterableItem[] => {
    return []
  }

  const isAtLeastOneFilterOptionsSelected = (): boolean => {
    return getSelectedFilterOptions().length > 0
  }

  const isTitleAndFiltersBarRequired = (): boolean => {
    return (
      getFilterResultsConfig()?.title_and_filters_bar_title != undefined ||
      isAtLeastOneFilterOptionsSelected()
    )
  }

  return (
    <>
      {getFilterResultsConfig() && (
        <article className="col-span-2 md:col-span-8 md:col-start-3 lg:col-span-12 lg:col-start-1 xl:col-span-8 xl:col-start-5">
          {getFilterResultsConfigTopBarContent() && (
            <FindUsResultsTopBar
              top_bar_content={getFilterResultsConfigTopBarContent()!}
            />
          )}
          {isTitleAndFiltersBarRequired() && (
            <FindUsResultsTitleAndFiltersBar
              title_and_filters_bar_title={
                getFilterResultsConfig()!.title_and_filters_bar_title
              }
              clear_all_button_label={clear_all_button_label}
              filterButtonClicked={() => filterButtonClicked()}
            />
          )}
          <FindUsResultsList
            card_config={getFilterResultsConfig()?.card_config}
            results_not_found={getFilterResultsConfig()?.results_not_found}
            clear_all_button_label={clear_all_button_label}
          />
          <FindUsResultsPaginationBar
            results_per_page={getFilterResultsConfig()?.results_per_page ?? 8}
          />
        </article>
      )}
    </>
  )
}

export default FindUsResults
