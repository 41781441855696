import React, { useContext } from 'react';
// import TemplateProps from "../../interfaces/TemplateProps.interface"
import Layout from '../utils/Layout/Layout.component';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import HeroBannerContext from '../../contexts/HeroBanner.context';
import SEO from '../../interfaces/SEO.interface';
import Meta from '../utils/Meta/Meta.component';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';
import LoginOrRegister from '../LoginOrRegister/LoginOrRegister.component';
import StoryblokEntryTemplateContext from '../../interfaces/Storyblok/StoryblokEntryTemplateContext.interface';
import getStoryblokWrapperEntries from '../../utils/getStoryblokWrapperEntries/getStoryblokWrapperEntries.util';
import getBlok from '../../utils/getBlok/getBlok.util';
import DynamicComponent from '../utils/DynamicComponent/DynamicComponent.component';
import BreadcrumbBar from '../bloks/BreadcrumbBar/BreadcrumbBar';
import LayoutContext from '../../contexts/LayoutContext';

interface TemplateProps {
  context: StoryblokEntryTemplateContext;
  venues: any;
  countdown?: JSX.Element;
}

const LoginOrRegisterPageTemplate: React.FC<TemplateProps> = ({ context, venues, countdown }) => {
  const {
    SEO,
    page_body,
    page_footer,
    breadcrumb_override,
    has_background,
    ...props
  }: {
    SEO?: SEO;
    page_body: Blok[];
    page_footer: Blok[];
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;
  const { header, footer, newsletter } = getStoryblokWrapperEntries();

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);
  const breadcrumbs = useContext(LayoutContext);
  return (
    <HeroBannerContext.Provider
      value={{
        isExtendedHeightRequired: true,
      }}
    >
      <BaseDataLayer field_component={context.field_component}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        {breadcrumbs && (
          <BreadcrumbBar {...breadcrumbs} breadcrumb_override={breadcrumb_override} hasBackground={has_background} />
        )}
        <LoginOrRegister {...props} />
        {page_body && page_body.map(blok => <DynamicComponent blok={blok} />)}
        {page_footer && page_footer.map(blok => <DynamicComponent blok={blok} />)}
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
      </BaseDataLayer>
    </HeroBannerContext.Provider>
  );
};

export default LoginOrRegisterPageTemplate;
