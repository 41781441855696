import React, { useEffect, useRef } from "react"
import DarkThemeBlokTear from "../../../assets/svgs/CornerTears/BlokTears/DarkThemeBlokTear.svg"
import TearHorizontalPosition from "../../../interfaces/enums/TearPositioning/TearHorizontalPosition.enum"

interface AvailabilityCheckerProps {
  heading: string
  iframe_link?: string
}

const AvailabilityChecker: React.FC<AvailabilityCheckerProps> = ({
  heading,
  iframe_link,
}) => {
  const iFrameRef = useRef(null)
  useEffect(() => {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent"
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message"
    var eventer = window[eventMethod]
    eventer(messageEvent, function (e: any) {
      var data = e && e.data && e.data ? e.data : null
      if (data && data.method == "resize") {
        if (data.height > 400 && iFrameRef?.current) {
          iFrameRef.current.style.height = `${data.height}px`
        }
      }
    })
  })
  return (
    <section className="bg-Primary-900 relative">
      <DarkThemeBlokTear horizontalPosition={TearHorizontalPosition.Trailing} />
      <div className="container mx-auto py-12 md:py-16 lg:py-20">
        <p className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-center text-White pb-8 md:pb-12 lg:pb-16">
          {heading}
        </p>
        {iframe_link && (
          <iframe
            ref={iFrameRef}
            src={iframe_link}
            id="frmMBAvailableActivities"
            frameBorder="0"
            className="w-full h-[37rem] sm:h-[35rem] md:h-[36rem] lg:h-[23.5rem] xl:h-[22rem] min-h-[400px]"
          ></iframe>
        )}
      </div>
    </section>
  )
}

export default AvailabilityChecker
