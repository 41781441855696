import React, { useContext, useEffect } from "react"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"
import ReactPaginate from "react-paginate"

interface FindUsResultsPaginationBarProps {
  results_per_page: number
}

const FindUsResultsPaginationBar: React.FC<FindUsResultsPaginationBarProps> = ({
  results_per_page,
}) => {
  const {
    filteredVenues,
    currentPageIndex,
    numberOfRequiredVenuesPerPage,
    updateNumberOfRequiredVenuesPerPage,
    updateCurrentPageIndex,
  } = useContext(FindUsPageContext);

  useEffect(() => {
    updateNumberOfRequiredVenuesPerPage(results_per_page)
  }, [])

  const getNumberOfFirstVenueOnPage = (): number => {
    return numberOfRequiredVenuesPerPage * currentPageIndex + 1
  }

  const getNumberOfLastVenueOnPage = (): number => {
    return (
      getNumberOfFirstVenueOnPage() -
      1 +
      filteredVenues[currentPageIndex].length
    )
  }

  const getNumberOfFilteredVenues = (): number => {
    return filteredVenues.flatMap(filteredVenue => filteredVenue).length
  }

  const hasFilteredVenues = (): boolean => {
    return getNumberOfFilteredVenues() > 0
  }

  const paginationBarTopBarListItemClassNames = "h-full w-10 md:w-12"
  const paginationBarTopListItemsWithBorderRightClassNames = `${paginationBarTopBarListItemClassNames} border-r border-r-Body-Disabled`
  const paginationBarTopBarListItemAnchorClassNames =
    "w-full h-full flex items-center justify-center text-Primary-900"

  const getPaginationBarBottomBarNumberSpanClassNames = "font-bold"

  const handlePageClick = (event: { selected: number }): void => {
    const pageNumber = event.selected
    // setQueryParameter(QueryParams.PAGE, `${pageNumber + 1}`);
  }

  return (
    <>
      {hasFilteredVenues() && (
        <div className="w-full flex items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={filteredVenues.length}
              previousLabel="<"
              containerClassName={
                "flex bg-White border border-Body-Disabled rounded-2xl h-12"
              }
              pageClassName={paginationBarTopListItemsWithBorderRightClassNames}
              previousClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              breakClassName={
                paginationBarTopListItemsWithBorderRightClassNames
              }
              nextClassName={paginationBarTopBarListItemClassNames}
              pageLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              previousLinkClassName={
                paginationBarTopBarListItemAnchorClassNames
              }
              breakLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              nextLinkClassName={paginationBarTopBarListItemAnchorClassNames}
              activeLinkClassName={"bg-Primary-900 text-[#fff]"}
            />
            <p className="text-Primary-900">
              Showing{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfFirstVenueOnPage()}
              </span>{" "}
              to{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfLastVenueOnPage()}
              </span>{" "}
              of{" "}
              <span className={getPaginationBarBottomBarNumberSpanClassNames}>
                {getNumberOfFilteredVenues()}
              </span>{" "}
              venues
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default FindUsResultsPaginationBar
