import React from "react"

interface MapPinProps {
  className?: string
}

const MapPin: React.FC<MapPinProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`transfrom fill-current ${className}`}
    >
      <g clipPath="url(#clip0_2232_34808)">
        <path d="M12 2C15.87 2 19 5.13 19 9C19 10.74 18.5 12.37 17.59 13.84C16.64 15.38 15.39 16.7 14.43 18.24C13.96 18.99 13.62 19.69 13.26 20.5C13 21.05 12.79 22 12 22C11.21 22 11 21.05 10.75 20.5C10.38 19.69 10.05 18.99 9.58 18.24C8.62 16.71 7.37 15.39 6.42 13.84C5.5 12.37 5 10.74 5 9C5 5.13 8.13 2 12 2ZM12 11.75C13.38 11.75 14.5 10.63 14.5 9.25C14.5 7.87 13.38 6.75 12 6.75C10.62 6.75 9.5 7.87 9.5 9.25C9.5 10.63 10.62 11.75 12 11.75Z" />
      </g>
      <defs>
        <clipPath id="clip0_2232_34808">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MapPin
