import React, { useState, useEffect } from "react"
import { isEditMode } from "../../../utils/isEditMode.util"
import isWindowInExistence from "../../../utils/isWindowInExistence.util"

const BreakpointChecker = () => {
  enum Breakpoint {
    sm = "sm 672px (640px ith 16px gutter)",
    md = "md 800px (768px with 16px gutter)",
    lg = "lg 1056px (1024px with 16px gutter)",
    xl = "xl 1440px (1280px with 80px gutter)",
    xxl = "2xl 1600px (1440px with 80px gutter)",
    xxxxl = "3xl 1920px (1536px with 192px gutter)",
  }

  const [windowWidth, setWindowWidth] = useState<number>()
  const [breakpoint, setBreakpoint] = useState<Breakpoint>()

  useEffect(() => {
    if (isWindowInExistence()) {
      setWindowWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    updateBreakpoint()
  }, [windowWidth])

  const updateBreakpoint = (): void => {
    if (windowWidth) {
      if (windowWidth < 800) {
        setBreakpoint(Breakpoint.sm)
      } else if (windowWidth < 1056) {
        setBreakpoint(Breakpoint.md)
      } else if (windowWidth < 1440) {
        setBreakpoint(Breakpoint.lg)
      } else if (windowWidth < 1600) {
        setBreakpoint(Breakpoint.xl)
      } else if (windowWidth < 1920) {
        setBreakpoint(Breakpoint.xxl)
      }
    }
  }

  const canShowBreakpointChecker = (): boolean => {
    return isEditMode() && isWindowInExistence()
  }

  if (canShowBreakpointChecker()) {
    return (
      <p className="bg-White p-2 border fixed top-0 left-0 z-50">
        {breakpoint}
      </p>
    )
  }

  return <></>
}

export default BreakpointChecker
