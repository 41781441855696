import React, { useContext, useEffect } from "react"
import {
  FilterItemSection,
  FindUsFilterSideBarConfigProps,
} from "../FindUsFilterAndResults.blok"
import classNames from "classnames"
import Close from "../../../../assets/svgs/Close.svg"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"
import FindUsFilterSections from "./FindUsFilterSections.component"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"

interface FindUsFiltersProps {
  filter_side_bar_config: FindUsFilterSideBarConfigProps[]
  clear_all_button_label: string
  isSmallerDeviceFilterPopupRequired: boolean
  closeButtonClicked: () => void
}

const FindUsFilters: React.FC<FindUsFiltersProps> = ({
  filter_side_bar_config,
  clear_all_button_label,
  isSmallerDeviceFilterPopupRequired,
  closeButtonClicked,
}) => {
  const {
    setIsInitialLocalStorageCheckComplete,
    updateSelectedFilters,
    updateSearchBarInputValue,
  } = useContext(FindUsPageContext)

  const getFilterSideBarConfig = (): FindUsFilterSideBarConfigProps => {
    return filter_side_bar_config[0]
  }

  /*-- 
    we would normally opt to do this in the 
    find us page template itself, but we need 
    access to the filterable items that the client 
    has selected through the cms to check if what 
    we will be doing with local storage is even 
    relevant
  --*/
  useEffect(() => {
    doInitialLocalStorageCheck()
  }, [])

  const doInitialLocalStorageCheck = (): void => {
    // search bar input
    const findUsSearchBarInputValue: string | undefined =
      localStorage.getItem("findUsSearchBarInputValue") ?? undefined
    if (findUsSearchBarInputValue) {
      updateSearchBarInputValue(findUsSearchBarInputValue)
    }

    // initial filterable items
    if (hasClientSelectedFilterableItemsFromCMS()) {
      const findUsInitiallySelectedFilterableItem: string | undefined =
        localStorage.getItem("findUsInitiallySelectedFilterableItems") ??
        undefined
      if (findUsInitiallySelectedFilterableItem) {
        const findUsInitiallySelectedFilterableItemsAsArray: string[] =
          findUsInitiallySelectedFilterableItem.split(", ")
        let clientSelectedFilterableItemsThatMatchInitiallySelectedFilterableItems: FilterableItem[] =
          []

        findUsInitiallySelectedFilterableItemsAsArray.forEach(
          (initiallySelectedFilterableItem: string) => {
            const indexOfClientSelectedFilterableItemThatMatchesInitiallySelectedFilterableItem: number =
              getAllClientSelectedFilterableItemsFromCMS().findIndex(
                (filterableItem: FilterableItem) => {
                  return (
                    filterableItem.content.filterable_item ===
                    initiallySelectedFilterableItem
                  )
                }
              )

            if (
              indexOfClientSelectedFilterableItemThatMatchesInitiallySelectedFilterableItem >
              -1
            ) {
              const clientSelectedFilterableItemThatMatchesInitiallySelectedFilterableItem: FilterableItem =
                getAllClientSelectedFilterableItemsFromCMS()[
                  indexOfClientSelectedFilterableItemThatMatchesInitiallySelectedFilterableItem
                ]
              clientSelectedFilterableItemsThatMatchInitiallySelectedFilterableItems.push(
                clientSelectedFilterableItemThatMatchesInitiallySelectedFilterableItem
              )
            }
          }
        )

        if (
          clientSelectedFilterableItemsThatMatchInitiallySelectedFilterableItems.length >
          0
        ) {
          updateSelectedFilters(
            clientSelectedFilterableItemsThatMatchInitiallySelectedFilterableItems
          )
        }
      }
    }

    // attempt to undo the local storage stuff
    removeItemFromLocalStorage("findUsSearchBarInputValue")
    removeItemFromLocalStorage("findUsInitiallySelectedFilterableItems")

    // now that we're done, we'll tell the parent they can continue
    setIsInitialLocalStorageCheckComplete(true)
  }

  const getAllClientSelectedFilterableItemsFromCMS = (): FilterableItem[] => {
    if (getFilterSideBarConfig() && getFilterSideBarConfig().filter_sections) {
      return getFilterSideBarConfig().filter_sections.flatMap(
        (filter_section: FilterItemSection) =>
          filter_section.section_filter_items
      )
    } else {
      return []
    }
  }

  const hasClientSelectedFilterableItemsFromCMS = (): boolean => {
    return getAllClientSelectedFilterableItemsFromCMS().length > 0
  }

  const removeItemFromLocalStorage = (localStorageKey: string): void => {
    if (localStorage.getItem(localStorageKey)) {
      localStorage.removeItem(localStorageKey)
    }
  }

  const filterBarClassNames = classNames(
    "font-montserrat flex fixed top-0 bottom-0 w-full z-20 transition-all duration-300 xl:block xl:static xl:left-0 xl:col-span-4 xl:pr-14 xl:transition-none xl:duration-0",
    {
      "-left-full delay-200": !isSmallerDeviceFilterPopupRequired,
      "left-0": isSmallerDeviceFilterPopupRequired,
    }
  )

  const filterBarContentContainerClassNames = classNames(
    "w-full max-w-[300px] bg-White px-4 py-8 overflow-y-auto xl:max-w-none xl:p-0 xl:bg-SP7"
  )

  const filterBarOverlayClassNames = classNames(
    "flex-1 bg-Black bg-opacity-30 transition-opacity duration-300 xl:hidden xl:transition-none xl:duration-0",
    {
      "opacity-0": !isSmallerDeviceFilterPopupRequired,
      "opacity-100 delay-200": isSmallerDeviceFilterPopupRequired,
    }
  )

  return (
    <aside className={filterBarClassNames}>
      <div className={filterBarContentContainerClassNames}>
        <div className="flex flex-col items-start pb-2 space-y-6 xl:flex-row xl:items-center xl:justify-between xl:space-y-0">
          <div className="w-full flex items-center justify-between space-x-4">
            <h3 className="font-semibold text-2xl text-Primary-900">
              {getFilterSideBarConfig().section_title}
            </h3>
            <button className="xl:hidden" onClick={() => closeButtonClicked()}>
              <Close className="" />
            </button>
          </div>
          <button
            className="font-bold text-base text-SP4 whitespace-nowrap"
            onClick={() => updateSelectedFilters([])}
          >
            {clear_all_button_label}
          </button>
        </div>
        <FindUsFilterSections
          filter_sections={getFilterSideBarConfig().filter_sections}
        />
      </div>
      <button
        onClick={() => closeButtonClicked()}
        className={filterBarOverlayClassNames}
      ></button>
    </aside>
  )
}

export default FindUsFilters
