import React from "react"

const QuoteIconLeft: React.FC = () => {
  return (
    <svg
      width="124"
      height="200"
      viewBox="0 0 124 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.2267 46.0149H36.0896C23.6512 46.0149 13.582 35.9458 13.582 23.5075C13.582 11.0691 23.6512 1 36.0896 1H45.7383C58.1767 1 68.2457 11.0691 68.2457 23.5075V46.0149H68.2267Z"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1719 17.3359H49.6353"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1719 28.1895H49.6353"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.8243 34.2646H48.1755C35.7372 34.2646 25.668 44.3338 25.668 56.7721V79.2796H57.8051C70.2435 79.2796 80.3127 69.2105 80.3127 56.7721C80.3127 44.3529 70.2435 34.2646 57.8243 34.2646ZM43.6855 59.7145C42.0615 59.7145 40.743 58.3962 40.743 56.7721C40.743 55.1481 42.0615 53.8297 43.6855 53.8297C45.3096 53.8297 46.6279 55.1481 46.6279 56.7721C46.6279 58.3962 45.3096 59.7145 43.6855 59.7145ZM52.9904 59.7145C51.3663 59.7145 50.0479 58.3962 50.0479 56.7721C50.0479 55.1481 51.3663 53.8297 52.9904 53.8297C54.6144 53.8297 55.9327 55.1481 55.9327 56.7721C55.9518 58.3962 54.6335 59.7145 52.9904 59.7145ZM62.2952 59.7145C60.6712 59.7145 59.3528 58.3962 59.3528 56.7721C59.3528 55.1481 60.6712 53.8297 62.2952 53.8297C63.9193 53.8297 65.2376 55.1481 65.2376 56.7721C65.2567 58.3962 63.9193 59.7145 62.2952 59.7145Z"
        fill="#FF5E67"
      />
      <path
        d="M14.9608 83.2529C5.15917 93.3221 -0.687453 108.13 1.43337 122.211C3.45866 135.662 14.1393 147.833 27.9151 149.916C42.5507 152.132 59.1925 139.961 57.4156 124.16C56.2309 113.556 44.2894 110.289 35.3093 112.849C26.7878 115.275 19.7183 121.772 15.6295 129.644C7.08888 146.171 13.4896 165.793 25.0109 179.11C38.5765 194.816 59.9566 201.331 80.2669 198.694C96.9277 196.535 112.442 187.976 123.581 175.499"
        stroke="#FF5E67"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default QuoteIconLeft
