import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config"

const getTearColourHexCodesFromTailwindConfig = (
  theme: "Dark-Theme" | "Light-Theme" | "Alternative-Theme" | "White-Theme"
): { primaryHexCode: string; secondaryHexCode: string } => {
  const config = resolveConfig(tailwindConfig).theme
  const colours = config?.colors
  const primaryHexCode: string = colours[`${theme}-Tear-Primary`]
  const secondaryHexCode: string = colours[`${theme}-Tear-Secondary`]
  return {
    primaryHexCode,
    secondaryHexCode,
  }
}

export default getTearColourHexCodesFromTailwindConfig
