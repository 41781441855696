import React from "react"

interface DownloadProps {
  className?: string
}

const Download: React.FC<DownloadProps> = ({ className }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      aria-labelledby="download"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M14 6.0968H10V0.0968018H4V6.0968H0L7 13.0968L14 6.0968ZM0 15.0968V17.0968H14V15.0968H0Z" />
    </svg>
  )
}

export default Download
