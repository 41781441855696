import React from 'react';

interface ChevronLeftProps {
  className?: string;
  rotateOnHover?: boolean;
  rotate?: boolean;
}
const ChevronLeft: React.FC<ChevronLeftProps> = ({ className, rotateOnHover, rotate }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={`transfrom fill-current ${className} ${rotateOnHover && 'group-hover:rotate-180'} ${
        rotate && 'rotate-180'
      }`}
    >
      <g clip-path="url(#clip0_4541_5126)">
        <path d="M11.6667 5L12.8417 6.175L9.02501 10L12.8417 13.825L11.6667 15L6.66668 10L11.6667 5Z" fill="#4B1375" />
      </g>
      <defs>
        <clipPath id="clip0_4541_5126">
          <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronLeft;
