import React, { useContext } from "react";
import DotsSpinner from "../../../../assets/spinners/dots.spinner";
import JobPageContext from "../../../../contexts/JobsPage.context";
import { IFilteredAd } from "../../../../services/jobAdder.service";
import JobResultItem from "./JobResultItem/JobResultItem.blok";

interface JobResultsProps  {
  Card_primary_cta_label: string;
  Card_secondary_cta_label: string;
  filterButtonClicked: () => void
}

const JobResults: React.FC<JobResultsProps> = ({ Card_primary_cta_label, Card_secondary_cta_label }: JobResultsProps) => { 
  const { filteredJobs, jobsLoading, numberOfJobsPerPage, currentPageIndex } = useContext(JobPageContext);

  return (
    <div id="job-results-main-content" className="flex flex-col gap-12 self-stretch h-full">      
      {!jobsLoading && Boolean(filteredJobs.length) && (
        <div className="flex flex-col gap-8">
          {filteredJobs[currentPageIndex].map((job: IFilteredAd, idx) => 
            <div key={idx}><JobResultItem Card_primary_cta_label={Card_primary_cta_label} Card_secondary_cta_label={Card_secondary_cta_label} job={job} /></div>
          )}
        </div>
      )}
      {!jobsLoading && !Boolean(filteredJobs.length) && (
        <div className="flex flex-col gap-8">
          <h3 className="text-2xl xl:text-2xl 2xl:text-2xl font-bold pb-4 text-Primary-900 w-full">No jobs found.</h3>
        </div>
      )}
      {jobsLoading && (<div className='flex items-center justify-center h-full w-full'><DotsSpinner /></div>)}     
    </div>        
  )
}

export default JobResults;