import isJSON from "../../../utils/isJSON/isJSON.util"
import { SiteSearchProps } from "./SiteSearchProps.interface"

const getModelledSiteSearchProps = (content: any): SiteSearchProps => {
  const parsedContent: any = isJSON(content) ? JSON.parse(content) : content

  return {
    seo: parsedContent.SEO,
    hero_banner_config: {
      title: parsedContent.search_hero_title,
      description: parsedContent.search_hero_description,
      input_placeholder: parsedContent.search_hero_input_placeholder,
    },
    results_list_config: {
      background_icon: parsedContent.background_icon,
      title: parsedContent.search_results_title,
      no_search_term_config: {
        title: parsedContent.no_search_term_title,
        description: parsedContent.no_search_term_description,
      },
      no_results_found_config: {
        title: parsedContent.no_results_found_title,
        description: parsedContent.no_results_found_description,
      },
    },
    pagination_bar_config: {
      max_results_per_page: parseInt(parsedContent.max_results_per_page),
      summary_text: parsedContent.summary_text,
    },
  }
}

export default getModelledSiteSearchProps
