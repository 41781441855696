import classNames from "classnames";
import React from "react";
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum";
import Button from "../../atoms/AnimatedButton/AnimatedButton.component";
import SocialButton from "../../atoms/SocialButton/SocialButton.component";
import { SocialProps } from "../JobInformation/JobInformation.blok";

interface ShareOnSocialProps {
  applicationLink: string;
  cta_label: string;
  socials: string[]
}

const socialMediaLinkMap = new Map<string, string>([
  ['linkedin', `https://www.linkedin.com/sharing/share-offsite/?url=${typeof window !== "undefined" && window.location.href}`],
  ['facebook', `https://www.facebook.com/sharer/sharer.php?u=${typeof window !== "undefined" && window.location.href}`],
  ['twitter', `https://twitter.com/share?url=${typeof window !== "undefined" && window.location.href}`],
]);

const ShareOnSocials: React.FC<ShareOnSocialProps> = ({ applicationLink, cta_label, socials }) => {
  return (
    <div className="py-10 flex flex-row justify-between items-center border-b border-t border-b-Body-Disabled border-t-Body-Disabled">
      <div>
        <Button
          onClick={() => {
            window.open(applicationLink);
          }}
          type={ButtonTypes.PRIMARY}
          label={cta_label}
          fullWidth={false}
        />
      </div>    

      <div id="share-on-social-icons" className="flex flex-row">
        {socials && !!socials.length && (
          <>
            {socials.map((social, i) => {
              return (
                <SocialButton
                  link={String(socialMediaLinkMap.get(social))}
                  type={ButtonTypes.PRIMARY}
                  styles="inline-block"
                  key={`social-${i}`}
                ></SocialButton>
              )              
            })}              
          </>
        )}
      </div>
    </div>
  )
}

export default ShareOnSocials;