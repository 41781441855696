import React from "react"

interface MinusProps {
  className?: string
}

const Minus: React.FC<MinusProps> = ({ className }) => {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H12V2H0V0Z" fill="#F46A6C"/>
    </svg>
  )
}

export default Minus;

