import React from 'react';
import TemplateProps from '../../interfaces/TemplateProps.interface';
import Layout from '../utils/Layout/Layout.component';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import HeroBannerContext from '../../contexts/HeroBanner.context';
import SEO from '../../interfaces/SEO.interface';
import Meta from '../utils/Meta/Meta.component';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';

const HomePageTemplate: React.FC<TemplateProps> = ({ context, venues, countdown }) => {
  const {
    SEO,
    page_hero,
    page_body,
    page_footer,
  }: {
    SEO?: SEO;
    page_hero: Blok[];
    page_body: Blok[];
    page_footer: Blok[];
  } = context.content;

  return (
    <HeroBannerContext.Provider
      value={{
        isExtendedHeightRequired: true,
      }}
    >
      <BaseDataLayer field_component={context.field_component}>
        <Layout countdown={countdown} bloks={{ page_hero, page_body, page_footer }} />
      </BaseDataLayer>
    </HeroBannerContext.Provider>
  );
};

export default HomePageTemplate;
