import React, { useContext } from 'react';
import TemplateProps from '../../interfaces/TemplateProps.interface';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import { layoutClassNames } from '../../styles/ClassNames/global.classes';
import DynamicComponent from '../utils/DynamicComponent/DynamicComponent.component';
import renderChildren from '../../utils/renderBloks.util';
import getStoryblokWrapperEntries from '../../utils/getStoryblokWrapperEntries/getStoryblokWrapperEntries.util';
import getBlok from '../../utils/getBlok/getBlok.util';
import SEO from '../../interfaces/SEO.interface';
import Meta from '../utils/Meta/Meta.component';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';
import LayoutContext from '../../contexts/LayoutContext';
import BreadcrumbBar from '../bloks/BreadcrumbBar/BreadcrumbBar';

const ArticleTemplate: React.FC<TemplateProps> = ({ context, countdown }) => {
  const {
    SEO,
    page_hero,
    page_body,
    page_footer,
    breadcrumb_override,
    has_background,
  }: {
    SEO?: SEO;
    page_hero: Blok[];
    page_body: Blok[];
    page_footer: Blok[];
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;

  const { header, footer, newsletter } = getStoryblokWrapperEntries();

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);
  const breadcrumbs = useContext(LayoutContext);

  return (
    <BaseDataLayer field_component={context.field_component}>
      <div className={layoutClassNames}>
        {headerBlok && <DynamicComponent blok={headerBlok} />}
        <article>
          {page_hero && renderChildren(page_hero)}
          <div className="container mx-auto pt-10 pb-20 md:pt-12">
            {breadcrumbs && (
              <BreadcrumbBar
                {...breadcrumbs}
                breadcrumb_override={breadcrumb_override}
                hasBackground={has_background}
              />
            )}
            {page_body && renderChildren(page_body)}
          </div>
          {page_footer && renderChildren(page_footer)}
        </article>
        {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
        {footerBlok && <DynamicComponent blok={footerBlok} />}
      </div>
    </BaseDataLayer>
  );
};

export default ArticleTemplate;
