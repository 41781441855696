import React from "react"
import Slider from "react-slick"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import "../../../styles/carousel.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface ImageProps {
  image: StoryblokAsset
}
interface ImageCarouselProps {
  fullSizeImages?: ImageProps[]
  leftImages?: ImageProps[]
  rightImages?: ImageProps[]
  stackedImages?: ImageProps[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  fullSizeImages,
  leftImages,
  rightImages,
  stackedImages,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const stackedImageForMapping =
    stackedImages &&
    stackedImages.reduce(function (
      accumulator,
      currentValue,
      currentIndex,
      array
    ) {
      if (currentIndex % 2 === 0)
        accumulator.push(array.slice(currentIndex, currentIndex + 2))
      return accumulator
    },
    [])

  const images =
    leftImages &&
    rightImages &&
    leftImages
      .flatMap((e, idx) => [
        e,
        stackedImageForMapping && stackedImageForMapping[idx],
        rightImages[idx],
      ])
      .filter(e => e)

  return (
    <Slider {...settings} className="flex">
      {images &&
        !!images.length &&
        images.map(item =>
          item instanceof Array ? (
            <div className="space-y-6 px-4  order-1">
              {item.length > 1 ? (
                <>
                  <div className="aspect-[9.73/9]">
                    <div className="h-full w-full">
                      <div className="h-full w-full rounded-6xl overflow-hidden">
                        {item && (
                          <Image
                            image={item[0].image}
                            objectFit="cover"
                          ></Image>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="aspect-[20/9]">
                    <div className="h-full w-full">
                      <div className="h-full w-full rounded-6xl overflow-hidden">
                        {item && (
                          <Image
                            image={item[1].image}
                            objectFit="cover"
                          ></Image>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="aspect-[3/4] px-4">
                  <div className="h-full w-full rounded-6xl overflow-hidden">
                    {item && (
                      <Image image={item[0].image} objectFit="cover"></Image>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="aspect-[3/4] px-4 order-2">
              <div className="h-full w-full rounded-6xl overflow-hidden">
                {item && <Image image={item.image} objectFit="cover"></Image>}
              </div>
            </div>
          )
        )}
    </Slider>
  )
}

export default ImageCarousel
