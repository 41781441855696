import isJSON from "../../../utils/isJSON/isJSON.util"
import { ArticleProps } from "./ArticleProps.interface"

const getModelledArticleProps = (content: any): ArticleProps => {
  const parsedContent: any = isJSON(content) ? JSON.parse(content) : content

  return {
    pagination_bar_config: {
      max_results_per_page: parseInt(parsedContent.max_results_per_page),
      summary_text: parsedContent.summary_text,
    },
  }
}

export default getModelledArticleProps
