import React from "react"

interface MobileFilterToggleProps {
  numberOfSelectedFilters: number
}

const MobileFilterToggle: React.FC<MobileFilterToggleProps> = ({
  numberOfSelectedFilters,
}) => {
  return (
    <div className="relative">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#3DCFFF" />
        <g clipPath="url(#clip0_1556_30972)">
          <path
            d="M18 26H22V24H18V26ZM11 14V16H29V14H11ZM14 21H26V19H14V21Z"
            fill="#4B1375"
          />
        </g>
        <defs>
          <clipPath id="clip0_1556_30972">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
      {numberOfSelectedFilters > 0 && (
        <div
          className="bg-Secondary-300 rounded-2xl flex items-center justify-center"
          style={{
            width: "16px",
            height: "16px",
            position: "absolute",
            top: "-2px",
            right: "-2px",
          }}
        >
          <p className="font-montserrat text-xs text-White">
            {numberOfSelectedFilters}
          </p>
        </div>
      )}
    </div>
  )
}

export default MobileFilterToggle
