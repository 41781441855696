import React, { useContext, useEffect, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import Location from '../../../assets/svgs/Location.svg';
import UserLocation from '../../../assets/svgs/UserLocation.svg';
import Coordinates from '../../../interfaces/Coordinates.interface';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { QueryParams } from '../../../interfaces/enums/QueryParams.enum';
import setQueryParameters from '../../../utils/setQueryParameters.util';
import Button from '../../atoms/Button/Button.component';
// import getPostCode from "../../../utils/getPostCode.util"
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { VenueIndexSearchBarProps } from '../../../interfaces/VenueIndex/VenueIndex.interface';
import getLatLng from '../../../utils/getLatLng.util';
import VenuesContext from '../../../contexts/VenuesContext';
import getQueryParameters from '../../../utils/getQueryParameters.util';

const VenueIndexSearchBar: React.FC<VenueIndexSearchBarProps> = ({ preSelectedFilters, placeholder_text, button }) => {
  const [searchBarInputValue, setSearchBarInputValue] = useState('');
  const [isFetchingCurrentUserPostcode, setIsFetchingCurrentUserPostcode] = useState<boolean>(false);
  const location = useLocation();
  const venueData = useContext(VenuesContext);

  useEffect(() => {
    if (!getQueryParameters(QueryParams.LAT_LNG) && !getQueryParameters(QueryParams.SEARCH)) {
      setSearchBarInputValue('');
    } else {
      getQueryParameters(QueryParams.SEARCH) &&
        setSearchBarInputValue(decodeURI(getQueryParameters(QueryParams.SEARCH)));
    }
  }, [getQueryParameters(QueryParams.LAT_LNG)]);

  const getCurrentUserLocation = async (): Promise<void> => {
    if (!isFetchingCurrentUserPostcode) {
      setIsFetchingCurrentUserPostcode(true);
      navigator.geolocation.getCurrentPosition(position => {
        if (location.href.includes('/find-us')) {
          setIsFetchingCurrentUserPostcode(false);
          setQueryParameters(`${position.coords.latitude},${position.coords.longitude}`, QueryParams.LAT_LNG);
        } else {
          navigate(`/find-us?${QueryParams.LAT_LNG}=${position.coords.latitude},${position.coords.longitude}`);
        }
      });
    }
  };
  //

  const searchClubs = async (e?: any) => {
    const result: { coordinates: Coordinates } | undefined = await getLatLng(searchBarInputValue);

    const matchedVenues = venueData.filter(venue =>
      venue?.name?.toLowerCase().includes(searchBarInputValue.toLowerCase()),
    );
    if (matchedVenues.length > 0) {
      if (location.href.includes('/find-us')) {
        setQueryParameters(encodeURI(searchBarInputValue), QueryParams.SEARCH);
      } else {
        if (preSelectedFilters && preSelectedFilters.length) {
          navigate(
            `/find-us?${QueryParams.SEARCH}=${encodeURI(searchBarInputValue)}&${QueryParams.FILTERS}=${encodeURI(
              preSelectedFilters.map(filter => filter.replace('-', ' ')),
            )}
          `,
          );
        } else {
          navigate(`/find-us?${QueryParams.SEARCH}=${encodeURI(searchBarInputValue)}`);
        }
      }
    } else {
      if (location.href.includes('/find-us')) {
        if (result?.coordinates?.lat && result?.coordinates?.lng) {
          setQueryParameters(`${result.coordinates.lat},${result.coordinates.lng}`, QueryParams.LAT_LNG);
        } else {
          setQueryParameters(`null`, QueryParams.LAT_LNG);
        }
      } else {
        if (result?.coordinates?.lat && result?.coordinates?.lng) {
          if (preSelectedFilters && preSelectedFilters.length) {
            navigate(
              `/find-us?${QueryParams.LAT_LNG}=${result.coordinates.lat},${result.coordinates.lng}&${
                QueryParams.FILTERS
              }=${encodeURI(preSelectedFilters.map(filter => filter.replace('-', ' ')))}`,
            );
          } else {
            navigate(`/find-us?${QueryParams.LAT_LNG}=${result.coordinates.lat},${result.coordinates.lng}`);
          }
        } else {
          if (preSelectedFilters && preSelectedFilters.length) {
            navigate(
              `/find-us?${QueryParams.FILTERS}=${encodeURI(
                preSelectedFilters.map(filter => filter.replace('-', ' ')),
              )}&${QueryParams.LAT_LNG}=null`,
            );
          } else {
          }
          navigate(
            `/find-us?${QueryParams.LAT_LNG}=null
            `,
          );
        }
      }
    }
  };

  const handleKeyPress = async (e: any) => {
    const FindClubsBtn = document.querySelector('#findClubsBtn');
    if (e.key == 'Enter' && FindClubsBtn) {
      searchClubs(e);
    }
  };

  return (
    <div className="col-span-6 col-start-4 md:flex items-center justify-center space-y-4 md:space-y-0 md:drop-shadow-lg sm:min-w-[35rem]">
      {placeholder_text && (
        <div className="relative w-full flex">
          <Location className="text-Secondary-300 absolute left-7 top-0 bottom-0 my-auto" />
          {!isFetchingCurrentUserPostcode && (
            <input
              onChange={e => setSearchBarInputValue(e.target.value)}
              onKeyDown={e => handleKeyPress(e)}
              value={searchBarInputValue}
              type="text"
              placeholder={placeholder_text}
              className="text-sm md:text-base 2xl:text-lg py-3 md:py-4 lg:py-5 pl-14 pr-14 leading-6 focus:ring-0 focus:outline-none rounded-full md:rounded-l-full md:rounded-r-none w-full placeholder-Primary-900"
            />
          )}
          {isFetchingCurrentUserPostcode && (
            <p className="bg-White text-left text-Primary-900 text-sm md:text-base 2xl:text-lg py-3 md:py-4 lg:py-5 pl-14 pr-14 leading-6 focus:ring-0 focus:outline-none rounded-full md:rounded-l-full md:rounded-r-none w-full">
              Finding your location...
            </p>
          )}
          <button
            onClick={() => getCurrentUserLocation()}
            aria-label="My location"
            className={`${isFetchingCurrentUserPostcode && 'pointer-events-none'}`}
          >
            <UserLocation
              className={`text-Primary-900 absolute right-4 top-0 bottom-0 my-auto scale-[0.8] ${
                isFetchingCurrentUserPostcode && 'animate-spin'
              } lg:scale-90 3xl:scale-100`}
            ></UserLocation>
          </button>
        </div>
      )}
      {button?.length && button[0]?.label && (
        <Button
          label={button[0].label ?? 'Find clubs'}
          type={ButtonTypes.WITH_INPUT}
          className={`whitespace-nowrap ${!searchBarInputValue && 'pointer-events-none'} ${
            isFetchingCurrentUserPostcode && 'pointer-events-none'
          }`}
          onClick={async () => {
            if (searchBarInputValue) {
              searchClubs();
            }
          }}
          id={'findClubsBtn'}
        ></Button>
      )}
      <div className="hidden">
        <LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}></LoadScript>
      </div>
    </div>
  );
};

export default VenueIndexSearchBar;
