import React from "react"

interface EmailProps {
  className?: string
}

const Email: React.FC<EmailProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      aria-labelledby="email"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M18 0.176636H2C0.9 0.176636 0 1.07664 0 2.17664V14.1766C0 15.2766 0.9 16.1766 2 16.1766H18C19.1 16.1766 20 15.2766 20 14.1766V2.17664C20 1.07664 19.1 0.176636 18 0.176636ZM17.6 4.42664L11.06 8.51664C10.41 8.92664 9.59 8.92664 8.94 8.51664L2.4 4.42664C2.15 4.26664 2 3.99664 2 3.70664C2 3.03664 2.73 2.63664 3.3 2.98664L10 7.17664L16.7 2.98664C17.27 2.63664 18 3.03664 18 3.70664C18 3.99664 17.85 4.26664 17.6 4.42664Z" />
    </svg>
  )
}

export default Email
