import React, { useEffect, useState } from 'react';
import MainNav from '../../molecules/MainNav/MainNav.molecule';
import PreNav from '../../molecules/PreNav/PreNav.molecule';
import SideNav from '../../molecules/SideNav/SideNav.molecule';
import { NavItem } from '../../../interfaces/Navigation.interface';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import { ButtonProps } from '../../../interfaces/Button.interface';
import { useScrollLock } from '../../../hooks/useScrollLock.hook';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import Headroom from 'react-headroom';
import '../../../styles/headroom.css';

interface HeaderProps {
  pre_navigation_items: NavItem[];
  login_link: StoryblokLink;
  logo: StoryblokAsset;
  main_navigation_items: NavItem[];
  primary_cta: ButtonProps[];
  is_search_enabled: boolean;
  search_link: StoryblokLink;
  search_bar_placeholder: string;
  search_bar_button_label: string;
}

const Header: React.FC<HeaderProps> = ({
  pre_navigation_items,
  logo,
  main_navigation_items,
  primary_cta,
  is_search_enabled,
  search_link,
  search_bar_placeholder,
  search_bar_button_label,
  login_link,
}) => {
  const [sideNav, setSideNav] = useState<boolean>(false);
  const [isNavFixed, setIsNavFixed] = useState<boolean>(false);
  const toggleSideNav = (state: boolean): void => setSideNav(state);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    sideNav ? lockScroll() : unlockScroll();
  }, [sideNav]);

  return (
    <header className="w-full">
      <div className="hidden lg:block bg-sherpa-red-main bg-Primary-900">
        <div className="container mx-auto">
          {pre_navigation_items && <PreNav links={pre_navigation_items} login_link={login_link}></PreNav>}
        </div>
      </div>

      <Headroom disableInlineStyles={true} pinStart={150}>
        <div className="relative z-50 bg-White">
          <div className="container mx-auto">
            {main_navigation_items && (
              <MainNav
                links={main_navigation_items}
                logo={logo}
                cta={primary_cta}
                is_search_enabled={is_search_enabled}
                search_link={search_link}
                search_bar_placeholder={search_bar_placeholder}
                search_bar_button_label={search_bar_button_label}
                toggleSideNav={toggleSideNav}
              ></MainNav>
            )}
          </div>
        </div>
      </Headroom>

      <div className="bg-White">
        <div className="container mx-auto">
          {main_navigation_items && pre_navigation_items && (
            <SideNav
              toggleSideNav={toggleSideNav}
              isActive={sideNav}
              pre_navigation_links={pre_navigation_items}
              login_link={login_link}
              main_navigation_links={main_navigation_items}
            ></SideNav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
