import React, { useEffect, useState } from "react"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import SecondaryActionCardItem from "./SecondaryActionCardItem.molecule"
import PrimaryActionCardItem from "./PrimaryActionCardItem.molecule"
import Edit from "../../../assets/svgs/Edit.svg"

interface Group {}

export interface PrimaryActionCardProps {
  step_1_heading: string
  step_1_option_1_heading: string
  step_1_option_1_icon: StoryblokAsset
  step_1_option_1_icon_active: StoryblokAsset
  step_1_option_2_heading: string
  step_1_option_2_icon: StoryblokAsset
  step_1_option_2_icon_active: StoryblokAsset
  isActive: boolean
  setActiveCard: (state: number) => void
  setSelectedOption: (state: number) => void
  selectedOption: number
}

const PrimaryActionCard: React.FC<PrimaryActionCardProps> = ({
  step_1_heading,
  step_1_option_1_heading,
  step_1_option_1_icon,
  step_1_option_1_icon_active,
  step_1_option_2_heading,
  step_1_option_2_icon,
  step_1_option_2_icon_active,
  isActive,
  setActiveCard,
  setSelectedOption,
  selectedOption,
}) => {
  return (
    <div
      className={`bg-SP7 p-6 sm:p-8 md:p-12 rounded-[20px] transition-height duration-150 delay-150 ${
        isActive
          ? "min-h-[617.3px] lg:min-h-[460.3px]"
          : "min-h-[94px] md:min-h-[141px] cursor-pointer"
      }`}
      onClick={e => {
        !isActive && setActiveCard(1)
      }}
    >
      <div className="flex justify-between">
        <p className="text-xl md:text-3xl text-body font-extrabold">
          1: {step_1_heading}
        </p>
        <Edit
          className={`transition-all duration-300 w-7 h-7 md:w-10 md:h-10 ${
            isActive ? "opacity-0" : "opacity-1"
          }`}
        />
      </div>

      {isActive ? (
        <div
          className={`grid grid-cols-10 gap-6 pt-8 md:pt-12  ${
            isActive
              ? "opacity-1 duration-300 delay-300"
              : "opacity-0 duration-150"
          }`}
        >
          <div className="col-span-10 col-start-1 md:col-span-8 md:col-start-2 lg:col-span-4 lg:col-start-2">
            <PrimaryActionCardItem
              heading={step_1_option_1_heading}
              icon={step_1_option_1_icon}
              activeIcon={step_1_option_1_icon_active}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              setActiveCard={setActiveCard}
              cardItemOption={1}
            />
          </div>
          <div className="col-span-10 col-start-1 md:col-span-8 md:col-start-2 lg:col-span-4 lg:col-start-6">
            <PrimaryActionCardItem
              heading={step_1_option_2_heading}
              icon={step_1_option_2_icon}
              activeIcon={step_1_option_2_icon_active}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              setActiveCard={setActiveCard}
              cardItemOption={2}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PrimaryActionCard
