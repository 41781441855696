import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import DynamicComponent from '../DynamicComponent/DynamicComponent.component';
import BreakpointChecker from '../BreakpointChecker/BreakpointChecker.util';
import renderBloks from '../../../utils/renderBloks.util';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import { layoutClassNames } from '../../../styles/ClassNames/global.classes';
import LayoutContext from '../../../contexts/LayoutContext';
import BreadcrumbBar from '../../bloks/BreadcrumbBar/BreadcrumbBar';

interface LayoutProps {
  // children: Blok[][] | undefined;
  countdown: JSX.Element | undefined;
  bloks: {
    page_hero: Blok[];
    page_body: Blok[];
    page_footer: Blok[];
  };
  breadcrumb_override?: string;
  hasBackground?: boolean;
}

const getBlok = (content: any): Blok | undefined => {
  if (content && content?.edges && content?.edges.length > 0) {
    const blok = content?.edges?.filter((edge: any) =>
      edge.node.full_slug.includes(process.env.GATSBY_DOMAIN_FOLDER),
    )[0]?.node?.content;
    if (blok) {
      return JSON.parse(blok);
    }
    return undefined;
  }
  return undefined;
};

const Layout: React.FC<LayoutProps> = ({ children, countdown, bloks, breadcrumb_override, hasBackground }) => {
  const { header, footer, newsletter } = useStaticQuery(graphql`
    {
      header: allStoryblokEntry(filter: { field_component: { eq: "Header" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      footer: allStoryblokEntry(filter: { field_component: { eq: "Footer" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      newsletter: allStoryblokEntry(filter: { field_component: { eq: "Newsletter" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `);

  const headerBlok = getBlok(header);
  const footerBlok = getBlok(footer);
  const newsletterBlok = getBlok(newsletter);
  const { page_hero, page_body, page_footer } = bloks;
  const breadcrumbs = useContext(LayoutContext);

  return (
    <div className={layoutClassNames}>
      <BreakpointChecker />
      {headerBlok && <DynamicComponent blok={headerBlok} />}
      <main>
        {renderBloks(page_hero, countdown)}
        {breadcrumbs && (
          <BreadcrumbBar
            {...breadcrumbs}
            breadcrumb_override={breadcrumb_override}
            hasBackground={Boolean(hasBackground)}
          />
        )}
        {renderBloks(page_body)}
        {renderBloks(page_footer)}
      </main>
      {newsletterBlok && <DynamicComponent blok={newsletterBlok} />}
      {footerBlok && <DynamicComponent blok={footerBlok} />}
    </div>
  );
};

export default Layout;
