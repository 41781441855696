import React, { useEffect } from 'react';

import { ButtonProps } from '../../../interfaces/Button.interface';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Button from '../../atoms/Button/Button.component';
import Image from '../../atoms/Image/Image.atom';
import moment from 'moment';

interface CountdownProps {
  icon: StoryblokAsset;
  heading: string;
  subheading: string;
  datetime: string;
  cta: ButtonProps[];
}
const Countdown: React.FC<CountdownProps> = ({ icon, heading, subheading, datetime, cta }) => {
  /*--
    Assume that the datetime is in the 
    format of YYYY-MM-DD HH:mm and UK 
    timezone - Convert to moment object
  --*/
  const eventDate = moment(datetime, 'YYYY-MM-DD HH:mm');

  /*--
    Create some state to hold the time 
    values
    --*/
  const [time, setTime] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  /*--
    Create a function to update the time 
    values
  --*/
  const updateTime = () => {
    const now = moment();
    const diff = eventDate.diff(now);
    var duration = moment.duration(diff);

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setTime({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  /*--
    Create a function to update the time 
    values every second
  --*/
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gradient-to-r !from-sherpa-red-main !to-sherpa-red-300 from-Primary-900 to-SP6">
      <div
        className="container mx-auto 
      flex flex-col md:flex-row
      md:items-center
      gap-6
      justify-between
      py-4
      "
      >
        <div className="flex items-center gap-3">
          <div className="w-20 h-20">
            <Image image={icon} maxWidth={70} maxHeight={70} />
          </div>
          <div className="flex flex-col">
            <div className="text-SP1 text-lg font-semibold">{heading}</div>
            <div className="text-White text-sm font-semibold">{subheading}</div>
          </div>
        </div>
        <div className="text-White text-center flex justify-between">
          <div className="p-3">
            <div className="text-2xl font-extrabold">{time.days}</div>
            <div className="text-sm">Days</div>
          </div>
          <div className="p-3 text-2xl font-extrabold">:</div>
          <div className="p-3">
            <div className="text-2xl font-extrabold">{time.hours}</div>
            <div className="text-sm">Hours</div>
          </div>
          <div className="p-3 text-2xl font-extrabold">:</div>
          <div className="p-3">
            <div className="text-2xl font-extrabold">{time.minutes}</div>
            <div className="text-sm">Minutes</div>
          </div>
          {/* Seconds For Testing */}
          {/* <div className="p-3 text-2xl font-extrabold">:</div>
          <div className="p-3">
            <div className="text-2xl font-extrabold">{time.seconds}</div>
            <div className="text-sm">Seconds</div>
          </div> */}
          {/* End Seconds For Testing */}
        </div>
        <div>
          {cta && !!cta.length && cta[0].label && cta[0].link && (
            <Button
              link={cta[0].link}
              label={cta[0].label}
              type={ButtonTypes.PRIMARY}
              size={ButtonSizeTypes.LG}
              className={`w-full`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Countdown;
