import React from 'react';

export interface Breadcrumb {
  slug: string;
  label: string;
}

export interface Breadcrumbs {
  breadcrumbs: Breadcrumb[];
  label: string;
}

const LayoutContext = React.createContext<Breadcrumbs>({
  breadcrumbs: [],
  label: '',
});

export default LayoutContext;
