import React, { Dispatch } from "react"
import { JobSearchFilterItem } from "../components/bloks/JobSearchFilterAndResults/JobSearchFilterAndResults.blok"
import { IFilteredAd } from "../services/jobAdder.service"

interface JobPageContext {
  searchBarInputValue: string
  filteredJobs: IFilteredAd[][] | [],
  jobsLoading: boolean
  numberOfJobsPerPage: number
  currentPageIndex: number
  totalNumberOfJobs: number | null
  selectedFilters: JobSearchFilterItem[]
  updateSearchBarInputValue: (value: string) => void
  updateFilteredJobs: Dispatch<IFilteredAd[][]>
  updateCurrentPageIndex: (offset: number, limit: number) => void
  updateSelectedFilters: (selectedFilters: JobSearchFilterItem[]) => void
  reset?: () => void
}

const JobPageContext = React.createContext<JobPageContext>({
  searchBarInputValue: "",
  filteredJobs: [],
  jobsLoading: false,
  numberOfJobsPerPage: 5000,
  currentPageIndex: 0,
  selectedFilters: [],
  updateSelectedFilters() {},
  updateSearchBarInputValue() {},
  updateFilteredJobs() {},
  updateCurrentPageIndex() { },
  totalNumberOfJobs: 0,
  reset() {},
})

export default JobPageContext
