import React, { useEffect, useRef } from 'react';

/*-- contexts --*/
import { Venue } from '../../../contexts/VenuesContext';

/*-- utils --*/
import isWindowInExistence from '../../../utils/isWindowInExistence.util';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

interface VenueAvailabilityCheckerProps extends BlokWithBorderIcons {
  venue: Venue;
  heading: string;
  iframe_link: string;
}

const VenueAvailabilityChecker: React.FC<VenueAvailabilityCheckerProps> = ({
  venue,
  heading,
  iframe_link,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  /*-- useRef --*/
  const iFrameRef = useRef(null);

  /*-- useEffect --*/
  useEffect(() => {
    if (isWindowInExistence()) {
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
      var eventer = window[eventMethod];
      eventer(messageEvent, function (e: any) {
        var data = e && e.data && e.data ? e.data : null;
        if (data && data.method == 'resize') {
          if (data.height > 400 && iFrameRef?.current) {
            iFrameRef.current.style.height = `${data.height}px`;
          }
        }
      });
    }
  }, []);

  return (
    <>
      {(venue?.links?.availabiltyLink || iframe_link) && heading && (
        <section className="bg-Primary-900" id="venue-availability-checker">
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className="container mx-auto py-12 md:py-16 lg:py-20">
              <p className="font-extrabold text-2xl md:text-3xl xl:text-4xl text-center text-White pb-8 md:pb-12 lg:pb-16">
                {heading}
              </p>
              <iframe
                ref={iFrameRef}
                src={venue?.links?.availabiltyLink || iframe_link}
                id="frmMBAvailableActivities"
                frameBorder="0"
                className="w-full h-[37rem] sm:h-[35rem] md:h-[36rem] lg:h-[23.5rem] xl:h-[22rem] min-h-[400px]"
              ></iframe>
            </div>
          </BorderIconsFactory>
        </section>
      )}
    </>
  );
};

export default VenueAvailabilityChecker;
