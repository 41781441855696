import React from "react"

const QuoteIconRight: React.FC = () => {
  return (
    <svg
      width="124"
      height="200"
      viewBox="0 0 124 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7733 46.0149H87.9104C100.349 46.0149 110.418 35.9458 110.418 23.5075C110.418 11.0691 100.349 1 87.9104 1H78.2617C65.8233 1 55.7543 11.0691 55.7543 23.5075V46.0149H55.7733Z"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.8281 17.3359H74.3647"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.8281 28.1895H74.3647"
        stroke="#845EC3"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.1757 34.2646H75.8245C88.2628 34.2646 98.332 44.3338 98.332 56.7721V79.2796H66.1949C53.7565 79.2796 43.6873 69.2105 43.6873 56.7721C43.6873 44.3529 53.7565 34.2646 66.1757 34.2646ZM80.3145 59.7145C81.9385 59.7145 83.257 58.3962 83.257 56.7721C83.257 55.1481 81.9385 53.8297 80.3145 53.8297C78.6904 53.8297 77.3721 55.1481 77.3721 56.7721C77.3721 58.3962 78.6904 59.7145 80.3145 59.7145ZM71.0096 59.7145C72.6337 59.7145 73.9521 58.3962 73.9521 56.7721C73.9521 55.1481 72.6337 53.8297 71.0096 53.8297C69.3856 53.8297 68.0673 55.1481 68.0673 56.7721C68.0482 58.3962 69.3665 59.7145 71.0096 59.7145ZM61.7048 59.7145C63.3288 59.7145 64.6472 58.3962 64.6472 56.7721C64.6472 55.1481 63.3288 53.8297 61.7048 53.8297C60.0807 53.8297 58.7624 55.1481 58.7624 56.7721C58.7433 58.3962 60.0807 59.7145 61.7048 59.7145Z"
        fill="#FF5E67"
      />
      <path
        d="M109.039 83.2529C118.841 93.3221 124.687 108.13 122.567 122.211C120.541 135.662 109.861 147.833 96.0849 149.916C81.4493 152.132 64.8075 139.961 66.5844 124.16C67.7691 113.556 79.7106 110.289 88.6907 112.849C97.2122 115.275 104.282 121.772 108.371 129.644C116.911 146.171 110.51 165.793 98.9891 179.11C85.4235 194.816 64.0434 201.331 43.7331 198.694C27.0723 196.535 11.5578 187.976 0.418704 175.499"
        stroke="#FF5E67"
        stroke-width="0.6565"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default QuoteIconRight
