import React from "react"

interface ChevronDownProps {
  className?: string
  rotateOnHover?: boolean
  rotate?: boolean
}
const ChevronDown: React.FC<ChevronDownProps> = ({
  className,
  rotateOnHover,
  rotate,
}) => {
  return (
    <svg
      width="12"
      height="8"
      className={`transfrom fill-current ${className} ${
        rotateOnHover && "group-hover:rotate-180"
      } ${rotate && "rotate-180"}`}
    >
      <path d="M1.41.59 6 5.17 10.59.59 12 2 6 8 0 2 1.41.59Z" />
    </svg>
  )
}

export default ChevronDown
