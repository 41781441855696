import moment from "moment"

const getFormattedDate = (
  dateToFormat: string,
  format: string = "DD/MM/YYYY"
): string => {
  return moment(dateToFormat).format(format)
}

export default getFormattedDate
