import React from 'react';
import classNames from 'classnames';
import { BackgroundThemeTypes } from '../../../interfaces/enums/BackgroundThemeTypes.enum';
import { BackgroundTextureTypes } from '../../../interfaces/enums/BackgroundTextureTypes.enum';
import BackgroundTear1 from '../../../assets/svgs/BackgroundTear1.svg';
import BackgroundTear2 from '../../../assets/svgs/BackgroundTear2.svg';

interface BackgroundTextureProps {
  type: string;
  svg?: React.ReactNode;
  children: React.ReactNode;
  svgClassName?: string;
  parentClassName?: boolean;
  theme?: string;
}

const BackgroundTexture: React.FC<BackgroundTextureProps> = ({
  type,
  svg,
  children,
  svgClassName = '',
  parentClassName = '',
  theme,
}) => {
  const ParentClasses = classNames(
    `h-auto absolute -z-10 left-[50%] top-0 -translate-x-[50%] ${parentClassName}`,
    {
      'min-w-[700px] w-full md:w-1200px lg:w-full 2xl:w-full':
        type === BackgroundTextureTypes.PRIMARY_TEAR || type === BackgroundTextureTypes.SECONDARY_TEAR,
    },
    {
      'lg:-top-8 xl:-top-10 2xl:-top-14 3xl:-top-20': type === BackgroundTextureTypes.PRIMARY_TEAR,
    },
    {
      'lg:-top-0 xl:-top-4 2xl:-top-6 3xl:-top-10': type === BackgroundTextureTypes.SECONDARY_TEAR,
    },
  );
  const SVGClasses = classNames(`${svgClassName}`, {
    '!text-sherpa-blue-50 text-Background-Texture-Primary':
      type === BackgroundTextureTypes.PRIMARY_TEAR || type === BackgroundTextureTypes.SECONDARY_TEAR,
  });

  return (
    <div className="w-full h-full relative">
      {theme !== BackgroundThemeTypes.NONE && (
        <div className={ParentClasses}>
          {type === BackgroundTextureTypes.PRIMARY_TEAR && <BackgroundTear1 className={SVGClasses} />}
          {type === BackgroundTextureTypes.SECONDARY_TEAR && <BackgroundTear2 className={SVGClasses} />}
        </div>
      )}
      {children}
    </div>
  );
};

export default BackgroundTexture;
