import React from 'react';
import { FAQsSearchHeroBannerProps } from '../FAQsSearchProps.interface';
import { SearchBox, useInstantSearch } from 'react-instantsearch-hooks-web';
import { valueExists } from '../../../../utils/valueExists/valueExists.util';
import isEmptyString from '../../../../utils/isEmptyString/isEmptyString.util';

const FAQsSearchHeroBanner: React.FC<FAQsSearchHeroBannerProps> = ({
  title,
  description,
  is_search_functionality_enabled,
  input_placeholder,
}) => {
  const NUMBER_OF_RESULTS_PLACEHOLDER: string = '<number-of-results-found>';

  const {
    results: { query, nbHits },
  } = useInstantSearch();

  const getNumberOfResultsFound = (): string => {
    return `${nbHits}` ?? '0';
  };

  const isHeroBannerRequired = (): boolean => {
    return isTopBarRequired() || isSearchBarRequired();
  };

  const isTopBarRequired = (): boolean => {
    return isTitleRequired() || isDescriptionRequired();
  };

  const isTitleRequired = (): boolean => {
    return valueExists(title) && !isEmptyString(title);
  };

  const getFormattedTitle = (): JSX.Element => {
    return (
      <h1 className="font-extrabold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl">
        {title.replaceAll(NUMBER_OF_RESULTS_PLACEHOLDER, getNumberOfResultsFound())}
      </h1>
    );
  };

  const isDescriptionRequired = (): boolean => {
    return valueExists(description) && !isEmptyString(description);
  };

  const getFormattedDescription = (): JSX.Element => {
    const descriptionTextSplitIntoIndividualWords: string[] = description.split(' ');
    return (
      <p className="text-lg font-semibold">
        {descriptionTextSplitIntoIndividualWords.map((word: string) =>
          word === NUMBER_OF_RESULTS_PLACEHOLDER ? (
            <span className="text-SP1">{getNumberOfResultsFound()} </span>
          ) : (
            `${word} `
          ),
        )}
      </p>
    );
  };

  const isSearchBarRequired = (): boolean => {
    return is_search_functionality_enabled;
  };

  return (
    <>
      {isHeroBannerRequired() && (
        <section className="bg-gradient-to-r !from-sherpa-red-main !to-sherpa-red-300 from-Primary-900 to-SP6">
          <div className="container mx-auto pt-12 pb-10 space-y-6 grid grid-cols-2 gap-x-6 md:pt-16 md:pb-12 md:grid-cols-12 xl:pt-20 xl:pb-16 2xl:pt-24">
            {isTopBarRequired() && (
              <div className="space-y-4 text-White text-center col-span-2 md:col-span-8 md:col-start-3">
                {isTitleRequired() && getFormattedTitle()}
                {isDescriptionRequired() && getFormattedDescription()}
              </div>
            )}
            {isSearchBarRequired() && (
              <SearchBox
                placeholder={input_placeholder}
                classNames={{
                  root: 'border-b border-b-SP1 pb-2 col-span-2 md:col-span-8 md:col-start-3',
                  form: 'w-full',
                  input:
                    'w-full border-l border-l-SP1 bg-Transparent py-2 px-3 font-semibold placeholder-White text-SP1 focus:outline-none',
                  submit: 'hidden',
                  submitIcon: 'hidden',
                  reset: 'hidden hover:hidden',
                  resetIcon: 'hidden hover:hidden',
                }}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default FAQsSearchHeroBanner;
