import React, { useContext, useState } from "react"
import classNames from "classnames"
import Tick from "../../../assets/svgs/Tick.svg"
import Image from "../../atoms/Image/Image.atom"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"

interface FilterItemProps {
  name: string
  icon: string
  activeFilters: string[]
  setFilters: (state: any) => void
}

const FilterItem: React.FC<FilterItemProps> = ({
  name,
  icon,
  activeFilters,
  setFilters,
}) => {
  const isActive = activeFilters.includes(name.toLowerCase())

  const checkboxButtonClassNames = classNames(
    "border-2 border-Secondary-300 rounded-sm w-3.5 h-3.5 p-[1px] flex items-center justify-center",
    {
      "bg-Secondary-300": isActive,
    }
  )

  const handleFilterToggleDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "venueFilter",
      filterName: name,
      filterSelected: isActive ? "Not Ticked" : "Ticked",
    })
  }

  return (
    <li className="flex items-center justify-between">
      <div className="flex items-center justify-start space-x-4">
        <div className="w-10 h-10">
          <Image imageUrl={icon} />
        </div>
        <p className="text-Body font-bold capitalize">{name}</p>
      </div>
      <button
        className={checkboxButtonClassNames}
        onClick={() => {
          if (isActive) {
            const updatedFilters = activeFilters.filter(
              filter => filter !== name.toLowerCase()
            )
            setQueryParameters(updatedFilters, QueryParams.FILTERS)
          } else {
            const updatedFilters = [...activeFilters, name]
            setQueryParameters(updatedFilters, QueryParams.FILTERS)
          }
        }}
      >
        {isActive && <Tick className="fill-White" />}
      </button>
    </li>
  )
}

export default FilterItem
