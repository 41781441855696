import classNames from "classnames"
import React, { useContext } from "react"
import MobileFilterToggle from "../../../../assets/svgs/MobileFilterToggle.svg"
import FindUsPageContext from "../../../../contexts/FindUsPage.context"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"

interface FindUsResultsTitleAndFiltersBarProps {
  title_and_filters_bar_title: string
  clear_all_button_label: string
  filterButtonClicked: () => void
}

const FindUsResultsTitleAndFiltersBar: React.FC<
  FindUsResultsTitleAndFiltersBarProps
> = ({
  title_and_filters_bar_title,
  clear_all_button_label,
  filterButtonClicked,
}) => {
  const { selectedFilters, updateSelectedFilters } =
    useContext(FindUsPageContext)

  const isSelectedFiltersEmpty = (): boolean => {
    return selectedFilters.length === 0
  }

  const findUsResultsTitleAndFilterBarClassNames = classNames(
    "pt-6 pb-0 space-y-6",
    {
      "xl:pb-0 xl:space-y-0": isSelectedFiltersEmpty(),
    }
  )

  return (
    <div className={findUsResultsTitleAndFilterBarClassNames}>
      {title_and_filters_bar_title && (
        <h3 className="font-extrabold text-3xl text-Primary-900">
          {title_and_filters_bar_title}
        </h3>
      )}
      <div className="flex items-center justify-start">
        <button
          onClick={() => filterButtonClicked()}
          className="mr-4 xl:hidden xl:mr-0"
        >
          <MobileFilterToggle
            numberOfSelectedFilters={selectedFilters.length}
          />
        </button>

        {!isSelectedFiltersEmpty() && (
          <>
            <ul className="flex items-center justify-start flex-nowrap space-x-4 overflow-x-auto overflow-y-hidden">
              {selectedFilters.map((selected_filter_option: FilterableItem) => (
                <li className="rounded-default bg-Secondary-100 p-2 text-sm text-Primary-900 font-semibold whitespace-nowrap">
                  {selected_filter_option.name}
                </li>
              ))}
            </ul>
            <button
              className="font-bold text-SP4 uppercase whitespace-nowrap ml-4"
              onClick={() => updateSelectedFilters([])}
            >
              {clear_all_button_label}
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default FindUsResultsTitleAndFiltersBar
