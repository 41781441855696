import { graphql, useStaticQuery } from "gatsby"

const getStoryblokWrapperEntries = () =>
  useStaticQuery(graphql`
    {
      header: allStoryblokEntry(filter: { field_component: { eq: "Header" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      footer: allStoryblokEntry(filter: { field_component: { eq: "Footer" } }) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      newsletter: allStoryblokEntry(
        filter: { field_component: { eq: "Newsletter" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)

export default getStoryblokWrapperEntries
