import React from "react"
import classNames from "classnames"
import CustomLink from "../CustomLink/CustomLink.atom"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { SocialTypes } from "../../../interfaces/enums/SocialTypes.enum"
import Linkedin from "../../../assets/svgs/Linkedin.svg"
import Instagram from "../../../assets/svgs/Instagram.svg"
import Twitter from "../../../assets/svgs/Twitter.svg"
import Facebook from "../../../assets/svgs/Facebook.svg"
import Tiktok from "../../../assets/svgs/Tiktok.svg"
import Youtube from "../../../assets/svgs/Youtube.svg"

interface SocialButtonProps {
  type: ButtonTypes
  link: string
  styles?: string
  background?: string
  size?: string
}

const SocialButton: React.FC<SocialButtonProps> = ({
  type,
  styles,
  size = "w-[64px] h-[64px]",
  link,
}) => {
  const SocialButtonClasses = classNames(
    `${styles} ${size} rounded-full flex items-center justify-center group transition-all`,
    {
      "bg-White hover:bg-Primary-900": type === ButtonTypes.PRIMARY,
    }
  )
  const SVGClasses = classNames(
    `text-Primary-900 group-hover:text-White transition-all`
  )
  const SecondarySVGFillClasses = classNames(
    `text-White group-hover:text-Primary-900 transition-all`
  )

  const SVG: React.ReactNode = link.includes(SocialTypes.LINKEDIN) ? (
    <Linkedin className={SVGClasses}></Linkedin>
  ) : link.includes(SocialTypes.INSTAGRAM) ? (
    <Instagram
      primaryFill={SVGClasses}
      secondaryFill={SecondarySVGFillClasses}
    ></Instagram>
  ) : link.includes(SocialTypes.TWITTER) ? (
    <Twitter className={SVGClasses}></Twitter>
  ) : link.includes(SocialTypes.FACEBOOK) ? (
    <Facebook className={SVGClasses}></Facebook>
  ) : link.includes(SocialTypes.TIKTOK) ? (
    <Tiktok className={SVGClasses}></Tiktok>
  ) : (
    link.includes(SocialTypes.YOUTUBE) && (
      <Youtube
        primaryFill={SVGClasses}
        secondaryFill={SecondarySVGFillClasses}
      ></Youtube>
    )
  )

  return (
    <>
      {link && (
        <CustomLink
          url={link}
          className={SocialButtonClasses}
          aria-label={"social-button"}
        >
          {SVG}
        </CustomLink>
      )}
    </>
  )
}

export default SocialButton
