import React, { useState, useContext } from "react"
import Location from "../../../assets/svgs/Location.svg"
import UserLocation from "../../../assets/svgs/UserLocation.svg"
import HeroBannerSearchBarConfigProps from "../../../interfaces/HeroBannerSearchBarConfig.interface"
import FindUsPageContext from "../../../contexts/FindUsPage.context"
import Button from "../../atoms/Button/Button.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import getQueryParameters from "../../../utils/getQueryParameters.util"
import VenuesContext, { Venue } from "../../../contexts/VenuesContext"
import ChevronDown from "../../../assets/svgs/ChevronDown.svg"

interface VenueTermsHeroSearchBarProps {
  button_label: string
  input_placeholder: string
  selectedVenue: Venue | null
  setSelectedVenue: (state: Venue) => void
}

const VenueTermsHeroSearchBar: React.FC<VenueTermsHeroSearchBarProps> = ({
  button_label,
  input_placeholder,
  selectedVenue,
  setSelectedVenue,
}) => {
  const venueData = useContext(VenuesContext)
  const [displayList, setDisplayList] = useState<boolean>(false)
  const [userSearch, setUserSearch] = useState<string>("")
  const [userIsSelectingVenue, setUserIsSelectingVenue] =
    useState<boolean>(false)

  const handleKeyPress = (e: any) => {
    if (e.key == "Enter") {
      alert("SHOW VENUE TERMS")
    }
  }

  const venueList = venueData
    .filter(venue =>
      venue.name.toLowerCase().includes(userSearch.toLowerCase())
    )
    .sort(function (a, b) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    })

  return (
    <>
      <div className="col-span-8 col-start-3 lg:col-span-6 lg:col-start-4 relative md:flex items-center justify-center space-y-4 md:space-y-0 md:drop-shadow-lg">
        <div className="relative w-full flex">
          <Location className="text-Secondary-300 absolute left-7 top-0 bottom-0 my-auto z-[1]" />
          <input
            onChange={e => setUserSearch(e.target.value)}
            value={userSearch}
            type="text"
            placeholder={input_placeholder || "Search or select from list"}
            onKeyDown={e => handleKeyPress(e)}
            onFocus={() => setDisplayList(true)}
            onBlur={() => !userIsSelectingVenue && setDisplayList(false)}
            className="text-sm md:text-base 2xl:text-lg py-3 md:py-4 lg:py-5 pl-14 pr-14 leading-6 focus:ring-0 focus:outline-none rounded-full w-full placeholder-Primary-900"
          />
          <ChevronDown className="absolute right-4 top-0 bottom-0 m-auto text-Primary-900 pointer-events-none" />
        </div>
        {displayList && !!venueList.length && (
          <div className="absolute left-3 md:left-7 right-3 md:right-7 top-[1.8rem] md:top-[3.5rem] lg:top-[4rem]">
            <ul
              className="bg-White py-4 text-Primary-900 font-semibold max-h-80 overflow-y-scroll rounded-b-[16px]"
              onMouseEnter={() => setUserIsSelectingVenue(true)}
              onMouseLeave={() => setUserIsSelectingVenue(false)}
            >
              {venueList.map(venue => (
                <li
                  className="venueListItem cursor-pointer w-full hover:bg-Primary-900 hover:text-White py-2 px-4 focus:bg-Primary-900 focus:text-White"
                  onClick={() => {
                    setUserSearch(venue.name)
                    setSelectedVenue(venue)
                    setDisplayList(false)
                  }}
                >
                  {venue.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export default VenueTermsHeroSearchBar
