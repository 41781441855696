import React, {
  useState,
  useEffect,
  RefObject,
  createRef,
  useMemo,
} from "react"
import { StoryblokAsset } from "../interfaces/Storyblok/StoryblokAsset.interface"

interface Image {
  image: StoryblokAsset
}

export const useDynamicBackground = (images: Image[], delay: number): any => {
  const numberOfBackgroundImages = images.length
  const [counter, setCounter] = useState(numberOfBackgroundImages)
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)

  const imageRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => images.map(() => createRef()),
    [images]
  )

  const changeBackgroundImage = (): void =>
    activeImageIndex < numberOfBackgroundImages - 1
      ? setActiveImageIndex(activeImageIndex + 1)
      : setActiveImageIndex(0)

  useEffect(() => {
    const timer: void | NodeJS.Timer =
      counter > 0
        ? setInterval(() => setCounter(counter - 1), delay || 2000)
        : (() => {
            setCounter(numberOfBackgroundImages)
            changeBackgroundImage()
          })()
    if (typeof timer === "number") {
      return () => clearInterval(timer)
    }
  }, [counter])

  return {
    activeImageIndex,
    imageRefs,
  }
}
