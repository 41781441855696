import React, { useEffect, useState } from "react";
import DotsSpinner from "../../../assets/spinners/dots.spinner";
import Blok from "../../../interfaces/Storyblok/Blok.interface";
import { getAd, IFilteredAd, showSalaryOrPerHour } from "../../../services/jobAdder.service";
import Card from "../../molecules/Card/Card.component";
import JobApplicationSupport from "../JobApplicationSupport/JobApplicationSupport.blok";
import ShareOnSocials from "../ShareOnSocials/ShareOnSocials";
import JobDescription from "./JobDescription/JobDescription.blok";
import JobSummary from "./JobSummary/JobSummary.blok";

export interface JobSummaryItem extends Blok {
  summaryItemLabel: string
  summaryItemValue: string
}

export interface SocialProps {
  social_options: string[]
}

export interface SupportProps {
  Contact_information: string[]
}

interface JobInformationProps {
  cta_label: string;
  socials: SocialProps[]
  support: SupportProps[]
}

const JobInformation: React.FC<JobInformationProps> = ({ cta_label, socials, support }: JobInformationProps) => {
  const [isJobLoading, setIsJobLoading] = useState<boolean>(true);
  const [ad, setAd] = useState<IFilteredAd | null>(null);
  const params = typeof window !== "undefined" && new URLSearchParams((window as Window).location.search);
  const adId = typeof window !== "undefined" && (params as URLSearchParams).get("adid");
  const [email] = support[0].Contact_information;

  const retrieveAd = async (adId: number) => {
    setIsJobLoading(true);
    const retrievedAd = await getAd(adId);
    setAd(retrievedAd);

    setIsJobLoading(false);
  }

  useEffect(() => {
    if (adId && !ad) {
      const fetchData = async () => {
        await retrieveAd(Number(adId));      
      }
  
      fetchData();
    }    
  }, [adId]);

 

  return (
    <section className="bg-SP7">
      <div className="container mx-auto gap-4 sm:gap-0 grid grid-cols-2 pt-16 pb-10 sm:grid-cols-12 sm:py-20">
        {isJobLoading && (<div className='flex items-center justify-center h-full w-full col-span-12'><DotsSpinner /></div>)}
        {!isJobLoading && (
          <>
            <aside className="col-span-12 sm:col-span-4">
              <Card heading="Job details" isExpandable={true}>
                {ad && (
                  <JobSummary applicationLink={ad.applicationLink} cta_label={cta_label} summaryItems={[
                    { summaryItemLabel: 'Title', summaryItemValue: ad.title },
                    { summaryItemLabel: 'Salary', summaryItemValue: showSalaryOrPerHour(ad.salary)},
                    { summaryItemLabel: 'Location', summaryItemValue: ad.countyBorough },
                    { summaryItemLabel: 'Contract Type', summaryItemValue: ad.contractType },
                    ] as JobSummaryItem[]}
                  />)}
              </Card>
            </aside>
      
            <div className="col-span-12 sm:col-span-7 sm:col-start-6">
              {ad && (
                <Card isExpandable={false}>      
                  <>
                    <JobDescription cta_label={cta_label} description={ad!.description!} title={ad.title} applicationLink={ad.applicationLink} />
                    <JobApplicationSupport email={email} />
                    <ShareOnSocials cta_label={cta_label} applicationLink={ad.applicationLink} socials={socials[0]!.social_options} />
                  </>
                </Card>
              )}
            </div>
          </>          
        )}
      </div>
    </section>
  )
}

export default JobInformation;