import React, { useState } from "react"
import SecondaryActionCard from "../molecules/LoginOrRegister/SecondaryActionCard.molecule"
import PrimaryActionCard from "../molecules/LoginOrRegister/PrimaryActionCard.molecule"
import { LoginOrRegisterProps } from "./LoginOrRegisterProps.interface"

const LoginOrRegister: React.FC<LoginOrRegisterProps> = ({
  page_title,
  ...props
}) => {
  const [activeCard, setActiveCard] = useState<number>(1)
  const [selectedOption, setSelectedOption] = useState<number>(0)

  return (
    <section className="pt-20 pb-32">
      <h1 className="text-5xl text-Primary-900 font-extrabold text-center pb-24">
        {page_title}
      </h1>
      <div className="grid grid-cols-12 space-y-12">
        <div className="col-span-10 col-start-2">
          <PrimaryActionCard
            {...props}
            isActive={activeCard === 1}
            setActiveCard={setActiveCard}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
        </div>
        <div className="col-span-10 col-start-2">
          <SecondaryActionCard
            {...props}
            isActive={activeCard === 2}
            setActiveCard={setActiveCard}
            activeCard={activeCard}
            selectedOption={selectedOption}
          />
        </div>
      </div>
    </section>
  )
}

export default LoginOrRegister
