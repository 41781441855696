import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import Placeholder from "../Placeholder/Placeholder.component"
import PropTypes from "prop-types"
import { getComponent } from "./DynamicComponentMap"
import { storyblokEditable } from "gatsby-source-storyblok"

export interface DynamicComponentProps {
  blok: Blok
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({ blok }) => {
  const Component = getComponent(blok.component)

  if (!Component) {
    return blok.component ? (
      <Placeholder componentName={blok.component} />
    ) : null
  }

  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <Component {...blok} />
    </div>
  )
}

DynamicComponent.propTypes = {
  blok: PropTypes.any,
}

export default DynamicComponent
