import React from "react"
import StoryblokHeadingAndRichText from "../../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import RichText from "../../../atoms/RichText/RichText.atom"

interface FindUsResultsTopBarProps {
  top_bar_content: StoryblokHeadingAndRichText
}

const FindUsResultsTopBar: React.FC<FindUsResultsTopBarProps> = ({
  top_bar_content,
}) => {
  return (
    <div className="border-b border-b-Body-Inactive border-dashed space-y-2 pb-6">
      {top_bar_content.heading && (
        <h3 className="text-lg font-semibold text-Primary-900">
          {top_bar_content.heading}
        </h3>
      )}
      {top_bar_content.rich_text && (
        <RichText content={top_bar_content.rich_text} />
      )}
    </div>
  )
}

export default FindUsResultsTopBar
