import React, { useState } from "react"
import Button from "../../atoms/Button/Button.component"
import classNames from "classnames"

// Types
import { ButtonProps } from "../../../interfaces/Button.interface"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"

// SVG
import Calendar from "../../../assets/svgs/Calendar.svg"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import { BorderIconsProps } from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

export interface SecondaryCardProps extends BorderIconsProps {
  heading: string
  date: string
  body_text: string
  cta: ButtonProps[]
  index: number
}
const SecondaryCard: React.FC<SecondaryCardProps> = ({
  heading,
  date,
  body_text,
  cta,
  index,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  return (
    <AnimateInComponent
      wrapperComponent={"div"}
      classNames={classNames(
        "w-full bg-White text-Primary-900 rounded-2xl transition-all duration-500 ease-in-out",
        {
          "opacity-0 translate-y-20": !didBecomeVisible,
          "delay-[200ms]": index === 1,
          "delay-[400ms]": index === 2,
          "delay-[600ms]": index === 3,
          "delay-[800ms]": index === 4,
          "delay-[1000ms]": index >= 5,
          "opacity-100 translate-y-0": didBecomeVisible,
        }
      )}
      didBecomeVisible={() => setDidBecomeVisible(true)}
    >
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="px-6 py-6 md:py-4 md:flex md:justify-between">
          <div className="pb-4 md:pb-0 flex-1">
            {heading && <p className="text-lg font-extrabold">{heading}</p>}
            {body_text && <p className="text-sm pr-16">{body_text}</p>}
          </div>
          <div className="md:flex items-center space-y-7 md:space-y-0 md:space-x-6">
            {date && (
              <div className="flex items-center space-x-2">
                <Calendar className="text-Secondary-300 scale-90" />
                <p className="text-sm font-semibold text-Body">{date}</p>
              </div>
            )}

            {!!cta.length && cta[0].label && cta[0].link?.cached_url && (
              <Button
                link={cta[0].link}
                label={cta[0].label}
                type={ButtonTypes.PRIMARY}
                size={ButtonSizeTypes.DEFAULT}
                className="w-full md:w-auto"
              ></Button>
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  )
}

export default SecondaryCard
