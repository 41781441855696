import React, { useContext } from "react"
import { getTemplate } from "./DynamicTemplateMap"
import StoryblokEntryTemplateContext from "../../../interfaces/Storyblok/StoryblokEntryTemplateContext.interface"
import Venues from "../../../interfaces/Airtable/Venues.interface"
import Banner from "../../bloks/Banner/Banner.blok"
import { graphql, useStaticQuery } from "gatsby"
import VenuesContext from "../../../contexts/VenuesContext"

interface DynamicTemplateProps {
  context: StoryblokEntryTemplateContext
}

const DynamicTemplate: React.FC<DynamicTemplateProps> = ({ context }) => {
  const field_component = context.field_component ?? "Page"

  const Template = getTemplate(field_component)

  const { countdowns, banners } = useStaticQuery(graphql`
    {
      countdowns: allStoryblokEntry(
        sort: { fields: id }
        filter: { field_component: { eq: "Countdown" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      banners: allStoryblokEntry(
        sort: { fields: id }
        filter: { field_component: { eq: "Banner" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)

  const getCountdownBlok = (content: any) => {
    const edges = content?.edges
    if (!edges) return null

    const domainAssociatedCountdownBlocks = edges.filter((edge: any) =>
      edge.node.full_slug.includes(process.env.GATSBY_DOMAIN_FOLDER)
    )

    const allCountdownBlocks = domainAssociatedCountdownBlocks.map(
      (edge: any) => JSON.parse(edge.node.content)
    )

    // Filter out countdowns that don't have this page uuid attached
    const countdownsForThisPageType = allCountdownBlocks.filter(
      (countdown: any) =>
        countdown.show_on_content_types.includes(field_component)
    )

    // Take the highest priority countdown
    const countdownBlok = countdownsForThisPageType.sort(
      (a: any, b: any) => a.priority - b.priority
    )[0]

    return countdownBlok
  }

  const countdownBlok = getCountdownBlok(countdowns)

  const getBannerBlok = (content: any) => {
    const edges = content?.edges
    if (!edges) return null

    const domainAssociatedBannerBlocks = edges.filter((edge: any) =>
      edge.node.full_slug.includes(process.env.GATSBY_DOMAIN_FOLDER)
    )

    const bannerBlocks = domainAssociatedBannerBlocks.map((edge: any) =>
      JSON.parse(edge.node.content)
    )

    // Filter out banners that don't have this page uuid attached
    const bannersForThisPageType = bannerBlocks.filter((banner: any) =>
      banner.show_on_content_types.includes(field_component)
    )

    // Take the highest priority banner
    const bannerBlok = bannersForThisPageType.sort(
      (a: any, b: any) => a.priority - b.priority
    )[0]

    return bannerBlok
  }

  const bannerBlok = getBannerBlok(banners)

  return (
    <>
      {bannerBlok && <Banner {...bannerBlok} />}

      <Template key={context.id} context={context} countdown={countdownBlok} />
    </>
  )
}

export default DynamicTemplate
