/*-- npm packages --*/
import React from 'react';
import classNames from 'classnames';

/*-- component bloks --*/
import SectionTitle from '../../bloks/SectionTitle/SectionTitle.blok';

/*-- component molecules --*/
import BackgroundIcons from '../../molecules/BackgroundIcons/BackgroundIcons.molecule';

/*-- component atoms --*/
import Image from '../../atoms/Image/Image.atom';
import Button from '../../atoms/Button/Button.component';

/*-- context --*/
import { Club, Service, Venue } from '../../../contexts/VenuesContext';

/*-- interfaces --*/
import VenueClubCard from '../../../interfaces/VenuePage/VenueClubCard.interface';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { BackgroundPositionTypes } from '../../../interfaces/enums/BackgroundPositionTypes.enum';
import { BreakpointTypes } from '../../../interfaces/enums/BreakpointTypes.enum';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';

/*-- utils --*/
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

interface VenuClubCardsProps extends BlokWithBorderIcons {
  venue: Venue;
  title: string;
  description: string;
  title_section_background_icon: StoryblokAsset;
  cards: VenueClubCard[];
  cards_section_background_icon: StoryblokAsset;
}

const getVenueClubCardsTitle = (title: string, venue: Venue): string => {
  const DYNAMIC_VENUE_NAME_INJECTOR: string = '<venue-name>';
  return title?.replaceAll(DYNAMIC_VENUE_NAME_INJECTOR, venue?.name || 'venue');
};

const getApplicableVenueServices = (venue: Venue, cards: VenueClubCard[]): Service[] => {
  if (venue?.services) {
    const venueServicesWithoutTypeDuplications: Service[] = getVenueServicesWithoutTypeDuplications(venue);
    const venueServicesThatHaveAvailableVenueElementsClubCard: Service[] =
      getVenueServicesThatHaveAvailableVenueElementsClubCard(venueServicesWithoutTypeDuplications, cards);
    return venueServicesThatHaveAvailableVenueElementsClubCard;
  } else {
    return [];
  }
};

/*--
    We are only interested in the service type, 
    and, as venues can have multiple services 
    that fall under the same service type, we 
    will end up with duplications. We do not want 
    this, so we will strip them out here
  --*/
const getVenueServicesWithoutTypeDuplications = (venue: Venue): Service[] => {
  if (venue?.services) {
    return venue?.services?.reduce((accumulator: Service[], current: Service) => {
      if (!accumulator.find((service: Service) => service.clubType === current.clubType)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  } else {
    return [];
  }
};

/*--
    Check, for every service that the venue has, 
    that we have a corresponding club card setup 
    in the global venue-elements VenueClubCards 
    blok. If we don't then we don't have any information 
    about how to present it, what theme it uses, 
    any background tear, icon etc, and so we can't 
    show it
  --*/
const getVenueServicesThatHaveAvailableVenueElementsClubCard = (
  services: Service[],
  cards: VenueClubCard[],
): Service[] => {
  if (services && cards?.length) {
    const availableVenueElementClubCardTypes: string[] = cards.map((venue_club_card: VenueClubCard) =>
      venue_club_card.airtable_club_type.toLowerCase(),
    );
    const venueServicesThatHaveAvailableVenueClubCard: Service[] = services.filter((service: Service) =>
      availableVenueElementClubCardTypes.includes(service.clubType.toLowerCase()),
    );
    return venueServicesThatHaveAvailableVenueClubCard;
  } else {
    return [];
  }
};

const configureVenueClubCard = (venue: Venue, service: Service, cards: VenueClubCard[]): JSX.Element => {
  /*-- 
      Get the club card corresponding to the applicable 
      club_type. This will get us all of our stylistic 
      instructions
    --*/
  const venueClubCard: VenueClubCard = cards.filter(
    (venue_club_card: VenueClubCard) =>
      venue_club_card.airtable_club_type.toLowerCase() === service.clubType.toLowerCase(),
  )[0];

  /*--
      Check if venue has specified any custom 
      properties that it wants to show instead of the 
      default values globally provided on the venueClubCard. 
      These are available via the 'venue.clubs' property
    --*/
  let venueServiceCardClubOverrides: Club | undefined = undefined;
  if (venue.clubs) {
    const matchingVenueClubsForServiceClubType: Club[] = venue.clubs.filter(
      (club: Club) => club.clubType.toLowerCase() === service.clubType.toLowerCase(),
    );
    if (matchingVenueClubsForServiceClubType) {
      venueServiceCardClubOverrides = matchingVenueClubsForServiceClubType[0];
    }
  }

  if (venueClubCard) {
    return (
      <li
        key={`${venueClubCard.airtable_club_type.replace(' ', '-').toLowerCase()}-card`}
        className={classNames(
          'col-start-1 col-span-12 xl:col-span-10 xl:col-start-2 3xl:col-span-8 3xl:col-start-3 rounded-7xl md:overflow-hidden',
          {
            'bg-Primary-900': venueClubCard.theme_new === 'DARK',
            'bg-SP6': venueClubCard.theme_new === 'LIGHT',
            '!bg-sherpa-red-main bg-Secondary-300': venueClubCard.theme_new === 'ALTERNATIVE',
            'bg-White text-Primary-900': venueClubCard.theme_new === 'WHITE',
            'text-White': venueClubCard.theme_new !== 'WHITE',
          },
        )}
      >
        <BorderIconsFactory
          type={BorderIconType.Card}
          border_icon_top_left={venueClubCard.border_icon_top_left}
          border_icon_top_right={venueClubCard.border_icon_top_right}
          border_icon_bottom_left={venueClubCard.border_icon_bottom_left}
          border_icon_bottom_right={venueClubCard.border_icon_bottom_right}
        >
          <div className="px-4 pt-4 pb-6 md:flex md:items-stretch md:pb-10 md:p-6 lg:p-8 2xl:p-10">
            {/*-- image --*/}
            <div className="md:mr-6 lg:mr-8 xl:mr-10 2xl:mr-16 md:min-w-[260px] lg:min-w-[340px] aspect-[16/9] md:aspect-[3/4] lg:aspect-square -mt-4 -mx-4 md:mx-0 md:-mt-0 pb-8 md:pb-0 drop-shadow-2xl md:drop-shadow-none">
              <div className="h-full overflow-hidden rounded-6xl md:rounded-2xl">
                {venueClubCard.image && venueClubCard.image.filename && (
                  <Image image={venueClubCard.image} objectFit="cover" />
                )}
              </div>
            </div>
            {/*-- copy --*/}
            <div>
              <div className="flex items-center pb-4 space-x-3">
                {/*-- icon --*/}
                {venueClubCard.icon && venueClubCard.icon.filename && (
                  <div className="w-10">
                    <Image image={venueClubCard.icon} objectFit="cover" />
                  </div>
                )}

                {/*-- title --*/}
                {(venueServiceCardClubOverrides?.name || venueClubCard.title) && (
                  <>
                    <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-extrabold">
                      {venueServiceCardClubOverrides?.name || venueClubCard.title}
                    </h2>
                    <div className="border-b-2 border-dashed"></div>
                  </>
                )}
              </div>

              {/*-- description --*/}
              {(venueServiceCardClubOverrides?.description || venueClubCard.fallback_description) && (
                <p className="text-base py-6">
                  {venueServiceCardClubOverrides?.description || venueClubCard.fallback_description}
                </p>
              )}

              {/*-- ctas --*/}
              {((venueClubCard.primary_cta_label &&
                venueClubCard.primary_cta_link &&
                venueClubCard.primary_cta_link.cached_url) ||
                (venueClubCard.secondary_cta_label &&
                  venueClubCard.secondary_cta_link &&
                  venueClubCard.secondary_cta_link.cached_url)) && (
                <div className="flex flex-col items-center gap-y-6 lg:flex-row lg:justify-start lg:gap-y-0 lg:gap-x-6">
                  {venueClubCard.primary_cta_label &&
                    venueClubCard.primary_cta_link &&
                    venueClubCard.primary_cta_link.cached_url && (
                      <Button
                        link={venueClubCard.primary_cta_link}
                        label={venueClubCard.primary_cta_label}
                        type={ButtonTypes.PRIMARY}
                        size={ButtonSizeTypes.LG}
                        className="w-full md:w-auto"
                      />
                    )}
                  {venueClubCard.secondary_cta_label &&
                    venueClubCard.secondary_cta_link &&
                    venueClubCard.secondary_cta_link.cached_url && (
                      <Button
                        link={venueClubCard.secondary_cta_link}
                        label={venueClubCard.secondary_cta_label}
                        type={ButtonTypes.SECONDARY}
                        size={ButtonSizeTypes.LG}
                        className={classNames('inline-block w-full md:w-auto mt-3 md:mt-0', {
                          'text-White': venueClubCard.theme !== 'white',
                          'text-Primary-900': venueClubCard.theme === 'white',
                        })}
                      ></Button>
                    )}
                </div>
              )}
            </div>
          </div>
        </BorderIconsFactory>
      </li>
    );
  } else {
    return <></>;
  }
};

const VenueClubCards: React.FC<VenuClubCardsProps> = ({
  venue,
  title,
  description,
  title_section_background_icon,
  cards,
  cards_section_background_icon,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  return (
    <>
      {cards && venue?.services && (
        <AnimateInComponent
          didBecomeVisible={() => {
            /*-- do we need this here? --*/
          }}
        >
          <section id="venue-club-cards">
            {(title || description) && (
              <SectionTitle
                section_title={getVenueClubCardsTitle(title, venue)}
                sub_text={description}
                icon={title_section_background_icon}
                theme={'white'}
              />
            )}
            <BorderIconsFactory
              type={BorderIconType.Blok}
              border_icon_top_left={border_icon_top_left}
              border_icon_top_right={border_icon_top_right}
              border_icon_bottom_left={border_icon_bottom_left}
              border_icon_bottom_right={border_icon_bottom_right}
            >
              <div className="container mx-auto pt-10 pb-16 lg:pb-20">
                <BackgroundIcons
                  type={BackgroundPositionTypes.BottomLeftToTopRight_OutsideX2}
                  visibleOn={BreakpointTypes.ThreeXL}
                  icon={cards_section_background_icon}
                  mirrorXLeft
                  mirrorYLeft
                >
                  <ul className="lg:grid grid-cols-12 lg:gap-x-6 auto-rows-max space-y-8">
                    {getApplicableVenueServices(venue, cards.reverse())
                      .map((service: Service) => configureVenueClubCard(venue, service, cards))
                      .reverse()}
                  </ul>
                </BackgroundIcons>
              </div>
            </BorderIconsFactory>
          </section>
        </AnimateInComponent>
      )}
    </>
  );
};

export default VenueClubCards;
