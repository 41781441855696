import React from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import PageIntroProps from "./PageIntroProps.interface"
import PageIntroGenerator from "./PageIntroGenerator.component"
import RichText from "../../atoms/RichText/RichText.atom"

interface PageIntroWithTextProps extends PageIntroProps {
  text: StoryblokRichtext
}

const PageIntroWithText: React.FC<PageIntroWithTextProps> = props => {
  const getRightColumn = (): JSX.Element => {
    return <RichText content={props.text} />
  }

  return (
    <PageIntroGenerator
      default_props={{ ...props }}
      top_title_text={props.heading}
      right_column={getRightColumn()}
    />
  )
}

export default PageIntroWithText
