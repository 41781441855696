import Coordinates from "../interfaces/Coordinates.interface"

const getLatLng = async (
  address: string
): Promise<{ coordinates: Coordinates } | undefined> => {
  const geocoder = new window.google.maps.Geocoder()

  return new Promise(resolve => {
    geocoder.geocode(
      {
        address: address,
        componentRestrictions: {
          country: process.env.GATSBY_COUNTRY_CODE || "GB",
        },
      },
      function (results, status) {
        if (status == "OK" && results) {
          resolve({
            coordinates: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
          })
        } else {
          resolve(undefined)
        }
      }
    )
  })
}

export default getLatLng
