import React from "react"
import { FAQsSearchPaginationBarProps } from "../FAQsSearchProps.interface"
import { Pagination } from "react-instantsearch-hooks-web"
import { valueExists } from "../../../../utils/valueExists/valueExists.util"
import isEmptyString from "../../../../utils/isEmptyString/isEmptyString.util"
import { useInstantSearch } from "react-instantsearch-hooks-web"

const FAQsSearchPaginationBar: React.FC<FAQsSearchPaginationBarProps> = ({
  max_results_per_page,
  summary_text,
}) => {
  const INDEX_OF_FIRST_RESULT_ON_PAGE_PLACEHOLDER: string =
    "<index-of-first-result-on-page>"
  const INDEX_OF_LAST_RESULT_ON_PAGE_PLACEHOLDER: string =
    "<index-of-last-result-on-page>"
  const NUMBER_OF_RESULTS_FOUND_PLACEHOLDER: string =
    "<number-of-results-found>"

  const {
    results: { query, page, nbHits, hits },
  } = useInstantSearch()

  const isPaginationBarRequired = (): boolean => {
    return nbHits > 0
  }

  const isSummaryTextRequired = (): boolean => {
    return valueExists(summary_text) && !isEmptyString(summary_text)
  }

  const getFormattedSummaryText = (): JSX.Element => {
    const summaryTextSplitIntoIndividualWords: string[] =
      summary_text.split(" ")
    return (
      <p className="text-center text-Primary-900">
        {summaryTextSplitIntoIndividualWords.map((word: string) =>
          isDynamicNumberPortionRequired(word) ? (
            <span className="font-bold">
              {word === INDEX_OF_FIRST_RESULT_ON_PAGE_PLACEHOLDER &&
                `${getIndexOfFirstResultOnPage()} `}
              {word === INDEX_OF_LAST_RESULT_ON_PAGE_PLACEHOLDER &&
                `${getIndexOfLastResultOnPage()} `}
              {word === NUMBER_OF_RESULTS_FOUND_PLACEHOLDER && `${nbHits} `}
            </span>
          ) : (
            `${word} `
          )
        )}
      </p>
    )
  }

  const isDynamicNumberPortionRequired = (word: string): boolean => {
    const dynamicNumberPortions: string[] = [
      INDEX_OF_FIRST_RESULT_ON_PAGE_PLACEHOLDER,
      INDEX_OF_LAST_RESULT_ON_PAGE_PLACEHOLDER,
      NUMBER_OF_RESULTS_FOUND_PLACEHOLDER,
    ]
    return dynamicNumberPortions.includes(word)
  }

  const getIndexOfFirstResultOnPage = (): number => {
    return max_results_per_page * page + 1
  }

  const getIndexOfLastResultOnPage = (): number => {
    return getIndexOfFirstResultOnPage() - 1 + hits.length
  }

  return (
    <>
      {/*-- we only want to show the pagination bar if there is a search term --*/}
      {isPaginationBarRequired() && (
        <div className="container mx-auto grid grid-cols-2 gap-x-6 md:grid-cols-12">
          <div className="col-span-2 space-y-4 flex flex-col items-center justify-center md:col-span-8 md:col-start-3">
            <Pagination
              classNames={{
                root: "w-fit",
                list: "flex items-center justify-center bg-White border border-Body-Disabled rounded-2xl h-12",
                firstPageItem:
                  "w-full h-full flex items-center justify-center text-Primary-900 border-r border-r-Body-Disabled w-12 hover:underline",
                previousPageItem:
                  "w-full h-full flex items-center justify-center text-Primary-900 border-r border-r-Body-Disabled w-12 hover:underline",
                pageItem:
                  "w-full h-full flex items-center justify-center text-Primary-900 border-r border-r-Body-Disabled w-12 hover:underline",
                nextPageItem:
                  "w-full h-full flex items-center justify-center text-Primary-900 border-r border-r-Body-Disabled w-12 hover:underline",
                lastPageItem:
                  "w-full h-full flex items-center justify-center text-Primary-900 w-12 hover:underline",
                selectedItem: "bg-Primary-900 text-[#fff] hover:no-underline",
              }}
            />
            {isSummaryTextRequired() && getFormattedSummaryText()}
          </div>
        </div>
      )}
    </>
  )
}

export default FAQsSearchPaginationBar
