export const mapStyles = [
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [
      {
        hue: process.env.GATSBY_MAP_LABEL_HUE || '#7B00FF',
      },
      {
        saturation: '28',
      },
      {
        lightness: '17',
      },
      {
        gamma: '0.87',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -11,
      },
      {
        color: process.env.GATSBY_MAP_LANDSCAPE_COLOR || '#D4D7F1',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: -78,
      },
      {
        hue: process.env.GATSBY_MAP_HUE || '#6600FF',
      },
      {
        lightness: -47,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -79,
      },
      {
        color: process.env.GATSBY_MAP_ROAD_COLOR || '#866CD1',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        lightness: 30,
      },
      {
        weight: 1.3,
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        color: process.env.GATSBY_MAP_TRANSIT_HUE || '#5E00FF',
      },
      {
        saturation: -16,
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'all',
    stylers: [
      {
        saturation: -72,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        saturation: '1',
      },
      {
        lightness: '5',
      },
      {
        gamma: '10.00',
      },
      {
        color: process.env.GATSBY_MAP_WATER_COLOUR || '#9986D8',
      },
    ],
  },
];
