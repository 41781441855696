import React from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import { Venue } from '../../../contexts/VenuesContext';
import SecondaryHero from '../../bloks/SecondaryHero/SecondaryHero.blok';
import { valueExists } from '../../../utils/valueExists/valueExists.util';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import { Building } from '../../../contexts/FiltersContext';
import { ButtonProps } from '../../../interfaces/Button.interface';

interface VenueHeroProps extends Blok {
  venue: Venue;
  hero_image: StoryblokAsset;
  cta_label: string;
  image: StoryblokAsset;
  buildings: Building[];
  show_contact_details: any;
  secondary_cta: ButtonProps[];
}

const VenueHero: React.FC<VenueHeroProps> = ({
  venue,
  hero_image,
  cta_label,
  image,
  buildings,
  show_contact_details,
  secondary_cta,
}) => {
  const getVenueAddressAsString = (): string => {
    const addressPartsArray: (string | undefined)[] = [
      venue?.address.line1,
      venue?.address.line2,
      venue?.address.townCity,
      venue?.address.county,
      venue?.address.region,
      venue?.address.postcode,
    ];
    const addressPartsArrayExcludingUndefined: string[] = addressPartsArray.filter((addressPart: string | undefined) =>
      valueExists(addressPart),
    ) as string[];
    const addressPartsString: string = addressPartsArrayExcludingUndefined.join(', ');
    return addressPartsString;
  };

  const getVenueBuildingTypeHeroIcon = (): string | undefined => {
    const venueBuildingType: Building = buildings.filter(
      (building: Building) => building.name === venue?.buildingType.name,
    )[0];
    if (venueBuildingType) return venueBuildingType.heroIcon;
    return undefined;
  };

  const getVenueHeroImage = (): StoryblokAsset | undefined => {
    if (hero_image?.filename) {
      return hero_image;
    } else if (image?.filename) {
      return image;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {venue && (
        <section id="venue-hero">
          <SecondaryHero
            name={venue.name}
            contact_number={venue.contactNumber}
            email={venue.contactEmail}
            address={getVenueAddressAsString()}
            cta_link={venue.links.bookingLink}
            cta_label={cta_label}
            icon={getVenueBuildingTypeHeroIcon()}
            hero_image={getVenueHeroImage()!}
            display_contact_number={Boolean(show_contact_details.find(e => e === 'contact_number'))}
            display_address={Boolean(show_contact_details.find(e => e === 'address'))}
            display_email={Boolean(show_contact_details.find(e => e === 'email'))}
            secondary_cta={secondary_cta}
          />
        </section>
      )}
    </>
  );
};

export default VenueHero;
