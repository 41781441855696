import React from "react"

interface CalendarProps {
  className: string
}

const Calendar: React.FC<CalendarProps> = ({ className }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M2 2H3V0H5V2H13V0H15V2H16C17.11 2 17.99 2.9 17.99 4L18 18C18 19.1 17.11 20 16 20H2C0.9 20 0 19.1 0 18V4C0 2.9 0.9 2 2 2ZM2 18H16V8H2V18ZM2 6H16V4H2V6ZM9 11H4V16H9V11Z" />
    </svg>
  )
}

export default Calendar
