import axios, { AxiosResponse } from "axios";
const baseUrl = `${process.env.GATSBY_API_URL}/jobs`;

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export enum SalaryType {
  Year = 'Year',
  Hour = 'Hour',
}

export interface ISalary {
  ratePer: SalaryType,
  rateLow: number,
  rateHigh: number,
}
export interface IFilteredAd {
  adId: number;
  contractType: string;
  category: string;
  countyBorough: string;
  weeklyHours: string;
  title: string;
  summary: string;
  description?: string;
  postedAt: string;
  expiresAt: string;
  updatedAt: string;
  applicationLink: string;
  salary: ISalary;
  hourlyRate?: string;
}

export interface IAdResponse {
  adId: number,
  title: string,
  reference: string,
  summary: string,
  description?: string,
  portal: {
    fields?: IField[]
    salary: ISalary;
  },
  postedAt: string,
  updatedAt: string,
  expiresAt: string,
  links: {
      self: string,
      applications: string,
      ui?: {
          self: string,
          applications: string
      }
  },
  countryCodeHint?: string,
  salary: ISalary;
}

export interface IField {
  fieldId: number,
  fieldName: string,
  fields?: Record<string, string |number>[],
  value: string,
  valueId: number
}

export enum Filters {
  limit = 'limit', 
  offset = 'offset',
  jobId = 'jobid',
}
export interface IJobsRequest {
  [Filters.limit]?: number;
  [Filters.offset]?: number;
  [Filters.jobId]?: number;
}

export interface IGetAdsResponse {
  items: IAdResponse[];
  totalCount: number;
}

const fieldMap: Record<string, string> = {
  ['Contract Type']: 'contractType',
  ['Category']: 'category',
  ['County/Borough']: 'countyBorough',
  ['Weekly Hours']: 'weeklyHours',
}

export function mapAdsResponseToAd(ad: IAdResponse): IFilteredAd {
  const { fields = [] } = ad.portal || {};
  const adFieldsToShow = ['Category', 'Contract Type', 'County/Borough', 'Weekly Hours'];
  const { title, summary, postedAt, expiresAt, updatedAt, links, adId, portal } = ad;
  const { salary } = portal;
  const { ui } = links;

  const fieldsFiltered = fields.filter((field: IField) => adFieldsToShow.includes(field.fieldName));
 
  const portalFields = fieldsFiltered.reduce((current: Record<string, string>, nextField: IField) => {
    return {
      ...current,
      [fieldMap[nextField.fieldName]]: nextField.value
    }
  }, {});

  return {
    adId, 
    contractType: portalFields.contractType,
    category: portalFields.category,
    countyBorough: portalFields.countyBorough,
    weeklyHours: portalFields.weeklyHours,
    hourlyRate: portalFields.hourlyRate || '',
    salary,
    title, 
    summary, 
    postedAt,
    expiresAt, 
    updatedAt,
    applicationLink: ui!.applications || '',
  }
}

export function mapAdResponseToAd(ad: IAdResponse): IFilteredAd {
  const { fields = [] } = ad.portal || {};
  const adFieldsToShow = ['Category', 'Contract Type', 'County/Borough', 'Weekly Hours'];
  const { title, summary, postedAt, expiresAt, updatedAt, links, adId, description, portal } = ad;
  const { salary } = portal;
  const { ui } = links;

  const fieldsFiltered = fields.filter((field: IField) => adFieldsToShow.includes(field.fieldName));
 
  const portalFields = fieldsFiltered.reduce((current: Record<string, string>, nextField: IField) => {
    return {
      ...current,
      [fieldMap[nextField.fieldName]]: nextField.value
    }
  }, {});

  return {
    adId, 
    contractType: portalFields.contractType,
    category: portalFields.category,
    countyBorough: portalFields.countyBorough,
    weeklyHours: portalFields.weeklyHours,
    title, 
    summary,
    description,
    postedAt,
    expiresAt, 
    updatedAt,
    applicationLink: ui!.applications || '',
    salary: salary,
    hourlyRate: portalFields.hourlyRate,
  }
}

export const getAds = async (params: Record<Filters, string>): Promise<IFilteredAd[]> => {
  const requestUrl = new URL(`${baseUrl}/ads`);

  requestUrl.search = new URLSearchParams(params) as unknown as string;
  
  const ads = await axios.get(`${requestUrl.href}`, 
    defaultOptions
  );
  return ads.data.items.map((job: IAdResponse) => mapAdsResponseToAd(job));
};

export const getAd = async (adId: number): Promise<IFilteredAd> => {
  const requestUrl = new URL(`${baseUrl}/ad/${adId}`);

  requestUrl.search = new URLSearchParams({}) as unknown as string;
  
  const {data: ad} = await axios.get(`${requestUrl.href}`, 
    defaultOptions
  );

  return mapAdResponseToAd(ad);
};

export function showSalaryOrPerHour(ad: ISalary) {
  const { rateLow, rateHigh, ratePer } = ad;
  const rateType = (ratePer === SalaryType.Hour) ? 'per hour' : 'per year';

  const formatRange = (rateLow === rateHigh) ? `£${rateLow}` : `£${rateLow} to £${rateHigh}`;
  
  return `${formatRange} ${rateType}`;
}

