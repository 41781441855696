/*-- npm packages --*/
import React from 'react';
import classNames from 'classnames';

/*-- component atoms --*/
import Image from '../../atoms/Image/Image.atom';
import Button from '../../atoms/Button/Button.component';

/*-- interfaces --*/
import { ServiceCardProps } from '../../../interfaces/VenuePage/Services.interface';

/*-- enums --*/
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';

/*-- assets --*/
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

const ServiceCard: React.FC<ServiceCardProps> = ({
  image,
  heading,
  body,
  primary_cta,
  secondary_cta,
  icon,
  theme,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const ContainerClassNames = classNames('md:flex md:items-stretch');

  const ImageWrapperClassNames = classNames(
    'md:mr-6 lg:mr-8 xl:mr-10 2xl:mr-16 md:min-w-[260px] lg:min-w-[340px] aspect-[16/9] md:aspect-[3/4] lg:aspect-square -mt-4 -mx-4 md:mx-0 md:-mt-0 pb-8 md:pb-0 drop-shadow-2xl md:drop-shadow-none',
  );

  const ImageClassNames = classNames('h-full overflow-hidden rounded-6xl md:rounded-2xl');

  return (
    <div
      className={classNames('rounded-7xl md:overflow-hidden', {
        'bg-Primary-900': theme === 'DARK',
        '!bg-sherpa-blue-900 bg-SP6': theme === 'LIGHT',
        'bg-Secondary-300': theme === 'ALTERNATIVE',
        'text-White': theme !== 'WHITE',
        'bg-White text-Primary-900': theme === 'WHITE',
      })}
    >
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="px-4 pt-4 pb-6 md:pb-10 md:p-6 lg:p-8 2xl:p-10">
          {/*-- content --*/}
          <div className={ContainerClassNames}>
            <div className={ImageWrapperClassNames}>
              <div className={ImageClassNames}>{image && <Image image={image} objectFit="cover" />}</div>
            </div>
            <div>
              <div className="flex items-center pb-4 space-x-3">
                <div className="w-10">
                  {React.isValidElement(icon) ? icon : icon?.filename && <Image image={icon} objectFit="cover" />}
                </div>
                {heading && <p className="font-secondary text-xl lg:text-2xl 2xl:text-3xl font-extrabold">{heading}</p>}
              </div>
              {heading && <div className="border-b-2 border-dashed"></div>}
              <p className="text-base py-6">{body}</p>
              {primary_cta && !!primary_cta.length && primary_cta[0].label && primary_cta[0].link?.cached_url && (
                <Button
                  link={primary_cta[0].link}
                  label={primary_cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.LG}
                  className="w-full md:w-auto"
                ></Button>
              )}

              {secondary_cta &&
                !!secondary_cta.length &&
                secondary_cta[0].label &&
                secondary_cta[0].link?.cached_url && (
                  <Button
                    link={secondary_cta[0].link}
                    label={secondary_cta[0].label}
                    type={ButtonTypes.SECONDARY}
                    size={ButtonSizeTypes.LG}
                    className="inline-block w-full md:w-auto mt-3 md:mt-0"
                  ></Button>
                )}
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </div>
  );
};

export default ServiceCard;
