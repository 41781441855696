import React from "react"
import "../../styles/spinner-dots.css"

const DotsSpinner: React.FC = () => {
  return (
    <div className="spinner-dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default DotsSpinner
