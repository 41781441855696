const getQueryParameters = (key: string): string => {
  const url = new URL(
    typeof window !== "undefined"
      ? window.location.href
      : "https://www.dali-speakers.com/"
  )
  const params = new URLSearchParams(url.search)
  const value = params.get(key) || ""

  return value
}

export default getQueryParameters
