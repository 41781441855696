import React, { useState } from 'react';
import Blok from '../../../interfaces/Storyblok/Blok.interface';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import BackgroundIcons from '../../molecules/BackgroundIcons/BackgroundIcons.molecule';
import { BackgroundPositionTypes } from '../../../interfaces/enums/BackgroundPositionTypes.enum';
import BlokWithBorderIcons from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import StoryblokContentType from '../../../interfaces/Storyblok/StoryblokContentType.interface';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import AccordionItem from './AccordionItem.component';

export interface AccordionItemProps extends StoryblokContentType {
  content: AccordionContent;
}

export interface AccordionContent extends Blok {
  question: string;
  answer: StoryblokRichtext;
  type: string;
}

interface AccordionProps extends BlokWithBorderIcons {
  accordion_items: AccordionItemProps[];
  icon: StoryblokAsset;
}

const Accordion: React.FC<AccordionProps> = ({
  accordion_items,
  icon,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  /*-- state --*/
  const [selectedFaqIndex, setSelectedFaqIndex] = useState<number | undefined>(0);

  const handleFAQWasClickedAction = (index: number): void => {
    setSelectedFaqIndex(index === selectedFaqIndex ? undefined : index);
  };

  return (
    <>
      {!!accordion_items.length && (
        <section>
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className="mx-auto container py-12 md:py-20">
              <BackgroundIcons
                type={BackgroundPositionTypes.TopLeftToBottomRight_Inside}
                icon={icon}
                iconLeftStyle={'-rotate-45 w-[120px] xl:w-[200px]'}
                iconRightStyle={'rotate-45 w-[120px] xl:w-[200px]'}
                rotateRight={true}
              >
                <div className="grid grid-cols-2 gap-x-6 md:grid-cols-12">
                  <ul className="col-span-2 space-y-4 md:col-span-8 md:col-start-3">
                    {accordion_items.map((item: AccordionItemProps, index: number) => (
                      <AccordionItem
                        key={item.uuid}
                        accordionItem={item}
                        index={index}
                        selectedAccordionItemIndex={selectedFaqIndex}
                        AccordionItemWasClicked={() => handleFAQWasClickedAction(index)}
                      />
                    ))}
                  </ul>
                </div>
              </BackgroundIcons>
            </div>
          </BorderIconsFactory>
        </section>
      )}
    </>
  );
};

export default Accordion;
