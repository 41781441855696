import React, { useContext } from 'react';
import LayoutContext from '../../contexts/LayoutContext';
import SEO from '../../interfaces/SEO.interface';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import TemplateProps from '../../interfaces/TemplateProps.interface';
import BreadcrumbBar from '../bloks/BreadcrumbBar/BreadcrumbBar';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';
import Layout from '../utils/Layout/Layout.component';

const PageTemplate: React.FC<TemplateProps> = ({ context, venues, countdown }) => {
  const {
    SEO,
    page_hero,
    page_body,
    page_footer,
    breadcrumb_override,
    has_background,
  }: {
    SEO?: SEO;
    page_hero: Blok[];
    page_body: Blok[];
    page_footer: Blok[];
    breadcrumb_override: string;
    has_background: boolean;
  } = context.content;

  return (
    <BaseDataLayer field_component={context.field_component}>
      <Layout
        countdown={countdown}
        bloks={{ page_hero, page_body, page_footer }}
        breadcrumb_override={breadcrumb_override}
        hasBackground={has_background}
      ></Layout>
    </BaseDataLayer>
  );
};

export default PageTemplate;
