import React, { useState, useEffect, useContext } from 'react';
import TemplateProps from '../../interfaces/TemplateProps.interface';
import Layout from '../utils/Layout/Layout.component';
import Blok from '../../interfaces/Storyblok/Blok.interface';
import SEO from '../../interfaces/SEO.interface';
import BaseDataLayer from '../utils/BaseDataLayer/BaseDataLayer.util';
import VenuesContext from '../../contexts/VenuesContext';

const VenueIndexPageTemplate: React.FC<TemplateProps> = ({ context, venues }) => {
  const venuess = useContext(VenuesContext);
  // const getUpdatedFilteredVenues = async () => {
  //   await pagedVenues(
  //     filterItemMatchingVenues(
  //       locationMatchingVenues(openToThePublicVenues(venues.venues))
  //     )
  //   )
  // }

  // const openToThePublicVenues = (venues: Venue[]): Venue[] => {
  //   return venues.filter((venue: Venue) => venue.isOpenToThePublic())
  // }

  // const locationMatchingVenues = async (venues: Venue[]): Promise<Venue[]> => {
  //   if (isValidPostcode(searchBarInputValue)) {
  //     setIsUpdatingFilteredVenues(true)
  //     const coordinates: Coordinates | undefined =
  //       await getCoordinatesFromPostcode(searchBarInputValue)
  //     setIsUpdatingFilteredVenues(false)
  //     if (coordinates) {
  //       return locationsCloseToPostcodeCoordinates(coordinates, venues)
  //     } else {
  //       return locationsWhereNameIncludesSearchInputValue(venues)
  //     }
  //   } else {
  //     return locationsWhereNameIncludesSearchInputValue(venues)
  //   }
  // }

  // const locationsCloseToPostcodeCoordinates = (
  //   coordinates: Coordinates,
  //   venues: Venue[]
  // ): Venue[] => {
  //   setSearchBarInputValueCoordinates(coordinates)
  //   const venuesThatHaveCoordinates: Venue[] = venues.filter((venue: Venue) =>
  //     venue.hasCoordinates()
  //   )

  //   const venuesThatHaveCoordinatesThatAreWithin20MilesOfSearchedPostcode: Venue[] =
  //     venuesThatHaveCoordinates.filter((venue: Venue) => {
  //       const TWENTY_MILES_IN_METRES: number = 32187
  //       return (
  //         venue.getDistanceFromCoordinates(coordinates) < TWENTY_MILES_IN_METRES
  //       )
  //     })

  //   const sortedVenuesThatHaveCoordinatesThatAreWithin20MilesOfSearchedPostcode: Venue[] =
  //     venuesThatHaveCoordinatesThatAreWithin20MilesOfSearchedPostcode.sort(
  //       (venueOne: Venue, venueTwo: Venue) =>
  //         venueOne.getDistanceFromCoordinates(coordinates) -
  //         venueTwo.getDistanceFromCoordinates(coordinates)
  //     )

  //   return sortedVenuesThatHaveCoordinatesThatAreWithin20MilesOfSearchedPostcode
  // }

  // const locationsWhereNameIncludesSearchInputValue = (
  //   venues: Venue[]
  // ): Venue[] => {
  //   return venues.filter((venue: Venue) => {
  //     if (venue.getVenueSiteDetails() && venue.getVenueSiteDetails()?.name) {
  //       return venue
  //         .getVenueSiteDetails()
  //         .name?.toLocaleLowerCase()
  //         .includes(searchBarInputValue.toLocaleLowerCase())
  //     } else {
  //       return false
  //     }
  //   })
  // }

  // const filterItemMatchingVenues = async (
  //   venues: Promise<Venue[]>
  // ): Promise<Venue[]> => {
  //   if (selectedFilters.length > 0) {
  //     const selectedFilterIds: string[] = selectedFilters.map(
  //       (selectedFilter: FilterableItem) =>
  //         selectedFilter.content.filterable_item
  //     )
  //     return (await venues).filter(
  //       (venue: Venue) =>
  //         venue
  //           .getFilterables()
  //           .filter((filterable: string) =>
  //             selectedFilterIds.includes(filterable)
  //           ).length > 0
  //     )
  //   } else {
  //     return await venues
  //   }
  // }

  // const pagedVenues = async (venues: Promise<Venue[]>): Promise<Venue[][]> => {
  //   const pagedVenues: Venue[][] = (await venues).reduce(
  //     (newArray: Venue[][], venue: Venue, index: number) => {
  //       const pageIndex: number = Math.floor(
  //         index / numberOfRequiredVenuesPerPage
  //       )
  //       if (!newArray[pageIndex]) {
  //         newArray[pageIndex] = [] // start a new chunk
  //       }
  //       newArray[pageIndex].push(venue)
  //       return newArray
  //     },
  //     []
  //   )

  //   setFilteredVenues(pagedVenues)

  //   return pagedVenues
  // }

  // /*----------- USE STATE -----------*/
  // const [
  //   isInitialLocalStorageCheckComplete,
  //   setIsInitialLocalStorageCheckComplete,
  // ] = useState<boolean>(false)
  // const [numberOfRequiredVenuesPerPage, setNumberOfRequiredVenuesPerPage] =
  //   useState<number>(8)
  // const [selectedFilters, setSelectedFilters] = useState<FilterableItem[]>([])
  // const [searchBarInputValue, setSearchBarInputValue] = useState<string>("")
  // const [searchBarInputValueCoordinates, setSearchBarInputValueCoordinates] =
  //   useState<Coordinates | undefined>(undefined)
  // const [filteredVenues, setFilteredVenues] = useState<Venue[][]>([])
  // const [isUpdatingFilteredVenues, setIsUpdatingFilteredVenues] =
  //   useState<boolean>(false)
  // const [selectedMapVenue, setSelectedMapVenue] = useState<Venue | undefined>(
  //   undefined
  // )
  // const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)

  // /*----------- USE EFFECTS -----------*/
  // useEffect(() => {
  //   /*--
  //     wait for the initial local storage check to
  //     become complete, and at this stage we will do
  //     an initial updatingOfTheFilteredVenues
  //   --*/
  //   getUpdatedFilteredVenues()
  // }, [isInitialLocalStorageCheckComplete])

  // useEffect(() => {
  //   /*--
  //     We don't want to trigger a venue filter
  //     until the initial localStorage check
  //     complete. See FindUsPageFilters.component.tsx
  //   --*/
  //   if (isInitialLocalStorageCheckComplete) {
  //     getUpdatedFilteredVenues()
  //   }
  // }, [selectedFilters])

  // const updateFilteredVenues = async (needsHardReset: boolean = false) => {
  //   getUpdatedFilteredVenues()
  //   setCurrentPageIndex(0)
  //   if (needsHardReset) {
  //     if (selectedFilters.length > 0) {
  //       setSelectedFilters([])
  //     }
  //     setSelectedMapVenue(undefined)
  //     setSearchBarInputValueCoordinates(undefined)
  //   }
  // }

  // const reset = (): void => {
  //   setSearchBarInputValue("")
  //   setSearchBarInputValueCoordinates(undefined)
  //   setCurrentPageIndex(0)
  //   setSelectedMapVenue(undefined)
  //   setSelectedFilters([])
  // }

  const {
    SEO,
    page_hero,
    page_body,
    page_footer,
  }: {
    SEO?: SEO;
    page_hero: Blok[];
    page_body: Blok[];
    page_footer: Blok[];
  } = context.content;

  return (
    // <FindUsPageContext.Provider
    //   value={{
    //     isInitialLocalStorageCheckComplete: isInitialLocalStorageCheckComplete,
    //     searchBarInputValue: searchBarInputValue,
    //     searchBarInputValueCoordinates: searchBarInputValueCoordinates,
    //     filteredVenues: filteredVenues,
    //     isUpdatingFilteredVenues: isUpdatingFilteredVenues,
    //     selectedMapVenue: selectedMapVenue,
    //     numberOfRequiredVenuesPerPage: numberOfRequiredVenuesPerPage,
    //     currentPageIndex: currentPageIndex,
    //     selectedFilters: selectedFilters,
    //     setIsInitialLocalStorageCheckComplete(value) {
    //       setIsInitialLocalStorageCheckComplete(value)
    //     },
    //     updateSearchBarInputValue(value) {
    //       setSearchBarInputValue(value)
    //     },
    //     updateFilteredVenues() {
    //       getUpdatedFilteredVenues()
    //     },
    //     updateSelectedMapVenue(selectedMapVenue) {
    //       setSelectedMapVenue(selectedMapVenue)
    //     },
    //     updateNumberOfRequiredVenuesPerPage(numberOfRequiredVenuesPerPage) {
    //       setNumberOfRequiredVenuesPerPage(numberOfRequiredVenuesPerPage)
    //     },
    //     updateCurrentPageIndex(newPageIndex) {
    //       setCurrentPageIndex(newPageIndex)
    //     },
    //     updateSelectedFilters(selectedFilters) {
    //       setSelectedFilters(selectedFilters)
    //     },
    //     searchBarPrimaryButtonWasClicked() {
    //       updateFilteredVenues(true)
    //     },
    //     reset() {
    //       reset()
    //     },
    //   }}
    // >
    <BaseDataLayer field_component={context.field_component}>
      <Layout bloks={{ page_hero, page_body, page_footer }}></Layout>
    </BaseDataLayer>
    // </FindUsPageContext.Provider>
  );
};

export default VenueIndexPageTemplate;
