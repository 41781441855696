import React, { useState, useContext } from "react"
import FindUsPageVenueCardConfigProps from "../../../interfaces/FindUsPage/FindUsPageVenueCardConfig.interface"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import FilterableItem from "../../../interfaces/Storyblok/StoryblokFilterableItem.interface"
import StoryblokHeadingAndRichText from "../../../interfaces/Storyblok/StoryblokHeadingAndRichText.interface"
import FindUsFilters from "./FindUsFilters/FindUsFilters.component"
import FindUsResults from "./FindUsResults/FindUsResults.component"
import FindUsPageContext from "../../../contexts/FindUsPage.context"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

export interface FilterItemSection extends Blok {
  section_title_label: string
  section_filter_items: FilterableItem[]
}

export interface FindUsFilterSideBarConfigProps extends Blok {
  section_title: string
  filter_sections: FilterItemSection[]
}

export interface FindUsResultsConfigProps extends Blok {
  top_bar_content: StoryblokHeadingAndRichText[]
  title_and_filters_bar_title: string
  card_list_title: string
  card_config: FindUsPageVenueCardConfigProps[]
  results_not_found: StoryblokHeadingAndRichText[]
  results_per_page: number
}

interface FindUsFilterAndResultsProps extends BlokWithBorderIcons {
  clear_all_button_label: string
  filter_side_bar_config: FindUsFilterSideBarConfigProps[]
  results_config: FindUsResultsConfigProps[]
}

const FindUsFilterAndResults: React.FC<FindUsFilterAndResultsProps> = ({
  clear_all_button_label,
  filter_side_bar_config,
  results_config,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [
    isSmallerDeviceFilterPopupRequired,
    setIsSmallerDeviceFilterPopupRequired,
  ] = useState<boolean>(false);

  return (
    <section className="bg-SP7">
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto grid grid-cols-2 gap-x-6 pt-16 pb-10 md:grid-cols-12 md:py-20">
          <FindUsFilters
            filter_side_bar_config={filter_side_bar_config}
            clear_all_button_label={clear_all_button_label}
            isSmallerDeviceFilterPopupRequired={
              isSmallerDeviceFilterPopupRequired
            }
            closeButtonClicked={() =>
              setIsSmallerDeviceFilterPopupRequired(false)
            }
          />
          <FindUsResults
            results_config={results_config}
            clear_all_button_label={clear_all_button_label}
            filterButtonClicked={() =>
              setIsSmallerDeviceFilterPopupRequired(
                !isSmallerDeviceFilterPopupRequired
              )
            }
          />
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default FindUsFilterAndResults
