import React, { useState, useEffect } from "react"
import Close from "../../../assets/svgs/Close.svg"

interface ModalProps {
  toggleModal: boolean
  onClose: (state: boolean) => void
  children: any
}

const Modal: React.FC<ModalProps> = ({ toggleModal, onClose, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(toggleModal)

  useEffect(() => {
    setIsOpen(toggleModal)
  }, [toggleModal])

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto"
  }, [isOpen])

  return (
    <div
      className={`${
        !isOpen && "opacity-0 pointer-events-none"
      } fixed h-screen w-screen z-30 top-0 left-0 bg-Opacity9 cursor-pointer py-0 transition-all duration-500`}
      onClick={() => {
        onClose(false)
      }}
    >
      <div className="container mx-auto flex justify-center items-center h-full w-full py-4">
        <div
          className="absolute top-6 right-7 cursor-pointer hover:scale-[1.2] transition-all duration-500"
          onClick={() => {
            onClose(false)
          }}
        >
          <Close className="text-[#FAE80B] w-6 h-6" />
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
