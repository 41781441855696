import React from "react"
import { SVG } from "../../interfaces/SVG.interface"

const Linkedin: React.FC<SVG> = ({ className }) => {
  return (
    <svg
      width="28"
      height="27"
      aria-labelledby="linkedin"
      className={`transfrom fill-current ${className}`}
    >
      <path d="M6.774 8.866H1.268v17.56h5.506V8.866ZM21.67 8.485a9.881 9.881 0 0 0-.634-.05c-3.084-.127-4.822 1.7-5.43 2.486-.166.216-.242.343-.242.343V8.917h-5.266v17.56h5.507V18.23c0-1.193-.089-2.462.508-3.553.507-.914 1.42-1.37 2.448-1.37 3.046 0 3.109 2.753 3.109 3.007v10.239h5.506V15.096c0-3.921-1.991-6.23-5.506-6.61ZM4.02 6.595A3.197 3.197 0 1 0 4.02.2a3.197 3.197 0 0 0 0 6.395Z" />
    </svg>
  )
}

export default Linkedin
