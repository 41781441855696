import React from "react"
import { SVGWithPaths } from "../../interfaces/SVG.interface"

const Instagram: React.FC<SVGWithPaths> = ({ primaryFill, secondaryFill }) => {
  return (
    <svg aria-labelledby="instagram" width="24" height="23">
      <path
        d="M19.519 22.67H4.48a3.785 3.785 0 0 1-3.775-3.775V3.858A3.785 3.785 0 0 1 4.48.082H19.52a3.785 3.785 0 0 1 3.775 3.776v15.037a3.773 3.773 0 0 1-3.775 3.776Z"
        className={`transfrom fill-current ${primaryFill}`}
      />
      <path
        d="M12.005 17.179a5.76 5.76 0 0 1-4.1-1.698 5.76 5.76 0 0 1-1.698-4.1 5.76 5.76 0 0 1 1.697-4.1 5.76 5.76 0 0 1 4.1-1.698c1.55 0 3.006.603 4.1 1.698a5.76 5.76 0 0 1 1.698 4.1 5.76 5.76 0 0 1-1.697 4.1 5.777 5.777 0 0 1-4.1 1.698Zm0-10.362a4.567 4.567 0 0 0-4.564 4.564 4.567 4.567 0 0 0 4.564 4.564 4.567 4.567 0 0 0 4.564-4.564c-.01-2.514-2.05-4.564-4.564-4.564ZM18.933 5.393a1.113 1.113 0 1 0 0-2.227 1.113 1.113 0 0 0 0 2.227Z"
        className={`transfrom fill-current ${secondaryFill}`}
      />
    </svg>
  )
}

export default Instagram
