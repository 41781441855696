import isJSON from "../../../utils/isJSON/isJSON.util"
import { FAQsSearchProps } from "./FAQsSearchProps.interface"

const getModelledFAQsSearchProps = (content: any): FAQsSearchProps => {
  const parsedContent: any = isJSON(content) ? JSON.parse(content) : content

  return {
    seo: parsedContent.SEO,
    hero_banner_config: {
      title: parsedContent.hero_title,
      description: parsedContent.hero_description,
      is_search_functionality_enabled:
        parsedContent.is_search_functionality_enabled,
      input_placeholder: parsedContent.search_bar_input_placeholder,
    },
    results_list_config: {
      background_icon: parsedContent.background_icon,
      title: parsedContent.search_results_title,
      no_results_found_config: {
        title: parsedContent.no_results_found_title,
        description: parsedContent.no_results_found_description,
      },
    },
    pagination_bar_config: {
      max_results_per_page: parseInt(parsedContent.max_results_per_page),
      summary_text: parsedContent.summary_text,
    },
  }
}

export default getModelledFAQsSearchProps
