import classNames from "classnames"
import React, { useState } from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { StoryblokIconListItem } from "../../../interfaces/Storyblok/StoryblokIconList.interface"
import Image from "../../atoms/Image/Image.atom"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface ImageAndTitledListProps extends BlokWithBorderIcons {
  image: StoryblokAsset
  title: string
  list: StoryblokIconListItem[]
}

const ImageAndTitledList: React.FC<ImageAndTitledListProps> = ({
  image,
  title,
  list,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  const transitionClassNames = "transition-all duration-700 ease-in-out"

  const verticalStackedClassNames = "col-span-2 md:col-span-10 md:col-start-2"

  const standardFlexingClassNames = "flex items-center justify-start"

  const leftColumnClassNames = classNames(
    `${transitionClassNames} ${verticalStackedClassNames} ${standardFlexingClassNames} xl:col-span-6 xl:col-start-1`,
    {
      "opacity-0 translate-y-20": !didBecomeVisible,
      "opacity-100 translate-y-0": didBecomeVisible,
    }
  )

  const rightColumnClassNames = classNames(
    `${verticalStackedClassNames} ${standardFlexingClassNames} xl:col-span-6 xl:col-start-7 2xl:col-span-5 2xl:col-start-8`,
    {}
  )

  const listTitleClassNames = classNames(
    `${transitionClassNames} opacity-0 translate-y-20 text-2xl lg:text-3xl`,
    {
      "opacity-100 translate-y-0": didBecomeVisible,
    }
  )

  const getListItemClassNames = (index: number): string => {
    return classNames(
      "flex items-center justify-start space-x-4 transition-all duration-700 ease-in-out opacity-0 translate-y-20",
      {
        "delay-100": index === 0,
        "delay-200": index === 1,
        "delay-300": index === 2,
        "delay-400": index === 3,
        "delay-500": index === 4,
        "delay-600": index === 5,
        "delay-700": index === 6,
        "delay-800": index === 7,
        "delay-900": index === 8,
        "delay-1000": index === 9,
        "delay-1100": index === 10,
        "delay-1200": index === 11,
        "delay-1300": index === 12,
        "opacity-100 translate-y-0": didBecomeVisible,
      }
    )
  }

  return (
    <AnimateInComponent didBecomeVisible={() => setDidBecomeVisible(true)}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto py-12 grid grid-cols-2 gap-y-10 md:py-16 md:grid-cols-12 xl:gap-x-6 xl:gap-y-0 2xl:pb-20">
          <div className={leftColumnClassNames}>
            <div className="w-full aspect-[3/2] rounded-6xl overflow-clip md:aspect-[16/9] xl:aspect-[3/4]">
              <Image image={image} objectFit="cover" />
            </div>
          </div>
          <div className={rightColumnClassNames}>
            <div className="space-y-10 font-montserrat font-extrabold text-Primary-900">
              <h3 className={listTitleClassNames}>{title}</h3>
              {!!list.length && (
                <ul className="space-y-8">
                  {list.map(
                    (listItem: StoryblokIconListItem, index: number) => (
                      <li
                        key={listItem._uid}
                        className={getListItemClassNames(index)}
                      >
                        <div className="w-14 h-14">
                          <Image image={listItem.icon} objectFit="cover" />
                        </div>
                        <p className="text-lg lg:text-xl flex-1">
                          {listItem.label}
                        </p>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </AnimateInComponent>
  )
}

export default ImageAndTitledList
