import React from "react"
import ReactPlayer from "react-player"

export interface YoutubeVideProps {
  video_id: string
  autoplay: boolean
  videoIsPlaying?: boolean
  loop?: boolean
  isForcedFullScreenRequired?: boolean
}

const YoutubeVideo: React.FC<YoutubeVideProps> = ({
  video_id,
  autoplay,
  videoIsPlaying = false,
  loop = false,
  isForcedFullScreenRequired = false,
}) => {
  const config = {
    youtube: {
      playerVars: { showinfo: 0, disablekb: 1 },
    },
  }

  return (
    <>
      {video_id && (
        <div
          className={`w-full h-full ${
            isForcedFullScreenRequired && "relative pt-[56.25%]"
          } ${autoplay && "pointer-events-none"}`}
        >
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${video_id}`}
            width="100%"
            height="100%"
            style={{ objectFit: "fill" }}
            config={config}
            playing={autoplay ? true : videoIsPlaying}
            loop={loop}
            muted={autoplay}
            controls={!autoplay}
            className={`${
              isForcedFullScreenRequired && "absolute top-0 left-0"
            }`}
          />
        </div>
      )}
    </>
  )
}

export default YoutubeVideo
