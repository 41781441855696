import React from "react"
import FilterableItem from "../../../../interfaces/Storyblok/StoryblokFilterableItem.interface"
import { FilterItemSection } from "../FindUsFilterAndResults.blok"
import FindUsFilterSectionItem from "./FindUsFilterSectionItem.component"

interface FindUsFilterSectionProps {
  filter_section: FilterItemSection
}

const FindUsFilterSection: React.FC<FindUsFilterSectionProps> = ({
  filter_section,
}) => {
  return (
    <>
      {!!filter_section.section_filter_items && (
        <li className="py-8 border-b border-b-Body-Disabled">
          {filter_section.section_title_label && (
            <h4>{filter_section.section_title_label}</h4>
          )}

          <ul className="space-y-4">
            {filter_section.section_filter_items.map(
              (filterable_item: FilterableItem) => (
                <FindUsFilterSectionItem
                  key={filterable_item.id}
                  filterable_item={filterable_item}
                />
              )
            )}
          </ul>
        </li>
      )}
    </>
  )
}

export default FindUsFilterSection
